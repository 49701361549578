.fetch-region-content {
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    line-height: 150%;
}



.fetch-region-photo {
    width: auto;
    overflow: auto;
    padding: 0 0 4rem 0;
}

.gallery-container a {
    text-decoration: underline;
}

#region-container {
    margin-top: 3rem;
}

.region-article-photo-one-image {
    width: 100%;
    /* height: 45vh; */
    object-fit: cover;
    float: left;
    /* margin-right: 2%; */
    overflow: auto;
    margin-bottom: 2vh;
}

/*For all*/

.fetch-region-photo-one {
    width: 100%;
}

.hide-250px-top {
    margin-top: -250px;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .fetch-region-photo-one, .content-paragraph {
        width: 100%;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {


    .fetch-region-photo-one, .content-paragraph {
        width: 100%;
    }
}

