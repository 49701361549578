

@media screen and (min-width: 0px) {


    .what-is-mat-header-v2-transition {
        width: 20px;
        border: solid 0.5px #3B3B3B;
        margin: 24px 0;
    }

    .how-it-works-subheader-right u {
        cursor: pointer;
    }

    audio::-webkit-media-controls-timeline,
    video::-webkit-media-controls-timeline {
        display: none;
    }
    audio::-webkit-media-controls,
    video::-webkit-media-controls {
        display: none;
    }

    .review-transition {
        width: 100%;
        overflow: auto;
        text-align: center;
        text-transform: uppercase;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
    }


    .review-transition-stars-container {
        width: 250px;
        overflow: auto;
        margin: 0 calc( 50% - 125px);
    }

    .review-transition-stars-number {
        width: auto;
        float: left;
        overflow: auto;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 48px */
        letter-spacing: 3.2px;
        text-transform: uppercase;
    }

    .review-transition-stars {
        width: 181px;
        overflow: auto;
        float: right;
    }

    .review-transition-star {
        float: right;
        width: 30px;
        margin: 7.5px 2.5px;
    }

    .review-transition-stars-desc {
        width: 100%;
        overflow: auto;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


















    
    .review-transition-button-left {
        width: auto;
        overflow: auto;
    }

    .testimonial-subscript-stars {
        width: 90.5px;
        height: 15px;
        margin-top: 5px;
    }


    .review-transition-logo {
        width: 100%;
        overflow: auto;
    }

    .support-arrow-arrow-v2 {
        width: 16px;
        margin-left: 7px;
        height: 12px;
        margin-top: 2.5px;
        cursor: pointer;
        transform: rotate(270deg);
    }


    .support-arrow-arrow-v2-inverse {
        width: 16px;
        margin-left: 7px;
        height: 12px;
        margin-top: 2.5px;
        transform: rotate(90deg);
        cursor: pointer;
    }


    .eapps-instagram-testimonials-item {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .eapps-instagram-testimonials-item eapps-instagram-testimonials-item-loaded {
        flex-basis: 100% !important;
    }

    .eapps-instagram-testimonials-item-empty {
        background: #FAF2ED !important;
    }

    .landing-page-container-version2 {
        /* Secondary/Stone Blue */

        background: #3F5C6B;

        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .eapps-instagram-testimonials-item-text-container {
        display: none !important;
    }

    .instagram-scroller::-webkit-scrollbar {
        display: none;
    }

    .eapps-instagram-testimonials-item-classic {
        background: #FAF2ED !important;
    }

    .instagram-controller-container {
        background: #78A891;
        overflow-x: hidden;
    }

    .instagram-controller-container::-webkit-scrollbar {
        display: none;
    }

    .eapps-instagram-testimonials-item-stars-container {
        display: none !important;
    }

    .eapps-instagram-testimonials-item-image-container {
        max-height: 352px !important;
    }

    .eapps-instagram-testimonials-item {
        border: none !important;
    }

    .eapps-instagram-testimonials-posts-load-more {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: static;
        width: 193px !important;
        height: 52px !important;


        /* Primary/Soft Black */

        background: #3B3B3B !important;
        border-radius: 30px !important;

        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 13px !important;
        line-height: 150% !important;
        text-transform: uppercase;

        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;

        color: white;
    }


    .instagram-scroller-header-v2 {
        float: left;
    }




}







