
@media screen and (min-width: 0px) {

    h1 {
        font-size: 16px;
        font-weight: 600;
    }

    h2 {
        font-size: 16px;
        font-weight: 600;
    }

    .visit-ikea-page {
        width: 100%;
        overflow: auto;
        text-align: center;
        font-size: 12px;
        color: #72696A;
        margin-bottom: 50px;
    }

    th u {
        cursor: pointer;
    }

    .blog-article-photo-description {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 2;
        color: #3B3B3B;
    }

    .blog-content-photo {
        width: 100%;
        overflow: auto;
        border-radius: 5px;
    }

    .blog-content-photo-small {
        width: 50%;
        margin: 0 23%;
        overflow: auto;
        border-radius: 5px;
    }

    .visit-custom-mat-button {
        width: auto;
        margin: 40px auto;
        display: table;
    }

    .blog-app-listing-item-container {
        margin-bottom: 30px;
        display: inline-block;
    }



    .carousel img {
        border-radius: 5px;
    }

    .carousel .control-dots .dot {
        width: 10px !important;
        height: 10px !important;
        display: inline-block !important;
        border-radius: 50% !important;
        background: #cccccc !important;
    }


    .carousel .control-dots .dot.selected {
        background: rgb(41, 41, 41) !important;
    }



    .carousel-read-more-button {
        cursor: pointer;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        background-color: rgb(41, 41, 41);
        height: 44px;
        width: auto;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        padding: 0px 27px;
        border: 0px;
        transition: all 0.3s ease 0s;
        border-radius: 3px;
        margin-bottom: 25px;
    }
    




    .blog-carousel-photo {
        width: 100%;
        overflow: auto;
        object-fit: initial;
    }



    .blog-listing-text-section {
        padding: 65px 30px 30px 30px;
        background: rgb(247, 247, 247);
        margin-top: -10px;
    }

    .blog-app-listing-subject-sub {
        font-size: 15px;
        font-weight: 400;
        color: rgb(94, 94, 94);
        line-height: 2;
        margin-bottom: 20px;
        text-align: center;
    }



    .blog-app-listing-read-more-button:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(41, 41, 41);
        transition: all 0.15s ease-in-out 0s;
    }

    .blog-app-listing-item-container {
        border-radius: 5px;
        width: auto;
        overflow: auto;
        display: inline-block;
    }





    .carousel-subject-sub {
        margin-bottom: 20px;
        color: rgb(94, 94, 94);
        line-height: 2;
    }

/*    .carousel .control-dots {
        margin-bottom: -10px;
    }*/


}


@media screen and (min-width: 0px) and (max-width: 769px) {


    .quantity-change-div-button {
        display: none;
    }

    .quantity-change-div {
        width: 100%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 100%;
        float: left;
    }


    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
    }

    .gallery-wall-content-left {
        float: left;
        width: 95%;
        max-height: 600px;
        line-height: 1.5;
        margin-bottom: 40px;
    }

    .gallery-wall-content-right {
        float: left;
        width: 95%;
        max-height: 800px;
        padding: 2.5% 0;
        line-height: 1.5;

    }
    
    .gallery-wall-content-image {
        width: 100%;
        overflow: auto;
    }

    .blog-carousel-photo {
        max-height: 225px;
        object-fit: cover !important;
    }

    .design-guide-illustration-video {
        width: 100%;
        height: 200px;
    }

    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 1;
        grid-column-gap: 32px;
        column-gap: 32px;
    }
    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }

    .blog-app-listing-subject-head {
        font-size: 24px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }


    .blog-app-container {
        width: auto;
        padding: 30px;
        margin-top: 90px;
    }

    .carousel-subject {
        text-align: center;
        width: auto;
        padding: 20px 30px 60px;
    }

    .carousel-subject-head {
        font-size: 24px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .blog-app-listing-col-left, .blog-app-listing-col-right {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);
        font-size: 14px;

    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .quantity-change-div-button {
        display: none;
    }


    .quantity-change-div {
        width: 100%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 30%;
        float: right;
    }

    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
        line-height: 1.5;

    }

    .gallery-wall-content-left {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%
    }

    .gallery-wall-content-right {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%
    }

    .blog-carousel-photo {
        max-height: 400px;
        object-fit: cover !important;
    }

    .design-guide-illustration-video {
        width: 100%;
        height: 400px;
    }


    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 2;
        grid-column-gap: 32px;
        column-gap: 32px;
    }

    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }


    .blog-app-container {
        width: auto;
        padding: 30px;
        margin-top: 140px;
    }

    .carousel-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .carousel-subject-sub {
        font-size: 15px;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);
        font-size: 14px;

    }

    .blog-app-listing-col-left {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-col-right {
        width: 47.5%;
        margin-left: 2.5%;
        overflow: auto;
        float: right;
    }

    .carousel-subject {
        width: auto;
        text-align: center;
        margin: 30px 120px;
        padding-bottom: 5px;
    }

    .blog-app-listing-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }
}

/*External Monitor*/

@media screen and (min-width: 1100px) {

    .quantity-change-div {
        width: 50%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 30%;
        float: right;
        margin-top: -15px;
    }

    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
        line-height: 1.5;

    }

    .gallery-wall-content-left {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%;
    }

    .gallery-wall-content-right {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%;
    }

    .blog-carousel-photo {
        max-height: 400px;
        object-fit: cover !important;
    }



    .design-guide-illustration-video {
        width: 100%;
        height: 780px;
    }

    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 2;
        grid-column-gap: 32px;
        column-gap: 32px;
    }

    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }

    .carousel-subject {
        width: auto;
        text-align: center;
        margin: 30px 120px;
        padding-bottom: 5px;
    }

    .blog-app-listing-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }

    .carousel-subject-head {
        font-size: 48px;
        line-height: 1.5;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .blog-app-container {
        width: 1140px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 150%;
        color: rgb(94, 94, 94);
        margin: 0 calc(50% - 570px);
        padding: 30px 0;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);

    }

    .blog-app-listing-col-left {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-col-right {
        width: 47.5%;
        margin-left: 2.5%;
        overflow: auto;
        float: right;
    }

    /*.carousel.carousel-slider .control-arrow {*/
    /*    margin-top: 310px !important;*/
    /*}*/


}