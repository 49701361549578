


.important-action-button:hover {
    background-color: white;
    color: #3B3B3B;
    /*border-color: #f59276;*/
    -webkit-transition: border-color .35s ease-out,color .25s ease-out;
    transition: border-color .35s ease-out,color .25s ease-out;
    -webkit-transition: background-color .35s ease-out,color .25s ease-out;
    transition: background-color .35s ease-out,color .25s ease-out;
    cursor: pointer;
}