.order-summary-item-container {
    width: calc(100% - 8% - 0.4rem);
    overflow-y: auto;
    padding: 2% 4% 6% 4%;
    background: #FAFAFA;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin: 2vw 1% 2rem 1%;
    border-radius: 0.2rem;
}

.float-left-section, .float-right-section {
    width: auto;
    overflow-y: auto;
}

.float-left-section {
    float: left;
}

.float-right-section {
    float: right;
}

.order-summary-subsection-container {
    width: 100%;
    overflow-y: auto;
}

.order-summary-item-breakdown {
    border-bottom: solid 0.05rem;
    border-bottom: 0.5rem;
}

.order-summary-indent {
    padding-left: 1rem;
    color: #8c8c8c;
}

.order-summary-second-mat-margin {
    margin-top: 1rem;
}

.order-summary-quantity-input-summary {
    width: 2.5rem;
    text-align: center;
    border: solid 0.05rem darkgrey;
    border-radius: 0.3rem;
    margin-left: 0.25rem;
    color: #514945;
}

.order-summary-checkout-container {
    width: 100%;
    overflow: auto;
    margin-top: 0.5rem;
    border-top: solid 0.05rem;
}

.delete-button {
    /*padding: 0.25rem 0.5rem;*/
    /*border: solid 0.05rem;*/
    /*border-radius: 0.25rem;*/
    /*padding: 0.25rem 0.5rem;*/
    /*border: solid 0.05rem;*/
    /*border-radius: 0.25rem;*/
    padding-top: 0.1rem;
    font-weight: 600;
    cursor: pointer;
}

.order-summary-remove-item-container {
    width: 100%;
    overflow-y: auto;
}

.capitalize {
    text-transform: capitalize;
}

.currency-container {
    color: #8c8c8c;
    margin-right: 0.2rem;
}

.currency-information {
    font-size: 0.8rem;
    line-height: 2;
    color: #8c8c8c;
}

.currency-information a {
    text-decoration: underline;
    color: #8c8c8c;
}

.title {
    color: cadetblue;
    padding-bottom: 2px;
}

.check {
    display: inline;
}

.check:after {
    content: "\2714";
    color: limegreen;
    font-weight: bold;
    font-size: 18px;
}

.continue-shopping-description-text {
    width: 100%;
    text-align: right;
    font-size: 0.8rem;
    padding: 0.5rem 0;
}

.cross-out {
    text-decoration: line-through;
}