@media screen and (min-width: 769px) and (max-width: 1100px) {

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        overflow-y: hidden;


        /*Black Friday Code Below*/

        background: black;

        /* Inside Auto Layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .notice-bar-version2, .notice-bar-version2-fr {
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        overflow-y: hidden;

        background: #3F5C6B;

        /* Inside Auto Layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .landing-page-faq {
        width: 50%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
    }

    .sort-by-frame-size-button-selected {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .event-message-version2, .event-message-version2-fr {
        width: 80%;
        overflow: auto;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        margin: 4px 10%;
        font-weight: 400;
    }



    .inspiration-post-moblet {
        width: 100%;
        overflow-y: auto;
        display: inline-block;
    }

    .landing-page-review-transition-ending-button-v3 {
        width: 100%;
        overflow: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-transition-ending-header-v3 {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        position: static;
        width: 600px;
        align-items: center;
        text-align: left;
        letter-spacing: 0.1em;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 calc(50% - 300px) 64px;
    }

    .transition-ig-feed-top-right {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        color: #666161;
        text-align: left;
        margin-bottom: 24px;
    }

    .transition-ig-feed-bottom {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .transition-ig-feed-bottom-img {
        width: 100%;
        overflow: auto;
        object-fit: cover;
    }

    .landing-page-review-transition-ending-v3{
        position: static;
        width: 655px;
        height: auto;
        left: 392px;
        top: 128px;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        background: #78A891;
        padding: 128px calc( 50% - 327.5px);
    }


    .landing-page-bottom-cover {
        background: #FAF2ED;
        padding: 64px 0;
        width: 100%;
        overflow: auto;
    }

    .landing-page-bottom {
        text-align: center;
        /* Header 4 */
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-highlight {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
        letter-spacing: 1.8px;
        text-transform: capitalize;
    }

    .navbar-top-button-mobile {
        width: auto;
        float: left;
        text-align: left;
        margin-bottom: 23px;
        text-transform: uppercase;
        color: #3B3B3B;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 20px;
        max-width: 200px;
    }


    .navbar-top-button-chevron {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        margin-right: 22.5px;
        margin-top: 27.5px;
    }


    .navbar-top-button-mobile-menu {
        width: 100%;
        overflow: auto;
        margin-bottom: 10px;
    }



    .mobile-navbar-open-wide-no-display {
        display: none;
        /*margin-right: -280px;*/
        transition: margin 1000ms ease-in-out;

    }

    .mobile-navbar-open-wide {
        width: 100%;
        height: 100vh;
        overflow: auto;
        z-index: 120;
        position: fixed;
        background: rgba(84, 84, 84, 0.50);
        backdrop-filter: blur(2.5px);
        top: 0;
    }



    .navbar-top-button-subheader {
        margin: 18px 20px;
        max-width: 200px;
        color: #686868;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: capitalize;
    }

    .navbar-mobile-header-indicator {
        width: 75%;
        float: left;
        overflow: auto;
    }

    .navbar-mobile-header-indicator-img {
        width: 52px;
        height: 52px;
        position: relative;
        float: left;
    }

    .precut-mat-container {
        margin-top: 60px;
        padding: 10%;
    }

    .navbar-mobile-header-indicator-text {
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        width: auto;
        overflow: auto;
        margin-top: 15px;
        float: left;
    }

    .navbar-top-button-header {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        margin: 30px 20px 12px;
    }



    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }


    .mobile-navbar-options-container {
        width: 273px;
        overflow: auto;
        z-index: 100;
        background: white;
        float: right;
        margin-left: calc( 100% - 273px);
        position: relative;
        -webkit-animation: drawer 100ms;
        animation: mobileDrawer 100ms;
    }

    .mobile-nav-head-section {
        width: 100%;
        overflow: auto;
    }

    .mobile-nav-close-icon {
        width: 34px;
        height: 34px;
        float: right;
        padding: 9px;
        margin-right: 8px;
    }

    @keyframes mobileDrawer {
        0% {margin-right: -280px;}
        100% {margin-right: 0px;}
    }
    @-webkit-keyframes mobileDrawer {
        0% {margin-right: -280px;}

        100% {margin-right: 0px;}
    }

    .mobile-navbar-options-menu {
        color: #F59276;
        margin: 5vh 0 8.125vw 0;
        font-size: 5vw;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mobile-navbar-closeout-button {
        width: 15vw;
        height: 15vw;
        color: #726969;
        margin-top: 3vw;
        float: right;
        position: fixed;
        top: 0;
        left: 63vw;
    }

    .mobile-language-selection {
        font-size: 5vw;
        text-align: center;
        width: 15.9375vw;
        overflow: auto;
        position: fixed;
        bottom: 10.9375vw;
        left: 32.5vw;
    }

    .mobile-navbar-options-submenu {
        font-size: 3.75vw;
        text-align: center;
        text-transform: uppercase;
        color: #F59276;
        font-weight: 600;
        margin-bottom: 3.75vw;
    }


    .navbar-top-cart-icon-counter {
        font-size: 3.125vw;
        margin-top: -13vw;
        width: 4.6875vw;
        height: 4.6875vw;
        margin-left: 9vw;
        background-color: #F59276;
        color: white;
        border-radius: 100%;
        line-height: 4.0625vw;
        padding-top: 0.75vw;
        text-align: center;
        position: absolute;
        padding-left: 0.3vmin;
    }

    .what-is-mat-page-v2 {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-page-v2 {
        text-align: left;
    }


    #special-memories-container {
        color: #3B3B3B;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 54px */
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-img {
        width: 60px;
        overflow: auto;
        margin: 0 calc(50% - 30px);
    }



    .landing-page-bottom-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.8px;
        text-transform: capitalize;
        width: 640px;
        margin: 0 calc( 50% - 320px) 56px;
    }

    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 32px calc(50% - 90px) 36px;
    }

    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-decoration-line: underline;
        text-transform: capitalize;
    }


    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 1.6px;
        text-decoration-line: underline;
        text-transform: capitalize;
    }

    .transition-ig-feed-container {
        overflow: hidden;
    }

    .how-it-works-page-v2 {
        width: 497px;
        overflow: auto;
        margin-left: calc(50% - 249.5px);
        margin-right: calc(50% - 249.5px);
        margin-top: 128px;
        margin-bottom: 128px;
    }

    #what-is-mat-header-v2-moblet {
        width: 100%;
        overflow: auto;
        padding: 0;
    }

    #landing-page-button-standard-v2-moblet {
        margin: 0 calc(50% - 96.5px);
    }
    
    .landing-page-product-list {
        width: 666px;
        margin-left: calc(50% - 155px);
        margin-top: 20px;
        overflow: auto;
    }


    .landing-page-subtext-first-v2 {
        width: 100%;
        overflow: auto;
        margin-top: -40px;
        font-family: Montserrat;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: auto;
        height: 59px;
        left: 8.4%;
        top: 382px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-start-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        /* position: absolute; */
        width: 310px;
        height: 52px;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 20px;
        float: left;
        margin-right: 22.5px;
    }

    .logo-vertical-v2 {
        margin: 47px 59px;
        float: left;
    }

    .landing-page-container {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: #FFFFFF;
        margin-top: 160px;
    }


    .mobile-language-selection-flag {
        width: 26px;
        height: 26px;
        margin-left: 10px;
        border-radius: 100%;
        float: left;
    }

    .mobile-language-selection-flag-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        color: white;
        margin-left: 10px;
        float: left;
    }

    .mobile-language-selection-bar {
        background: #3F5C6B;
        padding: 7px 0px 7px 4px;
        width: auto;
        overflow: auto;
    }

    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }

    .select-currency-options {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-currency-row {
        width: 100%;
        overflow: auto;
        margin-top: 5px;
    }

    .mobile-landing-page-title {
        width: auto;
        overflow-y: hidden;
        background: #8DB09D;
        padding: 60px 87px;
        overflow-x: hidden;
    }

    .mobile-landing-page-title-head {
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 60px */
        letter-spacing: 4.8px;
        text-transform: uppercase;
        color: white;
        background: #8DB09D;
        margin: 0;
        background: #8DB09D;
    }


    .select-currency-option-selected {
        background: #3B3B3B;
    }


    .select-currency-flag {
        float: left;
        width: 26px;
        height: 26px;
    }

    .select-currency-option-selected {
        background: #3B3B3B;
    }


    .mobile-landing-page-title-subhead {
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 45px */
        color: white;
        background: #8DB09D;
        margin: 0;
    }

    .logo-vertical-v2-narrow {
        margin: 47.55px 0 47.55px 7.76%;
        float: left;
    }

    .navbar-button-v2 {
        display: none;
    }

    .navbar-button-v2-narrow {
        display: none;
    }

    .navbar-start-designing-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin: 38px 1.5%;
        float: right;
        width: 142px;
        height: auto;
        right: 190.66px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

    }

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: right;
    }


    .navbar-v2 {
        width: 100%;
        overflow: auto;
        display: inline-block;
    }

    .navbar-v2-non-fixed {
        width: 100%;
        overflow: auto;
    }

    .landing-page-header-v2 {
        width: 100%;
        overflow: hidden;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        overflow-y: auto;
    }

    .landing-page-image-overlay-black-v2 {
        position: absolute;
        width: 100%;
        height: 602px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        opacity: 0.36;
    }

    .eapps-instagram-testimonials-posts.swiper-container {
        padding: 0 !important;
    }

    .how-it-works-subheader-left {
        width: 310px;
        overflow: auto;
        /* Body */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: left;
        /* Primary/Soft Black */
        color: #3B3B3B;
        /* Inside Auto Layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .how-it-works-header-left {
        width: 479px;
        overflow: auto;
        left: 17px;
        top: 0px;
        margin-bottom: 32px;

        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .diploma-mat-box {
        width: 40%;
        height: 320px;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
        max-height: 470px
    }

    .cert-mat-container .landing-page-standard-left-v2 {
        margin-top: 0;
    }

    .cert-mat-container .what-is-mat-desc-v2, .cert-mat-container .what-is-mat-desc-v2 {
        text-align: center;
    }

    #how-it-works-header-left-moblet {
        text-align: left;
        float: left;
    }

    #how-it-works-subheader-left-moblet {
        text-align: left;
        float: left;
    }

    #landing-page-standard-left-v2-moblet {
        margin-left: 0;
        margin-right: 0;
        margin-top: 32px;
    }

    #landing-page-standard-right-v2-moblet {
        padding-top: 12px;
        margin: 0 0 64px 0;
    }

    .landing-page-transition-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 128px 160px; */
        position: static;
        width: 100%;
        overflow: auto;
        background: #3F5C6B;
        padding: 128px 0;
    }

    .landing-page-transition-text-v2 {
        position: static;
        width: 640px;
        height: 108px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px calc( 50% - 320px );
    }

    .how-it-works-header-right {
        position: static;
        width: 479px;
        height: 88px;
        left: 17px;
        top: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .landing-page-review-container-v2 {
        width: 760px;
        /* height: 286px; */
        padding: 128px calc(50% - 380px);
        overflow: auto;
        min-height: 286px;
        background: white;
    }

    #landing-page-review-container-v2-moblet {
        width: 730px;
        height: 400px;
        padding: 128px calc(50% - 365px);
    }

    .landing-page-left-arrow-v2 {
        width: 10px;
        height: 20px;
        float: left;
        margin-left: 19px;
        margin-right: 5.8%;
        margin-top: 150px;
        cursor: pointer;
        position: absolute;
        left: 19px;
    }

    .landing-page-right-arrow-v2 {
        width: 10px;
        height: 20px;
        float: right;
        margin-right: 19px;
        margin-left: 5.8%;
        margin-top: 150px;
        cursor: pointer;
        position: absolute;
        right: 19px;
    }

    .landing-page-review-v2 {
        width: 610px;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
        margin: 0 calc( 50% - 305px);
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {
        width: 100%;
        height: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-content-v2 {
        /* Body 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }

    .landing-page-review-stars-v3 {
        width: 100%;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars-v2 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars {
        width: 30px;
        height: 30px;
        float: left;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-v2 {
        position: static;
        width: 655px;
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #D6D1BA;

        padding: 128px calc( 50% - 327.5px);
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 206px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        background: #FFFFFF;
        border-radius: 30px;
        margin-top: 19px;
        margin-left: calc( 50% - 127px);
    }



    .how-it-works-subheader-right {
        width: 377px;
        margin-top: 128px;
        overflow: auto;
        position: static;
        /* width: 310px; */
        height: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
        float: left;
    }

    .landing-page-standard-left-v2 {
        width: 560px;
        overflow: auto;
        margin-left: calc(50% - 280px);
        margin-right: calc(50% - 280px);
        margin-top: 150px;
    }

    .what-is-mat-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 0 24px 0;
        text-align: center;
        width: 80%;
        padding: 0 10%;
    }

    .product-benefits {
        padding: 25px 0;
    }

    .what-is-mat-desc-v2 {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .what-is-mat-line-break-v2 {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
    }

    .what-is-mat-image-v2 {
        position: static;
        width: 96%;
        padding: 2% 1.5%;
        height: 333px;
        left: 784px;
        top: 128px;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .landing-page-standard-right-v2 {
        width: 497px;
        overflow: auto;
        margin: 0 calc(50% - 249.5px) 128px;
        padding-top: 42px;
    }

    .customizable-container {
        width: auto;
        overflow-y: auto;
        padding: 128px 64px;
        background: #FAF2ED;
    }
    
    .customizable-subcontainer {
        width: 480px;
        overflow: auto;
    }

    .customizable-image-container {
        display: flex;
        width: 640px;
        justify-content: center;
        align-items: center;
        margin-left: calc(50% - 320px);
    }

    .customizable-subhead {
        font-size: 16px;
        font-style: normal;
        line-height: 150%;
        margin-bottom: 48px;
    }

    .mat-transition-container {
        width: calc(100% - 20px);
        overflow-y: auto;
        margin: 10px;
        overflow-x: hidden;
    }

    #landing-page-bottom-transition {
        margin: 128px 0;
    }


    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }


    .review-transition-widget-dials-container {
        width: 200px;
        overflow: auto;
        display: inline-block;
        float: left;
        margin: 20px calc( 50% - 100px);
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: auto;
    }

    .testimonial-desc {
        width: 55%;
        float: left;
        padding: 2.5%;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 19.5px */
        color: #3B3B3B;
    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 2.5%;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 10px;
        height: 10px;
        border-radius: 6px;
        border: 1px #3B3B3B solid;
        float: left;
    }


    .testimonial-photo {
        width: 100%;
        overflow: auto;
        height: 258px;
        object-fit: cover;
    }

    .testimonial-photo-container {
        width: 40%;
        float: left;
    }

    .review-transition-center {
        width: calc(100% - 164px);
        float: left;
        border-radius: 25px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 24px;
        background: #FFF;
        height: 258px;
        overflow: hidden;
    }



    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
        margin: 100px 16px;
    }

    .howItWorks-transition-img-right {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 2%;
        float: left;
    }

    .review-transition-widget {
        width: calc(100% - 50px);
        overflow: hidden;
        margin: 25px 25px 0 25px;
    }

    .howItWorks-transition-img-ctr {
        width: 102%;
        margin-bottom: 1%;
    }

    .testimonial-more-review-button {
        display: flex;
        /* padding: 8.527px 12.79px; */
        justify-content: center;
        align-items: center;
        grid-gap: 5.329px;
        gap: 5.329px;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        border: solid 0.5px;
        width: 250px;
        padding: 12.5px;
        border-radius: 16px;
        margin: 18px calc(50% - 138px) 0;
    }


    .howItWorks-transition-img-left {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 2%;
        float: left;

    }

    .mat-transition-img-left {
        width: 30%;
        overflow: auto;
        float: left;
        object-fit: cover;
        height: 262px;
    }
    
    .benefits-page-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-container {
        width: 100%;
        overflow-y: auto;
        padding: 64px 0;
    }

    .benefits-summary-component-img {
        width: 82px;
        overflow: auto;
        margin-left: calc(50% - 41px);
    }

    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
        margin: 16px 0;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 1.6px;
        margin-bottom: 64px;
    }

    .benefits-summary-component {
        width: 270px;
        overflow: auto;
        margin: 16px calc(50% - 135px)
    }

    .mat-transition-img-right {
        width: 70%;
        overflow: auto;
        float: left;
        object-fit: cover;
        height: 262px;
    }

    .customizable-head {
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .instagrom-scrollerpost-container {
        width: auto;
        height: 100%;
        float: left;
    }

    .instagram-scroller {
        width: 524px;
        overflow-x: scroll;
        display: flex;
        margin: 135px calc( 50% - 262px) 0 calc( 50% - 262px);
    }

    #what-is-mat-header-moblet-specific {
        width: 100%;
        padding: 0;
        text-align: left;
    }

    .instagram-scroller-right-subtitle {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 30px */
        letter-spacing: 2.4px;
        color: white;
    }

    #satisfaction-guarantee-container {
        background: white;
    }

    .instagram-scroller-right-title {
        color: initial;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        font-variant: small-caps;
        letter-spacing: 3.6px;
        color: white;
    }

    .inspiration-post-col-photo-landing-page {
        width: 100%;
        object-fit: cover;
    }

    .instagram-scroller-right {
        width: 524px;
        margin: 51px calc(50% - 262px) 0;
        color: white;
    }


    /*#landing-page-standard-right-moblet-specific {*/
    /*    width: 375px;*/
    /*    margin-left: 8.31%;*/
    /*    padding-top: 128px;*/
    /*    margin-bottom: 0;*/
    /*}*/

    #what-is-mat-desc-moblet-specific {
        width: 100%;
        text-align: left;
        padding: 0;
    }

    #explainer-start-designing-button {
        margin-top: 32px;
        margin-left: calc(50% - 82px);
    }
    
    .how-it-works-step2-desc-v2 {
        width: 560px;
        overflow: auto;
        margin-left: calc(50% - 280px);
        margin-right: calc(50% - 280px);
        margin-top: 128px;
    }
    
    .read-our-review-section {
        width: 100%;
        overflow: auto;
    }

    .landing-page-button-standard-v2 {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: static;
        width: 193px;
        height: 52px;


        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;

        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;

        color: white;
        cursor: pointer;
    }

    .logo-vertical-v2 {
        margin: 47.55px 0 47.55px 4.1%;
        float: left;
    }

    .logo-vertical-v2-narrow {
        margin: 15.55px 0 15.55px 4.1%;
        float: left;
    }

    .instagram-controller-container {
        width: 100%;
        overflow-y: auto;
        padding-bottom: 128px;
    }


    .how-to-measure-video-section {
        width: 50vw;
        height: 28vw;
        margin: 0 calc(49% - 25vw);
    }

    .blog-type-2-pinterest-pin {
        width: 50%;
        margin: 0 25%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 100%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: left;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        margin-bottom: 1.74vw;
    }

    .blog-type-2-container {
        width: 40%;
        overflow: auto;
        margin: 64px 30% 64px 30%;
        color: #3B3B3B;
    }

    .blog-type-2-paragraph {
        width: 100%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 50%;
        overflow: auto;
        margin: 2.5vw 25%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: center;
        font-weight: 400;
    }

    .how-it-works-box-right {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 60%;
        padding: 5vh 20%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 60%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 40%;
        float: left;
        overflow: auto;
    }

    .how-it-works-image-file {
        width: 45%;
        margin: 0 5% 0 0;
        float: left;
        overflow: auto;
    }

    .landing-page-image-file {
        width: 100%;
        overflow: auto;
    }

    .landing-page-image-container {
        width: 96.5%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        position: relative;
    }

    .landing-page-banner-highlight {
        width: 100%;
        overflow: hidden;
        margin: 5.55vw 0;
        font-size: 3.33vw;
        line-height: 3.96vw;
        text-align: center;
        color: #72696A;
        position: absolute;
    }

    .landing-page-banner-subtitle {
        width: 100%;
        position: absolute;
        text-align: center;
        overflow: hidden;
        margin-top: 15.41vw;
        line-height: 2.01vw;
        font-size: 1.39vw;
        color: #656565;
        font-weight: 400;
    }

    /*.landing-page-start-designing-button {*/
    /*    margin-left: calc( (100% - 22.857vw) / 2);*/
    /*    margin-top: 40.844vw;*/
    /*    width: 22.857vw;*/
    /*    text-align: center;*/
    /*}*/

    .landing-page-design-now-button {
        text-transform: uppercase;
        width: auto;
        padding: 0;
        width: 13.19vw;
        text-align: center;
        margin: 21.18vw calc( (100% - 13.19vw) / 2);
        padding-top: 1.01vw;
        padding-bottom: 1.01vw;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        position: absolute;
        line-height: 2.01vw;
        font-size: 1.11vw;
    }

    .landing-page-how-it-works-transition {
        text-align: center;
        padding: 2.78vw 15.83vw;
        color: #000;
        background: #F59276;
        margin: 1.67vw 1.81vw;
        color: white;
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .shipping-info-desc {
        color: white;
        line-height: 1.74vw;
    }


    .blog-type-2-pinterest-pin {
        width: 50%;
        margin: 0 25%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 100%;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696a;
        text-align: left;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        margin-bottom: 1.74vw;
    }

    .blog-type-2-container {
        width: 80%;
        overflow: auto;
        margin: 64px 10% 64px 10%;
    }

    .blog-type-2-paragraph {
        width: 100%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 50%;
        overflow: auto;
        margin: 2.5vw 25%;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: center;
        font-weight: 400;
    }

    .how-it-works-box-right {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 60%;
        padding: 5vh 20%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 100%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 100%;
        float: left;
        overflow: auto;
        margin-top: 5vh !important;
    }

    .how-it-works-image-file {
        width: 45%;
        margin: 0 5% 0 0;
        float: left;
        overflow: auto;
    }

    .landing-page-image-file {
        width: 100%;
        overflow: auto;
    }

    .landing-page-image-container {
        width: 180%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 3.247vw -40% 0 -40%;
        position: relative;
    }

    .landing-page-banner-highlight {
        width: 100%;
        overflow: hidden;
        margin: 10.39vw 0;
        font-size: 6.233vw;
        line-height: 7.402vw;
        text-align: center;
        color: #72696A;
        /* z-index: 10; */
        position: absolute;
    }

    .landing-page-banner-subtitle {
        width: 100%;
        position: absolute;
        text-align: center;
        overflow: hidden;
        margin-top: 35.844vw;
        line-height: 3.766vw;
        font-size: 2.597vw;
        color: #656565;
        font-weight: 400;
    }

    .navbar-top-button-inner-layer {
        width: calc(100% - 0.42vw);
        padding: 2.75vw 0;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        border-radius: 0.5vw;
        cursor: pointer;
    }

    .landing-page-start-designing-button {

        width: 180%;
        text-align: center;
        overflow: auto;
        margin: 47.844vw -40% 0 -40%;
        position: absolute;;
    }

    .landing-page-design-now-button {
        padding-top: 1.883vw;
        padding-bottom: 1.883vw;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        width: 22.857vw;
        margin: 0 calc( (100% - 22.857vw) / 2 );
        font-size: 2.08vw;
    }



    .landing-page-start-designing-button a:hover {
        text-decoration: none;
    }

    .landing-page-how-it-works-transition {
        text-align: center;
        padding: 2.78vw 0 3.376vw 0;
        background: #F59276;
        color: white;
        font-size: 2.0779vw;
        line-height: 3.247vw;
    }

    .shipping-info-highlights {
        margin: 0 9.350vw;
    }

    .shipping-info-desc {
        height: 4.545vw;
        color: white;
    }

    #landing-page-banner-highlight-fr {
        /*for french, reducing the font size since the copywriting is longer than english.*/
        font-size: 5.233vw;
    }

    .how-to-measure-video-section {
        width: 90vw;
        height: 50vw;
        margin: 0 calc(49% - 45vw);
    }

}
