.frame-category-image:hover {
    border-color: #F59276;
}

.frame-category-selected {
    border-color: #F59276;
}

.select-frame-product-preview {
    width: 80vw;
    overflow-y: auto;
    margin: 0 10vw 0 9vw;
}

.frame-product-preview-photo {
    width: 80%;
    margin: 0 10%;
}

.show-all-frames-button:hover {
    text-decoration-color: #F59276;
    text-decoration: underline;
}

.frame-product-description a {
    text-decoration: none;
}


.product-description-title {
    font-size: 0.8333vw;
    text-transform: uppercase;
    font-weight: 600;
    color: #F59276;
}

#frame-size-selector {
    width: 100%;
    overflow: hidden;
}

#frame-design-button {
    cursor: pointer;
}

.frame-size-desc-title  {
    width: auto;
    overflow: auto;
    color: #72696A;
    text-transform: uppercase;
    font-weight: 600;
}

.select-frame-title-container {
    width: 100%;
    color: #72696A;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .tablet-display-only {
        display: none;
    }

    .select-frame-title-container {
        margin: 10vw 0 7.5vw 0;
        font-size: 6.25vw;
        line-height: 7.8125vw;
    }

    .frame-category-image {
        width: 14vw;
        height: 14vw;
        margin: 0 1vw;
        padding: 1vw;
        border: 1vw solid white;
        cursor: pointer;
    }

    .frame-product-container {
        width: 80vw;
        overflow-y: auto;
        float: left;
        margin: 5.625vw 0;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 5vw;
        line-height: 7.8125vw;
    }



    .product-description, .product-listing-price {
        margin-top: 7.5vw;
        margin-bottom: 5.78125vw;
    }


    .frame-category-container {
        width: 20vw;
        height: 30vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }


    .select-frame-category-selector-container {
        width: 60vw;
        overflow-y: auto;
        margin: 0 calc( (100% - 60vw) / 2);
    }

    .show-all-frames-button {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        height: 2.22vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 100%;
        float: left;
        margin-top: 15vw;
        margin-bottom: 5vw;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 86vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(100% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 2.5vw 0 0 0;
        font-size: 4vw;
        line-height: 6.5625vw;
        letter-spacing: 0.04652777vw;
        text-align: center;
    }

    .frame-size-desc-subtitle {
        font-size: 5vw;
        color: #72696A;
        margin: 2.5vw 0 0 0;
        font-weight: 400;
        line-height: 6.5625vw;
        text-align: center;
    }

    .frame-size-desc-price {
        color: #F59276;
        font-size: 5vw;
        line-height: 7.8125vw;
        font-weight: 600;
        text-align: center;
        margin-top: 2.5vw;
        margin-bottom: 0;
    }

    .frame-size-desc-prod-summary {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        font-weight: 400;
        margin: 0.9722222vw 0;
    }

    .frame-size-desc-prod-summary-title {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        font-weight: 400;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
        margin-top: 3vw;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

    .frame-size-desc-price-subscript {
        font-size: 5vw;
        color: #72696A;
        margin: 2.5vw 0 5vw 0;
        font-weight: 600;
        line-height: 6.5625vw;
        text-align: center;
    }

    #frame-size-selector label {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 7.5vw;
        line-height: 9.0625vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 2.5vw 0 5vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        width: 100%;
        overflow: auto;
        margin: 1.63vw 0 0 0;
        text-align: center;
        display: inline;
        font-size: 5vw;
        line-height: 7.8125vw;
        padding: 3.28125vw 0;
        color: white;
        background: #F59276;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 15vw;
        height: 15vw;
        margin: 5vw 5vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }

    #frame-size-desc-prod-summary-mobile-display-only {
        margin: 5vw;
    }



}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {
    .select-frame-title-container {
        font-size: 2.597vw;
        line-height: 3.117vw;
        margin: 2.7777vw 0;
    }

    .frame-category-image {
        width: 6.5vw;
        height: 6.5vw;
        margin: 0.25vw;
        padding: 0.25vw;
        border: 0.25vw solid white;
        cursor: pointer;
    }

    .frame-category-container {
        width: 8vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 2.0779vw;
    }

    .select-frame-category-selector-container {
        width: 48vw;
        height: 12vw;
        margin: 0 calc( (100% - 48vw) / 2);
    }

    .show-all-frames-button {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        width: 30vw;
        margin: 0 calc((100% - 30vw) / 2);
        height: 2.22vw;
        text-decoration: underline;
    }

    .frame-product-container {
        width: 40vw;
        overflow-y: auto;
        float: left;
        margin-bottom: 5.45vw;
    }


    .product-description, .product-listing-price {
        margin-bottom: 1.67vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 80%;
        float: left;
        margin: 5% 10%;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 83.5vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(100% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 0;
        font-size: 2.597vw;
        line-height: 3.117vw;
        letter-spacing: 0.03vw;
        text-align: center;
    }

    .frame-size-desc-subtitle {
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .frame-size-desc-price {
        color: #F59276;
        text-align: center;
        font-weight: 400;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin: 0 0 2.403vw 0;
    }

    .frame-size-desc-prod-summary {
        line-height: 3.247vw;
        color: #72696A;
        /* text-align: center; */
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .frame-size-desc-prod-summary-title {
        line-height: 3.247vw;
        color: #72696A;
        margin: 2vw 0 1vw 0;
        font-size: 2.0779vw;
        font-weight: 500;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
    }

    #frame-size-selector label {
        font-family: Montserrat;
        font-weight: 400;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 2vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        margin: 1.63vw 0 0 0;
        text-align: center;
        display: inline;
        width: 100%;
        color: white;
        background-color: #F59276;
        padding: 1.883vw 0;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 6vw;
        height: 6vw;
        margin: 3vw 3vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }

    .tablet-display-only {
        display: inline;
    }

    .tablet-no-display {
        display: none;
    }

    .frame-size-desc-price-subscript {
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }



}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .tablet-display-only {
        display: none;
    }

    .select-frame-title-container {
        font-size: 1.388vw;
        line-height: 1.6666vw;
        margin: 3.9583vw 0 1.5vw 0;
    }

    .frame-category-image {
        width: 2.5vw;
        height: 2.5vw;
        margin: 0 1.5vw;
        padding: 0.15vw;
        border: 0.1vw solid white;
        cursor: pointer;
    }

    .frame-category-container {
        width: 6vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 1.11vw;
    }

    .select-frame-category-selector-container {
        width: 36vw;
        height: 5vw;
        margin: 0 calc((100% - 36vw) / 2) 0 calc((100% - 36vw) / 2);
    }

    .show-all-frames-button {
        font-size: 1.11vw;
        line-height: 2.569vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        width: 15vw;
        margin: 0 calc((100% - 15vw) / 2);
        height: 2.22vw;
    }

    .frame-product-container {
        width: 20vw;
        overflow-y: auto;
        float: left;
        margin-bottom: 5.45vw;
    }

    .product-description, .product-listing-price {
        margin-bottom: 1.67vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 60%;
        float: left;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 86vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(40% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 0;
        font-size: 1.33vw;
        line-height: 1.67vw;
        letter-spacing: 0.04652777vw;
    }

    .frame-size-desc-subtitle {
        width: 100%;
        overflow: auto;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .frame-size-desc-price {
        color: #F59276;
        font-weight: 400;
        width: 100%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        text-align: left;

    }

    .frame-size-desc-prod-summary {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
        margin: 1vw 0;
    }

    .frame-size-desc-prod-summary-title {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .frame-size-desc-price-subscript {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
    }

    #frame-size-selector label {
        color: #72696A;
        font-size: 1.11vw;
        line-height: 1.736111;
        font-weight: 400;
        letter-spacing: 0.04652777vw;
        font-family: "Montserrat";
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 0.9722222vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        width: 100%;
        margin: 1.63vw 0 0 0;
        text-align: center;
        background-color: #F59276;
        padding-top: 1.01vw;
        padding-bottom: 1.01vw;
        color: white;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 4vw;
        height: 4vw;
        margin: 4vw 4vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }



}

