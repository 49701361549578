/*For all*/

.what-we-do-description {
    line-height: 2.5;
    text-align: center;
    font-weight: 500;
}

.go-to-success-stories {
    text-align: center;
    cursor: pointer;
    /*color: white;*/
    opacity: 0.7;
    text-decoration: underline;
}

.go-to-success-stories:hover {
    color: #f59276;
}

.go-to-success-stories:hover {
    text-decoration: underline;
    font-weight: 500;
}

#how-it-works-topic-header {
    color: #514945;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    display: inline-block;
}

#transaction-topic-header-reduced-margin {
    margin-top: -10vh;
    width: 100%;
    text-align: center;
    border-bottom: none;
}

#what-we-do-description-margin {
    font-weight: 400;
}

.return-to-payment-page-button {
    width: 20%;
    overflow: auto;
    font-weight: 500;
    color: black;
    padding: 0.5rem 1rem;
    border: 0.1rem black solid;
    display: inline-block;
    text-align: center;
    margin: 0 39%;
}

.what-we-do-container-first-container {
    width: 100%;
    overflow-y: auto;
}



.section-topic-header {
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
    /* padding-bottom: 2rem; */
    border-bottom: #f59276 solid 0.1rem;
    width: auto;
    padding: 0;
    margin: 0;
    overflow: auto;
    display: inline-block;
}



.what-we-do-see-before-button:hover {
    background: #514945;
    color: white;
}

.what-we-do-second-left {
    overflow-y: auto;
    float: left;
    opacity: 0.8;
}

.what-we-do-second-right {
    overflow-y: auto;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2;
}

.what-we-do-container-second-container {
    width: 100%;
    overflow: auto;
}

.float-left {
    float: left;
}

.how-it-works-faq a:hover {
    text-decoration: underline;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    /*.what-we-do-description {*/
        /*padding: 3rem 0%;*/
        /*font-size: 1rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 0.7rem;
    }

    .what-we-do-description {
        padding: 3rem 5%;
        font-size: 0.7rem;
    }

    .what-we-do-first-left {
        width: 80%;
        overflow-y: auto;
        float: left;
        padding: 0 10%;
        font-size: 1rem;
        font-weight: 500;
        line-height: 2;
        text-align: center;
    }

    .what-we-do-first-right {
        width: 100%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 5vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 5%;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }

    .what-we-do-photo-before {
        width: 90%;
        margin: 0 5%;
        z-index: 10;
    }

    .what-we-do-see-before-button {
        text-align: center;
        margin: 2rem 0;
        font-size: 0.8rem;
        text-transform: capitalize;
        width: 100%;
    }

    .go-to-success-stories {
        width: 100%;
        overflow-y: auto;
        padding: 2rem 0 0 0;
        text-align: center;
    }

    .what-we-do-second-left {
        width: 100%;
        margin-bottom: 5vh;
    }

    .what-we-do-second-right {
        width: 90%;
        padding: 10vh 0 10vh 5%;
        text-align: center;
    }

    .how-it-works-faq {
        width: 80%;
        overflow-y: auto;
        margin-left: 10%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    /*.what-we-do-description {*/
        /*padding: 3rem 15%;*/
        /*font-size: 1.3rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*!*text-align: center;*!*/
    /*}*/

    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 16;
    }

    .what-we-do-first-left {
        width: 35%;
        overflow-y: auto;
        float: left;
        padding: 25vh 5% 10vh 5%;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 5vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }

    .what-we-do-see-before-button {
        overflow-y: auto;
        color: black;
        float: right;
        padding: 0.75rem;
        cursor: pointer;
        margin-right: 10%;
        text-transform: capitalize;
        letter-spacing: 0.05rem;
        border: solid 0.05rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        transition: background-color 0.3s linear;
        font-weight: 500;
    }

    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0.5rem;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 5vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 25vh 0 25vh 5%;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    /*.what-we-do-description {*/
        /*padding: 10rem 12.5%;*/
        /*font-size: 1.8rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 1.5rem;
    }

    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 1.3rem;
    }

    .what-we-do-first-left {
        width: 40%;
        overflow-y: auto;
        float: left;
        padding: 25vh 0 25vh 5%;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 10vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0.5rem;
    }


    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 10vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 25vh 0 25vh 5%;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    /*.what-we-do-description {*/
        /*padding: 5rem 10%;*/
        /*font-size: 2rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 1.5rem;
    }


    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 1.5rem;
    }

    .what-we-do-first-left {
        width: 40%;
        overflow-y: auto;
        float: left;
        padding: 15vh 0 15vh 5%;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 10vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }

    .what-we-do-see-before-button {
        overflow-y: auto;
        color: black;
        float: right;
        padding: 0.75rem;
        cursor: pointer;
        margin-right: 10%;
        text-transform: capitalize;
        letter-spacing: 0.05rem;
        border: solid 0.05rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        transition: background-color 0.3s linear;
        font-weight: 500;
    }

    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }


    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 10vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 15vh 0 15vh 5%;
        font-size: 2rem;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1.5rem;
    }

    #how-it-works-topic-header, .how-it-works-faq a {
        font-size: 1.5rem;
    }


}
