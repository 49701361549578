.review-section-directions {
    line-height: 2;
}

.no-display {
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 769px) {
    .tablet-display-only {
        display: none;
    }

    .mobile-no-display {
        display: none;
    }


    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        /*font-size: 2.222vw;*/
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 90%;
        overflow: auto;
        margin: 0 0% 5vh;
        padding: 2% 5% 10% 5%;
    }

    .review-field-submit-button-container {
        width: 100%;
        margin: 0;
    }
}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .tablet-no-display {
        display: none;
    }

    .tablet-display-only {
        display: inline-block;
    }

    .mobile-display-only {
        display: none;
    }

    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        /*font-size: 2.222vw;*/
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 70%;
        overflow: auto;
        margin: 0 10% 5vh;
        padding: 2% 5%;
    }

    .review-field-submit-button-container {
        width: 40%;
        margin: 0 30%;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .tablet-display-only {
        display: none;
    }

    .mobile-display-only {
        display: none;
    }

    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        font-size: 1.39vw;
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 60%;
        overflow: auto;
        margin: 0 15% 5vh;
        padding: 2% 5%;
    }

    .review-field-submit-button-container {
        width: 40%;
        margin: 0 30%;
    }

    .review-field-margin p {
        text-align: center;
    }
}
