/*For all*/


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .design-your-mat-box-video-container {
        width: 100%;
        height: 55vw;
    }

    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: hidden;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        text-align: right;
        cursor: pointer;
        width: 98%;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 4vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
    }

    .image-crop-section {
        width: 100%;
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 0;
        padding: 0;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .matboard-size-selection-demo-photo {
        width: 140%;
        margin-left: -20%;
    }

    .image-crop-action-tools {
        width: 110%;
        overflow: auto;
        margin-left: -5%;
        margin-bottom: 0.5vw;
        margin-top: 5%;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        text-decoration: underline;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin: 5vw 0 5vw 0;
        padding: 4vw 0;
        font-size: 5vw;
        line-height: 6.5625vw;
        text-align: center;
        width: 100%;
        background: #F59276;
        border-radius: 0.5vw;
        color: white;
        text-decoration: none;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        text-align: center;
        font-size: 4vmin;
        color: #72696A;
        margin: 5vw 0;
        font-weight: 400;
        line-height: 6.5625vw;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

}


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: scroll;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        width: 98%;
        text-align: right;
        cursor: pointer;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 1vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .image-crop-section {
        width: calc(100% - 0.4vmin);
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 0;
        padding: 0;
        background: white;
        /* border-radius: 1vmin; */
        border: solid #F59276 0.2vmin;
    }

    .image-crop-action-tools {
        width: 110%;
        overflow: auto;
        margin-left: -5%;
        margin-bottom: 0.5vw;
        margin-top: 5%;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        padding: 1vw 3vw;
        float: right;
        background: #FEF4F1;
        border-radius: 0.5vw;
        margin: 2vw;
        font-size: 2.0779vw;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        line-height: 3.247vw;
        color: #72696A;
        font-size: 2.0779vw;
        margin: 3vw 0;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

    .design-your-mat-box-video-container {
        width: 95%;
        height: 50vw;
        margin: 2.5% 2.5% 0 2.5%;
        float: left;
    }

    .design-your-mat-box-control-panel {
        width: 95%;
        overflow: auto;
        margin: 0 2.5% 0 2.5%;
        float: left;
    }

}

@media screen and (min-width: 1100px) {

    .design-your-mat-box-video-container {
        width: 55vw;
        height: 31vw;
        margin: 2vw 0 0 0;
        float: left;
    }

    .design-your-mat-box-control-panel {
        width: calc(100% - 58vw);
        float: left;
        overflow: auto;
        margin: 2vw 1vw 2vw 2vw;
    }

    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: scroll;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        width: 98%;
        text-align: right;
        cursor: pointer;
        margin-top: 15px;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 2vmin 3vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .image-crop-section {
        width: calc(92% - 0.4vmin);
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 2.5%;
        padding: 1.5%;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .image-crop-action-tools {
        width: 100%;
        overflow: auto;
        margin-bottom: 0.5vw;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        padding: 0.5vw 2vw;
        float: right;
        background: #FEF4F1;
        border-radius: 0.5vw;
        margin-right: 0.5vw;
        font-size: 1.11vw;
        margin-top: 0.25vw;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        font-size: 1.11vw;
        line-height: 3vw;
        color: #72696A;
        font-weight: 400;
        text-align: center;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

}
