@media screen and (min-width: 769px) and (max-width: 1100px) {

    /*.mobile-navbar-options-container {*/
    /*    width: calc(100% - 45px);*/
    /*    overflow: auto;*/
    /*    padding-right: 45px;*/
    /*    padding-bottom: 4.5px;*/
    /*    padding-top: 4.5px;*/
    /*    background: white;*/
    /*    position: fixed;*/
    /*    z-index: 100;*/
    /*}*/

    .navbar-top-button-groups {
        width: 100%;
        overflow-y: hidden;
        height: 160px;
        background: white;
        position: fixed;
        z-index: 30;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow-y: hidden;
        height: auto;
        position: fixed;
        background: white;
        z-index: 100;
        top: 0;
    }

    .support-v2-dropdown-button {
        text-align: right;
    }

    .mobile-navbar-open {
        width: 100%;
        overflow: auto;
        z-index: 100;
        background-color: #FFFFFF;
        position: fixed;
        top: 69px;
    }

    #mobile-menu-button-tablet {
        margin-right: 32px;
        margin-top: 40px;
    }

    .navbar-top-container {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        overflow: hidden;
    }

    .navbar-top-mobile-button {
        display: none;
    }

    .navbar-start-button {
        width: 200px;
        float: right;
        overflow: auto;
        margin-top: 38px;
        margin-right: 24px;
    }

    .navbar-top-gallery-web-button {
        font-size: 2.08vw;
        margin: 5.195vw 6.494vw;
        float: left;
    }

    .navbar-top-contact-web-button {
        font-size: 2.08vw;
        margin: 5.195vw 0;
        float: left;
    }

    .navbar-top-company-name-logo {
        width: 14.805vw;
        height: 4.79vw;
        position: absolute;
        float: left;
        margin: 1.04vw calc( (100% - 14.80vw) / 2);
    }

    .navbar-top-web-cart {
        width: 4.156vw;
        height: 3.896vw;
        float: left;
        margin: 4.675vw 0;
    }

    .navbar-top-web-language {
        width: auto;
        height: auto;
        color: #5F6062;
        margin: 5.194vw 6.494vw;
        float: left;
        font-size: 2.08vw;
    }

    .landing-page-banner {
        background: #F59276;
        color: white;
        padding: 1.299vw 0;
        font-weight: 300;
        text-align: center;
        font-family: Montserrat;
        font-size: 2.08vw;
    }

    .navbar-top-web-right-container {
        width: auto;
        float: left;
        position: absolute;
        margin-left: 73vw;
    }

    .navbar-top-cart-icon-counter {
        position: absolute;
        width: 2.5974vw;
        height: 2.5974vw;
        border-radius: 100%;
        text-align: center;
        margin-top: -5.25vw;
        margin-left: 2.25vw;
        color: #FFFFFF;
        background: #F59276;
        font-size: 1.818vw;
        padding-top: calc((2.5974vw - 2.33766vw) /2);
        padding-left: 0.15vw;
        line-height: 2.33766vw;
        font-weight: 300;
    }

    .logo-vertical-v2 {
        float: left;
    }

    .logo-vertical-v2-narrow {
        float: left;
    }

    .bag-icon-v2 {
        width: 48px;
        height: 48px;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 40px 15px 40px 0;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 15px 5px 0;
        float: right;
    }







}

