/*Mobile Only*/

.credit-card-containers {
    width: 150px;
    padding-left: 10px;
    margin-left: calc( 50% - 80px );
    margin-right: calc( 50% - 80px );
}

.credit-card-logos {
    width: 30px;
    float: left;
    overflow: auto;
    margin-right: 10px
}

.artwork-margin-indicator {
    position: relative;
}

.payments-container .shipping-summary-add-bottom-line {
    border-bottom: none;
}

.shipping-address-email div,
.shipping-address-recipient-phone div,
.shipping-address-recipient-name div,
.shipping-address-street div,
.shipping-addresscheckout-progress-option-selected-suite div,
.shipping-address-city div,
.province-state-selector-container div,
.shipping-address-postal-code div {
    width: 100%;
}

.StripeCheckout {
    border: none !important;
    font-weight: 400 !important;
    padding: 0 !important;
    text-align: center !important;
    background: #FFFFFF !important;
    box-shadow: none !important;
    margin: 0 calc( 50% - 90px) !important;
    border-radius: 15px !important;
}

.shipping-address-section-wrapper input {
    width: 88% !important;
}

.payment-processor-description {
    text-align: center;
}

.shipping-address-suite div {
    width: 100%;
    overflow: auto;
}

.province-state-selector-container div div {
    padding: 8.5px 14px !important;
}

.shipping-form-container input {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3B3B3B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
    padding: 0 14px !important;
    text-align: left;
}

.StripeCheckout {
    border: none !important;
    font-weight: 400 !important;
    padding: 0 !important;
    background: #FFFFFF !important;
    text-align: center !important;
}

.StripeCheckout span {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 150% !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    background: #3F5C6B !important;
    padding: 16px 24px !important;
    width: 130px !important;
    text-align: center !important;
    border-radius: 15px !important;
}

