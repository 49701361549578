@media screen and (min-width: 0px) and (max-width: 320px) {
    .navbar-top-company-desc-phrase-one {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 769px) {

    .mobile-landing-page-title {
        width: 100%;
        height: 136px;
        overflow: hidden;
        background: #8DB09D;
        padding: 25px 0 40px 0;
    }

    .mobile-landing-page-title-head {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: white;
        margin: 0;
    }

    .mobile-landing-page-title-subhead {
        color: white;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }

    .bag-icon-v2 {
        width: 70%;
        margin: 15%;
    }

    .navbar-top-button-groups {
        width: 100vw;
        overflow-y: hidden;
        /*position: fixed;*/
        background: white;
        z-index: 50;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow-y: hidden;
        height: auto;
        position: fixed;
        background: white;
        z-index: 100;
        top: 0;
    }

    #what-is-mat-header-v2-moblet {
        width: 90%;
        height: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin: 64px 5% 32px;
    }

    .mobile-navbar-open {
        width: 100%;
        height: auto;
        position: fixed;
        z-index: 100;
        background-color: #FFFFFF;
        /* margin-top: -10px; */
        top: 68px;
    }

    #what-is-mat-header-moblet-specific {
        margin-top: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        width: 100%;
        height: auto;
    }

    .instagram-scroller {
        width: auto;
        overflow-y: auto;
        margin-bottom: 120px;
    }

    #landing-page-button-standard-moblet-specific {
        position: static;
        width: 160px;
        height: auto;
        left: 68.5px;
        top: 156px;
        background: #FFFFFF;
        border-radius: 30px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 24px auto;
        padding: 16px 24px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        top: 50px;
        /* height: 700px; */
        /* min-height: 700px; */
        position: absolute;
        margin-top: 248px;
    }

    .landing-page-image-overlay-black-v2 {
        width: 100%;
        height: 700px;
        position: absolute;
        background: #666161;
        opacity: 0.36;
    }

    .landing-page-product-list {
        width: 100%;
        overflow: auto;
    }



    .landing-page-subtext-first-v2 {
        /*position: absolute;*/
        width: 100%;
        /*height: 92px;*/
        /* left: 58px; */
        margin-top: 0px;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        min-height: 640px;
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: 218px;
        height: 46px;
        /* left: 71px; */
        top: 281px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        margin-left: calc(50% - 109px);
    }

    .landing-page-start-button-v2 {
        color: #3B3B3B;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: inline-flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 30px;
        background: #FFF;
        width: 250px;
        height: 20px;
        z-index: 30;
        position: absolute;
        margin: -46px calc( 50% - 149px);
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }

    .landing-page-review-stars-v3 {
        width: 100%;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars-v2 {
        margin-bottom: 24px;
    }

    .what-is-mat-header-v2 {
        width: 248px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-left: calc(50% - 124px);
        margin-top: 0;
    }

    .product-benefits {
        padding: 25px 0;
    }

    .customizable-subcontainer {
        width: 100%;
        overflow: auto;
    }

    .customizable-subhead {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: left;
        width: 248px;
        margin: 24px calc(50% - 124px);
    }





    .what-is-mat-image-v2-mobile {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-line-break-v2 {
        width: 250px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin: 24px calc(50% - 125px);
    }

    #landing-page-standard-right-moblet-specific {
        margin-top: 64px;
        padding-top: 64px;
    }

    #what-is-mat-header-moblet-specific {
        margin-top: 0;
    }

    #what-is-mat-desc-moblet-specific {
        width: 90%;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        margin: 24px 5% 0 5%;
        font-style: normal;
    }

    .what-is-mat-image-v2 {
        width: 94%;
        padding: 0 2%;
        height: auto;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 5px 5px;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 5px;
        float: right;
    }



    .mobile-menu-button {
        margin-right: 15px;
    }

    .navbar-button-v2 {
        display: none;
    }

    .navbar-button-v2-narrow {
        display: none;
    }

    .navbar-start-designing-button-v2 {
        display: none;
    }


    .logo-vertical-v2 {
        width: 120px;
        overflow: auto;
        margin: 15px 24px;
        float: left;
    }

    .logo-vertical-v2-narrow {
        width: 120px;
        overflow: auto;
        margin: 15px 24px;
        float: left;
    }


    #navbar-top-company-description-br-responsive {
        display: inline;
    }

    .navbar-top-company-logo {
        width: 80%;
    }

    .navbar-top-gallery-web-button, .navbar-top-contact-web-button {
        display: none;
    }

    .navbar-top-company-name-logo {
        width: 20vw;
        height: 12.1875vw;
        margin: 1.5625vw calc( 50% - 10vw);
        position: absolute;
    }

    .navbar-top-mobile-button {
        display: none;
        width: 48px;
        height: 48px;
        float: right;
        text-align: center;
        cursor: pointer;
        margin: 8px 15px;
    }

    .navbar-top-mobile-button img {
        width: 100%;
    }

    .navbar-top-web-cart {
        width: 13.125vw;
        height: 13.125vw;
        float: left;
        text-align: center;
        cursor: pointer;
        margin: 1.5625vw 5vw 1.5625vw 58.75vw;
    }

    .navbar-top-web-language {
        display: none;
    }

    .mobile-no-display {
        display: none;
    }

    .landing-page-banner {
        font-size: 4.375vw;
        text-align: center;
        background-color: #F59276;
        color: white;
        font-size: 4.375vw;
        line-height: 5.625vw;
        font-weight: 300;
        padding: 3.75vw 0;
    }

    .landing-page-banner-highlight {
        font-size: 7.5vw;
        line-height: 9.0625vw;
        color: #72696A;
        letter-spacing: 0.15625vw;
        margin: 0;
    }

    .landing-page-banner-subtitle {
        margin: 5vw 0;
        font-size: 3.75vw;
        font-weight: 400;
        color: #656565;
        line-height: 5.9375vw;
    }

    .landing-page-desc-container {
        width: calc(100% - 4vw);
        position: absolute;
        margin: 12.5vw 2vw;
        text-align: center;
    }

    .landing-page-start-designing-button {
        position: absolute;
        margin: 67.71875vw 19.84375vw 0 19.84375vw;
        width: 60.3125vw;
        text-align: center;
        padding: 3.28125vw 0;
        border: 0.9375vw solid #F59276;
        cursor: pointer;
    }

    .landing-page-start-designing-button:hover {
        background-color: #F59276;
        color: white;
    }

    .landing-page-start-designing-button a {
        text-decoration: none;
        color: #F59276;
        cursor: pointer;
    }

    .landing-page-design-now-button {
        text-decoration: none;
        color: #F59276;
        /*cursor: pointer;*/
    }

    .landing-page-image-file {
        width: 180%;
        overflow: auto;
        margin: 0 -40%;
    }

    .product-offering-header {
        text-align: center;
        font-weight: 400;
        letter-spacing: 0.15625vw;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 7.5vw;
    }
    
    .mobile-navbar-options-container a {
        width: 100%;
        overflow: auto;
    }


    .navbar-top-button-mobile {
        width: auto;
        float: left;
        text-align: left;
        margin-bottom: 23px;
        text-transform: uppercase;
        color: #3B3B3B;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 20px;
        max-width: 200px;
    }

    #navbar-top-button-mobile-language-selection {
        text-align: right;
        float: right;
    }

    .navbar-top-button-chevron {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        margin-right: 22.5px;
        margin-top: 27.5px;
    }

    .navbar-mobile-header-indicator-img {
        width: 52px;
        height: 52px;
        position: relative;
        float: left;
    }


    
    .mobile-nav-head-section {
        width: 100%;
        overflow: auto;
    }

    .mobile-nav-close-icon {
        width: 34px;
        height: 34px;
        float: right;
        padding: 9px;
        margin-right: 8px;
    }

    .mobile-language-selection-flag {
        width: 26px;
        height: 26px;
        margin-left: 10px;
        border-radius: 100%;
        float: left;
    }

    .mobile-language-selection-flag-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        color: white;
        margin-left: 10px;
        float: left;
    }

    .mobile-language-selection-bar {
        background: #3F5C6B;
        padding: 7px 0px 7px 4px;
        width: auto;
        overflow: auto;
    }
    
    .select-currency-options {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-currency-row {
        width: 100%;
        overflow: auto;
        margin-top: 5px;
    }

    .navbar-top-button-header {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        margin: 30px 20px 12px;
    }

    /*.landing-page-container {*/
    /*    margin-top: 100px;*/
    /*}*/



    .navbar-top-button-subheader {
        margin: 18px 20px;
        max-width: 200px;
        color: #686868;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: capitalize;
    }

    .navbar-mobile-header-indicator {
        width: 75%;
        float: left;
        overflow: auto;
    }

    .navbar-mobile-header-indicator-text {
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        width: auto;
        overflow: auto;
        margin-top: 15px;
    }


    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }
    
    .currency-description {
        width: auto;
        float: left;
        overflow: auto;
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        margin-left: 5px;
    }

    .select-currency-flag {
        float: left;
        width: 26px;
        height: 26px;
    }

    .select-currency-option-selected {
        background: #3B3B3B;
    }
    
    .navbar-top-button-mobile-menu {
        width: 100%;
        overflow: auto;
        margin-bottom: 10px;
    }



    .mobile-navbar-open-wide-no-display {
        display: none;
        /*margin-right: -280px;*/
        transition: margin 1000ms ease-in-out;

    }

    .mobile-navbar-open-wide {
        width: 100%;
        height: 100vh;
        overflow: auto;
        z-index: 120;
        position: fixed;
        background: rgba(84, 84, 84, 0.50);
        backdrop-filter: blur(2.5px);
        top: 0;
    }

    .mobile-navbar-options-container {
        width: 273px;
        overflow: auto;
        z-index: 100;
        background: white;
        float: right;
        margin-left: calc( 100% - 273px);
        position: relative;
        -webkit-animation: drawer 100ms;
        animation: mobileDrawer 100ms;
    }

    @keyframes mobileDrawer {
        0% {margin-right: -280px;}
        100% {margin-right: 0px;}
    }
    @-webkit-keyframes mobileDrawer {
        0% {margin-right: -280px;}

        100% {margin-right: 0px;}
    }

    .mobile-navbar-options-menu {
        color: #F59276;
        margin: 5vh 0 8.125vw 0;
        font-size: 5vw;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mobile-navbar-closeout-button {
        width: 15vw;
        height: 15vw;
        color: #726969;
        margin-top: 3vw;
        float: right;
        position: fixed;
        top: 0;
        left: 63vw;
    }

    .mobile-language-selection {
        font-size: 5vw;
        text-align: center;
        width: 15.9375vw;
        overflow: auto;
        position: fixed;
        bottom: 10.9375vw;
        left: 32.5vw;
    }

    .mobile-navbar-options-submenu {
        font-size: 3.75vw;
        text-align: center;
        text-transform: uppercase;
        color: #F59276;
        font-weight: 600;
        margin-bottom: 3.75vw;
    }
    
    .landing-page-header-v2 {
        width: 100%;
        height: 620px;
        overflow: auto;
    }

    .navbar-top-cart-icon-counter {
        font-size: 3.125vw;
        margin-top: -13vw;
        width: 4.6875vw;
        height: 4.6875vw;
        margin-left: 9vw;
        background-color: #F59276;
        color: white;
        border-radius: 100%;
        line-height: 4.0625vw;
        padding-top: 0.75vw;
        text-align: center;
        position: absolute;
        padding-left: 0.3vmin;
    }
    
    .what-is-mat-page-v2 {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-page-v2 {
        text-align: left;
    }

}