/*For all*/

.canvas-app-container {
    width: 100%;
    overflow-y: auto;
    padding: 5vh 0;
}

.dimensions-unit-selector-container {
    width: auto;
    overflow-y: auto;
    min-height: 1.5rem;
    float: right;
}
.app-selector-header {
    width: auto;
    overflow: auto;
    float: left;
}

.primary-matting-dimensions-container {
    line-height: 1.5;
    padding: 0.1rem 0 0.75rem 0;
    width: auto;
    overflow: auto;
    font-weight: 600;
}

.secondary-matting-dimensions-container {
    line-height: 1.5;
}

.go-to-pricing-info {
    width: 100%;
    overflow: auto;
    text-align: right;
    padding-top: 0.5rem;
}

.go-to-pricing-info:hover {
    text-decoration: underline;
}

.selector-container {
    width: 100%;
    overflow-y: auto;
}

.window-size-selector, .matting-colour-selector, .matting-type-selector {
    padding: 3vh 0;
}

.selector-form {
    float: right;
}

.matting-width-selector-header {
    float: left;
}

.matting-width-selector-form {
    width: calc( 100% - 2rem);
    overflow: auto;
    padding-top: 0.5rem;
}

.matting-width-selector-form-center-artwork-button {
    float: right;
    padding: 0.1rem 1rem;
    border: solid 0.1rem #E9E9E9;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}

.window-size-selector-form {
    padding-bottom: 0.5rem;
}

input {
    width: 2rem;
    text-align: center;
}

.matting-colour-selector-item {
    width: 1rem;
    height: 1rem;
    float: left;
    text-align: center;
    margin: 0 0.25rem;
    border: solid 0.1rem #E9E9E9;
    border-radius: 100%;
}

.pricing-summary-details, .pricing-summary-shipping-line-item {
    width: 100%;
    overflow-y: auto;
}

.pricing-summary-details-item, .pricing-summary-details-shipping-charges {
    float: left;
}

.pricing-summary-details-price, .pricing-summary-details-price {
    float: right;
}

.canvas-app-rendering-pricing-section-header {
    font-weight: 600;
    width: auto;
    overflow: auto;
    float: left;
    padding: 0 0 0.5rem 0;
}

.pricing-summary-shipping-location-selector. .shipping-location-options {
    float: left;
}

.pricing-summary-shipping-location-selector, .shipping-location-options {
    width: auto;
    overflow: hidden;
    float: left;
}

.shipping-location-options {
    padding: 0 0 0 0.5rem;
    float: right;
}

.shipping-location-options form {
    float: left;
}

.pricing-summary-shipping-location-selector {
    padding: 6px 0 7px 0;
}

.pricing-summary-total-amount {
    font-weight: 600;
    padding-top: 0.25rem;
    margin-top: 0.5rem;
    border-top: solid 0.05rem;
}

.canvas-app-add-to-cart-button {
    color: #fff;
    border: 1px solid #514945;
    width: 100%;
    overflow: auto;
    padding: 0.5rem 0 0.5rem 0;
    background: #514945;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
}

.canvas-app-rendering-container {
    width: auto;
    overflow: auto;
}

.only-matboard-option {
    overflow-y: auto;
    text-align: right;
    width: auto;
    border: solid 0.1rem #E9E9E9;
    padding: 1vh 0.5rem;
    text-align: center;
    display: inline-block;
    float: right;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.rendering-image-upload-button {
    width: auto;
    overflow: auto;
    padding: 0.5rem;
    border: solid 0.1rem #514945;
    border-radius: 0.25rem;
}

.canvas-app-rendering-upload-button label {
    width: auto;
    overflow: auto;
    margin: 0.75rem;
    padding: 0.15rem;
    display: inline-block;
}

.add-second-matting-button {
    width: 100%;
    overflow: auto;
    font-size: 0.8rem;
    float: right;
    padding: 0.5rem 0;
    text-align: right;
}

.delivery-estimation-window {
    font-size: 0.8rem;
    padding: 0.25rem 0;
}

.matting-width-header {
    padding: 0.2rem 0 0.5rem;
}

.matting-colour-selector-header, .matting-thickness-selector-header {
    width: auto;
    float: left;
}

.matting-colour-selector-form, .matting-thickness-selector-form {
    width: auto;
    /*overflow: auto;*/
    float: right;
    padding: 0.5rem 0;
}

.matting-thickness-selector-header {
    width: auto;
    float: left;
    padding: 0.5rem 0;
}

.mat-board-size-input-field{
    width: 5rem;
    float: left;
}

.shipping-location-options .jss28 {
    width: 85% !important;
}

.jss28 {
    width: 100% !important;
    text-align: center !important;
}

.jss36 {
    padding-right: 0 !important;
}

.mat-board-size-input-multiplying-sign {
    font-size: 0.8rem;
    padding: 0.5rem;
    float: left;
}

.canvas-app-rendering-moulding-layer {
    overflow: hidden;
    padding: 0;
    box-shadow: rgba(10, 10, 10, 0.6) 0 0.1rem 0.2rem inset;
}

.mat-board-size-selector {
    padding: 3vh 0 1.5vh 0;
}

.mat-board-size-selector, .selector-container {
    overflow-x: hidden;
}

.mat-board-opening-input-field {
    width: calc( 25% - 0.3rem );
    overflow-x: hidden;
    overflow-y: auto;
    float: left;
    margin: 0 0.15rem !important;
}

.MuiTypography-root-40 MuiTypography-body1-49 MuiTypography-colorTextSecondary-73 {
    margin-left: -0.2rem !important;
}

.MuiSelect-root-76 {
    border-bottom: solid 0.1rem !important;
    margin-right: 0.1rem;
}

.matting-width-selector {
    width: auto;
    padding: 1rem 0 0.75rem 0;
    overflow: auto;
}

.upload-image-button {
    width: auto;
    overflow-y: auto;
    float: right;
    padding: 0.1rem 0.5rem;
    text-align: center;
    border: solid 0.1rem #e9e9e9;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}


.visualization-tool-header {
    font-size: 1rem;
    padding: 0.2rem 0 0 0;
    font-weight: 600;
}

.order-summary-subsection-container {
    padding: 1rem 0 0 0;
}

.common-level {
    width: 100%;
    overflow-y: auto;
    padding: 0 0 0.75rem 0;
}

.jss38 {
    margin-left: 0 !important;
}

.canvas-app-rendering-options {
    width: auto;
    overflow-y: auto;
    float: left;
    padding: 0.1rem 0;
}

.frame-colour-selector {
    float: right;
    width: auto;
    padding: 0.1rem 0.15rem;
    text-align: center;
    border: solid 0.1rem #e9e9e9;
    margin: 0 0 0 0.2rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}

.frame-layer {
    width: 20rem;
    overflow-y: auto;
    padding: 1rem;
    margin: 4rem calc( 50% - 11rem);
    /*background-color: black;*/
    box-shadow: rgba(0, 0, 0, 0.6) 0.5rem 0.2rem 1rem;
    background-size: 100% 100%;
    z-index: 5;
}

.visualization-tool-section {
    padding: 0;
    border-bottom: solid 0.05rem;
    margin-bottom: 0.5rem;
}

.rendering-matting-dimensions-indicator {
    width: auto;
    overflow: auto;
    padding: 0.5rem;
    position: absolute;
    font-size: 0.7rem;
    line-height: 2;
}

.canvas-app-rendering-hide-text-button {
    width: auto;
    overflow-y: auto;
    margin: 0.5rem;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.canvas-app-rendering-expand-button {
    width: auto;
    overflow-y: auto;
    margin: 0.5rem;
    float: right;
}

.canvas-app-rendering-expand-button-text {
    width: auto;
    overflow-y: auto;
    padding: 0.15rem;
    float: left;
}

.app-button {
    transition: background-color linear 0.3s;
}

.app-button:hover {
    cursor: pointer;
    background-color: #f1ece4;
}

.app-button-selected {
    background-color: #f1ece4;
}

.selected-colour {
    display: inline;
}

.unselected-colour {
    display: none;
}

.add-more-window-button, .change-opening-shape-button {
    width: auto;
    overflow: auto;
    font-size: 0.7rem;
    float: right;
    padding: 0.25rem 0 0.5rem 0;
    margin-left: 0.5rem;
}

.app-underline-button:hover {
    cursor: pointer;
    color: #c88d75;
    text-decoration: underline;
}

.app-button-no-hovering {
    cursor: pointer;
}

.app-text-based-option {
    float: left;
    width: auto;
    min-width: 2rem;
    text-align: center;
    border: solid 0.1rem #E9E9E9;
    margin: 0 0 0 0.1rem;
    border-radius: 0.5rem;
    font-family: "Montserrat";
}

.no-display {
    display: none;
}

.canvas-app-rendering-upload-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.rendering-section-hovered {
    background-color: #99ccff !important;
}

.cursor-pointer {
    cursor: pointer;
}

.canvas-app-rendering-upload-button:hover {
    text-decoration: underline;
}

.incorrect-value-warnings {
    position: absolute;
    font-size: .7rem;
    left: 2.5rem;
    color: #f44336;
    padding-top: .3rem;
    background: #FFFCFC;
    padding: 0.5rem;
}

.input-section-highlight-warning {
    background-color: #ffcdd2;
}

.primary-matting-chevron {
    border-bottom: solid 0.05rem;
    margin: 0 0 0.5rem 0;
    text-align: center;
}

.canvas-app-rendering-secondary-matting-and-image-layer {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: 100% 100%;
    box-shadow: rgba(10, 10, 10, 0.8) 0 0.05rem 0.05rem inset;
}

.canvas-app-rendering-primary-matting-layer {
    box-shadow: rgba(10, 10, 10, 0.8) 0 0.05rem 0.05rem inset;
}

.canvas-app-rendering-wall-layer-color {

}

.wall-background {
    width: 30%;
    position: absolute;
    z-index: -5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*background-image: url("https://previews.123rf.com/images/vichie81/vichie811010/vichie81101000214/8089461-yellow-modern-brick-wall-pattern.jpg");*/
}

.second-matting-options {
    width: 100%;
    overflow: auto;
    padding-top: 0.5rem;
}

.matting-measurement-unit-indicator {
    width: auto;
    overflow: auto;
    float: right;
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
}

.canvas-app-rendering-uploaded-artwork {
    width: 100%;
    height: 100%;
}

.canvas-app-mat-dimensions {
    width: calc(35% - 4rem);
    overflow-y: auto;
    float: left;
    padding: 0rem 1.5rem 0 2.5rem;
}

.canvas-app-mat-rendering {
    width: 30%;
    overflow-y: hidden;
    float: left;
    background: #c88d75;
}

.canvas-app-rendering-pricing {
    width: calc(35% - 4rem);
    overflow-y: auto;
    float: left;
    padding: 0rem 2.5rem 0 1.5rem;
}

.app-text-based-option {
    float: right;
}

.canvas-app-rendering-pricing-section-options {
    line-height: 1.5;
}

/*Removing arrows for the value entry text field section*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiFormControl-root-1 {
    width: 100%;
}


.MuiInputAdornment-positionEnd-38 {
    margin-left: 0 !important;
}

.shipping-summary-item-container {
    padding: 0.5rem 0;
}

.shipping-summary-left {
    width: 50%;
    float: left;
}

.change-currency-button {
    width: auto;
    overflow: auto;
    float: right;
    border: solid 0.1rem #E9E9E9;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.3s linear;
}

.change-currency-button:hover {
    background: #f1ece4;
}

.forEx-information {
    text-align: right;
    margin-top: 1rem;
}

.question-mark-content {
    position: absolute;
    width: 60vw;
    height: 70vh;
    margin: 0 20vw;
    background-color: white;
    z-index: 10;
}

.no-mat-board-opening-indicator {
    width: 100%;
    overflow: auto;
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
    padding-top: 2rem;
}

.landing-page-show-more-icon {
    position: fixed;
    z-index: 15;
    width: 2rem;
    color: white;
    opacity: 0.5;
    bottom: 0.5rem;
    left: calc(50% - 1rem);
    animation: pulse 3s infinite;
}

.show-matting-width-guide {
    width: 12rem;
    overflow-y: auto;
    position: absolute;
    font-size: 0.8rem;
    padding: 0.25rem;
}

.wall-background img {
    object-fit: cover;
}

@keyframes pulse {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.9
    }
    100% {
        opacity: 0.2;
    }
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .MuiInputBase-root-18 {
        width: 90% !important;
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {

    .canvas-app-container {
        width: auto;
        overflow-y: auto;
        padding: 0;
    }

    .canvas-app-mat-dimensions, .canvas-app-mat-rendering, .canvas-app-rendering-pricing {
        width: calc(100% - 2rem);
        overflow-y: hidden;
        padding: 1rem;
    }

    .canvas-app-mat-rendering {
        background: red;
        height: 60vh;
        overflow: hidden;
    }

    .wall-background {
        width: calc( 100% - 2rem);
        height: 60vh;
    }

    .wall-background img {
        width: 100%;
        height: 60vh;
    }

    .matting-colour-selector-item {
        margin: 0.5rem 0.25rem;
    }

    .selected-colour {
        width: 1rem;
        height: 1rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 1rem;
        line-height: 2;
    }

    .MuiInputBase-root-18 {
        width: 90% !important;
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

    .app-text-based-option {
        padding: 0.1rem 0.15rem;
        font-size: 0.7rem;
    }

    .app-text-based-option {
        margin-top: 0.2rem;
    }

    .canvas-app-rendering-pricing-section {
        margin: 0.5rem 0;
    }

    .order-summary-remove-item-container {
        font-size: 0.8rem;
    }

    .delete-button {
        font-size: 0.6rem;
    }

    .shipping-summary-item-container {
        font-size: 0.8rem;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 20%;
        font-size: 0.6rem;
        font-weight: 600;
    }

    .pricing-summary-details, .pricing-summary-shipping-line-item {
        font-size: 1rem;
        margin: 1rem 0;
    }

    .shipping-summary-item-container {
        text-align: center;
    }

    .canvas-app-rendering-pricing-section-header {
        width: 100%;
        text-align: center;
    }

    .change-currency-button {
        font-size: 1rem;
        padding: 0.1rem 0.5rem;
        margin: 0.5rem 0;
    }

    .only-matboard-option {
        font-size: 0.7rem;
    }

    .forEx-information {
        font-size: 0.8rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .canvas-app-mat-dimensions {
        width: calc(35% - 4rem);
        overflow-y: auto;
        float: left;
        padding: 0rem 1.5rem 0 2.5rem;
    }

    .canvas-app-mat-rendering {
        width: 30%;
        height: 70vh;
        overflow-y: hidden;
        float: left;
        background: #c88d75;
    }

    .wall-background {
        height: 70vh;
    }

    .wall-background img {
        width: 100%;
        height: 70vh;
    }

    .canvas-app-rendering-pricing {
        width: calc(35% - 4rem);
        overflow-y: auto;
        float: left;
        padding: 0rem 2.5rem 0 1.5rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .selected-colour {
        width: 1rem;
        height: 1rem;
    }

    .MuiInputBase-root-18 {
        /*width: 90% !important;*/
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        font-size: 0.6rem;
        padding-top: 20%;
        font-weight: 600;
    }

    .app-text-based-option {
        padding: 0.1rem 0.15rem;
        font-size: 0.8rem;
    }

    .canvas-app-rendering-pricing-section {
        margin: 0.5rem 0;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .delete-button {
        font-size: 0.6rem;
    }

    .shipping-summary-item-container {
        font-size: 0.8rem;
    }

    .change-currency-button {
        font-size: 0.8rem;
        padding: 0.1rem 0.5rem;
        margin: 0.5rem 0 1rem 0;
    }

    .only-matboard-option {
        font-size: 0.8rem;
    }

    .forEx-information {
        font-size: 0.8rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .upload-image-button label {
        font-size: 1rem;
        line-height: 1;
    }

    .common-level .canvas-app-rendering-pricing-section-options, .pricing-summary-shipping-location-selector, .canvas-app-rendering-pricing-section-header {
        font-size: 1.3rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 1rem;
        line-height: 2;
    }

    .matting-dimensions-container {
        font-size: 1.3rem;
    }

    .mat-board-size-input-field {
        width: 7.5rem;
    }

    .mat-board-size-selector {
        padding: 1rem 0;
    }

    .window-size-selector, .matting-colour-selector, .matting-type-selector {
        padding: 3vh 0;
    }

    .add-more-window-button, .change-opening-shape-button, .add-second-matting-button {
        font-size: 1rem;
        margin-left: 1rem;
    }

    .matting-measurement-unit-indicator {
        font-size: 1rem;
    }

    .dimensions-unit-selector-container {
        min-height: 2.5rem;
        padding-top: .5rem;
    }


    .app-text-based-option {
        font-size: 1rem;
        padding: 0.2rem 0.4rem;
    }

    .matting-colour-selector-item {
        width: 1.5rem;
        height: 1.5rem;
    }

    .matting-width-selector-form-center-artwork-button {
        font-size: 1rem;
    }

    .MuiInputBase-input-28 {
        font-size: 1.3rem !important;
    }

    .MuiTypography-root-40 {
        font-size: 1.3rem !important;
    }

    .matting-width-header {
        margin-bottom: 1rem;
    }

    .primary-matting-dimensions-container {
        padding-bottom: 1rem;
        font-size: 1.3rem;
    }

    .MuiInputBase-root-18 {
        width: 100% !important;
    }

    .MuiSelect-select-77 {
        min-width: 6rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 20%;
        font-size: 1rem;
    }

    .app-text-based-option {
        padding: 0.1rem 0.5rem;
        font-size: 1rem;
    }

    .canvas-app-container {
        width: 95%;
        overflow-y: auto;
        padding: 6rem 0;
        margin: 0 2.5%;
    }

    .canvas-app-mat-rendering, .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        height: 50vh;
        background: #FFFCFC;
    }

    .wall-background {
        height: 50vh;
    }

    .wall-background img {
        width: 100%;
        height: 50vh;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .delete-button {
        font-size: 1rem;
    }

    .currency-information {
        font-size: 1.3rem;
    }

    .shipping-summary-item-container {
        font-size: 1rem;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 40%;
        font-size: 1rem;
    }

    .change-currency-button {
        font-size: 1rem;
        padding: 0.25rem 0.75rem;
        margin: 1rem 0 1.5rem 1.5rem;
    }

    .only-matboard-option {
        font-size: 1rem;
    }

    .forEx-information {
        font-size: 1rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {

    .upload-image-button label {
        font-size: 1.3rem;
        line-height: 1;
    }

    .canvas-app-container {
        width: 95%;
        overflow-y: auto;
        padding: 6rem 0;
        margin: 0 2.5%;
    }

    .canvas-app-mat-rendering {
        width: 40%;
    }

    .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        width: calc(30% - 4rem);
        height: 70vh;
    }

    /*.canvas-app-matsize-rendering-comp-pricing-section {*/
        /*padding: 0.5rem 0 0 0;*/
    /*}*/

    .canvas-app-rendering-pricing-section-options {
        width: 100%;
        overflow: auto;
        font-size: 1.5rem;
        line-height: 1.5;
        padding: 1rem 0;
    }

    .comp-pricing-summary-shipping-line-item {
        width: 100%;
        overflow: auto;
        margin-top: 1rem;
    }

    .canvas-app-pricing-container {
        width: 100%;
        overflow: auto;
        margin-top: 2rem;
    }

    .canvas-app-rendering-pricing-section-header {
        font-size: 1.5rem;
    }

    .canvas-app-mat-dimensions {
        font-size: 1.5rem;
        line-height: 2;
    }

    .mat-board-size-input-multiplying-sign {
        padding: 0 1rem;
        font-size: 1.5rem;
    }

    .mat-board-size-input-field {
        width: 10rem;
    }

    .mat-board-size-selector-form {
        padding: 0.75rem 0 0 0;
    }

    .add-more-window-button, .change-opening-shape-button {
        font-size: 1rem;
        margin-left: 2rem;
    }

    .matting-width-selector-form-center-artwork-button {
        font-size: 1.3rem;
        margin-top: 0.25rem;
    }

    .matting-colour-selector-form, .matting-thickness-selector-form {
        padding: 1rem 0;
    }

    .matting-colour-selector-item {
        width: 1.5rem;
        height: 1.5rem;
    }

    .selected-colour {
        width: 1.5rem;
        height: 1.5rem;
        padding-bottom: 0.5rem;
    }

    .visualization-tool-header {
        font-size: 1.5rem;
    }

    .app-text-based-option, .add-second-matting-button {
        font-size: 1rem;
        padding: 0.2rem 1rem;
    }

    .selector-container {
        width: 100%;
        overflow-y: auto;
        margin: 1rem 0 0 0;
    }

    .canvas-app-mat-dimensions {
        padding: 0 3rem 0 1rem;
    }

    .matting-measurement-unit-indicator {
        font-size: 1.3rem;
    }

    .common-level {
        padding: 0 0 1rem 0;
    }

    .second-matting-options .app-text-based-option {

    }

    .wall-background {
        width: 40%;
        height: 70vh;
        background-repeat: repeat;
        background-size: 100%;
    }

    .wall-background img {
        width: 100%;
        height: 70vh;
    }

    .primary-matting-chevron {
        margin-top: 0.5rem;
    }

    .canvas-app-mat-rendering, .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        height: 70vh;
        background: #FFFCFC;
    }

    /*.comp-pricing-section-line-item {*/
        /*margin: 1rem 0;*/
    /*}*/

    .canvas-app-add-to-cart-button {
        font-size: 1.5rem;
        padding: 1rem 0;
    }

    .pricing-summary-total-amount {
        padding-top: 1.5rem;
    }

    .dimensions-unit-selector-container {
        min-height: 2.5rem;
    }

    .MuiInputBase-input-28 {
        font-size: 1.5rem !important;
    }

    .MuiTypography-root-40 {
        font-size: 1.5rem !important;
    }

    .MuiInputBase-root-18 {
        width: 9rem !important;
    }

    .MuiInputBase-root-18 {
        width: 100% !important;
    }

    .MuiSelect-select-77 {
        min-width: 8rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 40%;
        font-size: 1.3rem;
    }

    .app-text-based-option {
        padding: 0.1rem 0.25rem;
        font-size: 1.3rem;
    }

    .order-summary-subsection-container {
        font-size: 1.3rem;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .currency-information {
        font-size: 1.3rem;
    }

    .delete-button {
        font-size: 1rem;
    }

    .shipping-summary-item-container {
        font-size: 1.3rem;
    }

    .jss76 {
        font-size: 1.3rem !important;
        margin-left: 2rem !important;
    }


    .jss39 {
        font-size: 1.3rem !important;
    }

    .pricing-summary-shipping-line-item {
        margin: 1rem 0;
    }

    .change-currency-button {
        font-size: 1.3rem;
    }

    .change-currency-button {
        font-size: 1.3rem;
        padding: 0.5rem 1rem;
        margin: 1rem 0 1.5rem 1.5rem;
    }

    .only-matboard-option {
        font-size: 1rem;
    }

    .forEx-information {
        font-size: 1rem;
    }

    .go-to-pricing-info {
        font-size: 1rem;
    }

}
