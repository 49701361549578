body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*For all*/

a, div {
    letter-spacing: 0.025rem;
    color: initial;
}

.display-bottom-blur {
    width: 100%;
    position: fixed;
    height: 40vh;
    bottom: 0;
    background: rgba(84, 84, 84, 0.25);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    z-index: 30;
}

.fair-pricing-discount-section-container {
    margin-bottom: 128px;
}

.navbar-top-container {
    background-color: #FFFFFF;
    width: 100%;
    overflow: auto;
}

.navbar-top-container-narrow {
    background-color: #FFFFFF;
    width: 100%;
    overflow: auto;
    position: fixed;
}

.navbar-top-company-logo {
    font-family: 'Montserrat';
    font-weight: 400;
    float: left;
}

.navbar-top-company-logo-contianer {
    width: auto;
    overflow-y: auto;
}

.navbar-top-company-name {
    text-transform: uppercase;
    float: left;
    letter-spacing: 0.1rem;
}

.navbar-top-company-name-large-font {
    font-size: 1.4rem;
}

.navbar-top-company-name {
    font-size: 1.3rem;
}

.navbar-top-button {
    float: left;
}

.product-list-web-container {
    width: 24.18vw;
    height: 4.1vw;
    float: left;
    color: #72696A;
    font-family: Montserrat;
    letter-spacing: 0.33px;
    line-height: 1.32vw;
    text-align: right;
    position: absolute;
    margin: 1.39vw 1.39vw 1.39vw 18.36vw;
}

.navbar-top-web-language-selected {
    font-weight: 500;
}

.navbar-top-cart-icon {
    width: 100%;
    overflow: auto;
}

.navbar-top-button a {
    color: #F59276;
    font-size: 1.11vw;
    line-height: 1.597vw;
}

#navbar-top-start-designing-button a:hover {
    color: white;
}

a div:hover {
    transition: background-color 0.5s ease;
}

a:hover {
    transition: background-color 0.5s ease;
    /*text-decoration-color: #F59276;*/
}



.navbar-top-button-inner-layer {
    padding-top: 1.01vw;
    padding-bottom: 1.01vw;
    color: #F59276;
    font-weight: 400;
    border: solid 0.21vw #f59276;
    cursor: pointer;
}

.navbar-top-button-inner-layer:hover {
    color: white;
}

#navbar-top-start-designing-button:hover {
    text-decoration: none;
    color: white;
    background: #f59276;
}

.navbar-top-button:hover {
    color: white;
}

.navbar-top-button a:hover {
    color: white;
}

.navbar-top-company-description-br {
    display: none;
}

.navbar-top-company-name-logo-image {
    width: 100%;
    overflow: auto;
}

.primary-matting-chevron {
    cursor: pointer;
}

.mat-board-size-selector-header {
    padding: 0.5rem 0 0 0;
}

.question-mark-icon-container {
    width: auto;
    margin: 0;
    float: right;
    cursor: pointer;
}

.question-mark-icon {
    margin: 0.2rem 0 0 0.2rem;
}

.canvas-app-rendering-option {
    padding-top: 0;
}

.canvas-app-rendering-wall-layer {
    width: 100%;
    overflow-y: auto;
    background-color: mintcream;
}

.canvas-app-rendering-expand-button {
    cursor: pointer;
}

.canvas-app-rendering-expand-button-script {
    float: left;
    padding: 0.25rem;
}




.landing-page-banner {
    margin: 0;
}

.navbar-top-company-description {
    width: auto;
    overflow: auto;
    float: left;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 500;
    color: #f59276;
    margin-left: 0.5rem;
    line-height: 1.5;
    margin-top: 0.9rem;
    text-transform: lowercase;
}

.navbar-top-gallery-container {
    width: auto;
    float: left;
    margin-top: 1.5rem;
    margin-left: 1rem;
    font-size: 1.3rem;
    color: #f59276;
    line-height: 1;
}

.navbar-top-gallery-container span {
    font-size: 0.8rem;
    text-transform: lowercase;
}

.navbar-top-gallery-web-button a,
.navbar-top-contact-web-button a,
.product-list-web-container a,
.navbar-top-web-language a {
    color: #72696A;
}

.navbar-top-web-cart, .navbar-top-web-cart-icon {
    cursor: pointer;
}

.support-v2-dropdown-button {
    width: 100%;
}

.support-v2-dropdown-button:hover {
    text-decoration: underline;
}

.cart-item-count-container {
    width: 20px;
    height: 20px;
    background: #3F5C6B;
    border-radius: 100%;
    margin-top: -45px;
    margin-left: 23px;
    z-index: 50;
    position: absolute;
}

.cart-item-count {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: white;
    width: 20px;
    height: 20px;
    padding: 1px;
}

.bag-v2 {
    cursor: pointer;
}

.bag-v2-narrow {
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 1100px) {

    .support-v2-dropdown-button {
        margin-bottom: 24.5px;
    }

    .support-v2-dropdown {
        padding: 24.5px 0 0 24.5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #3B3B3B;
        text-transform: none;
        background: white;
        z-index: 100;
    }




    .start-designing-page-container {
        margin-top: 4rem;
        margin-bottom: 4rem;
        border-top: 0.05rem #f59276 solid;
    }

    /*.navbar-top-company-description {*/
    /*    font-size: 0.7rem;*/
    /*    margin: 0;*/
    /*    padding: 0;*/
    /*}*/

    /*.landing-page-banner {*/
    /*    font-size: 2.0778vw;*/
    /*}*/

    /*.mobile-no-display {*/
    /*    display: none;*/
    /*}*/

    .mat-board-size-selector-header {
        font-size: 1rem;
    }

    .question-mark-icon {
        width: 0.8rem;
    }


    .product-list-web-container {
        display: none;
    }

    .navbar-top-button {
             display: none;
    }


    .navbar-top-company-logo {
        width: 80%;
    }

    .question-mark-icon {
        width: 0.8rem;
    }

    /*.navbar-top-button-groups {*/
    /*    width: 100%;*/
    /*    overflow-y: auto;*/
    /*}*/
}

/*Normal Laptop and External Monitors*/








@media screen and (min-width: 0px) and (max-width: 320px) {
    .navbar-top-company-desc-phrase-one {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 769px) {

    .mobile-landing-page-title {
        width: 100%;
        height: 136px;
        overflow: hidden;
        background: #8DB09D;
        padding: 25px 0 40px 0;
    }

    .mobile-landing-page-title-head {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: white;
        margin: 0;
    }

    .mobile-landing-page-title-subhead {
        color: white;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }

    .bag-icon-v2 {
        width: 70%;
        margin: 15%;
    }

    .navbar-top-button-groups {
        width: 100vw;
        overflow-y: hidden;
        /*position: fixed;*/
        background: white;
        z-index: 50;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow-y: hidden;
        height: auto;
        position: fixed;
        background: white;
        z-index: 100;
        top: 0;
    }

    #what-is-mat-header-v2-moblet {
        width: 90%;
        height: auto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin: 64px 5% 32px;
    }

    .mobile-navbar-open {
        width: 100%;
        height: auto;
        position: fixed;
        z-index: 100;
        background-color: #FFFFFF;
        /* margin-top: -10px; */
        top: 68px;
    }

    #what-is-mat-header-moblet-specific {
        margin-top: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        width: 100%;
        height: auto;
    }

    .instagram-scroller {
        width: auto;
        overflow-y: auto;
        margin-bottom: 120px;
    }

    #landing-page-button-standard-moblet-specific {
        position: static;
        width: 160px;
        height: auto;
        left: 68.5px;
        top: 156px;
        background: #FFFFFF;
        border-radius: 30px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 24px auto;
        padding: 16px 24px;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        top: 50px;
        /* height: 700px; */
        /* min-height: 700px; */
        position: absolute;
        margin-top: 248px;
    }

    .landing-page-image-overlay-black-v2 {
        width: 100%;
        height: 700px;
        position: absolute;
        background: #666161;
        opacity: 0.36;
    }

    .landing-page-product-list {
        width: 100%;
        overflow: auto;
    }



    .landing-page-subtext-first-v2 {
        /*position: absolute;*/
        width: 100%;
        /*height: 92px;*/
        /* left: 58px; */
        margin-top: 0px;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        min-height: 640px;
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: 218px;
        height: 46px;
        /* left: 71px; */
        top: 281px;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        margin-left: calc(50% - 109px);
    }

    .landing-page-start-button-v2 {
        color: #3B3B3B;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: inline-flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 30px;
        background: #FFF;
        width: 250px;
        height: 20px;
        z-index: 30;
        position: absolute;
        margin: -46px calc( 50% - 149px);
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }

    .landing-page-review-stars-v3 {
        width: 100%;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars-v2 {
        margin-bottom: 24px;
    }

    .what-is-mat-header-v2 {
        width: 248px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
        margin-left: calc(50% - 124px);
        margin-top: 0;
    }

    .product-benefits {
        padding: 25px 0;
    }

    .customizable-subcontainer {
        width: 100%;
        overflow: auto;
    }

    .customizable-subhead {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-align: left;
        width: 248px;
        margin: 24px calc(50% - 124px);
    }





    .what-is-mat-image-v2-mobile {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-line-break-v2 {
        width: 250px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin: 24px calc(50% - 125px);
    }

    #landing-page-standard-right-moblet-specific {
        margin-top: 64px;
        padding-top: 64px;
    }

    #what-is-mat-header-moblet-specific {
        margin-top: 0;
    }

    #what-is-mat-desc-moblet-specific {
        width: 90%;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        margin: 24px 5% 0 5%;
        font-style: normal;
    }

    .what-is-mat-image-v2 {
        width: 94%;
        padding: 0 2%;
        height: auto;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 5px 5px;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 5px;
        float: right;
    }



    .mobile-menu-button {
        margin-right: 15px;
    }

    .navbar-button-v2 {
        display: none;
    }

    .navbar-button-v2-narrow {
        display: none;
    }

    .navbar-start-designing-button-v2 {
        display: none;
    }


    .logo-vertical-v2 {
        width: 120px;
        overflow: auto;
        margin: 15px 24px;
        float: left;
    }

    .logo-vertical-v2-narrow {
        width: 120px;
        overflow: auto;
        margin: 15px 24px;
        float: left;
    }


    #navbar-top-company-description-br-responsive {
        display: inline;
    }

    .navbar-top-company-logo {
        width: 80%;
    }

    .navbar-top-gallery-web-button, .navbar-top-contact-web-button {
        display: none;
    }

    .navbar-top-company-name-logo {
        width: 20vw;
        height: 12.1875vw;
        margin: 1.5625vw calc( 50% - 10vw);
        position: absolute;
    }

    .navbar-top-mobile-button {
        display: none;
        width: 48px;
        height: 48px;
        float: right;
        text-align: center;
        cursor: pointer;
        margin: 8px 15px;
    }

    .navbar-top-mobile-button img {
        width: 100%;
    }

    .navbar-top-web-cart {
        width: 13.125vw;
        height: 13.125vw;
        float: left;
        text-align: center;
        cursor: pointer;
        margin: 1.5625vw 5vw 1.5625vw 58.75vw;
    }

    .navbar-top-web-language {
        display: none;
    }

    .mobile-no-display {
        display: none;
    }

    .landing-page-banner {
        font-size: 4.375vw;
        text-align: center;
        background-color: #F59276;
        color: white;
        font-size: 4.375vw;
        line-height: 5.625vw;
        font-weight: 300;
        padding: 3.75vw 0;
    }

    .landing-page-banner-highlight {
        font-size: 7.5vw;
        line-height: 9.0625vw;
        color: #72696A;
        letter-spacing: 0.15625vw;
        margin: 0;
    }

    .landing-page-banner-subtitle {
        margin: 5vw 0;
        font-size: 3.75vw;
        font-weight: 400;
        color: #656565;
        line-height: 5.9375vw;
    }

    .landing-page-desc-container {
        width: calc(100% - 4vw);
        position: absolute;
        margin: 12.5vw 2vw;
        text-align: center;
    }

    .landing-page-start-designing-button {
        position: absolute;
        margin: 67.71875vw 19.84375vw 0 19.84375vw;
        width: 60.3125vw;
        text-align: center;
        padding: 3.28125vw 0;
        border: 0.9375vw solid #F59276;
        cursor: pointer;
    }

    .landing-page-start-designing-button:hover {
        background-color: #F59276;
        color: white;
    }

    .landing-page-start-designing-button a {
        text-decoration: none;
        color: #F59276;
        cursor: pointer;
    }

    .landing-page-design-now-button {
        text-decoration: none;
        color: #F59276;
        /*cursor: pointer;*/
    }

    .landing-page-image-file {
        width: 180%;
        overflow: auto;
        margin: 0 -40%;
    }

    .product-offering-header {
        text-align: center;
        font-weight: 400;
        letter-spacing: 0.15625vw;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 7.5vw;
    }
    
    .mobile-navbar-options-container a {
        width: 100%;
        overflow: auto;
    }


    .navbar-top-button-mobile {
        width: auto;
        float: left;
        text-align: left;
        margin-bottom: 23px;
        text-transform: uppercase;
        color: #3B3B3B;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 20px;
        max-width: 200px;
    }

    #navbar-top-button-mobile-language-selection {
        text-align: right;
        float: right;
    }

    .navbar-top-button-chevron {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        margin-right: 22.5px;
        margin-top: 27.5px;
    }

    .navbar-mobile-header-indicator-img {
        width: 52px;
        height: 52px;
        position: relative;
        float: left;
    }


    
    .mobile-nav-head-section {
        width: 100%;
        overflow: auto;
    }

    .mobile-nav-close-icon {
        width: 34px;
        height: 34px;
        float: right;
        padding: 9px;
        margin-right: 8px;
    }

    .mobile-language-selection-flag {
        width: 26px;
        height: 26px;
        margin-left: 10px;
        border-radius: 100%;
        float: left;
    }

    .mobile-language-selection-flag-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        color: white;
        margin-left: 10px;
        float: left;
    }

    .mobile-language-selection-bar {
        background: #3F5C6B;
        padding: 7px 0px 7px 4px;
        width: auto;
        overflow: auto;
    }
    
    .select-currency-options {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-currency-row {
        width: 100%;
        overflow: auto;
        margin-top: 5px;
    }

    .navbar-top-button-header {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        margin: 30px 20px 12px;
    }

    /*.landing-page-container {*/
    /*    margin-top: 100px;*/
    /*}*/



    .navbar-top-button-subheader {
        margin: 18px 20px;
        max-width: 200px;
        color: #686868;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: capitalize;
    }

    .navbar-mobile-header-indicator {
        width: 75%;
        float: left;
        overflow: auto;
    }

    .navbar-mobile-header-indicator-text {
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        width: auto;
        overflow: auto;
        margin-top: 15px;
    }


    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }
    
    .currency-description {
        width: auto;
        float: left;
        overflow: auto;
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        margin-left: 5px;
    }

    .select-currency-flag {
        float: left;
        width: 26px;
        height: 26px;
    }

    .select-currency-option-selected {
        background: #3B3B3B;
    }
    
    .navbar-top-button-mobile-menu {
        width: 100%;
        overflow: auto;
        margin-bottom: 10px;
    }



    .mobile-navbar-open-wide-no-display {
        display: none;
        /*margin-right: -280px;*/
        transition: margin 1000ms ease-in-out;

    }

    .mobile-navbar-open-wide {
        width: 100%;
        height: 100vh;
        overflow: auto;
        z-index: 120;
        position: fixed;
        background: rgba(84, 84, 84, 0.50);
        -webkit-backdrop-filter: blur(2.5px);
                backdrop-filter: blur(2.5px);
        top: 0;
    }

    .mobile-navbar-options-container {
        width: 273px;
        overflow: auto;
        z-index: 100;
        background: white;
        float: right;
        margin-left: calc( 100% - 273px);
        position: relative;
        animation: mobileDrawer 100ms;
    }

    @keyframes mobileDrawer {
        0% {margin-right: -280px;}
        100% {margin-right: 0px;}
    }

    .mobile-navbar-options-menu {
        color: #F59276;
        margin: 5vh 0 8.125vw 0;
        font-size: 5vw;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mobile-navbar-closeout-button {
        width: 15vw;
        height: 15vw;
        color: #726969;
        margin-top: 3vw;
        float: right;
        position: fixed;
        top: 0;
        left: 63vw;
    }

    .mobile-language-selection {
        font-size: 5vw;
        text-align: center;
        width: 15.9375vw;
        overflow: auto;
        position: fixed;
        bottom: 10.9375vw;
        left: 32.5vw;
    }

    .mobile-navbar-options-submenu {
        font-size: 3.75vw;
        text-align: center;
        text-transform: uppercase;
        color: #F59276;
        font-weight: 600;
        margin-bottom: 3.75vw;
    }
    
    .landing-page-header-v2 {
        width: 100%;
        height: 620px;
        overflow: auto;
    }

    .navbar-top-cart-icon-counter {
        font-size: 3.125vw;
        margin-top: -13vw;
        width: 4.6875vw;
        height: 4.6875vw;
        margin-left: 9vw;
        background-color: #F59276;
        color: white;
        border-radius: 100%;
        line-height: 4.0625vw;
        padding-top: 0.75vw;
        text-align: center;
        position: absolute;
        padding-left: 0.3vmin;
    }
    
    .what-is-mat-page-v2 {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-page-v2 {
        text-align: left;
    }

}
@media screen and (min-width: 769px) and (max-width: 1100px) {

    /*.mobile-navbar-options-container {*/
    /*    width: calc(100% - 45px);*/
    /*    overflow: auto;*/
    /*    padding-right: 45px;*/
    /*    padding-bottom: 4.5px;*/
    /*    padding-top: 4.5px;*/
    /*    background: white;*/
    /*    position: fixed;*/
    /*    z-index: 100;*/
    /*}*/

    .navbar-top-button-groups {
        width: 100%;
        overflow-y: hidden;
        height: 160px;
        background: white;
        position: fixed;
        z-index: 30;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow-y: hidden;
        height: auto;
        position: fixed;
        background: white;
        z-index: 100;
        top: 0;
    }

    .support-v2-dropdown-button {
        text-align: right;
    }

    .mobile-navbar-open {
        width: 100%;
        overflow: auto;
        z-index: 100;
        background-color: #FFFFFF;
        position: fixed;
        top: 69px;
    }

    #mobile-menu-button-tablet {
        margin-right: 32px;
        margin-top: 40px;
    }

    .navbar-top-container {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        overflow: hidden;
    }

    .navbar-top-mobile-button {
        display: none;
    }

    .navbar-start-button {
        width: 200px;
        float: right;
        overflow: auto;
        margin-top: 38px;
        margin-right: 24px;
    }

    .navbar-top-gallery-web-button {
        font-size: 2.08vw;
        margin: 5.195vw 6.494vw;
        float: left;
    }

    .navbar-top-contact-web-button {
        font-size: 2.08vw;
        margin: 5.195vw 0;
        float: left;
    }

    .navbar-top-company-name-logo {
        width: 14.805vw;
        height: 4.79vw;
        position: absolute;
        float: left;
        margin: 1.04vw calc( (100% - 14.80vw) / 2);
    }

    .navbar-top-web-cart {
        width: 4.156vw;
        height: 3.896vw;
        float: left;
        margin: 4.675vw 0;
    }

    .navbar-top-web-language {
        width: auto;
        height: auto;
        color: #5F6062;
        margin: 5.194vw 6.494vw;
        float: left;
        font-size: 2.08vw;
    }

    .landing-page-banner {
        background: #F59276;
        color: white;
        padding: 1.299vw 0;
        font-weight: 300;
        text-align: center;
        font-family: Montserrat;
        font-size: 2.08vw;
    }

    .navbar-top-web-right-container {
        width: auto;
        float: left;
        position: absolute;
        margin-left: 73vw;
    }

    .navbar-top-cart-icon-counter {
        position: absolute;
        width: 2.5974vw;
        height: 2.5974vw;
        border-radius: 100%;
        text-align: center;
        margin-top: -5.25vw;
        margin-left: 2.25vw;
        color: #FFFFFF;
        background: #F59276;
        font-size: 1.818vw;
        padding-top: calc((2.5974vw - 2.33766vw) /2);
        padding-left: 0.15vw;
        line-height: 2.33766vw;
        font-weight: 300;
    }

    .logo-vertical-v2 {
        float: left;
    }

    .logo-vertical-v2-narrow {
        float: left;
    }

    .bag-icon-v2 {
        width: 48px;
        height: 48px;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 40px 15px 40px 0;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 15px 5px 0;
        float: right;
    }







}


@media screen and (min-width: 1100px) {

    .cart-slider-container {
        position: absolute;
        background: #ffffff;
        width: 100%;
        height: auto;
        z-index: 30;
    }

    .navbar-web-hover {
        width: 100%;
        height: 30vh;
        position: fixed;
        top: 126px;
        left: 0;
        background: white;
        padding: 54px 132px;
        overflow: hidden;
        z-index: 10;
    }

    .navbar-web-hover-column {
        width: 24.5%;
        float: left;
    }


    .mobile-menu-button {
        display: none;
    }

    .navbar-v2 {
        position: fixed;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }

    .navbar-v2-non-fixed {
        position: relative;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }



    .navbar-v2-narrow {
        position: fixed;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }

    .navbar-button-v2 {
        width: auto;
        padding: 54px 16px;
        float: left;
        overflow: auto;
        cursor: pointer;
    }

    /*.navbar-button-v2:hover {*/
    /*    text-decoration: underline;*/
    /*}*/

    .logo-vertical-v2 {
        margin: 47.55px 98px  47.55px 4.1%;
        float: left;
    }

    .logo-vertical-v2-narrow {
        margin: 15.55px 128px 15.55px 4.1%;
        float: left;
    }

    .navbar-start-button {
        width: 200px;
        overflow-y: auto;
        float: right;
        margin: 38px 32px;
    }


    .navbar-button-v2-narrow {
        width: auto;
        height: 30px;
        margin: 22.5px 0 22.5px 16px;
        float: left;
        color: #3b3b3b;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 40px 15px 40px 0;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 15px 5px 0;
        float: right;
    }

    .bag-icon-v2 {
        margin: 4px;
        width: 40px;
        height: 40px;
    }




    .navbar-start-designing-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin: 38px 1.5%;
        float: right;
        width: 142px;
        height: auto;
        right: 190.66px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

    }

    .enfr-v2 {
        width: 57px;
        float: right;
        margin-left: 0;
        font-weight: 400
    }

    .navbar-top-web-language-selected {
        font-weight: 700 !important;
    }

    .enfr-v2 a span {
        font-weight: 400;
    }

    .navbar-web-hover-column-narrow {
        width: 15%;
        float: left;
    }

    .navbar-web-hover-title {
        width: 100%;
        overflow: auto;
        color: #3B3B3B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 225%; /* 36px */
        text-transform: uppercase;
    }

    .navbar-web-hover-button {
        color: #686868;
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 225%; /* 36px */
        margin-top: 4px;
        text-transform: capitalize;
    }


    .event-message-version2, .event-message-version2-fr {
        /*width: 300px;*/
        /*margin: 0 0 0 calc(50% - 150px);*/
        width: 40%;
        /*height: 24px;*/
        margin: 0 0 0 30%;
        float: left;
        text-transform: none;

        /* Body */
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        align-items: center;
        text-align: center;

        /* Secondary/White */
        color: #FFFFFF;
        padding-top: 4px;
        font-weight: 600;
    }

    .notice-bar-region-icon {
        color: white;
        width: 20px;
        height: 20px;
        margin: 5.5px;
        float: left;
    }

    .notice-bar-region-text {
        float: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: white;
        margin-top: 3.5px;
    }

    .notice-bar-region {
        color: white;
        float: right;
        text-transform: none;
        margin-right: 100px;
    }

    .notice-bar-contact-us {
        color: white;
        float: right;
        text-transform: none;
        margin-right: 64px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-top: 3.5px;
    }

    .navbar-web-hover-button:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .navbar-top-button-groups {
        width: 100%;
        overflow-y: hidden;
        height: 130px;
        padding-bottom: 28px;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow: hidden;
        height: 64px;
    }

    .support-v2-dropdown {
        padding: 16px 25px 25px 25px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: #3B3B3B;
        text-transform: none;
        background: white;
        position: absolute;
        z-index: 100;
    }

    .mobile-navbar-open {
        display: none;
    }

    .navbar-top-web-right-container {
        width: auto;
        float: left;
        position: absolute;
        margin-left: 70vw;
    }

    .navbar-top-container {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        font-size: 1.11vw;
        overflow: hidden;
    }

    .navbar-top-company-logo {
        font-size: 1.5rem;
        padding-top: 0.25vw;
    }

    #navbar-top-start-designing-button {
        text-transform: uppercase;
        width: auto;
        padding: 0;
        width: 13.19vw;
        text-align: center;
        margin: 1.63vw 0 1.63vw 0;
    }

    .navbar-top-company-name-logo {
        width: 7.92vw;
        height: 4.79vw;
        position: absolute;
        float: left;
        margin: 1.04vw 44.9vw;
    }


    .navbar-top-company-description {
        width: auto;
        overflow: auto;
        float: left;
        font-size: 0.8rem;
        margin: 0;
        font-weight: 500;
        color: #f59276;
        margin-left: 0.5rem;
        line-height: 1.5;
        margin-top: 0.75rem;
        text-transform: lowercase;
    }

    .landing-page-banner {
        background: #F59276;
        color: white;
        padding: 0.76vw;
        text-align: center;
        font-family: Montserrat;
        font-weight: 300;
        line-height: 1.32vw;
        font-size: 1.11vw;
    }

    .question-mark-icon {
        width: 0.8rem;
    }


    .start-designing-page-container {
        margin-top: 5rem;
        margin-bottom: 5rem;
        border-top: 0.05rem #f59276 solid;
    }

    .navbar-top-mobile-button {
        display: none;
    }

    .mat-board-size-selector-header {
        font-size: 1rem;
    }

    .question-mark-icon {
        width: 0.8rem;
    }

    .start-designing-page-container {
        margin-top: 5rem;
        margin-bottom: 5rem;
        border-top: 0.05rem #f59276 solid;
    }

    .navbar-top-gallery-web-button {
        font-size: 1.11vw;
        margin: 2.78vw 0 2.78vw 4.65vw;
        float: left;
    }

    .navbar-top-contact-web-button {
        float: left;
        margin: 2.78vw 0 2.78vw 2.78vw;
    }

    .navbar-top-web-cart {
        width: 2.22vw;
        height: 2.22vw;
        float: left;
        margin: 2.36vw 0 2.36vw 3.57vw;
    }

    .navbar-top-web-language {
        width: 4vw;
        height: 1.32vw;
        color: #5F6062;
        margin: 2.76vw 0.5vw 2.76vw 2.33vw;
        float: left;
    }

    .navbar-top-cart-icon-counter {
        position: absolute;
        width: 1.3888vw;
        border-radius: 100%;
        text-align: center;
        margin-top: -3vw;
        margin-left: 1.25vw;
        color: #FFFFFF;
        background: #F59276;
        font-size: 0.972vw;
        padding-top: calc((1.3888vw - 1.25vw) /2);
        line-height: 1.25vw;
        font-weight: 300;
    }
}

@media screen and (min-width: 1920px) {
    .navbar-top-button-groups-narrow {
        max-width: 1440px;
        margin-left: calc( 50% - 770px );
    }

    .cart-slider-container {
        position: absolute;
        background: #ffffff;
        width: 1440px;
        height: auto;
        z-index: 30;
    }

    .display-bottom-blur {
        width: 1440px;
        position: fixed;
        height: 50vh;
        bottom: 0;
        background: rgba(84, 84, 84, 0.25);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        z-index: 30;
    }

    .navbar-web-hover {
        width: 1440px;
        height: 30vh;
        position: fixed;
        top: 122px;
        left: 0;
        background: white;
        overflow: hidden;
        margin-left: calc(50% - 720px);
        z-index: 10;
    }

    .select-mat-container-narrow {
        overflow: auto;
        width: 1440px;
        margin-left: calc(50% - 720px) !important;
        min-height: 80vh;
    }


    .navbar-bottom-company-info-section {
        width: 1220px !important;
        overflow: auto !important;
        margin-left: 110px;
    }

    .general-static-page-container {
        width: 1120px !important;
        margin: 32px auto !important;
    }

}
/*For all*/
.landing-page-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: #FFFFFF;
}

.workflow-section {
    margin-top: 300px
}


.customizable-head {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 127.778% */
    letter-spacing: 3.6px;
    text-transform: uppercase;
    color: #3B3B3B
}





.landing-page-review-stars-container {
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
    padding: 64px 0;
}


video {
    -webkit-clip-path: inset(1px 1px);
            clip-path: inset(1px 1px);
}









.select-mat-intro {
    width: 100%;
    overflow: auto;
    position: relative;
}

.landing-page-banner a:hover {
    text-decoration: underline;
    color: white;
}

#matboard-only-mobile-landing {
    width: 100%;
    margin: 3vw 0 5vw 0;
}

.matting-dimension-indicator-helper {
    display: none;
    position: absolute;
}

.matting-dimension-indicator-helper-display {
    display: inline;
    position: absolute;
    background-color: rgb(118, 177, 228);
    text-align: left;
    font-size: 0.8rem;
}

.matting-dimension-indicator-helper-display-text {
    width: 14rem;
    overflow-y: auto;
    margin-top: 0.35rem;
    padding-left: 1rem;
    margin-left: 0.55rem;
    position: absolute;
    background-color: white;
    line-height: 1.5;
    font-size: 0.8rem;
    z-index: 10;
}

.matting-dimension-indicator-helper-arrow {
    width: 1rem;
    background-color: transparent;
    margin-left: -1rem;
    margin-top: 0.1rem;
    position: absolute;
}

.matting-dimension-indicator-helper-container {
    width: auto;
    overflow-y: auto;
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.landing-page-design-matboard-transition {
    background: #f1ece4;
    text-align: center;
    padding: 15vh 0 10vh 0;
    font-weight: 600;
    color: #000;
}

.introduction-section-1-highlight {
    color: #6a396a;
}





.landing-page-how-it-works-transition {
    font-weight: 300;
}

.shipping-info-highlights {
    font-weight: 500;
    color: white;
}

.shipping-info-highlights div {
    width: auto;
    float: left;
    overflow: auto;
}

.shipping-info-highlights-cities {
    color: white;
    cursor: pointer;
}

.shipping-info-highlights-cities:hover {
    text-decoration: underline;
}



.landing-page-how-it-works-transition-span {
    font-weight: 500;
    font-size: 0.8rem;
}

.landing-page-design-now-button:hover {
    text-decoration: none;
    background-color: #F59276;
    color: white;
}

.landing-page-image {
    width: 100%;
    overflow: auto;
}

.blog-type-2-image-file {
    width: 100%;
    overflow: auto;
}







/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: center;
        font-size: 16px;
    }



    .landing-page-transition-v2 {
        overflow-y: auto;
    }

    .notice-bar-version2-event {
        position: static;
        width: 100%;

        background: black;
        overflow-y: auto;

        left: 0.83px;
        top: 0px;


        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 0px 0px;
    }

    .notice-bar-version2 {
        position: static;
        width: 100%;
        /*height: 32px;*/
        background: #3F5C6B;


        left: 0.83px;
        top: 0px;


        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 0px 0px;
    }

    .landing-page-bottom-text-highlight {
        font-weight: 700;
        font-size: 24px;
    }

    .what-is-mat-desc-v2 {
        width: 248px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-left: calc(50% - 124px);
        text-align: left;
        margin-bottom: 64px;
    }

    .benefits-summary-container {
        width: 248px;
        margin-left: calc(50% - 124px);
        overflow: auto;
    }

    .benefits-summary-component-img {
        width: 82px;
        height: 73px;
        margin: 37px 83px 16px 83px;
    }

    /*.mat-transition-container {*/
    /*    width: 100%;*/
    /*    height: 180px;*/
    /*    overflow: hidden;*/
    /*}*/

    .benefits-page-container {
        width: 100%;
        overflow: auto;
        padding: 64px 0;
    }
    
    #how-it-works-video1, #how-it-works-video2 {
        width: 100%;
        overflow: auto;
        margin: 0;
    }

    #how-it-works-video1 {
        margi-top: 32px;
    }

    #how-it-works-video2 {
        margi-top: 64px;
    }

    .landing-page-button-standard-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 194px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        margin: 64px calc( 50% - 97px) 0;
    }

    #how-it-works-substeps-3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    #how-it-works-substeps-1, #how-it-works-substeps-2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-top: 24px;

    }


    .how-it-works-header-left, how-it-works-header-right {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }

    .how-it-works-step2-desc-v2 {
        width: 260px;
        margin: 32px calc( 50% - 124px);
        overflow: auto;
    }

    #how-it-works-image1, #how-it-works-image2 {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: auto;
    }

    .how-it-works-subheader-right u {
        text-decoration: none;
    }
    .mat-transition-container {
        width: auto;
        overflow: auto;
        margin: 10px;
    }

    .howItWorks-transition-img-ctr {
        width: 100%;
        height: auto;
        float: left;
    }

    .howItWorks-transition-img-left, .howItWorks-transition-img-right {
        width: 48%;
        height: auto;
        float: left;
        margin-top: 20px;
    }

    .review-transition-widget {
        width: calc(100% - 50px);
        overflow: hidden;
        margin: 25px;

    }

    .testimonial-photo-container {
        width: 100%;
        overflow: auto;
        float: left;
    }



    .review-transition-center {
        width: 100%;
        overflow: auto;
        float: left;
        border-radius: 25px;
        padding-bottom: 33px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 24px;
        background: #FFF;
    }



    .testimonial-photo {
        width: 268px;
        height: 310px;
        object-fit: cover;
        float: left;
        margin: 33px 27px 24px 33px;
    }

    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }

    .testimonial-desc {
        width: 270px;
        height: auto;
        padding: 0 36px 36px 36px;
        float: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #3B3B3B;
    }

    .review-transition-widget-dials-container {
        width: 140px;
        overflow: auto;
        display: inline-block;
        float: left;
        margin: 20px 16px 20px 16px;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        gap: 8px;
        overflow: auto;
    }

    .testimonial-more-review-button {
        width: 100%;
        text-align: center;
        text-decoration: underline;
        margin-top: 24px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
    }

    .progress-indicator {
        width: 272px;
        overflow: auto;
        margin-left: calc( 50% - 136px);
    }



    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        border: 1px #3B3B3B solid;
        float: left;
    }

    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
    }

    .arrow-button:hover {
        cursor: pointer;
    }

    .howItWorks-transition-img-left {
        margin-left: 2%;
    }

    .howItWorks-transition-img-right {
        margin-right: 2%;
    }

    #arrow-button-left {
        display: flex;
    }

    #arrow-button-right {
        display: flex;
    }



    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;

        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 1.5px;
    }

    .mat-transition-img-left {
        width: 28%;
        height: 166px;
        object-fit: cover;
        padding: 1%;
        float: left;
    }

    .mat-transition-img-right {
        width: 65%;
        height: 166px;
        object-fit: cover;
        padding: 1% 1% 1% 0;
    }



    .customizable-image-container {
        width: 248px;
        margin: 24px calc(50% - 124px);
        overflow: hidden;
    }

    .customizable-image-left, .customizable-image-ctr {
        width: 40%;
        float: left;
        margin-right: 2.5%;
        height: 97px;
        object-fit: fill;

    }

    .customizable-image-right {
        width: 12.5%;
        height: 97px;
        float: left;
        object-fit: fill;
    }

    .customizable-container {
        width: 100%;
        overflow: auto;
        background: #FAF2ED;
        padding: 64px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }



    .customizable-head {
        width: 248px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
        text-align: left;
        margin-left: calc( 50% - 124px);
    }




    .landing-page-standard-right-v2 {
        width: 330px;
        overflow: auto;
        margin: 24px calc( 50% - 165px) 0;
    }

    .landing-page-standard-left-v2 {
        width: 250px;
        overflow: auto;
        margin: 140px calc(50% - 125px) 0 calc(50% - 125px);
    }

    .landing-page-faq {
        width: 90%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
        margin-left: 5%;
    }

    .event-message-version2 {
        width: 100%;
        overflow: auto;
        margin: 0;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%; /* 24px */
        padding: 4px 0;
    }

    .notice-bar-version2-fr {
        position: static;
        width: 100%;
        height: 32px;
        left: 0.83px;
        top: 0px;
        background: #3F5C6B;
        /*background: black;*/
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .event-message-version2-fr {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 50px;
        margin-top: 0;

        /* Body */

        font-family: Montserrat;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        align-items: center;
        text-align: center;

        /* Secondary/White */

        font-weight: 600;

        color: #FFFFFF;
        padding-top: 4px;
    }

    .inspiration-post-col-photo {
        width: 100%;
        /*height: 352px;*/
        margin-top: -5px;
        object-fit: cover;
    }

    .inspiration-post-col-photo-landing-page {
        width: 247px;
        height: 309px;
        object-fit: cover;
    }

    .inspiration-post-moblet {
        width: 100%;
        height: auto;
    }

    .instagrom-scrollerpost-container {
        width: auto;
        float: left;
        margin-right: 32px;
    }



    .landing-page-review-transition-ending-button-v3 {
        width: 90%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin: 24px 5%;
        color: white;
    }

    .landing-page-review-transition-ending-header-v3 {
        position: static;
        width: 60%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        align-items: center;
        text-align: left;
        letter-spacing: 0.1em;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 20% 48px;
        text-transform: uppercase;
    }

    .transition-ig-feed-top-left {
        display: none;
    }

    #satisfaction-guarantee-container {

    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 36.38px;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .howItWorks-transition-img-right {
        width: 49%;
        height: 226px;
        object-fit: cover;
        margin-right: 1%;
        float: left;
    }


    .howItWorks-transition-img-left {
        width: 49%;
        height: 226px;
        object-fit: cover;
        margin-left: 1%;
        float: left;

    }

    .transition-ig-feed-bottom-img {
        width: 100%;
        overflow: auto;
        object-fit: cover;
    }

    .instagram-scroller-right {
        width: 248px;
        overflow: auto;
        margin: 0 calc( 50% - 124px) 64px;
    }

    .instagram-scroller {
        width: 248px;
        overflow-x: hidden;
        margin: 64px calc(50% - 124px) 42px;
    }

    .precut-mat-container {
        margin-top: 120px;
        padding: 5%;
    }


    .instagram-scroller-right-subtitle {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 20px */
        letter-spacing: 1.6px;
        color: white;
        margin-top: 20px;
    }

    .landing-page-review-transition-ending-v3{
        position: static;
        width: 100%;
        height: auto;


        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        padding: 64px calc( 50% - 327.5px);
    }

    .landing-page-review-v2 {
        width: 100%;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
    }

    .landing-page-review-content-v2 {
        margin: 24px 0;

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-bottom-cover {
        background: #FAF2ED;
        padding: 64px 0;
        width: 100%;
        overflow: auto;
    }

    .landing-page-bottom {
        text-align: center;
        /* Header 4 */
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-img {
        width: 60px;
        overflow: auto;
        margin: 0 calc(50% - 30px);
    }



    .landing-page-bottom-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.8px;
        text-transform: capitalize;
        width: 330px;
        margin: 0 calc( 50% - 165px) 56px;
    }
    
    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 32px calc(50% - 90px) 8px;
    }

    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1.3px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-transform: capitalize;
    }

    .transition-ig-feed-top-right {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        text-align: left;
    }

    .landing-page-review-container-v2 {
        width: 90%;
        padding: 128px 5%;
        overflow-y: auto;
        min-height: 286px;
        overflow-x: hidden;
        background: white;
    }

    .transition-ig-feed-container {
        width: calc( 100% - 32px);
        margin: 0 16px;
        overflow: auto;
    }
    .transition-ig-feed-bottom {
        margin-top: 16px;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;

    }

    .landing-page-left-arrow-v2, .landing-page-right-arrow-v2  {
        width: 10px;
        height: 18px;
        position: absolute;
        cursor: pointer;
    }

    .landing-page-arrow {
        width: 10px;
        height: 18px;
    }

    .landing-page-left-arrow-v2 {
        /*margin-top: 79px;*/
        /*margin-left: 9.79%;*/
        float: left;
        cursor: pointer;
        left: 10%;
    }

    .landing-page-right-arrow-v2 {
        /*margin-top: 79px;*/
        /*margin-left: 87.65%;*/
        float: right;
        cursor: pointer;
        right: 10%;
    }


    #landing-page-button-standard-v2-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3B3B3B;
        border-radius: 30px;
        width: 144px;
        padding: 16px 24px;
        text-align: center;
        margin-left: calc( 50% - 96px);
        margin-bottom: 64px;
        text-decoration: none !important;
    }

    #how-it-works-header-left-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 24px;
        width: 100%;
        overflow: auto;
    }

    #how-it-works-subheader-left-moblet {
        position: static;
        width: 310px;
        height: 48px;
        left: 24.5px;
        top: 112px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px calc(50% - 155px) 32px;
    }

    #how-it-works-header-right-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 64px 0px 24px 0;
    }

    #how-it-works-step2-desc-v2-moblet {
        width: 100%;
        overflow: auto;
    }

    #how-it-works-header-right-moblet {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 64px 0px 24px 0;
    }

    .landing-page-transition-text-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 80.5px 18px;
    }

    .how-it-works-subheader-right {
        margin-top: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    #how-it-works-subheader-right-moblet {
        width: 296px;
        overflow: auto;
        text-align: center;
        margin: 0 calc(50% - 148px) 32px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
        background: #D6D1BA;
    }

    .landing-page-review-transition-ending-header-v2 {
        width: 328px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #D6D1BA;
        padding: 64px 0 32px 0;
        margin: 0 calc(50% - 164px);
    }

    .landing-page-review-transition-ending-v2{
        width: 100%;
        padding-bottom: 64px;
        background: #D6D1BA;
    }

    .landing-page-review-transition-ending-button-v2 {
        width: 210px;
        overflow: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 10px; */
        padding: 16px 24px;
        background: #FFFFFF;
        border-radius: 30px;
        margin: 0 calc(50% - 129px);
        text-decoration: none !important;
    }

    .how-it-works-steps-container-v2 {
        padding-bottom: 64px;
        overflow-x: hidden;
    }


    .blog-type-2-pinterest-pin {
        width: 90%;
        margin: 5%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 90%;
        margin: 0 5% 5vh 5%;
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696a;
        font-weight: 400;
        font-style: italic;
        text-align: center;
    }

    .blog-type-2-container {
        position: static;
        width: 90%;
        overflow: auto;
        left: 0px;
        top: 116px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-bottom: 24px;
        margin: 5%;
    }

    .blog-type-2-paragraph {
        width: 90%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 90%;
        overflow: auto;
        margin: 10vw 5%;
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696A;
        text-align: left;
        font-weight: 400;
    }

    .how-it-works-box-right {
        line-height: 7.8125vw;
        font-size: 5vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 90%;
        padding: 10vw 5%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 100%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 100%;
        float: left;
        overflow: auto;
        margin-top: 5vh !important;
    }

    .how-it-works-image-file {
        width: 90%;
        margin: 5%;
        float: left;
        overflow: auto;
    }

    .how-to-measure-video-section {
        width: 100vw;
        height: 57vw;
        margin: 0;
    }

    .sort-by-frame-size-button-selected {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

}

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        overflow-y: hidden;


        /*Black Friday Code Below*/

        background: black;

        /* Inside Auto Layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .notice-bar-version2, .notice-bar-version2-fr {
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        overflow-y: hidden;

        background: #3F5C6B;

        /* Inside Auto Layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .landing-page-faq {
        width: 50%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
    }

    .sort-by-frame-size-button-selected {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 45.8%;
        overflow: auto;
        margin: 2% 1%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .event-message-version2, .event-message-version2-fr {
        width: 80%;
        overflow: auto;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #FFFFFF;
        margin: 4px 10%;
        font-weight: 400;
    }



    .inspiration-post-moblet {
        width: 100%;
        overflow-y: auto;
        display: inline-block;
    }

    .landing-page-review-transition-ending-button-v3 {
        width: 100%;
        overflow: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-transition-ending-header-v3 {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        position: static;
        width: 600px;
        align-items: center;
        text-align: left;
        letter-spacing: 0.1em;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 calc(50% - 300px) 64px;
    }

    .transition-ig-feed-top-right {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        color: #666161;
        text-align: left;
        margin-bottom: 24px;
    }

    .transition-ig-feed-bottom {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .transition-ig-feed-bottom-img {
        width: 100%;
        overflow: auto;
        object-fit: cover;
    }

    .landing-page-review-transition-ending-v3{
        position: static;
        width: 655px;
        height: auto;
        left: 392px;
        top: 128px;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        background: #78A891;
        padding: 128px calc( 50% - 327.5px);
    }


    .landing-page-bottom-cover {
        background: #FAF2ED;
        padding: 64px 0;
        width: 100%;
        overflow: auto;
    }

    .landing-page-bottom {
        text-align: center;
        /* Header 4 */
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-highlight {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
        letter-spacing: 1.8px;
        text-transform: capitalize;
    }

    .navbar-top-button-mobile {
        width: auto;
        float: left;
        text-align: left;
        margin-bottom: 23px;
        text-transform: uppercase;
        color: #3B3B3B;
        font-weight: 500;
        margin-top: 28px;
        margin-left: 20px;
        max-width: 200px;
    }


    .navbar-top-button-chevron {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        margin-right: 22.5px;
        margin-top: 27.5px;
    }


    .navbar-top-button-mobile-menu {
        width: 100%;
        overflow: auto;
        margin-bottom: 10px;
    }



    .mobile-navbar-open-wide-no-display {
        display: none;
        /*margin-right: -280px;*/
        transition: margin 1000ms ease-in-out;

    }

    .mobile-navbar-open-wide {
        width: 100%;
        height: 100vh;
        overflow: auto;
        z-index: 120;
        position: fixed;
        background: rgba(84, 84, 84, 0.50);
        -webkit-backdrop-filter: blur(2.5px);
                backdrop-filter: blur(2.5px);
        top: 0;
    }



    .navbar-top-button-subheader {
        margin: 18px 20px;
        max-width: 200px;
        color: #686868;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: capitalize;
    }

    .navbar-mobile-header-indicator {
        width: 75%;
        float: left;
        overflow: auto;
    }

    .navbar-mobile-header-indicator-img {
        width: 52px;
        height: 52px;
        position: relative;
        float: left;
    }

    .precut-mat-container {
        margin-top: 60px;
        padding: 10%;
    }

    .navbar-mobile-header-indicator-text {
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        width: auto;
        overflow: auto;
        margin-top: 15px;
        float: left;
    }

    .navbar-top-button-header {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
        margin: 30px 20px 12px;
    }



    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }


    .mobile-navbar-options-container {
        width: 273px;
        overflow: auto;
        z-index: 100;
        background: white;
        float: right;
        margin-left: calc( 100% - 273px);
        position: relative;
        animation: mobileDrawer 100ms;
    }

    .mobile-nav-head-section {
        width: 100%;
        overflow: auto;
    }

    .mobile-nav-close-icon {
        width: 34px;
        height: 34px;
        float: right;
        padding: 9px;
        margin-right: 8px;
    }

    @keyframes mobileDrawer {
        0% {margin-right: -280px;}
        100% {margin-right: 0px;}
    }

    .mobile-navbar-options-menu {
        color: #F59276;
        margin: 5vh 0 8.125vw 0;
        font-size: 5vw;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    .mobile-navbar-closeout-button {
        width: 15vw;
        height: 15vw;
        color: #726969;
        margin-top: 3vw;
        float: right;
        position: fixed;
        top: 0;
        left: 63vw;
    }

    .mobile-language-selection {
        font-size: 5vw;
        text-align: center;
        width: 15.9375vw;
        overflow: auto;
        position: fixed;
        bottom: 10.9375vw;
        left: 32.5vw;
    }

    .mobile-navbar-options-submenu {
        font-size: 3.75vw;
        text-align: center;
        text-transform: uppercase;
        color: #F59276;
        font-weight: 600;
        margin-bottom: 3.75vw;
    }


    .navbar-top-cart-icon-counter {
        font-size: 3.125vw;
        margin-top: -13vw;
        width: 4.6875vw;
        height: 4.6875vw;
        margin-left: 9vw;
        background-color: #F59276;
        color: white;
        border-radius: 100%;
        line-height: 4.0625vw;
        padding-top: 0.75vw;
        text-align: center;
        position: absolute;
        padding-left: 0.3vmin;
    }

    .what-is-mat-page-v2 {
        width: 100%;
        overflow: auto;
    }

    .what-is-mat-page-v2 {
        text-align: left;
    }


    #special-memories-container {
        color: #3B3B3B;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 54px */
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }

    .landing-page-bottom-text-img {
        width: 60px;
        overflow: auto;
        margin: 0 calc(50% - 30px);
    }



    .landing-page-bottom-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.8px;
        text-transform: capitalize;
        width: 640px;
        margin: 0 calc( 50% - 320px) 56px;
    }

    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 32px calc(50% - 90px) 36px;
    }

    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 1.3px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-transform: capitalize;
    }


    .transition-ig-feed-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 1.6px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-transform: capitalize;
    }

    .transition-ig-feed-container {
        overflow: hidden;
    }

    .how-it-works-page-v2 {
        width: 497px;
        overflow: auto;
        margin-left: calc(50% - 249.5px);
        margin-right: calc(50% - 249.5px);
        margin-top: 128px;
        margin-bottom: 128px;
    }

    #what-is-mat-header-v2-moblet {
        width: 100%;
        overflow: auto;
        padding: 0;
    }

    #landing-page-button-standard-v2-moblet {
        margin: 0 calc(50% - 96.5px);
    }
    
    .landing-page-product-list {
        width: 666px;
        margin-left: calc(50% - 155px);
        margin-top: 20px;
        overflow: auto;
    }


    .landing-page-subtext-first-v2 {
        width: 100%;
        overflow: auto;
        margin-top: -40px;
        font-family: Montserrat;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: auto;
        height: 59px;
        left: 8.4%;
        top: 382px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-start-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        /* position: absolute; */
        width: 310px;
        height: 52px;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 20px;
        float: left;
        margin-right: 22.5px;
    }

    .logo-vertical-v2 {
        margin: 47px 59px;
        float: left;
    }

    .landing-page-container {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: #FFFFFF;
        margin-top: 160px;
    }


    .mobile-language-selection-flag {
        width: 26px;
        height: 26px;
        margin-left: 10px;
        border-radius: 100%;
        float: left;
    }

    .mobile-language-selection-flag-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        color: white;
        margin-left: 10px;
        float: left;
    }

    .mobile-language-selection-bar {
        background: #3F5C6B;
        padding: 7px 0px 7px 4px;
        width: auto;
        overflow: auto;
    }

    .select-currency-option {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: solid 1px #3B3B3B;
        float: left;
        margin: 8px 8px 8px 20px
    }

    .select-currency-options {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-currency-row {
        width: 100%;
        overflow: auto;
        margin-top: 5px;
    }

    .mobile-landing-page-title {
        width: auto;
        overflow-y: hidden;
        background: #8DB09D;
        padding: 60px 87px;
        overflow-x: hidden;
    }

    .mobile-landing-page-title-head {
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 60px */
        letter-spacing: 4.8px;
        text-transform: uppercase;
        color: white;
        background: #8DB09D;
        margin: 0;
        background: #8DB09D;
    }


    .select-currency-option-selected {
        background: #3B3B3B;
    }


    .select-currency-flag {
        float: left;
        width: 26px;
        height: 26px;
    }

    .select-currency-option-selected {
        background: #3B3B3B;
    }


    .mobile-landing-page-title-subhead {
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 45px */
        color: white;
        background: #8DB09D;
        margin: 0;
    }

    .logo-vertical-v2-narrow {
        margin: 47.55px 0 47.55px 7.76%;
        float: left;
    }

    .navbar-button-v2 {
        display: none;
    }

    .navbar-button-v2-narrow {
        display: none;
    }

    .navbar-start-designing-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin: 38px 1.5%;
        float: right;
        width: 142px;
        height: auto;
        right: 190.66px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

    }

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: right;
    }


    .navbar-v2 {
        width: 100%;
        overflow: auto;
        display: inline-block;
    }

    .navbar-v2-non-fixed {
        width: 100%;
        overflow: auto;
    }

    .landing-page-header-v2 {
        width: 100%;
        overflow: hidden;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        overflow-y: auto;
    }

    .landing-page-image-overlay-black-v2 {
        position: absolute;
        width: 100%;
        height: 602px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        opacity: 0.36;
    }

    .eapps-instagram-testimonials-posts.swiper-container {
        padding: 0 !important;
    }

    .how-it-works-subheader-left {
        width: 310px;
        overflow: auto;
        /* Body */
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: left;
        /* Primary/Soft Black */
        color: #3B3B3B;
        /* Inside Auto Layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .how-it-works-header-left {
        width: 479px;
        overflow: auto;
        left: 17px;
        top: 0px;
        margin-bottom: 32px;

        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .diploma-mat-box {
        width: 40%;
        height: 320px;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
        max-height: 470px
    }

    .cert-mat-container .landing-page-standard-left-v2 {
        margin-top: 0;
    }

    .cert-mat-container .what-is-mat-desc-v2, .cert-mat-container .what-is-mat-desc-v2 {
        text-align: center;
    }

    #how-it-works-header-left-moblet {
        text-align: left;
        float: left;
    }

    #how-it-works-subheader-left-moblet {
        text-align: left;
        float: left;
    }

    #landing-page-standard-left-v2-moblet {
        margin-left: 0;
        margin-right: 0;
        margin-top: 32px;
    }

    #landing-page-standard-right-v2-moblet {
        padding-top: 12px;
        margin: 0 0 64px 0;
    }

    .landing-page-transition-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 128px 160px; */
        position: static;
        width: 100%;
        overflow: auto;
        background: #3F5C6B;
        padding: 128px 0;
    }

    .landing-page-transition-text-v2 {
        position: static;
        width: 640px;
        height: 108px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px calc( 50% - 320px );
    }

    .how-it-works-header-right {
        position: static;
        width: 479px;
        height: 88px;
        left: 17px;
        top: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .landing-page-review-container-v2 {
        width: 760px;
        /* height: 286px; */
        padding: 128px calc(50% - 380px);
        overflow: auto;
        min-height: 286px;
        background: white;
    }

    #landing-page-review-container-v2-moblet {
        width: 730px;
        height: 400px;
        padding: 128px calc(50% - 365px);
    }

    .landing-page-left-arrow-v2 {
        width: 10px;
        height: 20px;
        float: left;
        margin-left: 19px;
        margin-right: 5.8%;
        margin-top: 150px;
        cursor: pointer;
        position: absolute;
        left: 19px;
    }

    .landing-page-right-arrow-v2 {
        width: 10px;
        height: 20px;
        float: right;
        margin-right: 19px;
        margin-left: 5.8%;
        margin-top: 150px;
        cursor: pointer;
        position: absolute;
        right: 19px;
    }

    .landing-page-review-v2 {
        width: 610px;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
        margin: 0 calc( 50% - 305px);
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {
        width: 100%;
        height: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-content-v2 {
        /* Body 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }

    .landing-page-review-stars-v3 {
        width: 100%;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars-v2 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars {
        width: 30px;
        height: 30px;
        float: left;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-v2 {
        position: static;
        width: 655px;
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #D6D1BA;

        padding: 128px calc( 50% - 327.5px);
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 206px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        background: #FFFFFF;
        border-radius: 30px;
        margin-top: 19px;
        margin-left: calc( 50% - 127px);
    }



    .how-it-works-subheader-right {
        width: 377px;
        margin-top: 128px;
        overflow: auto;
        position: static;
        /* width: 310px; */
        height: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
        float: left;
    }

    .landing-page-standard-left-v2 {
        width: 560px;
        overflow: auto;
        margin-left: calc(50% - 280px);
        margin-right: calc(50% - 280px);
        margin-top: 150px;
    }

    .what-is-mat-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 0 24px 0;
        text-align: center;
        width: 80%;
        padding: 0 10%;
    }

    .product-benefits {
        padding: 25px 0;
    }

    .what-is-mat-desc-v2 {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .what-is-mat-line-break-v2 {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
    }

    .what-is-mat-image-v2 {
        position: static;
        width: 96%;
        padding: 2% 1.5%;
        height: 333px;
        left: 784px;
        top: 128px;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .landing-page-standard-right-v2 {
        width: 497px;
        overflow: auto;
        margin: 0 calc(50% - 249.5px) 128px;
        padding-top: 42px;
    }

    .customizable-container {
        width: auto;
        overflow-y: auto;
        padding: 128px 64px;
        background: #FAF2ED;
    }
    
    .customizable-subcontainer {
        width: 480px;
        overflow: auto;
    }

    .customizable-image-container {
        display: flex;
        width: 640px;
        justify-content: center;
        align-items: center;
        margin-left: calc(50% - 320px);
    }

    .customizable-subhead {
        font-size: 16px;
        font-style: normal;
        line-height: 150%;
        margin-bottom: 48px;
    }

    .mat-transition-container {
        width: calc(100% - 20px);
        overflow-y: auto;
        margin: 10px;
        overflow-x: hidden;
    }

    #landing-page-bottom-transition {
        margin: 128px 0;
    }


    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }


    .review-transition-widget-dials-container {
        width: 200px;
        overflow: auto;
        display: inline-block;
        float: left;
        margin: 20px calc( 50% - 100px);
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        gap: 8px;
        overflow: auto;
    }

    .testimonial-desc {
        width: 55%;
        float: left;
        padding: 2.5%;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 19.5px */
        color: #3B3B3B;
    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 2.5%;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 10px;
        height: 10px;
        border-radius: 6px;
        border: 1px #3B3B3B solid;
        float: left;
    }


    .testimonial-photo {
        width: 100%;
        overflow: auto;
        height: 258px;
        object-fit: cover;
    }

    .testimonial-photo-container {
        width: 40%;
        float: left;
    }

    .review-transition-center {
        width: calc(100% - 164px);
        float: left;
        border-radius: 25px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 24px;
        background: #FFF;
        height: 258px;
        overflow: hidden;
    }



    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
        margin: 100px 16px;
    }

    .howItWorks-transition-img-right {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 2%;
        float: left;
    }

    .review-transition-widget {
        width: calc(100% - 50px);
        overflow: hidden;
        margin: 25px 25px 0 25px;
    }

    .howItWorks-transition-img-ctr {
        width: 102%;
        margin-bottom: 1%;
    }

    .testimonial-more-review-button {
        display: flex;
        /* padding: 8.527px 12.79px; */
        justify-content: center;
        align-items: center;
        grid-gap: 5.329px;
        grid-gap: 5.329px;
        gap: 5.329px;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        border: solid 0.5px;
        width: 250px;
        padding: 12.5px;
        border-radius: 16px;
        margin: 18px calc(50% - 138px) 0;
    }


    .howItWorks-transition-img-left {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 2%;
        float: left;

    }

    .mat-transition-img-left {
        width: 30%;
        overflow: auto;
        float: left;
        object-fit: cover;
        height: 262px;
    }
    
    .benefits-page-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-container {
        width: 100%;
        overflow-y: auto;
        padding: 64px 0;
    }

    .benefits-summary-component-img {
        width: 82px;
        overflow: auto;
        margin-left: calc(50% - 41px);
    }

    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
        margin: 16px 0;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 1.6px;
        margin-bottom: 64px;
    }

    .benefits-summary-component {
        width: 270px;
        overflow: auto;
        margin: 16px calc(50% - 135px)
    }

    .mat-transition-img-right {
        width: 70%;
        overflow: auto;
        float: left;
        object-fit: cover;
        height: 262px;
    }

    .customizable-head {
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .instagrom-scrollerpost-container {
        width: auto;
        height: 100%;
        float: left;
    }

    .instagram-scroller {
        width: 524px;
        overflow-x: scroll;
        display: flex;
        margin: 135px calc( 50% - 262px) 0 calc( 50% - 262px);
    }

    #what-is-mat-header-moblet-specific {
        width: 100%;
        padding: 0;
        text-align: left;
    }

    .instagram-scroller-right-subtitle {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 30px */
        letter-spacing: 2.4px;
        color: white;
    }

    #satisfaction-guarantee-container {
        background: white;
    }

    .instagram-scroller-right-title {
        color: initial;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        font-feature-settings: "smcp";
        font-variant: small-caps;
        letter-spacing: 3.6px;
        color: white;
    }

    .inspiration-post-col-photo-landing-page {
        width: 100%;
        object-fit: cover;
    }

    .instagram-scroller-right {
        width: 524px;
        margin: 51px calc(50% - 262px) 0;
        color: white;
    }


    /*#landing-page-standard-right-moblet-specific {*/
    /*    width: 375px;*/
    /*    margin-left: 8.31%;*/
    /*    padding-top: 128px;*/
    /*    margin-bottom: 0;*/
    /*}*/

    #what-is-mat-desc-moblet-specific {
        width: 100%;
        text-align: left;
        padding: 0;
    }

    #explainer-start-designing-button {
        margin-top: 32px;
        margin-left: calc(50% - 82px);
    }
    
    .how-it-works-step2-desc-v2 {
        width: 560px;
        overflow: auto;
        margin-left: calc(50% - 280px);
        margin-right: calc(50% - 280px);
        margin-top: 128px;
    }
    
    .read-our-review-section {
        width: 100%;
        overflow: auto;
    }

    .landing-page-button-standard-v2 {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: static;
        width: 193px;
        height: 52px;


        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;

        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;

        color: white;
        cursor: pointer;
    }

    .logo-vertical-v2 {
        margin: 47.55px 0 47.55px 4.1%;
        float: left;
    }

    .logo-vertical-v2-narrow {
        margin: 15.55px 0 15.55px 4.1%;
        float: left;
    }

    .instagram-controller-container {
        width: 100%;
        overflow-y: auto;
        padding-bottom: 128px;
    }


    .how-to-measure-video-section {
        width: 50vw;
        height: 28vw;
        margin: 0 calc(49% - 25vw);
    }

    .blog-type-2-pinterest-pin {
        width: 50%;
        margin: 0 25%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 100%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: left;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        margin-bottom: 1.74vw;
    }

    .blog-type-2-container {
        width: 40%;
        overflow: auto;
        margin: 64px 30% 64px 30%;
        color: #3B3B3B;
    }

    .blog-type-2-paragraph {
        width: 100%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 50%;
        overflow: auto;
        margin: 2.5vw 25%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: center;
        font-weight: 400;
    }

    .how-it-works-box-right {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 60%;
        padding: 5vh 20%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 60%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 40%;
        float: left;
        overflow: auto;
    }

    .how-it-works-image-file {
        width: 45%;
        margin: 0 5% 0 0;
        float: left;
        overflow: auto;
    }

    .landing-page-image-file {
        width: 100%;
        overflow: auto;
    }

    .landing-page-image-container {
        width: 96.5%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        position: relative;
    }

    .landing-page-banner-highlight {
        width: 100%;
        overflow: hidden;
        margin: 5.55vw 0;
        font-size: 3.33vw;
        line-height: 3.96vw;
        text-align: center;
        color: #72696A;
        position: absolute;
    }

    .landing-page-banner-subtitle {
        width: 100%;
        position: absolute;
        text-align: center;
        overflow: hidden;
        margin-top: 15.41vw;
        line-height: 2.01vw;
        font-size: 1.39vw;
        color: #656565;
        font-weight: 400;
    }

    /*.landing-page-start-designing-button {*/
    /*    margin-left: calc( (100% - 22.857vw) / 2);*/
    /*    margin-top: 40.844vw;*/
    /*    width: 22.857vw;*/
    /*    text-align: center;*/
    /*}*/

    .landing-page-design-now-button {
        text-transform: uppercase;
        width: auto;
        padding: 0;
        width: 13.19vw;
        text-align: center;
        margin: 21.18vw calc( (100% - 13.19vw) / 2);
        padding-top: 1.01vw;
        padding-bottom: 1.01vw;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        position: absolute;
        line-height: 2.01vw;
        font-size: 1.11vw;
    }

    .landing-page-how-it-works-transition {
        text-align: center;
        padding: 2.78vw 15.83vw;
        color: #000;
        background: #F59276;
        margin: 1.67vw 1.81vw;
        color: white;
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .shipping-info-desc {
        color: white;
        line-height: 1.74vw;
    }


    .blog-type-2-pinterest-pin {
        width: 50%;
        margin: 0 25%;
    }

    .blog-type-2-paragraph-image-subscript {
        width: 100%;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696a;
        text-align: left;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        margin-bottom: 1.74vw;
    }

    .blog-type-2-container {
        width: 80%;
        overflow: auto;
        margin: 64px 10% 64px 10%;
    }

    .blog-type-2-paragraph {
        width: 100%;
    }

    .blog-type-2-header {
        width: 100%;
        text-align: left;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #3B3B3B;
        font-size: 36px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .blog-lead-photo, .blog-type-2-paragraph {
        width: 100%;
        /* font-size: 1.11vw; */
        /* line-height: 1.74vw; */
        /* color: #72696A; */
        text-align: left;
        font-weight: 400;
        color: #3B3B3B;
        font-size: 16px;
        line-height: 150%;
        font-family: Montserrat;
        font-style: normal;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-image-file-large {
        width: 90%;
        padding: 0 5%;
    }

    .how-it-works-leader-container {
        width: 50%;
        overflow: auto;
        margin: 2.5vw 25%;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: center;
        font-weight: 400;
    }

    .how-it-works-box-right {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: left;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
        width: 100%;
        margin: 2.5vw 0%;
    }

    .how-it-works-box {
        width: 60%;
        padding: 5vh 20%;
        overflow: auto;
    }

    .how-it-works-box-left {
        width: 100%;
        float: left;
        overflow: auto;
    }

    .how-it-works-box-right {
        width: 100%;
        float: left;
        overflow: auto;
        margin-top: 5vh !important;
    }

    .how-it-works-image-file {
        width: 45%;
        margin: 0 5% 0 0;
        float: left;
        overflow: auto;
    }

    .landing-page-image-file {
        width: 100%;
        overflow: auto;
    }

    .landing-page-image-container {
        width: 180%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 3.247vw -40% 0 -40%;
        position: relative;
    }

    .landing-page-banner-highlight {
        width: 100%;
        overflow: hidden;
        margin: 10.39vw 0;
        font-size: 6.233vw;
        line-height: 7.402vw;
        text-align: center;
        color: #72696A;
        /* z-index: 10; */
        position: absolute;
    }

    .landing-page-banner-subtitle {
        width: 100%;
        position: absolute;
        text-align: center;
        overflow: hidden;
        margin-top: 35.844vw;
        line-height: 3.766vw;
        font-size: 2.597vw;
        color: #656565;
        font-weight: 400;
    }

    .navbar-top-button-inner-layer {
        width: calc(100% - 0.42vw);
        padding: 2.75vw 0;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        border-radius: 0.5vw;
        cursor: pointer;
    }

    .landing-page-start-designing-button {

        width: 180%;
        text-align: center;
        overflow: auto;
        margin: 47.844vw -40% 0 -40%;
        position: absolute;;
    }

    .landing-page-design-now-button {
        padding-top: 1.883vw;
        padding-bottom: 1.883vw;
        color: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        width: 22.857vw;
        margin: 0 calc( (100% - 22.857vw) / 2 );
        font-size: 2.08vw;
    }



    .landing-page-start-designing-button a:hover {
        text-decoration: none;
    }

    .landing-page-how-it-works-transition {
        text-align: center;
        padding: 2.78vw 0 3.376vw 0;
        background: #F59276;
        color: white;
        font-size: 2.0779vw;
        line-height: 3.247vw;
    }

    .shipping-info-highlights {
        margin: 0 9.350vw;
    }

    .shipping-info-desc {
        height: 4.545vw;
        color: white;
    }

    #landing-page-banner-highlight-fr {
        /*for french, reducing the font size since the copywriting is longer than english.*/
        font-size: 5.233vw;
    }

    .how-to-measure-video-section {
        width: 90vw;
        height: 50vw;
        margin: 0 calc(49% - 45vw);
    }

}

/*Normal Laptop*/

.landing-page-review-transition-ending-v3 {
    overflow-x: hidden
}



@media screen and (min-width: 1100px) {

    .customizable-subhead {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        color: #3B3B3B;
        padding: 24px 405.5px 64px 405.5px;
    }

    .customizable-image-container {
        width: 993px;
        margin: 0 calc(50% - 496.5px)
    }

    .instagram-scroller-right {
        width: calc(90% - 573px);
        overflow: auto;
        float: right;
        margin-top: 300px;
        margin-right: 5%;
        margin-left: 5%;
    }


    .review-transition-widget {
        width: 1216px;
        overflow: hidden;
        margin: 83px calc( 50% - 608px) 55px;
    }

    .review-transition-logo-container {
        width: 124px;
        margin: 28px calc( 50% - 62px );
        height: 45px;
    }

    .testimonial-desc {
        width: 455px;
        height: auto;
        padding: 36px;
        float: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        color: #3B3B3B;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        gap: 16px;
        width: 250px;
        overflow: auto;
        padding: 0 30px;
        float: left;
    }

    .testimonial-more-review-button:hover {
        background: #3B3B3B;
        color: white;
        text-decoration: none;
    }

    .testimonial-more-review-button {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 30px;
        border: 1px solid #3B3B3B;
        width: 320px;
        overflow: auto;
        margin: 0 calc( 50% - 160px);
        text-transform: capitalize;
        text-decoration: none;
    }

    .dial-selected {
        background: #3B3B3B;
    }

    .dial {
        width: 10px;
        height: 10px;
        border-radius: 6px;
        border: 1px #3B3B3B solid;
        float: left;
    }

    .landing-page-bottom-text-highlight {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px; /* 150% */
        letter-spacing: 2.4px;
        text-transform: capitalize;
    }



    .testimonial-photo-container {
        width: 461px;
        overflow: auto;
        float: left;
    }

    .review-transition-widget-dials {
        display: flex;
        align-items: center;
        grid-gap: 16px;
        gap: 16px;
        width: 250px;
        overflow: auto;
        margin: 55px calc( 50% - 125px);
        padding: 0 34px;
    }



    .arrow-button {
        width: 50px;
        height: 50px;
        float: left;
        margin: 225px 32px;
    }

    .arrow-button:hover {
        cursor: pointer;
    }



    .testimonial-photo {
        width: 461px;
        height: 495px;
        object-fit: cover;
        float: left;
    }

    .mat-transition-container {
        width: 100%;
        height: 550px;
        overflow: hidden;
    }


    .review-transition-center {
        width: 988px;
        height: 485px;
        float: left;
        border-radius: 50px;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        background: #FFF;
    }


    .howItWorks-transition-img-ctr {
        width: 48%;
        height: 550px;
        object-fit: cover;
        margin-left: 1%;
        margin-right: 1%;
        float: left;
    }

    .mat-transition-img-left {
        width: 28%;
        height: auto;
        object-fit: cover;
        padding: 1%;
        float: left;
    }

    .mat-transition-img-right {
        width: 69%;
        height: auto;
        object-fit: cover;
        padding: 1% 1% 1% 0;
        float: left;
    }

    .customizable-container {
        width: 100%;
        overflow: auto;
        background: #FAF2ED;
        padding: 128px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }

    .customizable-image-left {
        width: 297px;
        margin-right: 128px;
        margin-bottom: 5px;
    }

    .customizable-image-ctr {
        width: 312.8px;
        margin-right: 128px;
    }

    .customizable-image-right {
        width: 126px;
        margin-bottom: 10px;
    }



    .instagram-scroller-right-subtitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        letter-spacing: 2.4px;
        color: white;
        margin-top: 24px;
    }

    .instagram-scroller-right-title {
        text-align: left;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 111.111% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: white;
    }

    .transition-ig-feed-container {
        margin-right: 13px;
    }

    .inspiration-post-col-photo-landing-page {
        width: 477px;
        height: 595px;
        object-fit: fill;
    }

    .transition-ig-feed-top-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .transition-ig-feed-bottom {
        width: 496px;
        height: 352px;
        overflow: hidden;
    }

    .transition-ig-feed-bottom-img {
        width: 496px;
        height: 352px;
        object-fit: cover;
    }
    
    .transition-ig-feed-top {
        width: 100%;
        overflow: auto;
        margin-bottom: 13px;
    }

    .transition-ig-feed-container {
        width: 496px;
        height: 100%;
        overflow-y: hidden;
        float: left;
    }

    .transition-ig-feed-top-left {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        float: left;
    }

    .transition-ig-feed-top-right {
        width: 80%;
        overflow: auto;
        float: left;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-left: 19px;
    }

    .landing-page-bottom-cover {
        width: 1440px;
        margin-left: calc(50% - 720px);
        overflow: auto;
        padding: 128px 0;
        background: #FAF2ED;
    }

    .landing-page-bottom-start-button {
        width: 194px;
        overflow: auto;
        margin: 0 calc( 50% - 97px);
    }



    .landing-page-bottom {
        width: 100%;
        color: #3B3B3B;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }

    .testimonial-subscript {
        width: 100px;
        margin-left: 36.38px;
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


    .howItWorks-transition-img-right {
        width: 24%;
        height: 550px;
        object-fit: cover;
        margin-right: 1%;
        float: left;
    }


    .howItWorks-transition-img-left {
        width: 24%;
        height: 550px;
        object-fit: cover;
        margin-left: 1%;
        float: left;
    }

    .landing-page-bottom-text {
        font-size: 20px;
        font-style: normal;
        line-height: 36px; /* 150% */
        letter-spacing: 2.4px;
        text-align: center;
        margin-bottom: 48px;
    }

    .landing-page-bottom-text b {
        text-transform: capitalize;
    }

    .landing-page-bottom-stars {
        width: 180px;
        overflow: auto;
        margin: 19px calc( 50% - 90px) 38.55px calc( 50% - 90px);
    }

    .transition-ig-feed-text {
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        letter-spacing: 1.8px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        text-transform: capitalize;
        cursor: pointer;
    }



    .transition-ig-feed {
        width: 2090px;
        height: 415px;
        margin: 0 calc(50% - 1045px) 108px calc(50% - 1045px);
    }

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        /* Notice Bar */
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */

        /*Black Friday Code Below*/

        background: black;

        /* Inside Auto Layout */

    }

    .notice-bar-version2, .notice-bar-version2-fr {
        /* Notice Bar */
        position: static;
        width: 100%;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;

        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        background: #3F5C6B;
      }

    .landing-page-faq {
        width: 50%;
        float: left;
        line-height: 1.5;
        margin-bottom: 64px;
    }

    .landing-page-subtext-first-v2-lead {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 4.8px;
        text-transform: uppercase;
        color: #FFF;
    }

    .landing-page-subtext-first-v2-sublead {
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 17px 0;
        text-transform: none;
        color: #FFF;
    }

    .landing-page-standard-right-v2 {
        width: 496px;
        overflow: auto;
        float: right;
        margin-bottom: 106px;
    }


    .landing-page-header-v2 {
        width: 100%;
        height: 602px;
        overflow: hidden;
        position: initial;
        margin-top: 150px;
    }

    .landing-page-product-list {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        border-radius: 30px;
    }

    .landing-page-subtext-first-v2 h1 {
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.1em;
        text-transform: uppercase
    }

    .landing-page-subtext-first-v2 {
        position: absolute;
        width: auto;
        height: 59px;
        left: 100px;
        top: 250px;

        /* Header 1 */
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        /*text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    }

    .landing-page-subtext-second-v2 {
        position: absolute;
        width: auto;
        height: 59px;
        left: 695px;
        top: 382px;

        /* Header 1 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 59px;
        /* identical to box height */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    }

    .landing-page-image-overlay-black-v2 {
        position: absolute;
        width: 55%;
        height: 640px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        opacity: 0.36;
    }

    .landing-page-image-overlay-image-v2 {
        width: 100%;
        height: 602px;
        overflow: hidden;
        left: 0px;
        object-fit: cover;
    }

    .landing-page-photo-overlay {
        position: absolute;
        width: 100%;
        max-width: 1440px;
        height: 602px;
        opacity: 0.7;
        background: linear-gradient(90deg, #432215 0%, #be8a6a00 52.79%);
    }



    .landing-page-image-overlay-grey-v2 {
        /*position: absolute;*/
        width: 100%;
        height: 602px;
        left: 0px;
        top: 0px;
        background: #C4C4C4;
    }

    .landing-page-start-button-v2 {
        display: block;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 300px;
        height: auto;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        box-sizing: border-box;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        float: left;
    }

    .landing-page-start-button-v2:hover {
        text-decoration: none;
        color: #FFFFFF;
        background: #3B3B3B;

    }

    .what-is-mat-page-v2 {
        width: 1122px;
        overflow: auto;
        overflow-x: hidden;
        margin: 128px calc(50% - 561px);
    }

    .landing-page-standard-left-v2 {
        width: 496px;
        overflow-y: auto;
        float: left;
    }

    .what-is-mat-header-v2 {
        /* Header 2 */
        width: 447px;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        margin-top: 0;
    }

    .product-benefits {
        padding: 25px 0;
    }


    .what-is-mat-desc-v2 {
        width: 406px;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
    }

    .what-is-mat-line-break-v2 {
        margin-bottom: 24px;
    }

    .what-is-mat-page-right-v2 {

    }

    .what-is-mat-image-v2 {
        width: 496px;
        overflow-y: auto;
    }

    .benefits-page-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-container {
        width: 1264px;
        overflow: auto;
        margin: 91.33px calc(50% - 632px);
    }

    .benefits-summary-component {
        width: 274px;
        float: left;
        padding: 0 21px
    }
    
    .benefits-page-img-container {
        width: 100%;
        overflow: auto;
    }

    .benefits-summary-component-img {
        width: 73px;
        height: 73px;
        margin-left: calc(50% - 36.5px);
        object-fit: cover;
    }

    .benefits-summary-component-lead {
        color: #3B3B3B;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 141.176% */
        letter-spacing: 1.7px;
        margin: 16px 0;
        text-transform: capitalize;
    }

    .benefits-summary-component-text {
        color: #3B3B3B;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 1.5px;
    }



    .benefits-page-container-img-side {
        width: 30%;
        height: 564px;
        overflow: hidden;
        float: left;
    }

    .benefits-page-container-img-ctr {
        width: 40%;
        height: 564px;
        overflow: hidden;
        float: left;
    }

    .instagram-scroller {
        width: 477px;
        height: 600px;
        overflow-y: scroll;
        margin: 64px 32px 64px 64px;
        padding-bottom: 200px;
        float: left;
    }

    .instagram-controller-container {
        width: 1440px;
        height: 770px;
        overflow-y: hidden;
        margin: 0 calc(50% - 720px)
    }

    .what-is-mat-added-margin {
        float: right;
        margin: 120px 6.1% 100px 0;
    }


    .instagram-scroller-header-v2 {
        float: left;
        width: 444px;
        margin-top: 303px;
    }

    .how-it-works-steps-container-v2 {
        width: 100%;
        overflow: auto;
        overflow-x: hidden;
    }

    .how-it-works-header-left {
        width: 496px;
        font-size: 36px;
        float: left;
    }

    .how-it-works-subheader-left {
        width: 496px;
        overflow: auto;
        font-size: 16px;
        line-height: 250%;
        text-align: left;
        color: #3B3B3B;
        margin: 24px 0px 24px 0px;
        float: left;
    }

    #explainer-start-designing-button {

    }

    .landing-page-button-standard-v2:hover {
        background: white;
        color: #3B3B3B;
        border: #3B3B3B solid 1px;

    }



    .landing-page-button-standard-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
    }

    .how-it-works-page-leader {
        width: 600px;
        margin-left: calc( 50% - 300px );
        margin-top: 85px;
        margin-bottom: 57px;
        text-align: center;
    }

    .how-it-works-page-button {

    }

    .how-it-works-step2-desc-v2 {
        position: static;
        width: 496px;
        overflow-x: hidden;
        overflow-y: auto;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 128px calc(27.3vw - 271px) 160px calc((27.3vw - 271px) * 1.33); */
        float: right;
        margin-top: 50px;
    }

    .how-it-works-header-right {
        position: static;
        width: 479px;
        height: 88px;
        left: 17px;
        top: 0px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;
        float: left;
    }

    .how-it-works-subheader-right {
        width: 377px;
        margin-top: 128px;
        overflow: auto;
        position: static;
        /* width: 310px; */
        height: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
        float: left;
    }

    .landing-page-transition-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 128px 160px; */
        position: static;
        width: 93%;
        overflow: auto;
        background: #3F5C6B;
        padding: 128px 3.5% 128px 3.5%;

    }

    .landing-page-transition-text-v2 {
        position: static;
        width: 1120px;
        height: 72px;
        left: 111px;
        top: 127px;

        /* Header 4 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */

        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;

        /* Secondary/White */

        color: #FFFFFF;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 10px 0px;
    }

    .landing-page-review-container-v2 {
        width: 760px;
        padding: 128px calc(50% - 380px);
        overflow: auto;
        min-height: 286px;
        background: white;
    }

    .landing-page-left-arrow-v2 {
        width: 10px;
        height: 20px;
        float: left;
        margin-left: 19px;
        margin-right: 45px;
        margin-top: 150px;
        cursor: pointer;
    }

    .landing-page-review-v2 {
        width: 610px;
        /* height: 286px; */
        float: left;
        text-align: center;
        min-height: 286px;
        overflow: auto;
    }

    .landing-page-right-arrow-v2 {
        width: 10px;
        height: 20px;
        float: right;
        margin-right: 19px;
        margin-left: 45px;
        margin-top: 150px;
        cursor: pointer;
    }

    .customer-review-image {
        width: 50px;
        height: 50px;
        overflow: auto;
        border-radius: 100%;
        margin-bottom: 10px;
    }

    .landing-page-reviewer-name-v2 {

        width: 100%;
        height: 20px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 24px;
    }

    .landing-page-review-content-v2 {
        /* Body 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        margin-bottom: 24px;
        /* or 36px */

        text-align: center;


        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-total-review-count-v2 {
        margin-top: 24px;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-stars-v4 {
        width: 26px;
        height: 26px;
        float: left;
        margin: 2px;
    }


    .landing-page-review-stars-v3 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 32px;
    }

    .landing-page-review-stars-v2 {
        width: 150px;
        overflow: auto;
        margin-left: calc(50% - 75px);
        margin-bottom: 24px;
    }

    .landing-page-review-stars {
        width: 30px;
        height: 30px;
        float: left;
    }

    .promotion-messages {
        width: 100%;
        overflow: auto;
        color: red;
        font-weight: 600;
        text-align: right;
        font-size: 16px;
    }


    .landing-page-review-transition-ending-v3{
        position: static;
        width: 1440px;
        margin-left: calc(50% - 720px);
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #FFFFFF;

        padding: 64px 0;
    }

    .landing-page-review-transition-ending-v2 {
        position: static;
        width: 1440px;
        margin-left: calc( 50% - 720px);
        height: auto;
        left: 392px;
        top: 128px;

        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        background: #D6D1BA;
        padding: 128px 0;
    }

    .landing-page-review-transition-ending-header-v3 {
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 64px;
    }

    .landing-page-review-transition-ending-header-v2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .landing-page-review-transition-ending-button-v3{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: static;
        width: 790px;
        margin: 36px calc(50% - 395px);
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        text-transform: none;
    }

    .landing-page-review-transition-ending-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 206px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        background: #FFFFFF;
        border-radius: 30px;
        margin-top: 19px;
        margin-left: calc( 50% - 127px);
    }

    .sort-by-frame-size-button-selected {
        width: 18.8%;
        overflow: auto;
        margin: 0.5%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        background: #3b3b3b;
        color: white;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }


    .sort-by-frame-size-button {
        width: 18.8%;
        overflow: auto;
        margin: 0.5%;
        float: left;
        line-height: 1.5;
        border: #3b3b3b 1px solid;
        color: #3b3b3b;
        border-radius: 30px;
        padding: 1% 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

}

/*Ultra Wide*/

@media screen and (min-width: 1920px) {

    .notice-bar-version2-event, .notice-bar-version2-fr-event {
        /* Notice Bar */

        position: static;
        width: 1440px;
        overlflow-y: auto;
        left: 0.83px;
        top: 0px;

        /*background: #3F5C6B;*/

        /*Black Friday Code Below*/

        background: black;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .notice-bar-version2, .notice-bar-version2-fr {
        /* Notice Bar */

        position: static;
        width: 1440px;
        overflow-y: auto;
        left: 0.83px;
        top: 0px;
        background: #3F5C6B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .landing-page-container {
        width: 1440px;
        margin-left: calc( 50% - 720px);
    }


    .navbar-v2 {
        width: 1440px;
        overflow: auto;
    }

    .landing-page-image-overlay-black-v2 {
        width: 1440px;
    }

    .landing-page-subtext-first-v2, .landing-page-subtext-second-v2 {
        margin-left: calc(50% - 770px);
    }






}


@media screen and (min-width: 0px) {


    .what-is-mat-header-v2-transition {
        width: 20px;
        border: solid 0.5px #3B3B3B;
        margin: 24px 0;
    }

    .how-it-works-subheader-right u {
        cursor: pointer;
    }

    audio::-webkit-media-controls-timeline,
    video::-webkit-media-controls-timeline {
        display: none;
    }
    audio::-webkit-media-controls,
    video::-webkit-media-controls {
        display: none;
    }

    .review-transition {
        width: 100%;
        overflow: auto;
        text-align: center;
        text-transform: uppercase;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px; /* 127.778% */
        letter-spacing: 3.6px;
    }


    .review-transition-stars-container {
        width: 250px;
        overflow: auto;
        margin: 0 calc( 50% - 125px);
    }

    .review-transition-stars-number {
        width: auto;
        float: left;
        overflow: auto;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 48px */
        letter-spacing: 3.2px;
        text-transform: uppercase;
    }

    .review-transition-stars {
        width: 181px;
        overflow: auto;
        float: right;
    }

    .review-transition-star {
        float: right;
        width: 30px;
        margin: 7.5px 2.5px;
    }

    .review-transition-stars-desc {
        width: 100%;
        overflow: auto;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 19.5px */
        letter-spacing: 1.3px;
        text-transform: uppercase;
    }


















    
    .review-transition-button-left {
        width: auto;
        overflow: auto;
    }

    .testimonial-subscript-stars {
        width: 90.5px;
        height: 15px;
        margin-top: 5px;
    }


    .review-transition-logo {
        width: 100%;
        overflow: auto;
    }

    .support-arrow-arrow-v2 {
        width: 16px;
        margin-left: 7px;
        height: 12px;
        margin-top: 2.5px;
        cursor: pointer;
        transform: rotate(270deg);
    }


    .support-arrow-arrow-v2-inverse {
        width: 16px;
        margin-left: 7px;
        height: 12px;
        margin-top: 2.5px;
        transform: rotate(90deg);
        cursor: pointer;
    }


    .eapps-instagram-testimonials-item {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .eapps-instagram-testimonials-item eapps-instagram-testimonials-item-loaded {
        flex-basis: 100% !important;
    }

    .eapps-instagram-testimonials-item-empty {
        background: #FAF2ED !important;
    }

    .landing-page-container-version2 {
        /* Secondary/Stone Blue */

        background: #3F5C6B;

        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .eapps-instagram-testimonials-item-text-container {
        display: none !important;
    }

    .instagram-scroller::-webkit-scrollbar {
        display: none;
    }

    .eapps-instagram-testimonials-item-classic {
        background: #FAF2ED !important;
    }

    .instagram-controller-container {
        background: #78A891;
        overflow-x: hidden;
    }

    .instagram-controller-container::-webkit-scrollbar {
        display: none;
    }

    .eapps-instagram-testimonials-item-stars-container {
        display: none !important;
    }

    .eapps-instagram-testimonials-item-image-container {
        max-height: 352px !important;
    }

    .eapps-instagram-testimonials-item {
        border: none !important;
    }

    .eapps-instagram-testimonials-posts-load-more {
        /* Auto Layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: static;
        width: 193px !important;
        height: 52px !important;


        /* Primary/Soft Black */

        background: #3B3B3B !important;
        border-radius: 30px !important;

        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 13px !important;
        line-height: 150% !important;
        text-transform: uppercase;

        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;

        color: white;
    }


    .instagram-scroller-header-v2 {
        float: left;
    }




}








/*For all*/

.canvas-app-container {
    width: 100%;
    overflow-y: auto;
    padding: 5vh 0;
}

.dimensions-unit-selector-container {
    width: auto;
    overflow-y: auto;
    min-height: 1.5rem;
    float: right;
}
.app-selector-header {
    width: auto;
    overflow: auto;
    float: left;
}

.primary-matting-dimensions-container {
    line-height: 1.5;
    padding: 0.1rem 0 0.75rem 0;
    width: auto;
    overflow: auto;
    font-weight: 600;
}

.secondary-matting-dimensions-container {
    line-height: 1.5;
}

.go-to-pricing-info {
    width: 100%;
    overflow: auto;
    text-align: right;
    padding-top: 0.5rem;
}

.go-to-pricing-info:hover {
    text-decoration: underline;
}

.selector-container {
    width: 100%;
    overflow-y: auto;
}

.window-size-selector, .matting-colour-selector, .matting-type-selector {
    padding: 3vh 0;
}

.selector-form {
    float: right;
}

.matting-width-selector-header {
    float: left;
}

.matting-width-selector-form {
    width: calc( 100% - 2rem);
    overflow: auto;
    padding-top: 0.5rem;
}

.matting-width-selector-form-center-artwork-button {
    float: right;
    padding: 0.1rem 1rem;
    border: solid 0.1rem #E9E9E9;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}

.window-size-selector-form {
    padding-bottom: 0.5rem;
}

input {
    width: 2rem;
    text-align: center;
}

.matting-colour-selector-item {
    width: 1rem;
    height: 1rem;
    float: left;
    text-align: center;
    margin: 0 0.25rem;
    border: solid 0.1rem #E9E9E9;
    border-radius: 100%;
}

.pricing-summary-details, .pricing-summary-shipping-line-item {
    width: 100%;
    overflow-y: auto;
}

.pricing-summary-details-item, .pricing-summary-details-shipping-charges {
    float: left;
}

.pricing-summary-details-price, .pricing-summary-details-price {
    float: right;
}

.canvas-app-rendering-pricing-section-header {
    font-weight: 600;
    width: auto;
    overflow: auto;
    float: left;
    padding: 0 0 0.5rem 0;
}

.pricing-summary-shipping-location-selector. .shipping-location-options {
    float: left;
}

.pricing-summary-shipping-location-selector, .shipping-location-options {
    width: auto;
    overflow: hidden;
    float: left;
}

.shipping-location-options {
    padding: 0 0 0 0.5rem;
    float: right;
}

.shipping-location-options form {
    float: left;
}

.pricing-summary-shipping-location-selector {
    padding: 6px 0 7px 0;
}

.pricing-summary-total-amount {
    font-weight: 600;
    padding-top: 0.25rem;
    margin-top: 0.5rem;
    border-top: solid 0.05rem;
}

.canvas-app-add-to-cart-button {
    color: #fff;
    border: 1px solid #514945;
    width: 100%;
    overflow: auto;
    padding: 0.5rem 0 0.5rem 0;
    background: #514945;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
}

.canvas-app-rendering-container {
    width: auto;
    overflow: auto;
}

.only-matboard-option {
    overflow-y: auto;
    text-align: right;
    width: auto;
    border: solid 0.1rem #E9E9E9;
    padding: 1vh 0.5rem;
    text-align: center;
    display: inline-block;
    float: right;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.rendering-image-upload-button {
    width: auto;
    overflow: auto;
    padding: 0.5rem;
    border: solid 0.1rem #514945;
    border-radius: 0.25rem;
}

.canvas-app-rendering-upload-button label {
    width: auto;
    overflow: auto;
    margin: 0.75rem;
    padding: 0.15rem;
    display: inline-block;
}

.add-second-matting-button {
    width: 100%;
    overflow: auto;
    font-size: 0.8rem;
    float: right;
    padding: 0.5rem 0;
    text-align: right;
}

.delivery-estimation-window {
    font-size: 0.8rem;
    padding: 0.25rem 0;
}

.matting-width-header {
    padding: 0.2rem 0 0.5rem;
}

.matting-colour-selector-header, .matting-thickness-selector-header {
    width: auto;
    float: left;
}

.matting-colour-selector-form, .matting-thickness-selector-form {
    width: auto;
    /*overflow: auto;*/
    float: right;
    padding: 0.5rem 0;
}

.matting-thickness-selector-header {
    width: auto;
    float: left;
    padding: 0.5rem 0;
}

.mat-board-size-input-field{
    width: 5rem;
    float: left;
}

.shipping-location-options .jss28 {
    width: 85% !important;
}

.jss28 {
    width: 100% !important;
    text-align: center !important;
}

.jss36 {
    padding-right: 0 !important;
}

.mat-board-size-input-multiplying-sign {
    font-size: 0.8rem;
    padding: 0.5rem;
    float: left;
}

.canvas-app-rendering-moulding-layer {
    overflow: hidden;
    padding: 0;
    box-shadow: rgba(10, 10, 10, 0.6) 0 0.1rem 0.2rem inset;
}

.mat-board-size-selector {
    padding: 3vh 0 1.5vh 0;
}

.mat-board-size-selector, .selector-container {
    overflow-x: hidden;
}

.mat-board-opening-input-field {
    width: calc( 25% - 0.3rem );
    overflow-x: hidden;
    overflow-y: auto;
    float: left;
    margin: 0 0.15rem !important;
}

.MuiTypography-root-40 MuiTypography-body1-49 MuiTypography-colorTextSecondary-73 {
    margin-left: -0.2rem !important;
}

.MuiSelect-root-76 {
    border-bottom: solid 0.1rem !important;
    margin-right: 0.1rem;
}

.matting-width-selector {
    width: auto;
    padding: 1rem 0 0.75rem 0;
    overflow: auto;
}

.upload-image-button {
    width: auto;
    overflow-y: auto;
    float: right;
    padding: 0.1rem 0.5rem;
    text-align: center;
    border: solid 0.1rem #e9e9e9;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}


.visualization-tool-header {
    font-size: 1rem;
    padding: 0.2rem 0 0 0;
    font-weight: 600;
}

.order-summary-subsection-container {
    padding: 1rem 0 0 0;
}

.common-level {
    width: 100%;
    overflow-y: auto;
    padding: 0 0 0.75rem 0;
}

.jss38 {
    margin-left: 0 !important;
}

.canvas-app-rendering-options {
    width: auto;
    overflow-y: auto;
    float: left;
    padding: 0.1rem 0;
}

.frame-colour-selector {
    float: right;
    width: auto;
    padding: 0.1rem 0.15rem;
    text-align: center;
    border: solid 0.1rem #e9e9e9;
    margin: 0 0 0 0.2rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
}

.frame-layer {
    width: 20rem;
    overflow-y: auto;
    padding: 1rem;
    margin: 4rem calc( 50% - 11rem);
    /*background-color: black;*/
    box-shadow: rgba(0, 0, 0, 0.6) 0.5rem 0.2rem 1rem;
    background-size: 100% 100%;
    z-index: 5;
}

.visualization-tool-section {
    padding: 0;
    border-bottom: solid 0.05rem;
    margin-bottom: 0.5rem;
}

.rendering-matting-dimensions-indicator {
    width: auto;
    overflow: auto;
    padding: 0.5rem;
    position: absolute;
    font-size: 0.7rem;
    line-height: 2;
}

.canvas-app-rendering-hide-text-button {
    width: auto;
    overflow-y: auto;
    margin: 0.5rem;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.canvas-app-rendering-expand-button {
    width: auto;
    overflow-y: auto;
    margin: 0.5rem;
    float: right;
}

.canvas-app-rendering-expand-button-text {
    width: auto;
    overflow-y: auto;
    padding: 0.15rem;
    float: left;
}

.app-button {
    transition: background-color linear 0.3s;
}

.app-button:hover {
    cursor: pointer;
    background-color: #f1ece4;
}

.app-button-selected {
    background-color: #f1ece4;
}

.selected-colour {
    display: inline;
}

.unselected-colour {
    display: none;
}

.add-more-window-button, .change-opening-shape-button {
    width: auto;
    overflow: auto;
    font-size: 0.7rem;
    float: right;
    padding: 0.25rem 0 0.5rem 0;
    margin-left: 0.5rem;
}

.app-underline-button:hover {
    cursor: pointer;
    color: #c88d75;
    text-decoration: underline;
}

.app-button-no-hovering {
    cursor: pointer;
}

.app-text-based-option {
    float: left;
    width: auto;
    min-width: 2rem;
    text-align: center;
    border: solid 0.1rem #E9E9E9;
    margin: 0 0 0 0.1rem;
    border-radius: 0.5rem;
    font-family: "Montserrat";
}

.no-display {
    display: none;
}

.canvas-app-rendering-upload-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.rendering-section-hovered {
    background-color: #99ccff !important;
}

.cursor-pointer {
    cursor: pointer;
}

.canvas-app-rendering-upload-button:hover {
    text-decoration: underline;
}

.incorrect-value-warnings {
    position: absolute;
    font-size: .7rem;
    left: 2.5rem;
    color: #f44336;
    padding-top: .3rem;
    background: #FFFCFC;
    padding: 0.5rem;
}

.input-section-highlight-warning {
    background-color: #ffcdd2;
}

.primary-matting-chevron {
    border-bottom: solid 0.05rem;
    margin: 0 0 0.5rem 0;
    text-align: center;
}

.canvas-app-rendering-secondary-matting-and-image-layer {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: 100% 100%;
    box-shadow: rgba(10, 10, 10, 0.8) 0 0.05rem 0.05rem inset;
}

.canvas-app-rendering-primary-matting-layer {
    box-shadow: rgba(10, 10, 10, 0.8) 0 0.05rem 0.05rem inset;
}

.canvas-app-rendering-wall-layer-color {

}

.wall-background {
    width: 30%;
    position: absolute;
    z-index: -5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /*background-image: url("https://previews.123rf.com/images/vichie81/vichie811010/vichie81101000214/8089461-yellow-modern-brick-wall-pattern.jpg");*/
}

.second-matting-options {
    width: 100%;
    overflow: auto;
    padding-top: 0.5rem;
}

.matting-measurement-unit-indicator {
    width: auto;
    overflow: auto;
    float: right;
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
}

.canvas-app-rendering-uploaded-artwork {
    width: 100%;
    height: 100%;
}

.canvas-app-mat-dimensions {
    width: calc(35% - 4rem);
    overflow-y: auto;
    float: left;
    padding: 0rem 1.5rem 0 2.5rem;
}

.canvas-app-mat-rendering {
    width: 30%;
    overflow-y: hidden;
    float: left;
    background: #c88d75;
}

.canvas-app-rendering-pricing {
    width: calc(35% - 4rem);
    overflow-y: auto;
    float: left;
    padding: 0rem 2.5rem 0 1.5rem;
}

.app-text-based-option {
    float: right;
}

.canvas-app-rendering-pricing-section-options {
    line-height: 1.5;
}

/*Removing arrows for the value entry text field section*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiFormControl-root-1 {
    width: 100%;
}


.MuiInputAdornment-positionEnd-38 {
    margin-left: 0 !important;
}

.shipping-summary-item-container {
    padding: 0.5rem 0;
}

.shipping-summary-left {
    width: 50%;
    float: left;
}

.change-currency-button {
    width: auto;
    overflow: auto;
    float: right;
    border: solid 0.1rem #E9E9E9;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.3s linear;
}

.change-currency-button:hover {
    background: #f1ece4;
}

.forEx-information {
    text-align: right;
    margin-top: 1rem;
}

.question-mark-content {
    position: absolute;
    width: 60vw;
    height: 70vh;
    margin: 0 20vw;
    background-color: white;
    z-index: 10;
}

.no-mat-board-opening-indicator {
    width: 100%;
    overflow: auto;
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
    padding-top: 2rem;
}

.landing-page-show-more-icon {
    position: fixed;
    z-index: 15;
    width: 2rem;
    color: white;
    opacity: 0.5;
    bottom: 0.5rem;
    left: calc(50% - 1rem);
    animation: pulse 3s infinite;
}

.show-matting-width-guide {
    width: 12rem;
    overflow-y: auto;
    position: absolute;
    font-size: 0.8rem;
    padding: 0.25rem;
}

.wall-background img {
    object-fit: cover;
}

@keyframes pulse {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.9
    }
    100% {
        opacity: 0.2;
    }
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .MuiInputBase-root-18 {
        width: 90% !important;
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {

    .canvas-app-container {
        width: auto;
        overflow-y: auto;
        padding: 0;
    }

    .canvas-app-mat-dimensions, .canvas-app-mat-rendering, .canvas-app-rendering-pricing {
        width: calc(100% - 2rem);
        overflow-y: hidden;
        padding: 1rem;
    }

    .canvas-app-mat-rendering {
        background: red;
        height: 60vh;
        overflow: hidden;
    }

    .wall-background {
        width: calc( 100% - 2rem);
        height: 60vh;
    }

    .wall-background img {
        width: 100%;
        height: 60vh;
    }

    .matting-colour-selector-item {
        margin: 0.5rem 0.25rem;
    }

    .selected-colour {
        width: 1rem;
        height: 1rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 1rem;
        line-height: 2;
    }

    .MuiInputBase-root-18 {
        width: 90% !important;
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

    .app-text-based-option {
        padding: 0.1rem 0.15rem;
        font-size: 0.7rem;
    }

    .app-text-based-option {
        margin-top: 0.2rem;
    }

    .canvas-app-rendering-pricing-section {
        margin: 0.5rem 0;
    }

    .order-summary-remove-item-container {
        font-size: 0.8rem;
    }

    .delete-button {
        font-size: 0.6rem;
    }

    .shipping-summary-item-container {
        font-size: 0.8rem;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 20%;
        font-size: 0.6rem;
        font-weight: 600;
    }

    .pricing-summary-details, .pricing-summary-shipping-line-item {
        font-size: 1rem;
        margin: 1rem 0;
    }

    .shipping-summary-item-container {
        text-align: center;
    }

    .canvas-app-rendering-pricing-section-header {
        width: 100%;
        text-align: center;
    }

    .change-currency-button {
        font-size: 1rem;
        padding: 0.1rem 0.5rem;
        margin: 0.5rem 0;
    }

    .only-matboard-option {
        font-size: 0.7rem;
    }

    .forEx-information {
        font-size: 0.8rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .canvas-app-mat-dimensions {
        width: calc(35% - 4rem);
        overflow-y: auto;
        float: left;
        padding: 0rem 1.5rem 0 2.5rem;
    }

    .canvas-app-mat-rendering {
        width: 30%;
        height: 70vh;
        overflow-y: hidden;
        float: left;
        background: #c88d75;
    }

    .wall-background {
        height: 70vh;
    }

    .wall-background img {
        width: 100%;
        height: 70vh;
    }

    .canvas-app-rendering-pricing {
        width: calc(35% - 4rem);
        overflow-y: auto;
        float: left;
        padding: 0rem 2.5rem 0 1.5rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .selected-colour {
        width: 1rem;
        height: 1rem;
    }

    .MuiInputBase-root-18 {
        /*width: 90% !important;*/
    }

    .MuiSelect-select-77 {
        min-width: 4rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        font-size: 0.6rem;
        padding-top: 20%;
        font-weight: 600;
    }

    .app-text-based-option {
        padding: 0.1rem 0.15rem;
        font-size: 0.8rem;
    }

    .canvas-app-rendering-pricing-section {
        margin: 0.5rem 0;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .delete-button {
        font-size: 0.6rem;
    }

    .shipping-summary-item-container {
        font-size: 0.8rem;
    }

    .change-currency-button {
        font-size: 0.8rem;
        padding: 0.1rem 0.5rem;
        margin: 0.5rem 0 1rem 0;
    }

    .only-matboard-option {
        font-size: 0.8rem;
    }

    .forEx-information {
        font-size: 0.8rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .upload-image-button label {
        font-size: 1rem;
        line-height: 1;
    }

    .common-level .canvas-app-rendering-pricing-section-options, .pricing-summary-shipping-location-selector, .canvas-app-rendering-pricing-section-header {
        font-size: 1.3rem;
    }

    .canvas-app-rendering-pricing-section-options {
        font-size: 1rem;
        line-height: 2;
    }

    .matting-dimensions-container {
        font-size: 1.3rem;
    }

    .mat-board-size-input-field {
        width: 7.5rem;
    }

    .mat-board-size-selector {
        padding: 1rem 0;
    }

    .window-size-selector, .matting-colour-selector, .matting-type-selector {
        padding: 3vh 0;
    }

    .add-more-window-button, .change-opening-shape-button, .add-second-matting-button {
        font-size: 1rem;
        margin-left: 1rem;
    }

    .matting-measurement-unit-indicator {
        font-size: 1rem;
    }

    .dimensions-unit-selector-container {
        min-height: 2.5rem;
        padding-top: .5rem;
    }


    .app-text-based-option {
        font-size: 1rem;
        padding: 0.2rem 0.4rem;
    }

    .matting-colour-selector-item {
        width: 1.5rem;
        height: 1.5rem;
    }

    .matting-width-selector-form-center-artwork-button {
        font-size: 1rem;
    }

    .MuiInputBase-input-28 {
        font-size: 1.3rem !important;
    }

    .MuiTypography-root-40 {
        font-size: 1.3rem !important;
    }

    .matting-width-header {
        margin-bottom: 1rem;
    }

    .primary-matting-dimensions-container {
        padding-bottom: 1rem;
        font-size: 1.3rem;
    }

    .MuiInputBase-root-18 {
        width: 100% !important;
    }

    .MuiSelect-select-77 {
        min-width: 6rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 20%;
        font-size: 1rem;
    }

    .app-text-based-option {
        padding: 0.1rem 0.5rem;
        font-size: 1rem;
    }

    .canvas-app-container {
        width: 95%;
        overflow-y: auto;
        padding: 6rem 0;
        margin: 0 2.5%;
    }

    .canvas-app-mat-rendering, .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        height: 50vh;
        background: #FFFCFC;
    }

    .wall-background {
        height: 50vh;
    }

    .wall-background img {
        width: 100%;
        height: 50vh;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .delete-button {
        font-size: 1rem;
    }

    .currency-information {
        font-size: 1.3rem;
    }

    .shipping-summary-item-container {
        font-size: 1rem;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 40%;
        font-size: 1rem;
    }

    .change-currency-button {
        font-size: 1rem;
        padding: 0.25rem 0.75rem;
        margin: 1rem 0 1.5rem 1.5rem;
    }

    .only-matboard-option {
        font-size: 1rem;
    }

    .forEx-information {
        font-size: 1rem;
    }

    .go-to-pricing-info {
        font-size: 0.8rem;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {

    .upload-image-button label {
        font-size: 1.3rem;
        line-height: 1;
    }

    .canvas-app-container {
        width: 95%;
        overflow-y: auto;
        padding: 6rem 0;
        margin: 0 2.5%;
    }

    .canvas-app-mat-rendering {
        width: 40%;
    }

    .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        width: calc(30% - 4rem);
        height: 70vh;
    }

    /*.canvas-app-matsize-rendering-comp-pricing-section {*/
        /*padding: 0.5rem 0 0 0;*/
    /*}*/

    .canvas-app-rendering-pricing-section-options {
        width: 100%;
        overflow: auto;
        font-size: 1.5rem;
        line-height: 1.5;
        padding: 1rem 0;
    }

    .comp-pricing-summary-shipping-line-item {
        width: 100%;
        overflow: auto;
        margin-top: 1rem;
    }

    .canvas-app-pricing-container {
        width: 100%;
        overflow: auto;
        margin-top: 2rem;
    }

    .canvas-app-rendering-pricing-section-header {
        font-size: 1.5rem;
    }

    .canvas-app-mat-dimensions {
        font-size: 1.5rem;
        line-height: 2;
    }

    .mat-board-size-input-multiplying-sign {
        padding: 0 1rem;
        font-size: 1.5rem;
    }

    .mat-board-size-input-field {
        width: 10rem;
    }

    .mat-board-size-selector-form {
        padding: 0.75rem 0 0 0;
    }

    .add-more-window-button, .change-opening-shape-button {
        font-size: 1rem;
        margin-left: 2rem;
    }

    .matting-width-selector-form-center-artwork-button {
        font-size: 1.3rem;
        margin-top: 0.25rem;
    }

    .matting-colour-selector-form, .matting-thickness-selector-form {
        padding: 1rem 0;
    }

    .matting-colour-selector-item {
        width: 1.5rem;
        height: 1.5rem;
    }

    .selected-colour {
        width: 1.5rem;
        height: 1.5rem;
        padding-bottom: 0.5rem;
    }

    .visualization-tool-header {
        font-size: 1.5rem;
    }

    .app-text-based-option, .add-second-matting-button {
        font-size: 1rem;
        padding: 0.2rem 1rem;
    }

    .selector-container {
        width: 100%;
        overflow-y: auto;
        margin: 1rem 0 0 0;
    }

    .canvas-app-mat-dimensions {
        padding: 0 3rem 0 1rem;
    }

    .matting-measurement-unit-indicator {
        font-size: 1.3rem;
    }

    .common-level {
        padding: 0 0 1rem 0;
    }

    .second-matting-options .app-text-based-option {

    }

    .wall-background {
        width: 40%;
        height: 70vh;
        background-repeat: repeat;
        background-size: 100%;
    }

    .wall-background img {
        width: 100%;
        height: 70vh;
    }

    .primary-matting-chevron {
        margin-top: 0.5rem;
    }

    .canvas-app-mat-rendering, .canvas-app-mat-dimensions, .canvas-app-rendering-pricing {
        height: 70vh;
        background: #FFFCFC;
    }

    /*.comp-pricing-section-line-item {*/
        /*margin: 1rem 0;*/
    /*}*/

    .canvas-app-add-to-cart-button {
        font-size: 1.5rem;
        padding: 1rem 0;
    }

    .pricing-summary-total-amount {
        padding-top: 1.5rem;
    }

    .dimensions-unit-selector-container {
        min-height: 2.5rem;
    }

    .MuiInputBase-input-28 {
        font-size: 1.5rem !important;
    }

    .MuiTypography-root-40 {
        font-size: 1.5rem !important;
    }

    .MuiInputBase-root-18 {
        width: 9rem !important;
    }

    .MuiInputBase-root-18 {
        width: 100% !important;
    }

    .MuiSelect-select-77 {
        min-width: 8rem !important;
        text-align: center;
    }

    .canvas-app-rendering-upload-button {
        padding-top: 40%;
        font-size: 1.3rem;
    }

    .app-text-based-option {
        padding: 0.1rem 0.25rem;
        font-size: 1.3rem;
    }

    .order-summary-subsection-container {
        font-size: 1.3rem;
    }

    .order-summary-small-font {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .currency-information {
        font-size: 1.3rem;
    }

    .delete-button {
        font-size: 1rem;
    }

    .shipping-summary-item-container {
        font-size: 1.3rem;
    }

    .jss76 {
        font-size: 1.3rem !important;
        margin-left: 2rem !important;
    }


    .jss39 {
        font-size: 1.3rem !important;
    }

    .pricing-summary-shipping-line-item {
        margin: 1rem 0;
    }

    .change-currency-button {
        font-size: 1.3rem;
    }

    .change-currency-button {
        font-size: 1.3rem;
        padding: 0.5rem 1rem;
        margin: 1rem 0 1.5rem 1.5rem;
    }

    .only-matboard-option {
        font-size: 1rem;
    }

    .forEx-information {
        font-size: 1rem;
    }

    .go-to-pricing-info {
        font-size: 1rem;
    }

}

.navbar-bottom-company-logo-container {
    text-transform: uppercase;
    letter-spacing: 2px;
}

.navbar-column-title-icons {
    width: 20px;
    float: left;
    margin-right: 10px
}




.navbar-bottom-sitemap {
    width: 100%;
    overflow-y: auto;
}

.navbar-sitemap-left-container, .navbar-sitemap-middle-container {
    float: left;
    overflow-y: auto;
}

.sitemap-button {
    width: auto;
    height: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5;
    display: inline-block;
    text-decoration: none;
    color: #514945;
    border-bottom: solid 1.5px #FCF9F8;
    transition: border 0.3s linear;
}

.sitemap-button:hover {
    border-bottom: solid 1.5px #FCC4BF;
}

.key-service-areas-header-text a:hover {
    border-bottom: solid 0.1rem #f59276;
}

#business-email-contact:hover {
    text-decoration: underline;
}

.navbar-right-container-image {
    width: 40%;
    overflow-y: auto;
    float: left;
}

.navbar-right-container-text {
    width: 55%;
    margin-left: 5%;
    float: left;
    font-size: 1rem;
    font-weight: 600;
}

.business-owner-header {
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 0;
    width: 100%;
    overflow-y: auto;
    text-align: left;
}

.bottom-contact-message {
    text-decoration: none;
}

.bottom-contact-message:hover {
    text-decoration: underline;
}


.business-owner-button {
    background-color: #514945;
    color: white;
    font-size: 1rem;
    width: 302px;
    overflow-y: auto;
    padding: 11px 30px;
    text-align: center;
    margin-top: 10px;
}

.business-owner-button:hover {
    background-color: #F59276;
    color: white;
    transition: background 0.5s linear, color 0.3s linear;
}

.bottom-contact-message {
    float: left;
    width: 100%;
}

.fa-heart {
    color: #f59276;
}

input::placeholder {
    color: #514945;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.navbar-bottom-container {
    background: #3F5C6B;
    position: relative;
    color: white;
}

.email-subscription-input-field {
    border: solid 1px #514945;
    width: 219px;
    height: 1.2rem;
    padding: 10px 5px;
    font-size: 1rem;
    color: #514945;
}

.email-subscription-submit-button {
    background: #514945;
    border: solid 0.5px #514945;
    width: 130px;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    font-family: Montserrat;
    cursor: pointer;
    padding: 0.6rem 0 0.65rem;
}

.email-subscription-submit-button:hover {
    background-color: #F59276;
    color: white;
    border: solid 0.5px #F59276;
    transition: border 0.5s linear, background 0.5s linear, color 0.3s linear;
}

.navbar-sitemap-right-separator {
    width: 300px;
    height: 1px;
    background-color: #514945;
}

.navbar-bottom-uppercase {
    text-transform: uppercase;
}

.navbar-bottom-section {
    width: auto;
    overflow: auto;
    font-size: 0.8rem;
    text-align: left;
    line-height: 2;
    float: left;
    padding-top: 0.75rem;
    padding-left: 1rem;
}

.business-owner-button {
    cursor: pointer;
}

.email-field-no-show {
    display: none;
}

.email-input-alternative-message {
    width: 100%;
    color: #F59276;
    font-size: 1.2rem;
    height: 1.2rem;
    text-align: center;
    font-weight: 400;
}

.navbar-sitemap-right-container {
    overflow-y: auto;
    float: left;
}

.key-service-areas {
    width: calc( 100% - 4rem );
    overflow: auto;
    margin-bottom: 4rem;
    padding: 2rem;
}

.key-service-areas-header-text {
    font-weight: 400;
    text-align: center;
    font-size: 0.8rem;
    line-height: 2;
}


.navbar-bottom-column-questions {
    font-size: 1.11vw;
    letter-spacing: 0.0174vw;
    line-height: 1.736vw;
    font-weight: 600;
    color: #72696A;
}

.navbar-bottom-column-question {
    font-weight: 600;
    color: #72696A;
}










.navbar-bottom-column-phone, .navbar-bottom-column-email {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 225%;
    /* or 36px */
    letter-spacing: 0.02em;
    color: white;

}

.navbar-bottom-company-address-container div {
    color: #72696A;
}


/*For all*/



/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .navbar-bottom-company-info-section {
        width: 100%;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
    }

    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;
        overflow: auto;
        text-align: right;
        margin-bottom: 48px;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {

    .navbar-bottom-column-min-height {
        min-height: 250px;
    }

    .navbar-sitemap-left-container, .navbar-sitemap-middle-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
    }

    .navbar-bottom-happiness-guarantee-banner {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
    }

    .navbar-bottom-company-address-container {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: right;
        letter-spacing: 1.3px;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
        margin-top: 24px;
    }

    .navbar-sitemap-right-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
        line-height: 2;
    }

    .key-service-areas-header-text {
        width: 100%;
    }

    .navbar-bottom-section {
        width: auto;
        overflow: auto;
        font-size: 0.8rem;
        text-align: center;
        padding: 1rem;
    }

    .navbar-bottom-container {
        background: #3F5C6B;
        position: relative;
        color: white;
        width: calc(100% - 128px);
        padding: 64px;
    }

    .navbar-bottom-column {
        width: 50%;
        height: auto;
        float: left;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: #3B3B3B;
        margin-bottom: 64px;
    }

    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
        margin-right: 22.9px;
    }

    .navbar-bottom-company-info-section {
        width: 100%;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
    }

    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 100%;
        overflow: auto;
        text-align: right;
        margin-top: 24px;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }




}

/*Normal Laptop*/

@media screen and (min-width: 1100px) and (max-width: 1919px) {

    .select-mat-rendering {
        width: 45%;
        margin-left: 2.5%;
        position: fixed;
        float: left;
        overflow-x: hidden;
    }

    .select-mat-rendering-event {
        /*width: 480px;*/
        width: 40%;
        margin-left: 10%;
        position: fixed;
        float: left;
        overflow: auto;
        /*margin: 0 calc( (100% - 960px) / 3 );*/
        overflow-x: hidden;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }


    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 100%;
        overflow: auto;
        text-align: right;
        margin-top: 72px;
    }
    .navbar-sitemap-left-container, .navbar-sitemap-middle-container {
        width: 25%;
    }

    .navbar-sitemap-right-container {
        margin-left: calc(50% - 362px);
        width: 362px;
    }

    .navbar-bottom-company-logo-container {
        font-size: 1rem;
    }

    .key-service-areas-header-text {
        width: 60%;
        margin-left: 20%;
    }

    .navbar-bottom-container {
        width: 100%;
        overflow: hidden;
        padding: 56px 0 24px 0;
        position: relative;
        color: white;
    }

    .navbar-bottom-column {
        width: 200px;
        height: auto;
        float: left;
        margin-right: 100px;
    }

    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        margin-bottom: 16px;
    }



    .navbar-column-buttons {
        width: 300px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
    }

    .navbar-bottom-company-address-container {
        width: 650px;
        overflow-y: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-happiness-guarantee-banner {
        margin-left: 22.9px;
        float: left;
        width: 502px;
        /* height: 48px; */
        /* left: 216px; */
        /* top: 371px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-leaf-vector {
        width: 15.69px;
        height: 16.86px;
    }

    .navbar-bottom-company-info-section {
        width: 1220px;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
        margin-left: calc( 50% - 610px);

    }

    .navbar-bottom-options  {
        width: 1220px;
        overflow: auto;
        margin-left: calc( 50% - 610px);
    }

    .navbar-bottom-options {
        width: 1220px;
        overflow: auto;
        margin: 0 calc( 50% - 610px);
    }


}

@media screen and (min-width: 1920px) {

    .navbar-bottom-options {
        width: 1220px;
        overflow: auto;
        margin: 0 calc( 50% - 610px);
    }

    .select-mat-rendering {
        width: 700px;
        overflow: auto;
        overflow-x: hidden;
        margin-left: 0px;
    }


    .select-mat-rendering-event {
        /*width: 480px;*/
        width: 30%;
        margin-left: 10%;
        position: fixed;
        float: left;
        overflow: auto;
        /*margin: 0 calc( (100% - 960px) / 3 );*/
        overflow-x: hidden;
    }


    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }

    .navbar-top-container {
        width: 1440px;
        margin-left: calc(50% - 720px);
    }


    .navbar-bottom-container {
        width: 1440px;
        overflow: hidden;
        padding: 56px 0 24px 0;
        position: relative;
        color: white;
        margin-left: calc(50% - 720px);
    }

    .navbar-bottom-happiness-guarantee-banner {
        margin-left: 22.9px;
        float: left;
        width: 502px;
        /* height: 48px; */
        /* left: 216px; */
        /* top: 371px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-company-info-section {
        width: 1220px;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
        margin-left: calc( 50% - 610px);

    }

    .navbar-bottom-company-address-container {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: right;
        letter-spacing: 1.3px;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
        margin-top: 24px;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
    }


}
/*For all*/

#what-we-do-container {
    width: auto;
    overflow-y: auto;
    background-color: #f1ece4;
    /*margin-top: 4rem;*/
}

.how-it-works-steps-image {
    object-fit: cover;
}

.how-it-works-steps-image-container, .how-it-works-steps-content {
    width: 100%;
    text-align: center;
}

.how-it-works-steps-sub-content-option {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
    margin: 1rem 0 0 0;
}

.how-it-works-steps-sub-content-option:hover {
    text-decoration: underline;
}

.how-it-works-quicklink {
    width: 97%;
    overflow-y: auto;
    color: #858585;
    text-align: center;
    cursor: pointer;
    /* text-decoration: underline; */
    margin: 5vh 1rem;
    float: left;
}

.how-it-works-quicklink:hover {
    text-decoration: underline;
}

.how-it-works-quicklink:hover {
    color: #f59276;
}

.how-it-works-steps-content {
    padding-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.2;
    color: #514945;
}

a {
    text-decoration: none;
}

.how-it-works-steps-content-desc {
    width: 100%;
    overflow: auto;
    margin-top: 1rem;
    text-align: center;
    line-height: 2;
    color: #858585;
}

#frequently-asked-question-button {
    color: #514945;
}

.how-it-works-step-container {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .how-it-works-steps {
        width: auto;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 2rem calc( 50% - 10rem );
    }

    /*.section-topic-header {*/
        /*font-size: 1.3rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 15rem;
        height: 15rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 7.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 2rem;
    }

    .how-it-works-container {
        padding: 5rem 0.5rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 1.5rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 15rem;
        height: 15rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 7.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 2rem;
    }

    .how-it-works-container {
        padding: 5rem 3rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 2rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 25rem;
        height: 25rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 12.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 4rem;
        font-size: 1.5rem;
    }

    .how-it-works-container {
        padding: 15rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 2rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 25rem;
        height: 25rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 12.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 4rem;
        font-size: 1.5rem;
    }

    .how-it-works-container {
        padding: 5rem 30rem;
    }

    #how-it-works-container {
        padding: 5rem 20%;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }

    .how-it-works-steps-content-desc {
        font-size: 1.3rem;
    }
}

/*For all*/

.what-we-do-description {
    line-height: 2.5;
    text-align: center;
    font-weight: 500;
}

.go-to-success-stories {
    text-align: center;
    cursor: pointer;
    /*color: white;*/
    opacity: 0.7;
    text-decoration: underline;
}

.go-to-success-stories:hover {
    color: #f59276;
}

.go-to-success-stories:hover {
    text-decoration: underline;
    font-weight: 500;
}

#how-it-works-topic-header {
    color: #514945;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    display: inline-block;
}

#transaction-topic-header-reduced-margin {
    margin-top: -10vh;
    width: 100%;
    text-align: center;
    border-bottom: none;
}

#what-we-do-description-margin {
    font-weight: 400;
}

.return-to-payment-page-button {
    width: 20%;
    overflow: auto;
    font-weight: 500;
    color: black;
    padding: 0.5rem 1rem;
    border: 0.1rem black solid;
    display: inline-block;
    text-align: center;
    margin: 0 39%;
}

.what-we-do-container-first-container {
    width: 100%;
    overflow-y: auto;
}



.section-topic-header {
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
    /* padding-bottom: 2rem; */
    border-bottom: #f59276 solid 0.1rem;
    width: auto;
    padding: 0;
    margin: 0;
    overflow: auto;
    display: inline-block;
}



.what-we-do-see-before-button:hover {
    background: #514945;
    color: white;
}

.what-we-do-second-left {
    overflow-y: auto;
    float: left;
    opacity: 0.8;
}

.what-we-do-second-right {
    overflow-y: auto;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2;
}

.what-we-do-container-second-container {
    width: 100%;
    overflow: auto;
}

.float-left {
    float: left;
}

.how-it-works-faq a:hover {
    text-decoration: underline;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    /*.what-we-do-description {*/
        /*padding: 3rem 0%;*/
        /*font-size: 1rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 0.7rem;
    }

    .what-we-do-description {
        padding: 3rem 5%;
        font-size: 0.7rem;
    }

    .what-we-do-first-left {
        width: 80%;
        overflow-y: auto;
        float: left;
        padding: 0 10%;
        font-size: 1rem;
        font-weight: 500;
        line-height: 2;
        text-align: center;
    }

    .what-we-do-first-right {
        width: 100%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 5vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 5%;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }

    .what-we-do-photo-before {
        width: 90%;
        margin: 0 5%;
        z-index: 10;
    }

    .what-we-do-see-before-button {
        text-align: center;
        margin: 2rem 0;
        font-size: 0.8rem;
        text-transform: capitalize;
        width: 100%;
    }

    .go-to-success-stories {
        width: 100%;
        overflow-y: auto;
        padding: 2rem 0 0 0;
        text-align: center;
    }

    .what-we-do-second-left {
        width: 100%;
        margin-bottom: 5vh;
    }

    .what-we-do-second-right {
        width: 90%;
        padding: 10vh 0 10vh 5%;
        text-align: center;
    }

    .how-it-works-faq {
        width: 80%;
        overflow-y: auto;
        margin-left: 10%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    /*.what-we-do-description {*/
        /*padding: 3rem 15%;*/
        /*font-size: 1.3rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*!*text-align: center;*!*/
    /*}*/

    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 16;
    }

    .what-we-do-first-left {
        width: 35%;
        overflow-y: auto;
        float: left;
        padding: 25vh 5% 10vh 5%;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 5vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }

    .what-we-do-see-before-button {
        overflow-y: auto;
        color: black;
        float: right;
        padding: 0.75rem;
        cursor: pointer;
        margin-right: 10%;
        text-transform: capitalize;
        letter-spacing: 0.05rem;
        border: solid 0.05rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        transition: background-color 0.3s linear;
        font-weight: 500;
    }

    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0.5rem;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 5vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 25vh 0 25vh 5%;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    /*.what-we-do-description {*/
        /*padding: 10rem 12.5%;*/
        /*font-size: 1.8rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 1.5rem;
    }

    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 1.3rem;
    }

    .what-we-do-first-left {
        width: 40%;
        overflow-y: auto;
        float: left;
        padding: 25vh 0 25vh 5%;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 10vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }


    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0.5rem;
    }


    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 10vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 25vh 0 25vh 5%;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1rem;
    }

    #how-it-works-topic-header {
        font-size: 1rem;
    }


}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    /*.what-we-do-description {*/
        /*padding: 5rem 10%;*/
        /*font-size: 2rem;*/
        /*font-weight: 300;*/
        /*line-height: 2.5;*/
        /*text-align: center;*/
    /*}*/

    .go-to-success-stories {
        font-size: 1.5rem;
    }


    .what-we-do-description {
        padding: 3rem 15%;
        font-size: 1.5rem;
    }

    .what-we-do-first-left {
        width: 40%;
        overflow-y: auto;
        float: left;
        padding: 15vh 0 15vh 5%;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2;
    }

    .what-we-do-first-right {
        width: 55%;
        overflow-y: auto;
        float: left;
        opacity: 0.8;
        margin-bottom: 10vh;
    }

    .what-we-do-photos {
        width: 90%;
        margin: 0 10% 0 0;
    }

    .what-we-do-see-before-button {
        overflow-y: auto;
        color: black;
        float: right;
        padding: 0.75rem;
        cursor: pointer;
        margin-right: 10%;
        text-transform: capitalize;
        letter-spacing: 0.05rem;
        border: solid 0.05rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        transition: background-color 0.3s linear;
        font-weight: 500;
    }

    .what-we-do-subheader {
        color: #858585;
        font-weight: 400;
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }


    .what-we-do-photo-before {
        width: 90%;
        margin: 0 10% 0 0;
        z-index: 10;
    }

    .go-to-success-stories {
        width: 50%;
        overflow-y: auto;
        padding: 0 25%;
        text-align: center;
    }

    .what-we-do-container-first-container {
        margin-bottom: 20vh;
    }

    .what-we-do-second-left {
        width: 55%;
        margin-bottom: 10vh;
    }

    .what-we-do-second-right {
        width: 40%;
        padding: 15vh 0 15vh 5%;
        font-size: 2rem;
    }

    .how-it-works-faq {
        width: 60%;
        overflow-y: auto;
        margin-left: 20%;
    }

    .how-it-works-faq a {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .section-topic-header {
        font-size: 1.5rem;
    }

    #how-it-works-topic-header, .how-it-works-faq a {
        font-size: 1.5rem;
    }


}

/*For all*/

.customer-review-header {
    text-align: center;
    letter-spacing: 0.67px;
    margin: 5.55vh 0;
    color: #72696A;
    font-size: 2.222vw;
    font-size: 1.39vw;
    line-height: 1.67vw;
    font-weight: 600;
    text-transform: uppercase;
}


.navbar-column-title-divider {
    width: 100%;
    height: 16px;
}

.eapg-photo-gallery-loadMore {
    width: 280px;
    margin: 0 auto;
    padding: 16px 60px;
    display: block !important;
    border: 2px solid #111;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: 0.3s ease all;
    text-align: center;
}


.customer-review-section-customer-location {
    margin-top: 1rem;
}

.more-review-button {
    width: 100%;
    overflow: auto;
    text-align: center;
    margin-top: 2.5vh;
}


.customer-review-container {
    width: 100%;
    overflow-y: auto;
}



/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .support-v2:hover {
        cursor: pointer;
    }

    .customer-review-section-divider {
        width: 50vw;
        overflow: auto;
        margin-left: calc((100% - 50vw) / 2);
    }

    .customer-review-section-divider div {
        width: 12.5vw;
        overflow: auto;
        text-align: center;
        float: left;
        cursor: pointer;
    }


    .overall-review-stars {
        width: 100%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        font-size: 5vw;
        line-height: 1.5;
        text-align: center;
        margin-left: 0;
        margin-bottom: 2.5%;
    }

    .customer-review-dot-not-selected {
        font-size: 2vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-dot-selected {
        font-size: 2vw;
        text-align: center;
        color: #F59276;
    }

    .customer-review-number {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.5;
        font-size: 5vw;
        text-align: center;
    }

    .customer-review-container {
        padding-bottom: 5vh;
    }

    .customer-review-header {
        margin-top: 12.5vw;
        margin-bottom: 4.6875vw;
        font-size: 7.5vw;
        line-height: 17.8125vw;
        letter-spacing: 0.15625vw;
    }

    .customer-review-section-row {
        width: 90vw;
        overflow: auto;
        padding-bottom: 12.5vw;
        margin: 5vh 5vw;
        border-bottom: 0.3125vw solid #DEDAD3;
    }

    .customer-review-selected, .customer-review-not-selected {
        width: 12.5vw;
        height: 2.5vw;
        float: left;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 3.125vw;
    }


    .customer-review-section-row-left, .customer-review-section-row-right {
        width: 90vw;
        overflow: auto;
        margin: 7.5vw 0 0 0;
    }

    .customer-review-section-row-right {
        line-height: 7.8125vw;
    }

    .customer-review-section-customer-location {
        margin: 2.5vw 0;
        font-size: 5vw;
        color: #72696A;
    }

    .customer-review-section-customer-name {
        font-size: 6.25vw;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.13125vw;
        color: #72696A;
    }

    .customer-review-section-customer-review-content {
        font-size: 5vw;
        color: #72696A;
    }

    .customer-review-section-customer-review-header {
        font-size: 5vw;
        margin-bottom: 2.5vw;
        text-transform: uppercase;
        font-weight: 600;
        color: #72696A;
        line-height: 7.8125vw;
    }

    .navbar-bottom-options {
        width: auto;
        overflow-y: auto;
        padding: 0 16px 0 16px;
    }


    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }

    .navbar-bottom-column {
        font-size: 3.75vw;
        line-height: 5.625vw;
        text-align: center;
        margin-top: 12.5vw;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }

    .navbar-bottom-column-email, .navbar-bottom-column-question, .navbar-bottom-column-phone {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        letter-spacing: 0.02em;
        color: white;
        text-align: left;
    }

    .navbar-bottom-company-logo {
        width: 35.625vw;
        margin: 8.75vw calc((100% - 35.625vw) / 2) 7.5vw;
    }

    .navbar-bottom-company-address-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        width: auto;
        margin: 0 16px 63.72px;
    }

    .mobile-display-only {
        display: inline-block;
    }



}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {

    .support-v2:hover {
        cursor: pointer;
    }

    .overall-review-stars {
        width: 60%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
        text-align: center;
        margin-left: 20%;
        margin-bottom: 2.5%;
    }

    .customer-review-dot-not-selected {
        font-size: 0.75vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-dot-selected {
        font-size: 0.75vw;
        text-align: center;
        color: #F59276;
    }

    .customer-review-number {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
    }

    .customer-review-section-row-left {
        width: 100%;
        overflow-y: auto;
        float: left;
        margin-bottom: 1rem;
        text-align: center;
    }

    .customer-review-section-row-right {
        width: calc( 100% - 24.675vw);
        overflow: auto;
        font-size: 5vw;
        line-height: 1.5;
        text-align: left;
    }


    #business-owner-alternative, #business-email-contact {
        text-align: center;
    }

    .customer-review-container {
        width: auto;
        padding: 5.70vw 7.5%;
    }

    .customer-review-header {
        text-align: center;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 1.39vw;
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .customer-review-section-divider {
        width: 20.79vw;
        overflow: auto;
        margin-left: calc((100% - 20.79vw) / 2);
    }

    .customer-review-dot-selected {
        font-size: 0.75vw;
    }

    .customer-review-section-divider div {
        width: 5.194vw;
        overflow: auto;
        text-align: center;
        float: left;
        cursor: pointer;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 1.298vw;
    }

    .customer-review-section-row {
        padding: 6.234vw 0;
        width: auto;
        overflow: auto;
        letter-spacing: 0.05rem;
        margin: 0;
        border-bottom: 0.0694vw solid #DEDAD3;
    }

    .customer-review-section-row-left {
        width: 24.675vw;
        overflow-y: auto;
        float: left;
        text-align: left;

    }

    .customer-review-section-customer-name {
        font-weight: 600;
        font-size: 2.597vw;
        text-transform: uppercase;
        color: rgba(0,0,0,0.5);
        line-height: 3.117vw;
        letter-spacing: 0.061vw;
    }

    .customer-review-section-customer-location {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
    }

    .customer-review-section-customer-review-header {
        font-size: 2.597vw;
        color: #72696A;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        line-height: 3.117vw;
        font-weight: 600;
    }

    .customer-review-section-customer-review-content {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.247vw;
        font-size: 2.078vw;
    }

    .mobile-display-only {
        display: none;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .support-v2:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .customer-review-dot-not-selected {
        font-size: 0.5vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-number {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .customer-review-dot-selected {
        font-size: 0.5vw;
        text-align: center;
        color: #F59276;
    }

    .overall-review-stars {
        width: 40%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
        /* float: right; */
        text-align: center;
        margin-left: 30%;
        margin-bottom: 2.5%;
    }

    .customer-review-container-new-design {
        margin: 1.67vw 1.81vw;
    }

    .customer-review-container {
        margin: 1.67vw 1.81vw;
        width: auto;
        overflow: auto;
        padding: 5.70vw 0;
    }

    .customer-review-section-row-left {
        width: 30%;
        overflow-y: auto;
        float: left;
    }

    .customer-review-section-row-right {
        width: auto;
        overflow: auto;
        line-height: 1.5;
    }

    .customer-review-section-customer-review-header {
        font-weight: 600;
        font-size: 1.39vw;
        text-transform: uppercase;
        letter-spacing: 0.029vw;
        color: rgba(0,0,0,0.5);
        line-height: 1.67vw;
    }

    .customer-review-section-divider {
        width: 11.12vw;
        overflow: auto;
        margin-left: calc((100% - 11.12vw) / 2);
    }

    .customer-review-section-divider div {
        width: 2.78vw;
        overflow: auto;
        float: left;
        cursor: pointer;
        text-align: center;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 0.69vw;
    }

    .customer-review-section-row {
        padding: 2.78vw 0;
        width: auto;
        overflow: auto;
        letter-spacing: 0.05rem;
        margin: 0 13.33vw;
        border-bottom: 0.0694vw solid #DEDAD3;
    }

    .customer-review-section-customer-name {
        font-weight: 600;
        font-size: 1.39vw;
        text-transform: uppercase;
        letter-spacing: 0.029vw;
        color: rgba(0,0,0,0.5);
        line-height: 1.67vw;
    }

    .customer-review-section-customer-location {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .customer-review-section-customer-review-content {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .mobile-display-only {
        display: none;
    }

}



.customer-review-selected {
     background-color: #F59276;
}

.customer-review-not-selected {
    background-color: #F8F6F2;
}

.customer-review-container a {
    font-weight: 400;
    color: #72696A;
    text-align: center;
    width: 100%;
}

#navbar-column-title-checkout-section {

}

.customer-review-container {
    width: 96%;
    margin-top: 160px;
    padding: 2%;
    overflow: hidden;
}



@media screen and (min-width: 1440px) {

    .customer-review-container {
        width: 1360px;
        margin-left: calc(50% - 720px);
        margin-top: 160px;
        padding: 40px;
        overflow: hidden;
    }

    #navbar-column-title-checkout-section, .cart-slider-shopped-item-right, .item-total-title, .item-total-amount {
        font-size: 16px;
    }

    .navbar-bottom-column {
        width: 200px;
        height: auto;
        float: left;
        margin-right: 100px;
    }


    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }


    #cart-slider-checkout-button {
        padding: 0.75vw 0 !important;
    }
}

/*Mobile Only*/

.credit-card-containers {
    width: 150px;
    padding-left: 10px;
    margin-left: calc( 50% - 80px );
    margin-right: calc( 50% - 80px );
}

.credit-card-logos {
    width: 30px;
    float: left;
    overflow: auto;
    margin-right: 10px
}

.artwork-margin-indicator {
    position: relative;
}

.payments-container .shipping-summary-add-bottom-line {
    border-bottom: none;
}

.shipping-address-email div,
.shipping-address-recipient-phone div,
.shipping-address-recipient-name div,
.shipping-address-street div,
.shipping-addresscheckout-progress-option-selected-suite div,
.shipping-address-city div,
.province-state-selector-container div,
.shipping-address-postal-code div {
    width: 100%;
}

.StripeCheckout {
    border: none !important;
    font-weight: 400 !important;
    padding: 0 !important;
    text-align: center !important;
    background: #FFFFFF !important;
    box-shadow: none !important;
    margin: 0 calc( 50% - 90px) !important;
    border-radius: 15px !important;
}

.shipping-address-section-wrapper input {
    width: 88% !important;
}

.payment-processor-description {
    text-align: center;
}

.shipping-address-suite div {
    width: 100%;
    overflow: auto;
}

.province-state-selector-container div div {
    padding: 8.5px 14px !important;
}

.shipping-form-container input {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3B3B3B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
    padding: 0 14px !important;
    text-align: left;
}

.StripeCheckout {
    border: none !important;
    font-weight: 400 !important;
    padding: 0 !important;
    background: #FFFFFF !important;
    text-align: center !important;
}

.StripeCheckout span {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 150% !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    background: #3F5C6B !important;
    padding: 16px 24px !important;
    width: 130px !important;
    text-align: center !important;
    border-radius: 15px !important;
}



@media screen and (min-width: 0px) and (max-width: 769px) {

    .delivery-payments-section-shipping-payments-container {
        margin: 10px 0;
    }

    .promotions-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        border: solid 1px #3B3B3B;
    }

    .promotions-button div {
        color: white;
    }

    .promotions-button:hover {
        background: white;
    }


    .promotions-button:hover div {
        color: #3B3B3B;
    }

    .saved-prev-address {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        width: 94%;
        height: 200px;
        padding: 2.5%;
        border: solid 1px rgb(118, 118, 118);
        border-radius: 5px;
        float: left;
        margin-bottom: 20px;
    }

    .payments-container {
        width: 90%;
        overflow: auto;
        margin: 0 5% 64px 5%;
    }

    .delivery-payments-section-order-summary-container {
        overflow-y: auto;
        float: left;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .shipping-address-city, .shipping-address-region {
        width: 49%;
        float: left;
        margin-top: 1rem;
    }

    .shipping-address-city {
        width: 49%;
        margin-right: 1% !important;
    }

    .shipping-address-region {
        width: 49%;
        margin-top: 1rem !important;
    }




    .shipping-address-country {
        /*margin-top: 1rem;*/
        /*margin-bottom: 1rem;*/
    }

    .shipping-address-country {
        width: 49%;
        margin-right: 1%;
        float: left;
        padding-top: 0.3rem;
    }

    .shipping-address-suite, .shipping-address-country {
        margin-right: 1% !important;
    }

    /*.shipping-address-postal-code input {*/
    /*padding: 0.57rem 0;*/
    /*}*/

    /*.shipping-address-postal-code {*/
    /*    margin-left: 1% !important;*/
    /*}*/

    .shipping-form-container-section-header {
        color: #72696A;
        text-align: center;
        font-weight: 600;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

    .shipping-address-recipient-phone {
        width: 100%;
    }

    .StripeElement {
        /*width: 87%;*/
        border-bottom: solid 0.1rem;
        padding: 0.5rem;
        border: none;
    }

    /*.payment-section-dividers {*/
    /*    width: 100%;*/
    /*    margin: 5vw 0;*/
    /*}*/


    .shipping-form-container, .shipping-form-container-section-header div {
        width: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .credit-card-form-container {
        margin-top: 1rem;
    }

    .StripeCheckout {
        border: none !important;
        font-weight: 400 !important;
        padding: 0 !important;
        text-align: center !important;
        background: #FFFFFF !important;
    }

    .StripeCheckout span {
        color: #fff !important;
        font-weight: 500 !important;
        background: #514945 !important;
        text-align: center !important;
        box-shadow: none !important;
        font-family: "Montserrat" !important;
        padding: 0.5rem 0 !important;
        transition: background-color linear 0.3s;
        -webkit-font-smoothing: subpixel-antialiased;
        text-transform: uppercase;
        padding: 10px 25px !important;
    }




    .link-to-stripe {
        text-decoration: underline;
        cursor: pointer;
    }


    .shipping-address-country .jss1 {
        width: 100% !important;
        overflow-y: auto !important;
    }

    .app-button, .app-button label, .app-button input {
        cursor: pointer;
    }

    .shipping-address-province-locked {
        width: 49%;
        overflow: auto;
        float: left;
        border-bottom: solid 0.1rem;
        margin-right: 1%;
        padding: 1rem 0 0.5rem 0;
        font-size: 1rem;
        color: #72696A;
        font-weight: 500;
        text-align: center;
    }

    .shipping-address-country-locked {
        overflow: auto;
        float: left;
        border-bottom: solid 0.1rem;
        margin-left: 1%;
        padding: 1.2rem 0 0.5rem 0;
        color: #72696A;
        font-weight: 500;
        text-align: center;
    }

    .shipping-form-container input {
        font-family: Montserrat;
        font-weight: 500;
        color: #72696A;
        /* font-size: 1.11vw; */
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

    .order-summary-italic {
        font-style: italic;
    }

    .show-spinner-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.6);
        top: 0;
        left: 0;
        margin-top: 0rem;
        z-index: 300;
        padding: 40vh 0;
    }

    .loader-text {
        color: white;
        margin-top: 1rem;
        text-align: center;
    }

    .loader {
        border: 0.5rem solid #f3f3f3; /* Light grey */
        border-top: 0.5rem solid #3498db; /* Blue */
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 2s linear infinite;
        margin-left: calc( 50% - 1.5rem );
    }

    .discount-input-summary {
        width: 5rem;
        text-align: center;
        border: solid 0.05rem darkgrey;
        border-radius: 0.3rem;
        margin-left: 0.25rem;
        color: #514945;
    }

    .promo-code-selector {
        font-family: Montserrat;
        font-style: normal;
        /*font-weight: normal;*/
        /*text-align: left;*/


        /*Black Friday Promo Code*/
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        cursor: pointer;
    }

    .promo-code-container {
        font-size: 0.8rem;
        width: 300px;
        overflow: hidden;
        border: black solid 1px;
        border-radius: 10px;
        margin-top: 10px;
        margin-left: calc(50% - 150px);
        margin-bottom: 30px;
    }

    .promo-application-view {
        width: auto;
        overflow: auto;
        float: right;
    }

    .success {
        color: #228B22;
    }

    .error {
        color: #F59276;
    }

    .shipping-address-email, .shipping-address-recipient-phone,
    .shipping-address-recipient-name, .shipping-address-street, .shipping-address-suite, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code, .province-state-selector-container, .discount-input-summary {
        width: 99%;
        margin: 0.5vw 0 !important;
    }

    .province-state-selector-container, .province-state-selector-details {
        font-family: Montserrat;
        font-weight: 500;
        color: #72696A;
        text-align: left;
        width: 99%;
        padding: 0 10px;
    }

    .province-state-selector-details-input {
        padding: 0 10px;
    }

    .payment-processor-description {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 5;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .credit-card-company-logos {
        width: 10vw;
        float: right;
        padding: 1vw;
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .delivery-payments-section-order-summary-container {
        width: 100%;
    }

    .delivery-payments-section-order-summary-container .pricing-summary-details, .delivery-payments-section-order-summary-container .pricing-summary-shipping-line-item {
        font-size: 1rem;
        padding: 0.5rem 0;
    }

    /*.shipping-address-recipient-name, .shipping-address-street, .shipping-address-email {*/
    /*    margin-top: 0.25rem !important;*/
    /*    margin-bottom: 0.5rem !important;*/
    /*    width: 100% !important;*/
    /*    overflow-y: auto !important;*/
    /*}*/


    .StripeCheckout {
        margin-top: 0.5rem;
    }


    .shipping-address-country-locked {
        width: 49%;
        font-size: 1rem;
    }

    .country-selector-container {
        width: 100%;
    }

    #select-Country {
        font-family: Montserrat;
        font-weight: 500;
        color: #72696A;
        text-align: center;
    }

    .select-country-form {
        width: 100%;
    }

    .country-selector-lable {
        width: 50%;
        padding: 0;
        margin: 0;
        float: left;
    }

    .country-selector-label span {
        font-family: Montserrat;
        font-weight: 500;
        color: #72696A;
        text-align: center;

    }

    .payment-section-container-header {
        font-size: 6.25vw;
        width: 100%;
        line-height: 7.8125vw;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        margin: 5vw 0;
        text-transform: uppercase;
    }


    .checkout-progress-option-selected {
        display: inline-block !important;
        width: 100%;
        overflow: auto;
    }

    .checkout-progress-option {
        display: none;
    }

    .shipping-address-section-wrapper {
        width: 99%;
        overflow: auto;
        margin-bottom: 32px;
        overflow-x: hidden;
    }

    .shipping-form-container-section-header {

        width: 100%;
        overflow: auto;
        /* Header 4 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */

        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;

        color: #000000;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 12px;

    }

    .add-text-fields-text-field-title {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        /* Secondary/Dark Gray */

        color: #666161;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 9.27px 1px 4.73px 1px;
    }

    .delivery-timeline-notification {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 7;
        flex-grow: 0;
        margin: 8px 0px;
        margin-bottom: 30px;
    }

    .checkout-progress-option-left, .checkout-progress-option {
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: none;
        /* identical to box height, or 19px */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }



    .review-section-container-header {
        width: 100%;
        overflow: auto;
        padding-bottom: 24px;
        margin-bottom: 24px;
        padding-top: 12px;
        border-bottom: 1px solid #3b3b3b;
    }

    .summary-review-button-left {
        width: auto;
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .review-page-reminder .cart-slider-ending-subheading {
        margin-bottom: 0;
    }

    .review-page-reminder {
        margin: 24px 0;
    }

    .summary-review-button-right {
        width: auto;
        float: right;
        overflow: auto;
        text-align: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

}



@media screen and (min-width: 769px) and (max-width: 1100px) {

    .delivery-payments-section-shipping-payments-container {
        margin: 10px 0;
    }

    .promotions-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        border: solid 1px #3B3B3B;
    }

    .promotions-button div {
        color: white;
    }

    .promotions-button:hover {
        background: white;
    }


    .promotions-button:hover div {
        color: #3B3B3B;
    }

    .saved-prev-address {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        width: 42.5%;
        height: 200px;
        padding: 2.5%;
        border: solid 1px rgb(118, 118, 118);
        border-radius: 5px;
        float: left;
        margin-right: 2%;
    }

    .payment-processor-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        flex: none;
        order: 5;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .review-page-reminder .cart-slider-ending-subheading {
        margin-bottom: 0;
    }

    .review-page-reminder {
        margin: 24px 0;
    }

    .summary-review-button-left {
        width: auto;
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .summary-review-button-right {
        width: auto;
        float: right;
        overflow: auto;
        text-align: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }


    .review-section-container-header {
        width: 100%;
        overflow: auto;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #3b3b3b;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .add-text-fields-text-field-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
        margin-bottom: 8px;
        text-transform: uppercase;
    }


    .payments-container {
        width: 640px;
        overflow: auto;
        margin: 0 calc( 50% - 320px );
        margin-top: 160px;
        margin-bottom: 128px;
    }

    .delivery-payments-section-order-summary-container {
        overflow-y: auto;
        float: left;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .shipping-address-city, .shipping-address-region {
        width: 49%;
        float: left;
        margin-top: 1rem;
    }

    /*.shipping-address-city {*/
    /*    width: 49%;*/
    /*    margin-right: 1% !important;*/
    /*}*/

    /*.shipping-address-region {*/
    /*    width: 49%;*/
    /*    margin-top: 1rem !important;*/
    /*}*/




    /*.shipping-address-country {*/
    /*    !*margin-top: 1rem;*!*/
    /*    !*margin-bottom: 1rem;*!*/
    /*}*/

    /*.shipping-address-country {*/
    /*    width: 49%;*/
    /*    margin-right: 1%;*/
    /*    float: left;*/
    /*    padding-top: 0.3rem;*/
    /*}*/

    /*.shipping-address-suite, .shipping-address-country {*/
    /*    margin-right: 1% !important;*/
    /*}*/

    /*.shipping-address-postal-code input {*/
    /*padding: 0.57rem 0;*/
    /*}*/

    /*.shipping-address-postal-code {*/
    /*    margin-left: 1% !important;*/
    /*}*/

    .shipping-form-container-section-header {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #000000;
        text-transform: uppercase;
        margin-bottom: 21.27px;
    }

    /*.order-summary-item-container {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .shipping-address-recipient-phone {
        width: 100%;
    }

    .StripeElement {
        /*width: 87%;*/
        border-bottom: solid 0.1rem;
        padding: 0.5rem;
        border: none;
    }

    /*.payment-section-dividers {*/
    /*    width: 100%;*/
    /*    margin: 5vw 0;*/
    /*}*/


    .shipping-form-container, .shipping-form-container-section-header div {
        width: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .credit-card-form-container {
        margin-top: 1rem;
    }

    .StripeCheckout {
        border: none !important;
        font-weight: 400 !important;
        padding: 0 !important;
        text-align: center !important;
    }


    .link-to-stripe {
        text-decoration: underline;
        cursor: pointer;
    }


    .shipping-address-country .jss1 {
        width: 100% !important;
        overflow-y: auto !important;
    }

    .app-button, .app-button label, .app-button input {
        cursor: pointer;
    }

    /*.shipping-address-province-locked {*/
    /*    width: 49%;*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    border-bottom: solid 0.1rem;*/
    /*    margin-right: 1%;*/
    /*    padding: 1rem 0 0.5rem 0;*/
    /*    font-size: 1rem;*/
    /*    color: #72696A;*/
    /*    font-weight: 500;*/
    /*    text-align: center;*/
    /*}*/

    /*.shipping-address-country-locked {*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    border-bottom: solid 0.1rem;*/
    /*    margin-left: 1%;*/
    /*    padding: 1.2rem 0 0.5rem 0;*/
    /*    color: #72696A;*/
    /*    font-weight: 500;*/
    /*    text-align: center;*/
    /*}*/

    /*.shipping-form-container input {*/
    /*    font-family: Montserrat;*/
    /*    font-weight: 500;*/
    /*    color: #72696A;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*    text-align: left;*/
    /*    padding-left: 10px;*/
    /*    padding-right: 10px;*/
    /*}*/

    .order-summary-italic {
        font-style: italic;
    }

    .show-spinner-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.6);
        top: 0;
        left: 0;
        margin-top: 0rem;
        z-index: 300;
        padding: 40vh 0;
    }

    .loader-text {
        color: white;
        margin-top: 1rem;
        text-align: center;
    }

    .loader {
        border: 0.5rem solid #f3f3f3; /* Light grey */
        border-top: 0.5rem solid #3498db; /* Blue */
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 2s linear infinite;
        margin-left: calc( 50% - 1.5rem );
    }
    .artwork-margin-indicator-left
    .discount-input-summary {
        width: 5rem;
        text-align: center;
        border: solid 0.05rem darkgrey;
        border-radius: 0.3rem;
        margin-left: 0.25rem;
        color: #514945;
    }

    .promo-code-selector {
        font-family: Montserrat;
        font-style: normal;
        /*font-weight: normal;*/
        /*text-align: left;*/


        /*Black Friday Promo Code*/
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        line-height: 150%;

        color: #3B3B3B;
        cursor: pointer;
    }

    .promo-code-container {
        font-size: 0.8rem;
        width: 300px;
        overflow: hidden;
        border: black solid 1px;
        border-radius: 10px;
        margin-top: 10px;
        margin-left: calc(50% - 150px);
        margin-bottom: 30px;
    }

    .promo-application-view {
        width: auto;
        overflow: auto;
        float: right;
    }

    .success {
        color: #228B22;
    }

    .error {
        color: #F59276;
    }

    .shipping-address-email, .shipping-address-recipient-phone, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code  {
        width: calc( 50% - 6px);
        float: left;
        margin-right: 6px !important;
        margin-top: 5.27px !important;
    }

    /*.province-state-selector-container, .province-state-selector-details {*/
    /*    font-family: Montserrat;*/
    /*    font-weight: 500;*/
    /*    color: #72696A;*/
    /*    text-align: left;*/
    /*    width: calc( 100% - 10px );*/
    /*    padding: 0 10px;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .credit-card-company-logos {
        width: 5vw;
        float: right;
        padding: 1vw;
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .delivery-payments-section-order-summary-container {
        width: 100%;
    }

    /*.delivery-payments-section-shipping-payments-container {*/
    /*    width: 90%;*/
    /*    overflow-y: auto;*/
    /*    margin: 5% 5%;*/
    /*}*/

    .delivery-payments-section-order-summary-container .pricing-summary-details, .delivery-payments-section-order-summary-container .pricing-summary-shipping-line-item {
        font-size: 1rem;
        padding: 0.5rem 0;
    }

    /*.shipping-address-recipient-name, .shipping-address-street, .shipping-address-email {*/
    /*    margin-top: 0.25rem !important;*/
    /*    margin-bottom: 0.5rem !important;*/
    /*    width: 100% !important;*/
    /*    overflow-y: auto !important;*/
    /*}*/


    .StripeCheckout {
        margin-top: 0.5rem;
    }



    .shipping-address-country-locked {
        width: 49%;
        font-size: 1rem;
    }

    .country-selector-container {
        width: 100%;
    }

    #select-Country {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;
    }

    .select-country-form {
        width: 100%;
    }

    .country-selector-lable {
        width: 50%;
        padding: 0;
        margin: 0;
        float: left;
    }

    .country-selector-label span {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;

    }

    .payment-section-container-header {
        width: auto;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        font-size: 2.597vw;
        line-height: 3.117vw;
        margin: 2vw;
        text-transform: uppercase;
    }

    .checkout-progress-bar {
        width: auto;
        height: 84px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        text-align: center;
    }

    .cart-slider-shopped-item-delete {
        width: 20px;
        overflow: auto;
        float: right;
        margin-left: 34px;
        margin-top: 20px;
    }

    .checkout-progress-option-selected {
        border-bottom: 1.5px solid #3B3B3B;
    }

    .checkout-progress-option-left {
        width: auto;
        overflow-y: auto;
        margin-left: 80px;
        float: left;
        cursor: pointer;
    }

    .checkout-progress-option {
        width: auto;
        overflow-y: auto;
        margin-left: 65px;
        float: left;
        cursor: pointer;
    }
    .shipping-address-section-wrapper {
        width: 100%;
        overflow-y: auto;
        margin-bottom: 48px;
        overflow-x: hidden;
    }

    .shipping-address-recipient-name, .shipping-address-street, .shipping-address-suite, .discount-input-summary {
        width: calc( 100% - 6px);
        margin-top: 5.27px;
    }

    .shipping-address-email, .shipping-address-recipient-phone, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code  {
        width: calc( 50% - 6px);
        float: left;
        margin-right: 6px !important;
        margin-top: 5.27px !important;
    }

    .shipping-address-suite div {
        width: 100%;
    }

    .delivery-timeline-notification {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        padding-top: 8px;
        margin-bottom: 30px;
    }

}



.saved-prev-address:hover {
    cursor: pointer;
    background: #3B3B3B;
    color: white;
}

.landing-page-button-standard-v2 div {
    color: white;
}

.landing-page-button-standard-v2:hover div {
    color: #3B3B3B;
}

@media screen and (min-width: 1100px) {

    .promotions-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        border: solid 1px #3B3B3B;
    }

    .promotions-button div {
        color: white;
    }

    .promotions-button:hover {
        background: white;
    }


    .promotions-button:hover div {
        color: #3B3B3B;
    }


    .saved-prev-address {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        width: 42.5%;
        height: 200px;
        padding: 2.5%;
        border: solid 1px rgb(118, 118, 118);
        border-radius: 5px;
        float: left;
        margin-right: 2%;
    }


    .review-page-reminder .cart-slider-ending-subheading {
        margin-bottom: 0;
    }

    .review-page-reminder {
        margin: 24px 0;
    }

    #confirmation-container-web {
        width: 50%;
        margin-left: 25%;
    }



    .summary-review-button-left {
        width: auto;
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .summary-review-button-right {
        width: auto;
        float: right;
        overflow: auto;
        text-align: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .review-section-container-header {
        width: 100%;
        overflow: auto;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #3b3b3b;
    }



    .add-text-fields-text-field-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
        margin-bottom: 8px;
    }

    .order-summary-item-container {
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .payments-container {
        width: 1440px;
        padding: 0;
        margin-left: calc(50% - 720px);
        overflow: auto;
        margin-top: 160px;
        margin-bottom: 128px;
    }

    .delivery-payments-section-order-summary-container {
        overflow-y: auto;
        float: left;
    }



    .shipping-address-country {
        /*margin-top: 1rem;*/
        /*margin-bottom: 1rem;*/
    }

    .shipping-address-country {
        width: 49%;
        margin-right: 1%;
        float: left;
        padding-top: 0.3rem;
    }

    .shipping-address-suite, .shipping-address-country {
        margin-right: 1% !important;
    }

    /*.shipping-address-postal-code input {*/
    /*padding: 0.57rem 0;*/
    /*}*/

    /*.shipping-address-postal-code {*/
    /*    margin-left: 1% !important;*/
    /*}*/

    .shipping-form-container-section-header {
        width: 50%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #000000;
        text-transform: uppercase;
        margin-bottom: 21.27px;

    }


    .shipping-address-recipient-phone {
        width: 100%;
    }

    .StripeElement {
        /*width: 87%;*/
        border-bottom: solid 0.1rem;
        padding: 0.5rem;
        border: none;
    }

    /*.payment-section-dividers {*/
    /*    width: 100%;*/
    /*    margin-bottom: 2vw;*/
    /*}*/


    .shipping-form-container, .shipping-form-container-section-header div {
        width: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .credit-card-form-container {
        margin-top: 1rem;
    }




    .link-to-stripe {
        text-decoration: underline;
        cursor: pointer;
    }


    .shipping-address-country .jss1 {
        width: 100% !important;
        overflow-y: auto !important;
    }

    .app-button, .app-button label, .app-button input {
        cursor: pointer;
    }

    .shipping-address-province-locked {
        width: 49%;
        overflow: auto;
        float: left;
        border-bottom: solid 0.1rem;
        margin-right: 1%;
        padding: 1rem 0 0.5rem 0;
        font-size: 1rem;
        color: #777;
        font-weight: 500;
        text-align: center;
    }

    .shipping-address-country-locked {
        overflow: auto;
        float: left;
        border-bottom: solid 0.1rem;
        margin-left: 1%;
        padding: 1.2rem 0 0.5rem 0;
        color: #777;
        font-weight: 500;
        text-align: center;
    }

    .shipping-form-container input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 8px 0px;
        padding: 0 14px !important;
        text-align: left;
    }

    .order-summary-italic {
        font-style: italic;
    }

    .show-spinner-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.6);
        top: 0;
        left: 0;
        margin-top: 0rem;
        z-index: 300;
        padding: 40vh 0;
    }

    .loader-text {
        color: white;
        margin-top: 1rem;
        text-align: center;

    }

    .loader {
        border: 0.5rem solid #f3f3f3; /* Light grey */
        border-top: 0.5rem solid #3498db; /* Blue */
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 2s linear infinite;
        margin-left: calc( 50% - 1.5rem );
    }

    .discount-input-summary {
        width: 5rem;
        text-align: center;
        border: solid 0.05rem darkgrey;
        border-radius: 0.3rem;
        margin-left: 0.25rem;
        color: #514945;
    }

    .promo-code-selector {
        font-family: Montserrat;
        font-style: normal;
        /*font-weight: normal;*/
        /*text-align: left;*/
        /*Black Friday Promo Code*/
        font-weight: 600;
        text-align: center;

        font-size: 16px;
        line-height: 150%;

        color: #3B3B3B;
        cursor: pointer;
    }

    .promo-code-container {
        font-size: 0.8rem;
        width: 300px;
        overflow: hidden;
        border: black solid 1px;
        border-radius: 10px;
        margin-top: 10px;
        margin-left: calc(50% - 150px);
        margin-bottom: 30px;
    }

    .promo-application-view {
        width: auto;
        overflow: auto;
        float: right;
    }

    .success {
        color: #228B22;
    }

    .error {
        color: #F59276;
    }

    .shipping-address-email, .shipping-address-recipient-phone, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code  {
        width: calc( 50% - 6px);
        float: left;
        margin-right: 6px !important;
        margin-top: 5.27px !important;
    }

    .shipping-address-recipient-name, .shipping-address-street, .shipping-address-suite, .discount-input-summary {
        width: calc( 100% - 6px);
        margin-top: 5.27px;
    }

    .province-state-selector-details {
        width: 100%;
        font-family: Montserrat;
        text-align: left;
        font-size: 1.11vw;
    }

    .province-state-selector-container div div {
        padding: 8.5px 14px !important;
    }

    /*.province-state-selector-container div {*/
    /*    font-family: Montserrat;*/
    /*    font-style: normal;*/
    /*    font-weight: normal;*/
    /*    font-size: 16px;*/
    /*    line-height: 150%;*/
    /*    color: #3B3B3B;*/
    /*    flex: none;*/
    /*    order: 0;*/
    /*    flex-grow: 0;*/
    /*    margin: 8px 0px;*/
    /*    padding: 0 !important;*/
    /*}*/

    .province-state-selector-details-input {
        padding: 0 10px;
    }

    .payment-processor-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        flex: none;
        order: 5;
        flex-grow: 0;
        margin: 12px 0px;
    }

    .credit-card-company-logos {
        width: 2.5vw;
        float: right;
        padding: 0.25vw;
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .delivery-payments-section-order-summary-container {
        width: 100%;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 45%;
        overflow: auto;
        margin: 0 27.5% 30px;
    }

    .delivery-payments-section-order-summary-container .pricing-summary-details, .delivery-payments-section-order-summary-container .pricing-summary-shipping-line-item {
        font-size: 1rem;
        padding: 0.5rem 0;
    }

    /*#delivery-payments-section-web-only {*/
    /*    width: 95%;*/
    /*    overflow: auto;*/
    /*}*/



    /*.shipping-address-recipient-name, .shipping-address-street, .shipping-address-email {*/
    /*    margin-top: 0.25rem !important;*/
    /*    margin-bottom: 0.5rem !important;*/
    /*    width: 100% !important;*/
    /*    overflow-y: auto !important;*/
    /*}*/

    .StripeCheckout {
        margin-top: 0.5rem;
    }

    .shipping-address-country-locked {
        width: 49%;
        font-size: 1rem;
    }

    .country-selector-container {
        width: 100%;
    }

    #select-Country {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;
    }

    .select-country-form {
        width: 100%;
    }

    .country-selector-lable {
        width: 50%;
        padding: 0;
        margin: 0;
        float: left;
    }

    .country-selector-label span {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;

    }



    .payment-section-container-header {
        width: auto;
        font-size: 1.39vw;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        margin: 0 0 1.67vw 0;
        line-height: 1.67vw;
        text-transform: uppercase;
    }

    .shipping-or-pickup-options {
        font-size: 1.11vw;
        line-height: 1.74vw;
        padding-top: 10px;
        color: #8c8c8c;
        font-weight: 500;
    }

    .shipping-or-pickup-options a {
        color: #8c8c8c;
        text-decoration: underline;
    }

    .shipping-address-section-wrapper {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 24px;
    }

    .checkout-progress-bar {
        width: 640px;
        height: 84px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        text-align: center;
        margin-left: calc(50% - 320px);
    }

    .cart-slider-shopped-item-delete {
        width: 20px;
        overflow: auto;
        float: right;
        margin-left: 34px;
        margi-top: 20px;
    }

    .checkout-progress-option-selected {
        border-bottom: 1.5px solid #3B3B3B;
    }

    .checkout-progress-option-left {
        width: auto;
        overflow-y: auto;
        margin-left: 80px;
        float: left;
        cursor: pointer;
    }

    .checkout-progress-option {
        width: auto;
        overflow-y: auto;
        margin-left: 80px;
        float: left;
        cursor: pointer;
    }

    .checkout-progress-option:hover {
        cursor: pointer;
        border-bottom: 1.5px solid #3B3B3B;
    }

    .delivery-timeline-notification {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        padding-top: 8px;
        margin-bottom: 30px;
    }

    .checkout-button {
        width: 100%;

    }



}
.order-summary-item-container {
    width: calc(100% - 8% - 0.4rem);
    overflow-y: auto;
    padding: 2% 4% 6% 4%;
    background: #FAFAFA;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin: 2vw 1% 2rem 1%;
    border-radius: 0.2rem;
}

.float-left-section, .float-right-section {
    width: auto;
    overflow-y: auto;
}

.float-left-section {
    float: left;
}

.float-right-section {
    float: right;
}

.order-summary-subsection-container {
    width: 100%;
    overflow-y: auto;
}

.order-summary-item-breakdown {
    border-bottom: solid 0.05rem;
    border-bottom: 0.5rem;
}

.order-summary-indent {
    padding-left: 1rem;
    color: #8c8c8c;
}

.order-summary-second-mat-margin {
    margin-top: 1rem;
}

.order-summary-quantity-input-summary {
    width: 2.5rem;
    text-align: center;
    border: solid 0.05rem darkgrey;
    border-radius: 0.3rem;
    margin-left: 0.25rem;
    color: #514945;
}

.order-summary-checkout-container {
    width: 100%;
    overflow: auto;
    margin-top: 0.5rem;
    border-top: solid 0.05rem;
}

.delete-button {
    /*padding: 0.25rem 0.5rem;*/
    /*border: solid 0.05rem;*/
    /*border-radius: 0.25rem;*/
    /*padding: 0.25rem 0.5rem;*/
    /*border: solid 0.05rem;*/
    /*border-radius: 0.25rem;*/
    padding-top: 0.1rem;
    font-weight: 600;
    cursor: pointer;
}

.order-summary-remove-item-container {
    width: 100%;
    overflow-y: auto;
}

.capitalize {
    text-transform: capitalize;
}

.currency-container {
    color: #8c8c8c;
    margin-right: 0.2rem;
}

.currency-information {
    font-size: 0.8rem;
    line-height: 2;
    color: #8c8c8c;
}

.currency-information a {
    text-decoration: underline;
    color: #8c8c8c;
}

.title {
    color: cadetblue;
    padding-bottom: 2px;
}

.check {
    display: inline;
}

.check:after {
    content: "\2714";
    color: limegreen;
    font-weight: bold;
    font-size: 18px;
}

.continue-shopping-description-text {
    width: 100%;
    text-align: right;
    font-size: 0.8rem;
    padding: 0.5rem 0;
}

.cross-out {
    text-decoration: line-through;
}



.important-action-button:hover {
    background-color: white;
    color: #3B3B3B;
    /*border-color: #f59276;*/
    transition: border-color .35s ease-out,color .25s ease-out;
    transition: background-color .35s ease-out,color .25s ease-out;
    cursor: pointer;
}
.shipping-summary-status-header {
    width: 100%;
    overflow-y: auto;
    /*background: #8BBCA9;*/
    background: #9eb9d3;
    color: white;
    padding: 1rem 0;
    margin: 0.5rem 0;
    font-weight: 500;
    border-radius: 0.3rem;
    text-align: center;
}

.delivery-info-header-regular-2 {
    /* Header 5 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #000000;
    margin: 16px 0;
}

.change-order-actions-container {
    width: 100%;
    overflow-y: auto;
    margin-top: 1rem;
}

.change-order-action-button {
    padding: 1rem 0;
    margin: 0.5rem 1% 0.5rem 0;
    width: 46%;
    overflow-y: auto;
    float: left;
    /* border-radius: 0.3rem; */
    cursor: pointer;
    transition: background linear 0.3s;
    border: solid 0.1rem #514945;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: #514945;
}

.modify-order-action-button {
    padding: 1rem 0;
    margin: 0.5rem 0 0.5rem 1%;
    width: 48%;
    overflow-y: auto;
    float: left;
    /* border-radius: 0.3rem; */
    cursor: pointer;
    transition: background linear 0.3s;
    border: solid 0.1rem #514945;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: #514945;
}

.order-confirmation-header-subtext {
    width: 100%;
    overflow: auto;
    margin-top: 0.5rem;
    font-size: 0.8rem;
}

.order-confirmation-header-subtext img {
    width: 1.5vw;
}

.change-order-action-button:hover {
    background: #514945;
    color: white;
}

.modify-order-action-button:hover {
    background: #514945;
    color: white;
}

.order-tracking-day-breaker {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 2rem;
    font-weight: 600;
    padding-top: 2rem;
}

.order-tracking-order-container {
    width: auto;
    overflow: auto;
    border-top: 0.05rem solid;
    margin-top: 2rem;
}

.tracking-order-input-container div {
    width: 100% !important;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.tracking-order-input-container {
    margin-bottom: 1rem;
}

.order-tracking-error-section div {
    text-align: center;
    padding: 5rem 0;
    color: #f44336;
}

.checkout-shop-more-button {
    width: 100px;
    height: 20px;
    background: #3B3B3B;
    font-size: 13px !important;
    border-radius: 30px;
    padding: 16px 24px;
    margin-left: calc( 50% - 74px);
}

.order-confirmation-header {
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #b8b8b8;
    font-weight: 600;
    text-align: center;


}

.delivery-info-header-emphasis {
    font-family: Montserrat;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 11px;
}

.delivery-info-header-regular {
    /* Header 5 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* identical to box height, or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #000000;
}

.order-confirmation-highlight {
    font-weight: 500;
    margin-right: 0.5rem;
}

.customer-info-row-container {
    width: auto;
    overflow: auto;
    padding: 1rem;
    border: solid rgb(223, 223, 223) 0.075rem;
    border-bottom: none;
    line-height: 2;
}

.customer-info-row-container-left {
    width: 35%;
    float: left;
    color: #555;
    font-weight: 500;
    font-size: 0.8rem;
}

.customer-info-row-container-right {
    width: 65%;
    overflow-y: auto;
    float: left;
    color: #72696A;
    font-size: 0.8rem;
    font-weight: 500;
}

.confirmation-customer-info {
    margin-top: 3.5%;
}

#customer-info-bottom-row {
    border-bottom: solid rgb(223, 223, 223) 0.075rem;
}

/*For all*/




/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .delivery-info-wrapper-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        text-align: center;

    }
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .order-tracking-entry-form {
        margin: 0%;
        overflow: hidden;
    }

    #colour-description-container {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 8px;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .order-tracking-entry-form {
        margin: 0 25%;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

    .order-tracking-entry-form {
        margin: 0 50%;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .order-tracking-entry-form {
        margin: 0 50%;
    }

    .landing-page-subtext-first-v2, .landing-page-subtext-second-v2 {
        width: 1200px;
    }

    .future-inspirations-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 269px;
        background: #3F5C6B;
        border-radius: 30px;
        text-align: center;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px auto;
        margin-top: 24px;
    }

}



@media screen and (min-width: 0px) and (max-width: 769px) {

    .delivery-info-wrapper-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        text-align: center;

    }


    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .delivery-info-wrapper-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-align: center;
        width: 450px;
        margin: 0 auto;
    }

    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}

@media screen and (min-width: 1100px) {

    .delivery-info-wrapper-subheading {

        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 11;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .delivery-info-wrapper {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        color: #000000;


        /* Inside Auto Layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
    }
}


/*For all*/

.user-policies-container {
    width: auto;
    overflow: auto;
    line-height: 1.5;
}

.user-policy-header {
    /* Header 2 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;
    /* or 128% */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
}

.faster-shipping-area-expandable {
    margin-top: 12px;
}

.user-policy-paragraph {
    width: auto;
    overflow: auto;
    /* Body */
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    margin-bottom: 8px;
    color: #3B3B3B;
}

.city-name-container {
    width: 40%;
    float: left;
    overflow-y: auto;

}

.city-name-container a{
    margin-left: 10px;
    text-decoration: underline;
}

.user-policy-paragraph-narrow {
    width: 640px;
    overflow: auto;
    margin: 32px calc(50% - 320px);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;;
}

.user-policy-subheader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
    margin: 32px 0 8px 0;
}


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .user-policies-container {
        width: 90%;
        overflow: auto;
        margin: 150px 5%;
    }
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .user-policies-container {
        width: 90%;
        overflow: auto;
        margin: 150px 5%;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px){
    .user-policies-container {
        width: 1120px;
        overflow: auto;
        margin: 32px calc(50% - 560px);
    }
}


.order-change-input {
    width: 100%;
    overflow-y: auto;
}

.order-change-form-right textarea {
    height: 45vh;
}

.order-change-form-submit-button {
    width: 100%;
    overflow-y: auto;
}

.order-change-form-submit-button button {
    width: 10rem;
    margin: 0.5rem calc( 50% - 5rem )
}

.order-change-input div, order-change-form-right div {
    width: 100% !important;
}

/*For all*/


/*Mobile Only*/


/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .order-change-button {
        width: 96%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 96%;
        margin: 0 2%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 96%;
        margin: 0 2%;
        float: right;
    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 49%;
        float: left;
        overflow-y: auto;
        margin-right: 1%;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 50%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 50%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}

.workflow-order-container {
    width: 100%;
    overflow: auto;
    border-bottom: solid 1px black;
    margin-bottom: 2rem;
    line-height: 2;

}

.workflow-order-container-overall-info {
    width: 35%;
    overflow: auto;
    float: left;
}

.workflow-order-container-order-items {
    width: 30%;
    overflow: auto;
    float: left;
}

.workflow-order-container-order-leader {

}

.workflow-section-title {
    font-weight: 600;
    font-size: 1.3rem;
}

.workflow-section-subtitle {
    margin-right: 0.5rem;
    font-weight: 500;
}

.workflow-section .select-mat-rendering {
    position: absolute !important;
}

.workflow-section .select-mat-rendering {
    width: 50%;
    position: fixed;
    float: left;
    overflow: auto;
    margin-left: 0 !important;
}

.workflow-order-container-pricing {

}

.workflow-order-container-primary-matting {

}

.workflow-order-container-primary-window {

}

.workflow-order-container-primary-secondary {

}

.workflow-status-change-button {
text-align: center;
width: 80%;
overflow: auto;
margin-right: 20%;
border: solid 0.1rem;
margin: 1rem 0;
cursor: pointer;
padding: 0.5rem 0;
}
/*Mobile Only*/

@media screen and (min-width: 0px) {

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid black !important;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid black !important;
    }

    /*For all*/
    .gallery-container {
        width: auto;
        overflow: auto;
    }

    .gallery-section-content-highlights {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .precut-window-selector {
        width: 100%;
        overflow: hidden;
    }

    .cart-slider-shopped-item-quantity {
        overflow: auto;
    }


    .MuiListItem-button {
        text-transform: capitalize !important;
    }

    .custom-design-padding-box {
        width: auto;
        overflow: auto;
        margin-left: calc( 50% - 81px);
        margin-right: calc( 50% - 81px);
        margin-top: 16px;
        margin-bottom: 32px;
    }


    .overview-section-left {
        float: left;
        padding: 0 4%;
        border-right: solid #F3F6F6 0.1rem;
        border-bottom: solid #F3F6F6 0.1rem;
    }

    .overview-section-right {
        width: 32%;
        float: left;
        padding: 0 4%;
    }

    .overview-section-left-image {
        width: 100%;
    }

    .overview-section-header-container {
        position: absolute;
        line-height: 2;
        padding: 1rem 1rem 1rem 0;
        overflow-y: auto;
    }

    .overview-section-header {
        background: black;
        color: white;
        padding: 0 1rem;
        width: auto;
        overflow: auto;
        font-size: 1rem;
        text-transform: uppercase;
    }

    .inspiration-post {
        width: 100%;
        overflow-y: auto;
        display: inline-block;
        margin-top: -5px;
    }





    .overview-section-header, .overview-section-subheader {
        cursor: pointer;
    }

    .inspiration-logo-IG-POST {
        width: 24px;
        height: 24px;
        float: right;
    }

    .overview-section-subheader {
        background: white;
        color: black;
        padding: 0 1rem;
        width: auto;
        overflow: auto;
        font-size: 1.3rem;
    }

    .overview-section-header-alternative {
        font-size: 1rem;
        font-style: italic;
        font-weight: 500;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
    }

    .overview-section-subheader-alternative {
        color: #858585;
        font-size: 0.8rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }

    .gallery-left-item-container {
        width: 100%;
        padding-bottom: 2.5rem;
        border-bottom: solid #F3F6F6 0.1rem;
        margin-bottom: 2.5rem;
        overflow: auto;
    }

    .gallery-left-item-container-left {
        width: 15%;
        float: left;
    }

    .gallery-left-item-section {
        background: black;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        text-align: center;
        font-weight: 500;
        color: white;
        padding: 0.25rem 0.5rem 0.75rem 0.5rem;
    }

    .gallery-left-item-container-left span {
        font-size: 0.6rem;
        font-weight: 600;
    }

    .overview-section-left-image-alternative {
        width: 20%;
        float: left;
        overflow: auto;
    }

    .overview-section-header-container-alternative-two {
        width: 77%;
        float: left;
        overflow: auto;
        margin-left: 3%;
    }

    .article-list-container {
        width: 100%;
        overflow: auto;
        border-bottom: #F3F6F6 solid 0.1rem
    }

    .article-list-header {
        text-transform: uppercase;
        color: #c88d75;
        font-weight: 500;
    }

    .article-list-container-option {
        width: auto;
        float: left;
        margin: 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
        border-bottom: 0.1rem solid white;
    }

    .article-list-container-option:hover {
        border-bottom: 0.1rem solid;
        transition: border-bottom-color 0.3s linear;
    }

    .article-list-container-selector {
        width: 100%;
        overflow-y: auto;
        margin: 0.25rem 0;
    }

    .article-list-selected {
        border-bottom: 0.1rem solid;
        color: #f59276;
    }

    .article-list-left {
        width: 5rem;
        height: 5rem;
        float: left;
    }

    .article-list-right {
        width: calc( 99% - 6rem);
        overflow: auto;
        float: left;
        margin-left: 1rem;
    }

    .article-list-image {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
    }

    .article-list-item {
        width: 100%;
        overflow: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: #F3F6F6 solid 0.1rem;
        cursor: pointer;
    }

    .article-list-right-category {
        color: #858585;
        font-weight: 500;
        font-size: 0.8rem;
        padding-bottom: 0.25rem;
        text-transform: uppercase;
        margin-top: 0.5rem;
    }

    .article-list-right-title {
        width: auto;
        overflow: auto;
        line-height: 1.5;
        font-size: 0.9rem;
    }

    .gallery-container-topic {
        width: 80%;
        overflow: auto;
        margin-left: 10%;
        color: #858585;
        font-size: 0.8rem;
        padding-left: 3.5%;
    }

    .gallery-container-topic h1 {
        font-size: 1.5rem;
        color: black;
        font-weight: 500;
        margin: 1rem 0 0.5rem 0;
        padding: 1rem 0;
        border-top: #F3F6F6 solid 0.1rem;
        border-bottom: #F3F6F6 solid 0.1rem;
    }

    .gallery-container-topic h3 {
        font-weight: 400;
        float: left;
        width: auto;
        margin-right: 1rem;
    }

    .overview-section-right .overview-section-header-container-alternative {
        margin-bottom: 2rem;
    }

    .gallery-section-content {
        font-size: 1rem;
        line-height: 2;
    }


    .image-gallery-play-button {
        display: none;
    }

    .gallery-container-topic h3 {
        font-weight: 400;
        float: left;
        width: auto;
    }

    .gallery-section-content a {
        text-decoration: underline;
    }

    .content-paragraph {
        width: 100%;
        overflow: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .blog-article-photo-one {
        width: 100%;
        overflow: auto;
    }

    .blog-article-photo-one-image {
        width: 100%;
    }

    .inspiration-load-more-button {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 142px;
        /* height: 52px; */
        left: 489px;
        top: 1762.72px;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        text-align: center;
        margin: 62px calc( 50% - 95px);
        cursor: pointer;
    }

    .blog-article-photo-two-portrait {
        width: 100%;
        overflow-y: auto;
    }


    .image-subscript-type1 {
        margin-top: -8vh;
        margin-bottom: 10vh;
    }

    .image-subscript, .image-subscript-type1 {
        font-size: 0.8rem;
        color: #858585;
        text-align: center;
    }

    .overview-section-left-image, .overview-section-header-container-alternative {
        cursor: pointer;
    }



    .design-guide-button {
        /* Header 4 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 68px;
        margin-bottom: 16px;
    }



    .back-to-my-design-button{
        width: 300px;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: block;
        text-align: center;
        align-items: center;
        color: #3B3B3B;
        margin: 0 calc( 50% - 150px);
    }

    .back-to-my-design-button-icon {
        width: 9px;
        height: 12px;
        margin-right: 20px;
    }

    .design-guide-content-container {
        width: auto;
        margin-top: 80px;
        margin-bottom: 16px;
        background-color: #DDDDDD;
        padding: 32px 32px 64px 32px;
    }

    .design-guide-content-container-topic {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: left;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 30px;
        text-align: center;
    }

    .design-guide-content {
        width: 100%;
        overflow-y: auto;
    }



    .design-guide-content-container-walkthrough-button {
        width: 100%;
        margin-top: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .design-guide-content-container-walkthrough-button:hover {
        text-decoration: underline;
    }

    .design-guide-inquiry {
        width: 100%;
        overflow: auto;
        margin: 92px 0;
    }

    .design-guide-inquiry {
        width: 100%;
        overflow-y: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #666161;
    }

    .design-guide-section-button-selected {
        text-decoration: underline;
    }

    .design-guide-content-instruction {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 56px;
    }

    .design-guide-photo-container {
        width: 100%;
        overflow: auto;
    }

    .design-guide-step-by-step-container {
        width: 100%;
        overflow: auto;
        margin-bottom: 80px;
    }

    .design-guide-step-by-step-container-left-desc {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .design-guide-colour-box div {
        width: 100%;
        height: 40px;
    }

    .fetch-precut-mats-subtitle {
        font-size: 16px;
        line-height: 150%;
    }

    .fetch-precut-mats-title-container:hover {
        text-decoration: underline;
    }




}












@media screen and (min-width: 1100px) {


    .precut-container {
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
        margin-top: 70px;
    }

    .precut-mat-container {
        width: 1440px;
        margin: 160px calc(50% - 720px);
    }

    .diploma-mat-box {
        width: 40%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
        max-height: 470px
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }

    .precut-container-left-photo {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-left {
        width: 50%;
        overflow: auto;
        float: left;
    }

    #diploma-mat-left {
        width: 50%;
    }

    #diploma-mat-right {
        width: 45%;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .pre-cut-mat-box-img{
        height: 200px;
    }


    .pre-cut-mat-dropdown-menu-container {
        width: 80%;
        overflow: auto;
    }

    .precut-window-selector {
        width: 100%;
        overflow: auto;
    }

    .precut-window-selector div {
        width: 97%;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: 500;
        color: #3b3b3b;
    }

    .precut-window-selector div div {
        padding: 14px !important;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }


    .precut-size-header {
        width: 100%;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight : 700;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        padding-bottom: 16px;
    }

    .precut-container-leader-right:hover {
        text-decoration: underline;
    }

    .precut-container-leader-left {
        /* width: 13px; */
        height: 13px;
        margin: 3px 5px 3px 0;
        /* overflow: auto; */
        float: left;
    }

    .precut-container {
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
    }


    .precut-sub-container {
        width: 80%;
        margin: 0 10%;
        overflow: auto;
        margin-bottom: 24px;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }



    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }

    .pre-cut-mat-box {
        width: 20%;
        float: left;
        margin: 2.5% 2.5% 0 2.5%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .matboard-colour-name:hover {
        text-decoration: underline;
    }

    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }


    .precut-mat-sorting-button {
        width: 50%;
        float: left;
        text-align: center;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .precut-mat-sorting-button-selected {
        text-decoration: underline;
    }

    .precut-mat-sorting-button-unselected:hover {
        text-decoration: underline;
    }

    .design-guide-colour-box {
        width: 25%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: right;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: right;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }

    .design-guide-photo-5, .design-guide-photo-7 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
        text-align: right;
        margin-left: 0;
    }

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 350px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 350px;
        object-fit: fill;
        float: left;
    }

    .design-guide-content-instruction-container {
        width: 100%;
        overflow: auto;
        padding: 56px 0 0 0 ;
    }


    .design-guide-desc {
        width: 50%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        width: 25%;
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: left;
    }

    .design-guide-container {
        width: auto;
        margin: 52px 220px;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 16px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 80%;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        font-weight: 700;
        color: white;
    }

    .inspiration-logo-name-subscript {
        width: 80%;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        color: white;
    }

    .inspiration-logo-name-subscript a {
        color: white;
        cursor: pointer;
    }

    .inspiration-posts-col {
        width: 352px;
        float: left;
        overflow: auto;
        margin-top: 32px;
    }

    .inspiration-post-col-photo {
        width: 100%;
    }

    .inspiration-posts {
        width: 1120px;
        overflow-y: auto;
        column-count: 3;
        grid-column-gap: 32px;
        column-gap: 32px;
    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 1120px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0 calc(50% - 560px);
    }

    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }


    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }


    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -10vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }


    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }


    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }
    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 32px 11%;
    }



}

@media screen and (min-width: 1920px) {

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        width: 100%;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .precut-container {
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        width: 100%;
        overflow: auto;
        margin-bottom: 24px;
        margin-top: 70px;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .precut-container-right {
        width: 35%;
        overflow: auto;
        float: left;
        margin-left: 5%;
    }

    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .precut-container-leader-left {
        /* width: 13px; */
        height: 13px;
        margin: 3px 5px 3px 0;
        /* overflow: auto; */
        float: left;
    }

    .precut-container-leader-right:hover {
        text-decoration: underline;
    }


    .precut-container-left-photo {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }

    .matboard-colour-name {
        margin-top: 24px;
    }

    .diploma-mat-box {
        width: 40%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .pre-cut-mat-box {
        width: 20%;
        float: left;
        margin: 2.5% 2.5% 0 2.5%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .matboard-colour-name:hover {
        text-decoration: underline;
    }

    .precut-mat-sorting-container {
        width: 50%;
        overflow: auto;
        margin: 24px 25%;
    }

    .precut-mat-sorting-button {
        width: 50%;
        float: left;
        text-align: center;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .precut-mat-sorting-button-selected {
        text-decoration: underline;
    }

    .precut-mat-sorting-button-unselected:hover {
        text-decoration: underline;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 500px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-container {
        width: 1440px;
        margin: 52px auto;
    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 500px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: right;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin-left: 0;
        float: left;
        text-align: right;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-left: 7.5%;
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: 42.5%;
        overflow: auto;
        object-fit: fill;
        margin-right: 7.5%;
        float: left;
        text-align: right;
    }

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 600px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 600px;
        object-fit: fill;
        float: left;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 16px;
        color: #3B3B3B;
    }


    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }

    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -10vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }



    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }

    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 32px 11%;
    }
}
/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .carousel-root {
        width: 100%;
    }

    .pre-cut-mat-box-img {
        width: 100%;
        max-height: 230px;
    }

    .pre-cut-mat-box-img {
        height: 300px;
    }

    .pre-cut-mat-box {
        width: 50%;
        float: left;
        margin-bottom: 10%;
    }

    .fetch-precut-mats-title-container {
        width: 100%;
        text-align: center;
        margin-top: 12px;
        line-height: 1.5;
    }

    .carousel-image-item {
        height: 450px;
    }

    .thumbs-wrapper img {
        height: 75px;
    }

    .precut-container-left-photo {
        width: 100%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }


    .precut-container-leader-left {
        width: 13px;
        height: 13px;
        float: left;
        cursor: pointer;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    .design-guide-colour-box {
        width: 33%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }

    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: calc( 100% - 272px);
        overflow: auto;
        object-fit: fill;
        margin: 36px 136px;
        float: right;
    }


    .design-guide-step-by-step-container-right {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 136px;
        float: left;
        text-align: left;
    }


    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: calc( 100% - 272px);
        overflow: auto;
        object-fit: fill;
        margin: 36px 136px;
        float: left;
    }



    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 300px;
        object-fit: fill;
        float: left;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 300px;
        object-fit: fill;
        float: left;
    }

    .design-guide-content-instruction-container {
        width: auto;
        overflow: auto;
        padding: 56px 49px 0 49px;
    }


    .design-guide-desc {
        width: 50%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        width: 25%;
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: left;
    }

    .design-guide-container {
        width: auto;
        margin: 170px 0px;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 11px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 100%;
        font-style: normal;
        font-size: 27.589px;
        font-weight: 700;
        line-height: 150%;
        color: white;
    }

    .inspiration-logo-name-subscript a {
        font-size: 27.589px;
        font-style: normal;
        font-weight: 400;
        color: white;
        line-height: 150%;
    }



    .inspiration-posts-col {
        width: 304px;
        float: left;
        overflow: auto;
        margin-top: 32px;
    }

    .inspiration-post-col-photo {
        /*width: 100%;*/
        /*height: 187px;*/
        /*margin-top: -5px;*/
        /*object-fit: cover;*/
    }

    #inspiration-post-col-photo-main-page {
        width: 100%;
    }

    .inspiration-posts {
        width: 640px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 64px calc(50% - 320px);
        column-count: 2;
        grid-column-gap: 32px;
        column-gap: 32px;

    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 768px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 150px calc(50% - 384px);
    }



    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {


        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }


    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 118px;
    }

    .overview-section-left {
        width: 51%;
    }

    .overview-section-header-container {
        margin-top: -20vh;
        width: 40%;
    }

    .gallery-left-item-container-right {
        width: 85%;
        float: left;
    }

    .blog-article-photo-two-image {
        width: 49%;
        margin-right: 1%;
        float: left;
        height: 40vh;
        object-fit: cover;
    }

    .blog-article-photo-two {
        width: 60vw;
        overflow: auto;
        position: absolute;
        margin-left: -10vw;
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: 35vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 50%;
        overflow-y: auto;
        float: left;
    }



    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .faster-shipping-area-chevron {
        width: 15px;
        height: 18px;
        float: left;
        margin: 0 15px 0 10px;
    }

    .faster-shipping-expanable-container {
        width: auto;
        overflow: auto;
        padding: 12px 0 11px 0;
    }

    .faster-shipping-area-chevron-inverse {
        width: 15px;
        height: 18px;
        float: left;
        margin: 0 15px 0 10px;
        transform: rotate(180deg);
    }

    .faster-shipping-area-province-name-divider {
        width: 100%;
        height: 1px;
        background: #3B3B3B;
        opacity: 0.25;
    }

    .faster-shipping-area-province-name {
        width: calc(100% - 60px);
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .faster-shipping-expanable-subcontainer {
        width: 100%;
        overflow: auto;
        padding-bottom: 11px;
    }

    .general-static-page-container {
        width: 78%;
        overflow: auto;
        margin: 150px 11%;
    }


    .precut-container-left {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .precut-container-left-colours {
        width: 45%;
        margin: 2.5%;
        overflow: auto;
        float: left;
        object-fit: cover;
    }



}
@media screen and (min-width: 0px) and (max-width: 769px) {

    .thumbs-wrapper {
        display: none;
    }

    .carousel-root {
        width: 100%;
    }

    .fetch-precut-mats-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        width: 130px;
        margin-left: calc(50% - 65px);
    }

    .carousel-image-item {
        height: 275px;
    }

    .thumbs-wrapper img {
        height: 50px;
    }

    .precut-window-selector .add-text-fields-text-field-title {
        font-size: 16px;
        font-weight: 700;
    }

    .precut-window-selector {
        width: 97.5%;
    }

    .precut-container .precut-container {
        width: 100%;
        overflow: auto;
        margin-bottom: 16px;
    }

    .precut-container-left-photo, .precut-container-left-colours {
        overflow: auto;
        float: left;
        display: table;
    }

    .precut-container-left {
        width: 100%;
        overflow: auto;
        overflow-x: scroll;
        display: flex;
    }

    .precut-container-right .add-text-fields-text-field-title {
        font-size: 16px !important;
        font-weight: 700;
        text-transform: uppercase;
    }

    .diploma-mat-box {
        width: 90%;
        float: left;
        margin: 4.9%;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        cursor: pointer;
        color: #3B3B3B;
    }

    .pre-cut-mat-box {
        width: 50%;
        float: left;
        margin: 2.5% 0;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        cursor: pointer;
        color: #3B3B3B;
    }

    .pre-cut-mat-box-img{
        height: 140px;
    }

    .design-guide-colour-box{
        width: 50%;
        overflow: auto;
        display: inline-block;
        text-align: center;
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;

        min-height: 40px;
        float: left;

    }



    .general-static-page-container {
        width: 90%;
        overflow: auto;
        margin: 100px 5%;
    }

    .faq-page-heading {
        width: auto;
        overflow: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 32px;
    }

    .design-guide-container {
        width: auto;
        margin: 52px 0px;
    }


    .precut-container-leader-left {
        width: 16px;
        height: 16px;
        float: left;
        cursor: pointer;
        padding: 3px 6px 3px 0;
    }

    .precut-container-leader-right {
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
    }

    /*.gallery-container {*/
    /*    margin: 64px 0;*/
    /*}*/

    .design-guide-mobile-dropdown-icon {
        float: left;
    }

    .design-guide-step-by-step-container-left {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin: 0 16px;
        float: left;
        text-align: center;
    }

    .design-guide-selection-button-container {
        width: 100%;
        display: flex;
    }


    .design-guide-photo-container-subtitle {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-bottom: 96px;
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
    }


    .design-guide-photo-container-title {
        /* Header 2 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 96px;
        margin-bottom: 24px;
        margin-left: 16px;
        margin-right: 16px;
        width: auto;
    }

    .design-guide-photo-3 {
        width: 50%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        margin-right: 2.5%;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-4 {
        width: 47.5%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        margin-top: 2.5%;
        float: left;
    }

    .design-guide-photo-6 {
        width: 100%;
        overflow: auto;
        object-fit: fill;
        float: left;
    }


    .design-guide-step-by-step-container-right {
        width: auto;
        overflow: auto;
        object-fit: fill;
        margin-left: 16px;
        margin-right: 16px;
        float: left;
        text-align: center;
    }



    .design-guide-step-by-step-container-left-topic {
        /* Header 3 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
    }


    .design-guide-photo-5, .design-guide-photo-7 {
        width: 100%;
        overflow: auto;
        object-fit: fill;
        float: left;
    }

    /*.design-guide-step-by-step-container-left {*/
    /*    width: 42.5%;*/
    /*    overflow: auto;*/
    /*    object-fit: fill;*/
    /*    margin-right: 7.5%;*/
    /*    float: left;*/
    /*    text-align: right;*/
    /*}*/

    .design-guide-photo-1 {
        width: 60%;
        overflow: auto;
        margin-right: 2.5%;
        height: 150px;
        object-fit: fill;
        float: left;
    }


    .design-guide-content-instruction-container {
        width: 100%;
        overflow: auto;
        padding: 56px 0 0 0 ;
    }

    .design-guide-photo-2 {
        width: 37.5%;
        overflow: auto;
        height: 150px;
        object-fit: fill;
        float: left;
    }


    .design-guide-desc {
        width: 100%;
        overflow: auto;
        float: left;
        font-style: normal;
        line-height: 150%;
    }


    .design-guide-section-button {
        float: left;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        color: #3B3B3B;
    }

    .design-guide-image {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .inspiration-logo-IG-POST-container {
        width: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin: 11px;
        color: #3B3B3B;
    }

    .inspiration-logo-name {
        width: 60%;
        float: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: white;
    }

    .inspiration-posts-col,.inspiration-logo-name-subscript a {
        width: 98%;
        float: left;
        overflow: auto;
        color: white;
    }




    .instagram-scroller-right-title {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        font-feature-settings: "smcp";
        font-variant: small-caps;
        letter-spacing: 2px;
        color: white;
    }

    /*.inspiration-post-col-photo {*/
    /*    width: 100%;*/
    /*}*/

    .inspiration-posts {
        width: 98%;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 64px 1%;
    }

    .inspiration-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        /* identical to box height, or 128% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 24px 0px;
        cursor: pointer;
    }

    .inspiration-listing-desc {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
    }

    .inspiration-page-container {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 120px;
    }

    .general-static-page-container-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px;
    }

    .inspiration-posts {
        width: 100%;
        overflow-y: auto;
    }

    .general-static-page-container-topic-heading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0 0 0;
    }

    .general-static-page-container-topic-subheading {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text li, .general-static-page-container-text {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 8px 0px;
    }

    .general-static-page-container-text-line-break {
        padding-bottom: 64px;
        border-bottom: solid 1px #3B3B3B;
    }

    .overview-section-left {
        width: 91%;
    }
    .overview-section-right, .gallery-left-item-container-left {
        display: none;
    }
    .overview-section-header-container {
        margin-top: -15vh;
        width: 85%;
    }
    .overview-section-header, .overview-section-subheader {
        max-width: 75%;
        font-size: 0.8rem;
    }
    .gallery-left-item-container-right {
        width: 100%;
    }
    .overview-section-left {
        border-right: none;
    }

    .blog-article-photo-two-image {
        width: 100%;
        float: left;
        overlow-y: auto;
        margin-bottom: 5vh;
    }

    .blog-article-photo-two {
        width: 100%;
        overflow: auto;
    }

    .image-subscript {
        width: 100%;
        overflow: auto;
        margin-top: -10vh;
        padding-bottom: 3rem;
    }

    .blog-article-photo-two-image-portrait {
        width: 100%;
        overflow-y: auto;
    }

    .design-guide-section-button-selected {
        text-align: center;
        margin: 0 auto;
    }

    .design-guide-mobile-dropdown-icon {
        transform: rotate(
                270deg
        );
        margin-left: 5px;
        width: 10px;
    }

}


.faq-table-row {
    border: solid #F3F6F6 0.1rem;
    width: 23.5%;
    overflow: auto;
    padding: 16px 6px;
    text-align: center;
    font-weight: 600;
}

.faq-table-row-subtext {
    font-size: 10px;
}

.faq-table-row-number {
    border: solid #F3F6F6 0.1rem;
    padding: 16px 6px;
    text-align: center;
    font-weight: 600;
}

.faq-table-row-size {
    text-align: center;
}

#faq-container a {
    text-decoration: underline;
}

.faq-header {
    font-size: 1.3rem;
    color: #3B3B3B;
    padding-bottom: 1.5rem;
}

.faq-category-header {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #3B3B3B;
}

.faster-shipping-area-expandable {
    width: 100%;
    overflow: auto;
}

.faq-question {
    width: auto;
    overflow: auto;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 1em;
    color: #3B3B3B;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 11px;
    cursor: pointer;
}

.faq-paragraph {
    width: auto;
    overflow: auto;
    font-size: 1rem;
    padding-bottom: 1rem;
    line-height: 2;
}


.faq-static-page-container-heading {
    width: 100%;
    overflow: auto;
    text-align: left;
    /* Header 4 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
    margin-bottom: 28px;



}

.faq-question-close {
    width: 22px;
    margin-left: 10px;
    margin-right: 30px;
    float: left;
}

.faq-question-open {
    width: 22px;
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 5px;
    transform: rotate(180deg);
    float: left;
}

.faq-answer-paragraph {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    color: #3B3B3B;
    margin-top: 16px;
}

.faq-question-right {
    width: calc(100% - 62px);
    float: left;
    overflow: hidden;
}

.faq-answer {
    margin-bottom: 43px;
}

.faq-question-bloc {
    margin-bottom: 64px;
}

.faq-question-right {
    line-height: 150%;
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 10% 5rem 10%;
    }
}


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .faq-table-row-number {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .faq-table-row-size {
        text-align: center;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) and (max-width: 1450px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}
.fetch-region-content {
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    line-height: 150%;
}



.fetch-region-photo {
    width: auto;
    overflow: auto;
    padding: 0 0 4rem 0;
}

.gallery-container a {
    text-decoration: underline;
}

#region-container {
    margin-top: 3rem;
}

.region-article-photo-one-image {
    width: 100%;
    /* height: 45vh; */
    object-fit: cover;
    float: left;
    /* margin-right: 2%; */
    overflow: auto;
    margin-bottom: 2vh;
}

/*For all*/

.fetch-region-photo-one {
    width: 100%;
}

.hide-250px-top {
    margin-top: -250px;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .fetch-region-photo-one, .content-paragraph {
        width: 100%;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {


    .fetch-region-photo-one, .content-paragraph {
        width: 100%;
    }
}


/*For all*/
.landing-section {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 10vh;
}



.landing-section-img {
    width: 80%;
    height: auto;
    object-fit: cover;
    overflow-y: auto;
    padding-left: 10%;
    padding-right: 10%;
    background: #ebedee;
}

#landing-section-polaroid-background {
    background: #EAEAEA;
}

.emphasis-type-1 {
    text-align: center;
    background-color: #514945;
    color: #fff;
    border: 1px solid #514945;
    width: 10rem;
    font-weight: 400;
    padding: 1rem 3rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.8rem;
    display: inline-block;
}

.emphasis-type-1 div {
    color: white;
}

.emphasis-section {
    color: #fff;
    padding-left: 3px;
    font-size: 10px;
    box-shadow: 0 0 0 0 hsla(0, 0%, 100%, .7);
    -webkit-animation: pulse 1.5s infinite;
    width: auto;
    height: 10px;
    border-radius: 100%;
    text-align: center;
}

.emphasis-spacing {
    margin-left: 5px;
}

.emphasis-type-1:hover {
    background-color: #f59276;
    border-color: #f59276;
    transition: border-color .35s ease-out,color .25s ease-out;
    transition: background-color .35s ease-out,color .25s ease-out;
}

.introduction-button-groups {
    display: inline-block;
    width: 100%;
    overflow: auto;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 5px rgba(232,76,61,0);
    }
}

.landing-page-product-selection-option {
    width: 15vw;
    float: left;
    text-align: center;
}

.landing-section-left, .landing-section-right {
    background-color: #f1ece4;
    color: #000;
}

#why-matboard-container {
    background: #FFAD9C;
    margin-top: 5vh;
    padding: 5vh 3rem;
}

#why-matboard-container .what-we-do-container-first-container {
    margin-bottom: 0;
}

#why-matboard-container .what-we-do-container-first-container .what-we-do-first-right {
    opacity: 1;
    margin: 0;
}

.why-matboard-header {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    margin-top: 5vh;
}

.why-matboard-subheader {
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.why-matboard-transition {
    width: 4rem;
    height: 1vh;
    background: white;
    margin-top: 2vh;
}

#why-matboard-container .what-we-do-first-left {
    text-align: left;
}

#why-matboard-container .what-we-do-first-left {
    padding-left: 5%;
}

.introduction-section-header {
    font-size: 2rem;
    line-height: 1.5;
}

.introduction-section-subheader {
    font-size: 1.3rem;
    margin-top: 2vh;
}

.intro-section-header-separator {
    width: 4vw;
    height: 0.75vh;
    background: #514945;
    margin-left: 4vw;
    margin-top: 2vh;
    font-weight: 400;
}



/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .landing-section-left, .landing-section-right {
        width: 100%;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 3vh 5vw 2vh 5vw;
        font-weight: 400;
        font-size: 1.3rem;
        text-align: center;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
        font-size: 1rem;
        text-align: center;
    }

    .emphasis-type-1 {
        margin-left: calc(50% - 8rem);
    }

    .intro-section-header-separator {
        display: none;
    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 500;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        text-align: center;
        background-color: #514945;
        color: #fff;
        border: 1px solid #514945;
        width: 10rem;
        font-weight: 400;
        /* margin-left: 4vw; */
        /* margin-right: 4vw; */
        padding: 1rem 3rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.8rem;
        margin-left: calc(50% - 8rem);
        display: inline-block;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }



}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }



}

.product-offering-container {
    width: 100%;
    overflow: auto;
    background: #f9f9f9;
}

.product-offering-item {
    width: 23%;
    padding: 5vh 4%;
    float: left;
    background: #EBEDEE;
    margin: 1%;
    margin-bottom: 5vh;
}

.blog-article-content th {
    border: 0.5px solid #72696A;
    padding: 10px 2.5px;
    width: 40%;
}

.product-offering-image-container {
    width: 100%;
    overflow: auto;
}

.product-offering-image {
    width: 100%;
    overflow: auto;
}

.product-offering-title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
}

.product-offering-desc {
    line-height: 2;
    text-align: center;
    margin-bottom: 0.5rem;
    color: #858585;
}

.product-offering-price-range {
    text-align: center;
    color: #858585;
    line-height: 2;
    font-size: 0.8rem;
}

.product-offering-action-button {
    font-size: 0.8rem;
    text-align: center;
    width: 45%;
    float: left;
    text-transform: uppercase;
    margin-top: 2vh;
    padding: 2%;
    cursor: pointer;
    font-weight: 500;
}

.product-offering-action-button:hover {
    text-decoration: underline;
}


.product-listing-action-button:hover {
    color: white;
    background: #F59276;
    /*padding: 1.01vw 0;*/
    /*border: #F59276 0.208vw solid;*/
}

.select-product-margin {
    margin: 1.6666vw 0;
}

.product-list-item-image {
    width: 100%;
}

/*.customer-review-container {*/
/*    width: 100%;*/
/*    overflow: auto;*/
/*}*/

.product-list-item a {
    text-decoration: none;
}

.product-listing-desc-select-option {
    color: #72696A;
}

.product-listing-desc-select-option a {
    color: #72696A;
}

.product-listing-desc-select-option:hover {
    cursor: pointer;
    text-decoration: underline;
}

.pricing-table table {
    width: 100%;
    margin-bottom: 2.5vw;
}





/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {


    .product-list-container {
        width: calc(100% - 10vw);
        overflow: auto;
        margin: 0 5vw 5.45vw 5vw;
    }

    table {
        border-collapse: collapse;
        color: #72696A;
    }

    .product-divider {
        width: 9.6875vw;
        height: 2.5vw;
        background-color: #F59276;
        margin: 12.5vw calc( (100% - 9.6875vw ) /2 ) 4.375vw;
    }

    .product-listing-header {
        text-align: center;
        color: #72696A;
        font-size: 6.25vw;
        width: 100%;
        line-height: 7.8125vw;
        font-weight: 600;
    }

    .product-listing-desc {
        margin: 0;
        text-align: center;
        color: #72696A;
        font-weight: 400;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

    .product-listing-price {
        font-size: 5vw;
        text-align: center;
        margin: 7.5vw;
        font-weight: 600;
        color: #F59276;
        line-height: 6.5625vw;
    }

    .product-listing-action-button {
        width: 60.3125vw;
        border: 0.9375vw solid #F59276;
        text-align: center;
        margin: 0 calc( (100% - 60.3125vw) /2 );
        padding: 4.53125vw 0;
        color: #F59276;
        text-transform: uppercase;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 4.5315vw;
    }

    .product-list-item {
        margin-bottom: 11.71875vw;
    }

    #product-list-item-box-2 img {
        margin: 0;
    }

    .landing-page-how-it-works-transition {
        width: auto;
        background-color: #F59276;
        padding: 12.5vw 4.6875vw;
    }

    .shipping-info-desc {
        font-size: 5vw;
        line-height: 7.8125vw;
        text-align: center;
        color: white;
        margin-bottom: 7.5vw;
    }

    .shipping-info-highlights {
        font-weight: 500;
        font-size: 5vw;
        color: white;
        line-height: 7.8125vw;
        text-align: center;
    }

    .pricing-table {
        width: 100%;
        margin-bottom: 5vw
    }

    .product-listing-header, .product-listing-desc {
        margin: 5vh 0;
    }

    .fair-pricing-discount-section-container {
        width: 90%;
        margin: 5%;
        margin-top: 120px;
    }

    .product-listing-subdescription {
        text-align: center;
        color: #72696A;
        font-weight: 400;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .product-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px 24px 0;
    }

    .product-listing-subdescription {
        line-height: 3.247vw;
        color: #72696A;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .fair-pricing-discount-section-container {
        width: 90%;
        margin: 200px 5%
    }

    .pricing-table .product-listing-desc {
        width: 80%;
        font-size: 2.0779vw;
        margin: 4vmin 10% 5vw 10%;
    }

    .product-offering-header {
        font-size: 4.156vw;
    }

    .faq-table-row {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
    }

    table {
        border-collapse: collapse;
        font-size: 2.597vw;
        color: #72696A;
    }

    .pricing-table {
        width: 95%;
        margin: 5vw 2.5%;
    }

    .product-list-item {
        width: 61.688vw;
        overflow: auto;
        float: left;
        margin: 0 calc( (100% - 61.688vw) / 2);
    }

    .product-list-item-single {
        width: auto;
        overflow: auto;
    }

    #product-list-item-box-2 img {
        margin: 0;
    }


    .product-divider {
        width: 5.195vw;
        height: 1.039vw;
        background-color: #F59276;
        margin: 5.195vw calc( (100% - 5.195vw) / 2) 1.818vw;
    }

    .product-listing-header {
        width: auto;
        font-size: 2.597vw;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        margin: 0 0 3.117vw 0;
        line-height: 3.117vw;
    }

    .product-listing-desc {
        font-size: 1.11vw;
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 5.195vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .product-listing-price {
        color: #F59276;
        text-align: center;
        font-weight: 600;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin: 0 0 2.403vw 0;
    }

    .product-listing-action-button {
        background-color: inherit;
        font-size: 1.11vw;
        color: #F59276;
        padding: 1.01vw 0;
        text-align: center;
        width: 10.9vw;
        margin-left: calc((100% - 20.3896vw) /2);
        border: #F59276 0.208vw solid;
        cursor: pointer;
        width: 20.3896vw;
        padding: 1.883vw 0;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin-bottom: 5vw;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 3.96vw;
    }

    .product-offering-header {
        text-align: center;
        letter-spacing: 0.67px;
        line-height: 7.4026vw;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 4.1558vw;
        text-transform: uppercase;
    }

    .product-list-container {
        width: calc(100% - 18vw);
        overflow: auto;
        margin: 0 9vw 5.45vw 9vw;
    }


}

.product-listing-desc li {
    text-align: left;
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .product-listing-subdescription {
        width: 50%;
        margin: 0 25%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        padding: 0 0 5vw 0;
        color: #72696A;
    }

    table {
        border-collapse: collapse;
        color: #72696A;
    }

    .table {
        font-weight: 400;
    }

    .pricing-table {
        width: 78%;
        margin: 24px 11%;
        font-size: 12px;
    }

    .product-list-item {
        width: 25vw;
        overflow: auto;
        float: left;
    }

    .product-list-item-single {
        width: auto;
        overflow: auto;
        margin: 0 36.5vw;
    }

    .product-list-item-image {
        width: 22.5vw;
        margin: 0 1.25vw;
    }

    .product-divider {
        width: 2.78vw;
        height: 0.56vw;
        background-color: #F59276;
        margin: 2.78vw calc(50% - 1.39vw) 0.97vw;
    }


    .product-listing-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 32px 0px 24px 0;
    }

    .product-listing-desc {
        position: static;
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin-top: 24px;
        margin-bottom: 49px;
    }

    .product-listing-price {
        color: #F59276;
        text-align: center;
        font-weight: 600;
        font-size: 1.11vw;
        line-height: 1.46vw;
        margin: 0 0 1.28vw 0;
    }

    .product-listing-action-button {
        background-color: inherit;
        font-size: 1.11vw;
        color: #F59276;
        padding: 1.01vw 0;
        text-align: center;
        width: 10.9vw;
        margin-left: calc(50% - 5.45vw);
        border: #F59276 0.208vw solid;
        cursor: pointer;
    }

    .product-listing-action-button-bottom-margin {
        margin-bottom: 1.96vw;
    }


    #product-list-item-box-1, #product-list-item-box-2 {
        margin-left: 2vw;
    }

    /*.product-list-item-image {*/
    /*    margin: 0;*/
    /*}*/

    .product-offering-header {
        text-align: center;
        margin: 5.55vh 0;
        font-weight: 600;
        text-transform: uppercase;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        text-align: left;

    }

    .product-list-container {
        width: calc(100% - 18vw);
        overflow: auto;
        margin: 0 9vw 5.45vw 9vw;
    }

    .pricing-table .product-listing-desc {
        font-size: 1.11vw;
        line-height: 1.74vw;
        margin-top: 4vmin;

    }




}
/*For all codes Only*/
@media screen and (min-width: 0px) {

    .bold {
        font-weight: 600;
    }

    .diploma-mat-rendering .artwork-margin-controller-up, .diploma-mat-rendering .matboard-window-central-controller, .diploma-mat-rendering .artwork-margin-controller-down{
        display: none;
    }

    .select-mat-row li{
        margin: 4px 0;
    }

    .matboard-size-popular-values-input {
        width: 100%;

    }


    .select-mat-guidance-desc-long{
        width: calc( 100% - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
    }

    .matboard-size-popular-values-input select {
        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        padding: 10.5px 14px;
    }

    .value-type-selected {
        font-weight: bold;
    }

    .value-type-selector {
        margin-bottom: 12px;
        cursor: pointer;
    }

    .value-type-selector {

        float: left;
        margin-right: 5px;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .window-size-indicator {
        display: none;
    }


    .matboard-colour-selected {
        border: 4px solid #FFDE52;
        margin: -4px;
        border-radius: 2px;
    }

    .additional-notes-add-margin {
        margin-bottom: 18px;
    }

    .add-border-top {
        border-top: solid 1px #828282;
        padding-top: 8px;
    }

    .select-window-multiple-window-dimensions div div, .select-mat-product-order-quantity div div {
        border-radius: 2px;
    }

    .select-window-multiple-window-dimensions div, .select-mat-product-order-quantity div {
        padding: 0;
        margin: 0;
    }

    .req-mat-sample-button:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .select-matboard-size-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }



    .select-matboard-description-container {
        color: #3b3b3b;
    }

    .change-matboard-colour-button:hover {
        text-decoration: underline;
    }

    .select-frame-size-leader-color {
        color: #72696A;
    }

    .window-opening-field-exit:hover {
        color: white;
        background: #F59276;
    }

    .select-matboard-window {
        background-size: 100% 100%;
        box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);
        float: left;
        background: white;
    }

    .select-mat-rendering, .iconSize {
        float: left;
    }

    .iconSize, .select-matboard-artwork {
        position: relative;
        z-index: 10;
    }

    .select-matboard-artwork {
        width: 100%;
        height: 100%;
        /*box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);*/
    }

    .user-window-image {
        width: 100%;
        height: 100%;
        margin: 0;
        background: white;
        box-shadow: inset 0 0.1vw 0.25vw rgba(10,10,10,.8);
    }

    .artwork-margin-indicator-down {
        float: left;
        position: absolute;
    }


    .artwork-margin-indicator-up, .artwork-margin-indicator-number-up {
        color: #514945;
        border-color: #514945;
    }

    .artwork-margin-indicator-down, .artwork-margin-indicator-number-down {
        color: #F59276;
        border-color: #F59276;
    }

    .artwork-margin-indicator-left, .artwork-margin-indicator-number-left {
        color: #92BBD9;
        border-color: #92BBD9;
    }

    .artwork-margin-indicator-right, .artwork-margin-indicator-number-right {
        color: #B4876E;
        border-color: #B4876E;
    }

    .window-size-input {
        width: 100%;
    }

    .artwork-margin-indicator-number-right {
        display: initial
    }

    #order-checkout-button {
        width: 130px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #3B3B3B;
        border-radius: 30px;
        padding: 16px 24px;
        float: left;
        cursor: pointer;
    }

    #order-checkout-button-inner-layer {
        color: white;
        text-align: center;
    }

    .multiple-window-artwork-size-selection-panel-title {
        font-size: 4vw;
        line-height: 6.5625vw;
        font-family: "Montserrat";
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        color: #72696A;
        font-weight: 400;
    }

    .select-mat-window-quantity-container label span  {
        font-family: "Montserrat";
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .select-mat-window-quantity div label, .select-window-multiple-window-dimensions div label {
        background: white;
    }



    .select-matboard-section {
        width: 100%;
        height: 100%;
        background-size: 100% 110% !important;
        background: white;
        box-shadow: inset 0 0.1rem 0.2rem rgba(10,10,10,.6);
        overflow: hidden;
    }

    .select-mat-background-wall {
        position: absolute;
        width: calc( 100% - 10rem);
        height: 75vh;
        overflow: hidden;
    }



    .select-mat-frame {
        z-index: 10;
        box-shadow: 0.5rem 0.2rem 1rem rgba(255,255,255,.6);
    }

    .select-mat-add-window-icon {
        width: 2vw;
        overflow-y: auto;
        padding-top: 0.25vw;
    }

    .select-mat-add-window-caption {
        width: calc( 100% - 0.25vw );
        color: #72696A;
    }



    .select-matboard-description-container {
        overflow-y: auto;
        float: left;
    }


    .select-mat-product-change {
        width: 100%;
        overflow: auto;
    }

    .select-mat-product-desc-name {
        font-size: 1.3888vw;
        text-transform: uppercase;
        font-weight: 500;
        color: #72696A;
        line-height: 1.66666vw;
        letter-spacing: 0.029166vw;
    }

    .select-mat-product-dimensions {
        width: auto;
        overflow: auto;
        font-size: 1rem;
        margin-top: 0.55555vw;
        font-size: 1.11vw;
        color: #72696A;
        line-height: 1.7361vw;
    }

    .artwork-margin-indicator-left  {
        font-weight: 500;
        position: absolute;
        float: left;
        text-align: right;
    }

    .select-mat-product-price {
        font-size: 2rem;
        line-height: 2;
        color: black;
        font-weight: 500;
    }

    .select-mat-product-description-content {
        line-height: 1.5;
    }

    .change-frame-orientation-button {
        float: left;
        margin: 0 0.25rem;
        cursor: pointer;
    }

    .select-mat-product-change-button {
        text-align: center;
        width: calc( 100% - 1vmin);
        overflow: auto;
        margin-top: 3vmin;
        padding: 1vmin 0;
        border: solid 0.1vmin;
    }

    .select-mat-product-order-quantity label {
        padding: 0.25vmin 1vmin;
        background: white;
    }

    .select-mat-product-order-quantity div {
        width: 100%;
    }

    .change-frame-orientation-section {
        width: 100%;
        overflow: auto;
    }

    .select-mat-product-order-quantity-container {
        width: auto;
        overlfow: auto;
        margin-top: 2vmin;
    }

    .select-mat-window-quantity-column {
        float: left;
        width: 20%;
        overflow: auto;
    }

    .select-mat-window-quantity {
        float: left;
        width: 35%;
        margin-right: 27px;
    }

    .select-mat-window-quantity-row {
        float: left;
        width: 20%;
        overflow: auto;
    }

    .select-mat-window-quantity-column div, .select-mat-window-quantity div, .select-mat-window-quantity-row div {
        margin: 0;
    }

    .select-mat-window-quantity-row-column-middle {
        width: 8px;
        overflow: hidden;
        float: left;
        font-style: normal;
        padding: 20px 12px 0 12px;
        color: #3b3b3b;
    }

    .window-opening-field-bridge {
        width: 20%;
        float: left;
        overflow: auto;
        margin-top: 1vmin;
        text-align: center;
    }

    .select-mat-window-quantity-container {
        width: 100%;
        overflow: auto;
    }

    .display {
        color: #3B3B3B;
    }



    .artwork-dimension-view {
        width: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 5% 10% 2% 10%;
        padding: 5%;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .iconSize:hover {
        cursor: pointer;
    }

    .window-movement-indicator {
        width: 100%;
        font-size: 2vmin;
        margin-top: 0.5vmin;
        text-align: center;
    }

    /*.artwork-margin-controller-up, .artwork-margin-controller-down {*/
    /*    width: 30%;*/
    /*    overflow: auto;*/
    /*    text-align: center;*/
    /*    margin-left: 35%;*/
    /*}*/

    .artwork-margin-indicator-up {
        font-weight: 500;
        position: absolute;
        float: left;
    }

    .window-opening-field {
        width: 40%;
        float: left;
        overflow: hidden;
    }

    .artwork-margin-indicator-right {
        font-weight: 500;
        position: absolute;
        text-align: center;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: calc( 50% - 3vw);
        height: 50%;
    }

    .select-matboard-input-modal {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        margin: 5vmin calc(50% - 45vmin);
        border-radius: 1vmin;
        margin-top: 5vmin;
    }

    .select-matboard-modal-title {
        width: auto;
        overflow: auto;
        background: white;
        padding: 3vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
    }

    .select-matboard-modal-subtitle {
        padding: 0 10vmin 5vmin 10vmin;
        line-height: 2;
    }

    .window-opening-field-container {
        width: 100%;
        overflow: auto;
    }


    .window-opening-field-form-aid {
        width: 100%;
        overflow: auto;
        margin-top: 2vmin;
        text-align: right;
        font-size: 2vmin;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .window-opening-field-exit {
        overflow: auto;
        text-align: center;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
        color: #F59276;
        font-size: 2vmin;
        font-weight: 500;
        float: right;
    }

    .window-opening-field-exit-container {
        width: auto;
        overflow-y: auto;
        padding: 0 10vmin 1vmin 10vmin;
        cursor: pointer;
    }

    .window-size-indicator {
        font-size: 2vmin;
        width: calc(100% - 3vw);
        padding: 0;
        min-width: 1.5vw;
        overflow: auto;
        text-align: center;
        float: left;
        display: none;
    }

    .matboard-window-action-icon-container {
        width: 100%;
        overflow: auto;
    }

    .matboard-window-size-indicator {
        font-weight: 500;
        cursor: pointer;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
    }

    .second-mat-price {
        float: right;
    }

}
@media screen and (min-width: 0px) and (max-width: 769px) {

    .precut-size-header {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
    }

    .select-window-multiple-window-dimensions div select, .select-mat-product-order-quantity div select{
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
    }

    .select-window-multiple-window-dimensions div, .select-mat-product-order-quantity div {
        width: 100%;
    }

    /*.select-window-multiple-window-dimensions div {*/
    /*    margin-top: 5px;*/
    /*}*/

    .mobile-and-tablet-display-only {
        display: inherit;
    }

    .artwork-margin-indicator-number-right {
        display: inline;
        width: 40px;
        height: 18px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .select-mat-container {
        width: 100%;
        overflow: auto;
        /*Black Friday Code Below*/
        margin-top: 32px;
    }

    .switch-to-drawing-tool-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin: 32px 0;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
    }



    .checkout-subtotal-figure {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */
        text-align: center;
        /* Primary/Soft Black */
        color: #3B3B3B;
        margin-top: 8px;
    }

    #order-checkout-button {
        width: auto;
        margin-left: calc( 50% - 73px);
        cursor: pointer;
    }

    .mobile-select-mat-checkout-button {
        width: 100%;
        overflow: auto;
        margin-top: 16px;
        padding-bottom: 32px;
        border-bottom: 1px solid #3b3b3b;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .double-mat-price-indicator-mobile {
        float: right;
        color: #3b3b3b;
    }

    #select-matboard-window-caption-text-double-matting {
        margin-top: 12px;
        width: 100%;
    }

    .select-mat-guidance-desc-long{
        width: calc( 100% );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    #backing-mat-price-and-colour-container {
        width: calc(100% - 42px);
        float: left;
    }

    #window-size-round-window-container {
        width: calc(100% - 42px);
        float: left;
        overflow-x: hidden;
    }

    #double-mat-mobile-float-right  {
        width: calc(100% - 42px);
        float: left;
    }

    .diploma-mat {
        margin-top: 12px;
    }

    #select-mat-row-mobile-margin-left-1, #select-mat-row-mobile-margin-left-2, #double-mat-float-right-2, #window-size-multiple-window-option {
        width: calc(100% - 12px);
        margin-left: 12px;
    }

    #window-size-multiple-window-text {
        width: calc( 100% - 42px);
        float: left;
        overflow-x: hidden;
    }

    #backing-mat-prices-mobile {
        width: auto;
        float: left;
    }

    #select-matboard-window-sizing-mobile {
        margin-bottom: 8px;
        margin-top: 12px;
    }



    #select-mat-row-round-option {
        width: calc(100% - 12px);
        margin-left: 12px;
        margin-top: 16px;
    }

    #matboard-window-size-mobile {
        float: right;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-decoration
        : underline;
        color: #3B3B3B;
        margin: 16px 0 0 0px;
        cursor: pointer;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        /*margin-top: 12px;*/
        color: #3b3b3b;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: 100%;
        overflow: auto;
    }

    /*.mobile-add-row-bottom {*/
    /*    border-bottom: 1px solid #666161;*/
    /*}*/

    .select-window-multiple-window-dimensions {
        width: 42%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .select-mat-row-divider {
        width: 100%;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #3B3B3B;
        margin-bottom: 20px;
        float: left;
        margin-left: 37px
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        display: block;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 36px );
        float: left;
        overflow: auto;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    #select-matboard-unit-mobile {
        width: 200px;
        overflow: auto;
        float: right;
        text-align: right;
        margin-top: 5px;
    }

    .select-mat-container-row {
        width: 100%;
        overflow: auto;
        text-align: center;
        /*float: right;*/
        /*text-align: right;*/
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .req-mat-sample-button-cover {
        width: 100%;
        overflow: auto;
        margin: 0;
    }

    .cart-slider-shopped-item-subheading {
        margin-top: 15px;
    }




    .live-help-contact-box {
        position: fixed;
        width: 85vw;
        overflow: auto;
        background: white;
        right: 5vw;
        bottom: 15vw;
        padding: 2vw;
        line-height: 10vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.5vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .select-mat-section-frame-buttons {
        float: right;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 7.5vw;
        height: 7.5vw;
        position: fixed;
        right: 3vw;
        bottom: 3vw;
        cursor: pointer;
    }


    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
    }

    .color-modal-open-icon {
        width: 7vw;
        margin-left: calc(50% - 3.5vw);
    }

    .artwork-margin-indicator-number-up {
        width: 40px;
        height: 18px;
        position: absolute;
        bottom: 30%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        left: 5px;
        text-align: left;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        top: 10px;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-left {
        text-align: left;
        width: 40px;
        height: 18px;
        position: absolute;
        top: -20px;
        right: 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        text-align: right;
        color: #3B3B3B;
    }


    .req-mat-sample-button {
        font-size: 4vw;
        line-height: 6.5625vw;
        padding: 0 2vw;
        margin: 4vw 5vw;
        text-align: center;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        font-size: 5vw;
        line-height: 6.5625vw;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }

    .select-mat-rendering {
        width: 82%;
        /*position: fixed;*/
        z-index: 50;
        padding-bottom: 32px;
        /*margin-top: 65px;*/
        overflow-x: hidden;
        padding-left: 10%;
        padding-right: 10%;
        background: white;
    }

    .select-mat-rendering-event {
        width: 82%;
        z-index: 50;
        padding-bottom: 32px;
        overflow-x: hidden;
        padding-left: 10%;
        padding-right: 10%;
        background: white;
    }

    .select-matboard-description-container {
        width: 91%;
        overflow: auto;
        margin: 16px 4.3%;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .artwork-dimension-view {
        height: 14vmin;
    }

    .iconSize {
        width: 6vw;
        height: 6vw;
        float: left;
    }

    .artwork-margin-controller-up {
        width: 100%;
        height: 25%;
        text-align: center;
    }

    .artwork-margin-controller-left {
        float: left;
        position: absolute;
        width: 50%;
        left: 0;
    }

    .artwork-margin-controller-right {
        width: 50%;
        float: right;
        position: absolute;
        right: 0;
    }

    .select-mat-window-quantity-row-column-transition {
        width: 14%;
        overflow: auto;
        float: left;
        padding: 37px 0 0 0;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 25%;
        /*margin: 0 calc( (100% - 10vw) / 2);*/
        text-align: center;
        position: absolute;
        bottom: 0;
    }

    .artwork-margin-indicator-up {
        width: 50%;
        font-size: 4.08vw;
        border-left: 0.3vw solid;
        margin-left: 50%;
        bottom: 100%;
        position: absolute;
    }

    .artwork-margin-indicator-down {
        font-size: 4vmin;
        border-left: 0.3vw solid;
        left: 0.15vw;
        position: absolute;
        top: 100%;
        left: 50%;
    }

    .artwork-margin-indicator-left {
        border-top: 0.3vw solid;
        position: absolute;
        right: 100%;
        top: calc(50% - 1.65vw);
    }

    .artwork-margin-indicator-right {
        float: left;
        width: 10vw;
        text-align: center;
        position: absolute;
        left: 100%;
        border-top: 0.3vw solid;
        top: calc(50% - 1.65vw);
    }

    .artwork-margin-move-up {
        margin-left: calc( 50% - 3vw);
        margin-right: calc( 50% - 3vw);
    }

    .artwork-margin-move-right {
        float: right;
        top: -1.5vw
    }

    .artwork-margin-move-down {
        margin: 0 calc(50% - 3vw);
        bottom: 1vw;
    }

    .select-mat-navigation-icon {
        width: 18px;
        overflow: auto;
        float: left;
        margin-right: 11px;
        margin-top: 3px;
    }

    .artwork-margin-move-left {
        float: left;
        top: -1.5vw;
    }

    .select-matboard-modal-subtitle {
        font-size: 4vmin;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-opening-field-form-aid {
        font-size: 4vmin;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 5vmin 10vmin;
    }

    .window-opening-field-exit {
        width: calc(100% - 0.4vmin);
        padding: 2vw 0;
    }

    .window-opening-illustration-image-file {
        width: 80%;
        margin: 0 10%;
        margin-bottom: 1vmin;
    }


    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 6vw;
    }

    .matboard-window-size-indicator {
        font-size: 4vw;
        display: none;
    }

    .select-mat-window-quantity-container .display label span {
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-size: 5vw;
    }

    .product-rendering-subcontrol-button {
        font-size: 1.11vw;
        line-height: 1.74vw;
        /*color: #72696A;*/
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 97%;
        margin: 0 1%;
        padding: 1vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 7.5vw;
        float: left;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: left;
        width: 45vw;
        overflow: auto;
        text-align: left;
        margin-top: 0.5vw;
        margin-left: 2.5vw;
    }

    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .matboard-colour {
        width: 100%;
        height: 100%;
    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 0.5px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
        float: left;
    }

    .select-matboard-window-caption-text {
        width: 230px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        overflow-x: hidden;
        margin-top: 12px;
    }

    #select-matboard-window-captiaon-selected-colour-text-mobile {
        width: 100%;
        overflow: auto;
        float: left;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    #double-mat-mobile-float-right-price-2 {
        width: auto;
        float: left;
        text-align: left;
    }

    #double-mat-mobile-float-right-price {
        width: auto;
        float: left;
        text-align: left;
        margin-top: 12px;
        color: #3b3b3b;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
        margin-top: 12px;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -8vw 0 0 1vw;
    }

    .matboard-selected-colour-container {
        width: 7.5vw;
        height: 7.5vw;
        float: left;
        margin: 2% 1%;
        float: left;
        border: 0.1vw solid #e9e9e9;
        border-radius: 0.5vw;
        cursor: pointer;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
        margin-left: 12px;
    }

    .frame-size-desc-dimensions {
        width: 100%;
        overflow-y: auto;
        float: left;
        /* margin-top: 0.25vw; */
        margin: 2.5vw 0;
    }

    .frame-size-desc-button {
        width: 100%;
        overflow-y: auto;
        float: left;
        /* margin-top: 0.25vw; */
        margin: 2.5vw 20vw;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .select-your-frame-size-modal {
        width: 95%;
        background: #FEF4F1;
        overflow: auto;
        margin: 2.5%;
        border-radius: 1vmin;
    }

    .select-frame-size-leader {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        text-align: center;
        margin: 0;
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: center;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin: 1vw 0;
        font-size: 4vw;
        line-height: 6.5625vw;
    }

    .change-matboard-colour-button {
        font-size: 4vw;
        line-height: 6.5625vw;
        width: 100%;
        display: inline-block;
        overflow: auto;
        text-align: center;
        padding-top: 0.5vw;
        color: #72696A;
        cursor: pointer;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    #choose-double-matting-option {
        margin-bottom: 28px;
        margin-left: 12px;
        width: calc(100% - 12px);
    }

    #backing-mat-pricing-mobile {
        float: right;
        color: #3b3b3b;
    }

    #backing-mat-price-and-colour-container {
        width: calc(100% - 42px);
        float: left;
    }

    #backing-mat-price-and-colour-container-2 {
        width: 100%;
        overflow: auto;
        float: left;
        margin-top: 12px;
    }

    .add-to-bag-button {
        width: 150px;
        padding: 8px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        order: 0;
        flex-grow: 0;
        margin: 18px calc( 50% - 91px);
        background: #3B3B3B;
        border-radius: 30px;
        cursor: pointer;
    }

    .precut-size-header {
        font-size: 16px;
        font-weight: 700;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 10px !important;
        margin-bottom: 15px;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

}
@media screen and (min-width: 769px) and (max-width: 1100px) {

    .matboard-size-frations .window-size-input {
        width: 44%;
        float: left;
        margin-right: 5% !important;

    }

    .matboard-size-frations select {
        float: right;
        padding: 10.5px 14px;
        margin: 0;
        background: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        display: flex;
        align-items: center;
        text-align: center;
    }


    .select-mat-guidance-desc-long{
        width: calc( 100% - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .matboard-size-fractional-input {
        width: 65%;
        float: left;
    }

    .matboard-size-frations div {
        border-radius: 2px;
    }

    .mobile-and-tablet-display-only {
        display: inherit;
    }

    .artwork-margin-indicator-number-right {
        width: 40px;
        position: absolute;
        height: 18px;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .artwork-margin-move-down {
        position: absolute;
        left: calc(50% - 1.5vw);
        bottom: 1.5vw;
    }

    .artwork-margin-move-up {
        width: 3vw;
        height: 3vw;
        left: calc(50% - 1.5vw);
    }

    .switch-to-drawing-tool-container {
        width: 400px;
        margin-left: calc(50% - 200px);
        text-align: center;
        margin-top: 32px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        text-transform: uppercase;
        cursor: pointer;
    }

    .checkout-subtotal-figure {
        width: auto;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 8px;
    }

    .select-mat-row-divider {
        width: calc(100% - 36px);
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .req-mat-sample-button-cover {
        /* Primary/Light Gray */
        width: 445px;
        overflow: auto;
        margin-left: calc( 50% - 232.5px );
        background: #F2F2F2;
        border-radius: 2px;
        /* Inside Auto Layout */
        flex: none;
        order: 3;
        flex-grow: 0;
        padding: 10px;
    }
    .live-help-contact-box {
        position: fixed;
        width: 60vw;
        overflow: auto;
        background: white;
        right: 2vw;
        bottom: 7.5vw;
        padding: 2vw;
        line-height: 5vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.2vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 4vw;
        height: 4vw;
        position: fixed;
        right: 2vw;
        bottom: 2vw;
        cursor: pointer;
    }


    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 24px;
    }

    .color-modal-open-icon {
        width: 3vw;
        margin: 0 calc(50% - 1.5vw);
        cursor: pointer;
    }

    .req-mat-sample-button {
        width: 50%;
        float: left;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .product-rendering-subcontrol-button {
        font-size: 2.0779vw;
        line-height: 3.247vw;
        /*color: #72696A;*/
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 47%;
        margin: 0 1%;
        padding: 1vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    .select-mat-window-quantity-row-column-transition {
        width: 9px;
        overflow: auto;
        float: left;
        padding: 25px 35px 0 35px;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 3vw;
        float: left;
        margin: 0 0.5vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: left;
    }


    .artwork-margin-indicator-number-up {
        width: 40px;
        height: 18px;
        position: absolute;
        bottom: 30%;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-down {
        width: 6vw;
        height: 3vw;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-left {
        width: 40px;
        height: 18px;
        position: absolute;
        top: -20px;
        right: 30px;
        text-align: right;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }

    .select-mat-rendering-event {
        width: 60%;
        z-index: 50;
        background: white;
        padding-bottom: 32px;
        margin-top: 140px;
        overflow-x: hidden;
        padding-left: 20%;
        padding-right: 20%;
    }


    .select-mat-rendering {
        width: 60%;
        z-index: 50;
        background: white;
        padding-bottom: 32px;
        margin-top: 140px;
        overflow-x: hidden;
        padding-left: 20%;
        padding-right: 20%;
    }

    .select-matboard-window {
        box-shadow: inset 0 0.05rem 0.05rem rgba(10,10,10,.8);
        float: left;
    }

    .select-matboard-description-container {
        width: 480px;
        overflow: auto;
        float: left;
        margin-left: calc( (100% - 480px) / 2 );
        margin-top: 16px;
    }

    .select-mat-window-quantity-container .display label span {
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .multiple-window-artwork-size-selection-panel-title {
        width: 100%;
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
        font-size: 2.597vw;
        line-height: 3.117vw;
        padding: 2vw 0;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field input {
        padding-top: 2px !important;
    }

    .window-opening-field label + .MuiInput-formControl-24 {
        margin-top: 0px !important;
    }

    .artwork-dimension-view {
        height: 7vmin;
    }

    .iconSize {
        width: 3vw;
        height: 3vw;
    }

    .artwork-margin-controller {
        font-size: 2.08vw;
    }

    .artwork-margin-controller-up, .artwork-margin-controller-down {;
        font-size: 2.08vw;
    }

    .artwork-margin-controller-up {
        width: 1.5vw;
        height: 1.5vw;
        text-align: center;
        position: absolute;
        left: calc( 50% - 0.75vw );
    }

    .artwork-margin-controller-left {
        width: 3vw;
        height: 3vw;
        top: calc(50% - 1.5vw);
        position: absolute;
    }

    .artwork-margin-controller-right {
        width: 3vw;
        height: 3vw;
        float: right;
        font-size: 2.08vw;
        position: absolute;
        right: 0;
        top: calc(50% - 1.5vw);
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 3vw;
        /* margin: 0 calc( (100% - 6vw) / 2); */
        text-align: center;
        /* margin-top: 1vw; */
        position: absolute;
        bottom: -1vw;
    }

    .artwork-margin-indicator-up {
        font-weight: 500;
        position: absolute;
        float: left;
        border-left: solid 0.2vw;
        left: 50%;
        bottom: 100%;
    }

    .artwork-margin-indicator-down {
        /* font-size: 2.08vmin; */
        margin-top: 2.1vw;
        /* margin-left: -3vw; */
        border-right: solid 0.2vw;
        font-weight: 500;
        position: absolute;
        left: 50%;
        bottom: 1vw;
    }

    .artwork-margin-indicator-left {
        float: left;
        width: 6vw;
        padding: 0.3vw 0;
        border-top: solid 0.2vw;
        position: absolute;
        right: 100%;
        top: 50%;
    }

    .artwork-margin-indicator-right {
        float: right;
        padding: 0.3vw 0;
        border-top: solid 0.2vw;
        text-align: center;
        position: absolute;
        left: 100%;
        top: calc(50%);
    }

    .artwork-margin-move-right {
        float: left;
        margin: 0;
    }

    .artwork-margin-move-left {
        float: right;
        margin: 0;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        position: absolute;
        left: 5px;
        text-align: left;
        top: 30%;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: 22.5%;
        height: 50%;
        position: absolute;
    }

    .select-matboard-modal-subtitle {
        font-size: 2vmin;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-opening-field-form-aid {
        font-size: 2vmin;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 1vmin 10vmin;
    }

    .window-opening-field-exit {
        width: 10vw;
        padding: 1vw;
    }

    .window-opening-illustration-image-file {
        width: 50%;
        margin-left: 25%;
        margin-bottom: 1vmin;
    }


    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 3vw;
    }

    .matboard-window-size-indicator {
        font-size: 2vw;
    }


    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .matboard-colour {
        width: 100%;
        height: 100%;
    }

    .select-matboard-description-container .matboard-selected-colour {
        float: right;


    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 1px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
    }

    .select-matboard-window-caption-text {
        width: 250px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -5.5vw 0 0 2.5vw;
    }

    .matboard-selected-colour-container {
        width: 30px;
        height: 30px;
        float: left;
        margin: 6px;
        border-radius: 1px;
        cursor: pointer;
        border: 0.5px solid #666161;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
    }

    .frame-size-desc-dimensions {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-top: 0.25vw;
    }

    .frame-size-desc-button {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-left: 0.5vw;
        text-decoration: underline;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .matboard-size-selection-demo-photo {
        width: 80%;
        margin-left: 10%;
    }

    .select-your-frame-size-modal {
        width: 100vmin;
        background: #FEF4F1;
        overflow: auto;
        margin: 1.5vmin calc(50% - 51.5vmin);
        border-radius: 1vmin;
    }

    .select-frame-size-leader {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        width: 100%;
        text-align: center;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: center;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin-top: 0.75vw;
    }

    .change-matboard-colour-button {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        width: 100%;
        overflow: auto;
        text-align: center;
        padding-top: 1vw;
        color: #72696A;
        cursor: pointer;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

    .add-to-bag-button {
        width: 150px !important;
        overflow: auto;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 8px 16px;
        float: left;
        cursor: pointer;
    }

    .select-mat-container-row {
        width: 200px;
        float: right;
        text-align: right;
        line-height: 150%;
        font-size: 16px;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        float: left;
    }


    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        float: left;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 4px;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: calc(100% - 36px);
        overflow: auto;
        margin-left: 36px;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 72px - 55px );
        float: left;
        overflow: auto;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: right;
        /* Body 2 (Underlined Body) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        /* identical to box height, or 24px */

        -webkit-text-decoration-line: underline;

                text-decoration-line: underline;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .select-window-multiple-window-dimensions {
        width: 181px;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        margin-top: 5px;
        color: #3b3b3b;
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        /* Secondary/Dark Gray */

        color: #666161;
    }

    .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        display: block;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #3B3B3B;
        margin: 10px 0 0 0;
        cursor: pointer;
    }

    .MuiIconButton-root-123 {
        padding: 0.5vw 1vw !important;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3B3B3B;
        border: solid 1px #666161;
        border-radius: 2px;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    #choose-double-matting-option {
        margin-bottom: 28px;
    }

    .select-mat-navigation-icon {
        float: left;
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .select-mat-navigation-script {
        /* Body */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }


}
@media screen and (min-width: 1100px) {

    .select-mat-input-field-direction-long a {
        color: #666161;
        text-decoration: underline;
    }

    .select-mat-input-field-direction-long a:hover {
        color: #666161;
        text-decoration: underline;
    }

    .second-mat-price {
        width: auto;
        margin-left: 7px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    #select-mat-row-mobile-margin-left-2 {
        cursor: pointer;
    }

    .mobile-and-tablet-display-only {
        display: none;
    }

    .checkout-subtotal-figure {
        width: auto;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 8px;
    }



    .MuiIconButton-root-123 {
        padding: 0.5vw 1vw !important;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3B3B3B;
        border: solid 1px #666161;
        border-radius: 2px;
    }


    .matboard-size-selection-demo-photo {
        width: 50%;
        margin-left: 25%;
    }

    .req-mat-sample-button {
        font-size: 1.11vw;
        line-height: 1.32vw;
        padding: 0 1vw;
        float: left;
    }

    .live-help-contact-box {
        position: fixed;
        width: 30vw;
        overflow: auto;
        background: white;
        right: 2vw;
        bottom: 5vw;
        padding: 2vw;
        line-height: 2.5vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.1vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 2vw;
        height: 2vw;
        position: fixed;
        right: 2vw;
        bottom: 2vw;
        cursor: pointer;
    }

    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
    }

    .color-modal-open-icon {
        width: 1.5vw;
        margin: 0 calc(50% - 0.75vw);
        cursor: pointer;
    }

    .select-mat-window-quantity-row-column-transition {
        /*width: 9px;*/
        /*overflow: auto;*/
        /*float: left;*/
        /*padding: 25px 35px 0 35px;*/
        /*text-align: center;*/
        /*overflow: hidden;*/
        /*color: #3B3B3B;*/
        width: 20%;
        overflow: auto;
        float: left;
        padding: 25px 0;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 1vmin 10vmin;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        font-size: 1.11vw;
        line-height: 1.74vw;
        overflow: auto;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }



    #diploma-mat-container {
        margin-top: 150px;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: 25%;
        height: 50%;
    }

    .select-matboard-description-container {
        width: 45%;
        margin-right: 5%;
        overflow: auto;
        float: right;
        padding-bottom: 36px;
    }

    .add-to-bag-button {
        width: 150px;
        overflow: auto;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 8px 16px;
        float: left;
        cursor: pointer;
    }

    .select-mat-row-divider {
        width: calc(99% - 36px);
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-matboard-window-caption-text {
        width: 250px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
        margin-top: 4px;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .diploma-mat {
        margin-top: 12px;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 4px;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-window-multiple-window-dimensions {
        /*width: 181px;*/
        width: 40%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box-line-breaker {
        width: 10%;
        overflow: auto;
        float: left;
        text-align: center;
        padding-top: 40px;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 72px - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }



    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #3B3B3B;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

    .select-mat-container-row {
        width: 200px;
        float: right;
        text-align: right;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        width: 40%;
        float: left;
        margin: 0 5%;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .window-opening-field input {
        padding-top: 2px !important;
    }

    .window-opening-field label + .MuiInput-formControl-24 {
        margin-top: 0px !important;
    }

    .artwork-dimension-view {
        height: 7vmin;
    }

    .iconSize {
        width: 1.5vw;
        height: 1.5vw;
    }

    .artwork-margin-controller-up {
        width: 1.5vw;
        height: 25%;
        margin: 0 calc( (100% - 1.5vw) / 2);
        text-align: center;
    }

    .artwork-margin-controller-left {
        width: 50%;
        height: 100%;
        float: left;
        position: absolute;
    }

    .artwork-margin-controller-right {
        width: 50%;
        height: 100%;
        float: right;
        position: absolute;
        right: 0;
    }

    .artwork-margin-move-down {
        position: absolute;
        bottom: 0;
        left: calc(50% - 0.75vw);
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 25%;
        text-align: center;
        position: absolute;
        bottom: 0;
    }

    .artwork-margin-indicator-up {
        font-size: 1.5vmin;
        float: left;
        margin-top: -1vw;
        width: 1.5vw;
        margin-left: 0.75vw;
        border-left: 0.1vw solid;
        position: absolute;
        bottom: 100%;
    }

    .artwork-margin-indicator-left {
        width: 1.5vw;
        font-size: 1.5vmin;
        padding: 0.3vw 0;
        border-top: 0.1vw solid;
        position: absolute;
        right: 100%;
        top: 50%;
    }

    .artwork-margin-indicator-right {
        float: right;
        font-size: 1.5vmin;
        padding: 0.3vw 0;
        border-top: 0.1vw solid;
        text-align: center;
        left: 100%;
        top: 50%;
    }

    .artwork-margin-move-right {
        position: absolute;
        float: right;
        margin: 0;
        top: calc(50% - 0.75vw);
        right: 0;
    }

    .artwork-margin-move-left {
        float: left;
        margin: 0;
        top: calc(50% - 0.75vw);
        position: absolute;
    }

    .artwork-margin-indicator-down {
        font-size: 1.5vmin;
        width: 1.5vw;
        border-left: 0.1vw solid;
        position: absolute;
        top: 100%;
        left: 50%;
    }

    .select-matboard-modal-subtitle {
        padding: 0 10vmin 5vmin 10vmin;
        line-height: 2;
        font-size: 2vmin;
    }

    .window-opening-field-form-aid {
        font-size: 2vmin
    }

    .window-opening-field-exit {
        width: 10vw;
        padding: 1vw;
    }

    .window-opening-illustration-image-file {
        width: 50%;
        margin-left: 25%;
        margin-bottom: 1vmin;
    }

    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 1.5vw;
    }

    .matboard-window-size-indicator {
        font-size: 0.8vw;
    }

    .artwork-margin-indicator-number-up {
        width: 30px;
        height: 18px;
        bottom: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        position: absolute;
        left: 5px;
        text-align: left;
    }

    .switch-to-drawing-tool-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 16px 0px;
        width: 400px;
        text-align: center;
        margin-left: calc( 50% - 200px);
        text-transform: uppercase;
        cursor: pointer;
    }

    .req-mat-sample-button-cover {
        /* Primary/Light Gray */
        width: 445px;
        overflow: auto;
        margin-left: calc( 50% - 232.5px );
        background: #F2F2F2;
        border-radius: 2px;
        /* Inside Auto Layout */
        flex: none;
        order: 3;
        flex-grow: 0;
        padding: 10px;

    }

    .select-mat-navigation-icon {
        float: left;
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .select-mat-navigation-script {
        /* Body */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        position: absolute;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        top: 30%;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-top {
        text-align: center;
        width: 30px;
        height: 18px;
        background: white;
    }

    .artwork-margin-indicator-number-left {
        position: absolute;
        width: 40px;
        height: 18px;
        top: -20px;
        right: 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        text-align: right;
        color: #3B3B3B;
    }

    .artwork-margin-indicator-number-right {
        width: 40px;
        height: 18px;
        position: absolute;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        align-items: center;
        color: #3B3B3B;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
    }

    .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        display: block;
    }

    .multiple-window-artwork-size-selection-panel-title {
        font-size: 1.11vw;
        line-height: 1.74vw;
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
    }

    .product-rendering-subcontrol-button {
        font-size: 1.11vw;
        line-height: 3.247vw;
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 45.5%;
        padding: 0.25vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    #product-rendering-subcontrol-button-left {
        margin: 0 3% 0 0;
    }

    #product-rendering-subcontrol-button-right {
        margin: 0 0 0 3%;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 1.5vw;
        float: right;
        margin: 0.25vw 0.5vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: right;
        padding: 0.25vw 0;
    }

    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .matboard-colour {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .select-matboard-description-container .matboard-selected-colour {
        float: right;
    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 1px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
        margin-top: 4px;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        float: left;
        margin-top: 5px;
        color: #3b3b3b;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -2.5vw 0.5vw;
    }

    .matboard-selected-colour-container {
        width: 30px;
        height: 30px;
        float: left;
        margin: 6px;
        border-radius: 1px;
        cursor: pointer;
        border: 0.5px solid #666161;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
        margin: 6px 0;
        min-height: 36px;
    }



    .frame-size-desc-dimensions {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-top: 0.25vw;
    }

    .frame-size-desc-button {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-left: 0.5vw;
        text-decoration: underline;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #3B3B3B;
        margin: 10px 0 0 0;
        cursor: pointer;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
        padding: 0;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .select-your-frame-size-modal {
        width: 90vw;
        background: #FEF4F1;
        overflow: auto;
        border-radius: 1vmin;
        margin: 1vw 4vw;
    }

    .select-frame-size-leader {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        text-align: left;
        color: #72696A;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: left;
        font-size: 1.11vw;
        line-height: 1.74vw;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin-top: 0.75vw;
    }

    .change-matboard-colour-button {
        font-size: 1.11vw;
        line-height: 1.74vw;
        width: 100%;
        overflow: auto;
        text-align: center;
        color: #72696A;
        cursor: pointer;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: calc(100% - 36px);
        overflow: auto;
        margin-left: 36px;
    }

    #choose-double-matting-option {
        margin-bottom: 12px;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    .select-mat-container-narrow {
        min-height: 95vh;
        margin-top: 170px;
    }

    .matboard-size-frations .window-size-input {
        width: 44%;
        float: left;
        margin-right: 5%;

    }

    .matboard-size-frations select {
        float: right;
        padding: 10.5px 14px;
        margin: 0;
        background: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        display: flex;
        align-items: center;
        text-align: center;
    }

    .matboard-size-fractional-input {
        width: 65%;
        float: left;
    }

    .matboard-size-frations div {
        border-radius: 2px;
    }





}
.frame-category-image:hover {
    border-color: #F59276;
}

.frame-category-selected {
    border-color: #F59276;
}

.select-frame-product-preview {
    width: 80vw;
    overflow-y: auto;
    margin: 0 10vw 0 9vw;
}

.frame-product-preview-photo {
    width: 80%;
    margin: 0 10%;
}

.show-all-frames-button:hover {
    -webkit-text-decoration-color: #F59276;
            text-decoration-color: #F59276;
    text-decoration: underline;
}

.frame-product-description a {
    text-decoration: none;
}


.product-description-title {
    font-size: 0.8333vw;
    text-transform: uppercase;
    font-weight: 600;
    color: #F59276;
}

#frame-size-selector {
    width: 100%;
    overflow: hidden;
}

#frame-design-button {
    cursor: pointer;
}

.frame-size-desc-title  {
    width: auto;
    overflow: auto;
    color: #72696A;
    text-transform: uppercase;
    font-weight: 600;
}

.select-frame-title-container {
    width: 100%;
    color: #72696A;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .tablet-display-only {
        display: none;
    }

    .select-frame-title-container {
        margin: 10vw 0 7.5vw 0;
        font-size: 6.25vw;
        line-height: 7.8125vw;
    }

    .frame-category-image {
        width: 14vw;
        height: 14vw;
        margin: 0 1vw;
        padding: 1vw;
        border: 1vw solid white;
        cursor: pointer;
    }

    .frame-product-container {
        width: 80vw;
        overflow-y: auto;
        float: left;
        margin: 5.625vw 0;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 5vw;
        line-height: 7.8125vw;
    }



    .product-description, .product-listing-price {
        margin-top: 7.5vw;
        margin-bottom: 5.78125vw;
    }


    .frame-category-container {
        width: 20vw;
        height: 30vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }


    .select-frame-category-selector-container {
        width: 60vw;
        overflow-y: auto;
        margin: 0 calc( (100% - 60vw) / 2);
    }

    .show-all-frames-button {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        height: 2.22vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 100%;
        float: left;
        margin-top: 15vw;
        margin-bottom: 5vw;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 86vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(100% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 2.5vw 0 0 0;
        font-size: 4vw;
        line-height: 6.5625vw;
        letter-spacing: 0.04652777vw;
        text-align: center;
    }

    .frame-size-desc-subtitle {
        font-size: 5vw;
        color: #72696A;
        margin: 2.5vw 0 0 0;
        font-weight: 400;
        line-height: 6.5625vw;
        text-align: center;
    }

    .frame-size-desc-price {
        color: #F59276;
        font-size: 5vw;
        line-height: 7.8125vw;
        font-weight: 600;
        text-align: center;
        margin-top: 2.5vw;
        margin-bottom: 0;
    }

    .frame-size-desc-prod-summary {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        font-weight: 400;
        margin: 0.9722222vw 0;
    }

    .frame-size-desc-prod-summary-title {
        font-size: 5vw;
        line-height: 7.8125vw;
        color: #72696A;
        font-weight: 400;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
        margin-top: 3vw;
        font-size: 5vw;
        line-height: 7.8125vw;
    }

    .frame-size-desc-price-subscript {
        font-size: 5vw;
        color: #72696A;
        margin: 2.5vw 0 5vw 0;
        font-weight: 600;
        line-height: 6.5625vw;
        text-align: center;
    }

    #frame-size-selector label {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 7.5vw;
        line-height: 9.0625vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 2.5vw 0 5vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        width: 100%;
        overflow: auto;
        margin: 1.63vw 0 0 0;
        text-align: center;
        display: inline;
        font-size: 5vw;
        line-height: 7.8125vw;
        padding: 3.28125vw 0;
        color: white;
        background: #F59276;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 15vw;
        height: 15vw;
        margin: 5vw 5vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }

    #frame-size-desc-prod-summary-mobile-display-only {
        margin: 5vw;
    }



}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {
    .select-frame-title-container {
        font-size: 2.597vw;
        line-height: 3.117vw;
        margin: 2.7777vw 0;
    }

    .frame-category-image {
        width: 6.5vw;
        height: 6.5vw;
        margin: 0.25vw;
        padding: 0.25vw;
        border: 0.25vw solid white;
        cursor: pointer;
    }

    .frame-category-container {
        width: 8vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 2.0779vw;
    }

    .select-frame-category-selector-container {
        width: 48vw;
        height: 12vw;
        margin: 0 calc( (100% - 48vw) / 2);
    }

    .show-all-frames-button {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        width: 30vw;
        margin: 0 calc((100% - 30vw) / 2);
        height: 2.22vw;
        text-decoration: underline;
    }

    .frame-product-container {
        width: 40vw;
        overflow-y: auto;
        float: left;
        margin-bottom: 5.45vw;
    }


    .product-description, .product-listing-price {
        margin-bottom: 1.67vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 80%;
        float: left;
        margin: 5% 10%;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 83.5vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(100% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 0;
        font-size: 2.597vw;
        line-height: 3.117vw;
        letter-spacing: 0.03vw;
        text-align: center;
    }

    .frame-size-desc-subtitle {
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .frame-size-desc-price {
        color: #F59276;
        text-align: center;
        font-weight: 400;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        margin: 0 0 2.403vw 0;
    }

    .frame-size-desc-prod-summary {
        line-height: 3.247vw;
        color: #72696A;
        /* text-align: center; */
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }

    .frame-size-desc-prod-summary-title {
        line-height: 3.247vw;
        color: #72696A;
        margin: 2vw 0 1vw 0;
        font-size: 2.0779vw;
        font-weight: 500;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
    }

    #frame-size-selector label {
        font-family: Montserrat;
        font-weight: 400;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 2vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        margin: 1.63vw 0 0 0;
        text-align: center;
        display: inline;
        width: 100%;
        color: white;
        background-color: #F59276;
        padding: 1.883vw 0;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 6vw;
        height: 6vw;
        margin: 3vw 3vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }

    .tablet-display-only {
        display: inline;
    }

    .tablet-no-display {
        display: none;
    }

    .frame-size-desc-price-subscript {
        line-height: 3.247vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 1vw 0;
        font-weight: 400;
        font-size: 2.0779vw;
    }



}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .tablet-display-only {
        display: none;
    }

    .select-frame-title-container {
        font-size: 1.388vw;
        line-height: 1.6666vw;
        margin: 3.9583vw 0 1.5vw 0;
    }

    .frame-category-image {
        width: 2.5vw;
        height: 2.5vw;
        margin: 0 1.5vw;
        padding: 0.15vw;
        border: 0.1vw solid white;
        cursor: pointer;
    }

    .frame-category-container {
        width: 6vw;
        overflow: auto;
        float: left;
        font-size: 1.11vw;
    }

    .frame-category-desc {
        text-align: center;
        color: #72696A;
        font-size: 1.11vw;
    }

    .select-frame-category-selector-container {
        width: 36vw;
        height: 5vw;
        margin: 0 calc((100% - 36vw) / 2) 0 calc((100% - 36vw) / 2);
    }

    .show-all-frames-button {
        font-size: 1.11vw;
        line-height: 2.569vw;
        color: #72696A;
        text-align: center;
        cursor: pointer;
        width: 15vw;
        margin: 0 calc((100% - 15vw) / 2);
        height: 2.22vw;
    }

    .frame-product-container {
        width: 20vw;
        overflow-y: auto;
        float: left;
        margin-bottom: 5.45vw;
    }

    .product-description, .product-listing-price {
        margin-bottom: 1.67vw;
    }

    .frame-size-selection {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 80;
        top: 0;
    }

    .frame-size-image-container {
        width: 60%;
        float: left;
    }

    .image-gallery-thumbnails-wrapper {
        margin-top: 1vw;
    }

    .frame-size-selector-container {
        width: 86vw;
        /* height: 90vh; */
        overflow-y: auto;
        margin: 1.67vw 1.81vw;
        background: white;
        padding: 5vw;
    }

    .frame-size-desc-container {
        float: left;
        width: calc(40% - (3.47222vw * 2));
        margin: 0 3.47222vw;
    }

    .frame-size-desc-title {
        margin: 0;
        font-size: 1.33vw;
        line-height: 1.67vw;
        letter-spacing: 0.04652777vw;
    }

    .frame-size-desc-subtitle {
        width: 100%;
        overflow: auto;
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .frame-size-desc-price {
        color: #F59276;
        font-weight: 400;
        width: 100%;
        font-size: 1.11vw;
        line-height: 1.74vw;
        text-align: left;

    }

    .frame-size-desc-prod-summary {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
        margin: 1vw 0;
    }

    .frame-size-desc-prod-summary-title {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    .frame-size-desc-price-subscript {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        font-weight: 400;
    }

    #frame-size-selector p, #frame-size-selector div {
        font-family: Montserrat;
        font-weight: 400;
        color: #72696A;
    }

    #frame-size-selector label {
        color: #72696A;
        font-size: 1.11vw;
        line-height: 1.736111;
        font-weight: 400;
        letter-spacing: 0.04652777vw;
        font-family: "Montserrat";
    }

    .frame-size-desc-prod-summary-text li {
        margin-bottom: 0.5vw;
    }

    #frame-size-selector {
        margin: 0.9722222vw 0;
    }

    #frame-design-go-back-button {
        float: left;
        width: 10vw;
        margin: 1.63vw 0 1.63vw 0;
        text-align: center;
    }

    #frame-design-button {
        float: right;
        width: 100%;
        margin: 1.63vw 0 0 0;
        text-align: center;
        background-color: #F59276;
        padding-top: 1.01vw;
        padding-bottom: 1.01vw;
        color: white;
    }

    .navbar-top-button:hover, .navbar-top-button-inner-layer:hover {
        color: white;
        background-color: #F59276;
    }

    .modal-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 4vw;
        height: 4vw;
        margin: 4vw 4vw 0 0;
        cursor: pointer;
    }

    .modal-close-button:hover {
        color: #F59276;
    }



}


/*For all*/


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .design-your-mat-box-video-container {
        width: 100%;
        height: 55vw;
    }

    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: hidden;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        text-align: right;
        cursor: pointer;
        width: 98%;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 4vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
    }

    .image-crop-section {
        width: 100%;
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 0;
        padding: 0;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .matboard-size-selection-demo-photo {
        width: 140%;
        margin-left: -20%;
    }

    .image-crop-action-tools {
        width: 110%;
        overflow: auto;
        margin-left: -5%;
        margin-bottom: 0.5vw;
        margin-top: 5%;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        text-decoration: underline;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin: 5vw 0 5vw 0;
        padding: 4vw 0;
        font-size: 5vw;
        line-height: 6.5625vw;
        text-align: center;
        width: 100%;
        background: #F59276;
        border-radius: 0.5vw;
        color: white;
        text-decoration: none;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        text-align: center;
        font-size: 4vmin;
        color: #72696A;
        margin: 5vw 0;
        font-weight: 400;
        line-height: 6.5625vw;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

}


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: scroll;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        width: 98%;
        text-align: right;
        cursor: pointer;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 1vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .image-crop-section {
        width: calc(100% - 0.4vmin);
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 0;
        padding: 0;
        background: white;
        /* border-radius: 1vmin; */
        border: solid #F59276 0.2vmin;
    }

    .image-crop-action-tools {
        width: 110%;
        overflow: auto;
        margin-left: -5%;
        margin-bottom: 0.5vw;
        margin-top: 5%;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        padding: 1vw 3vw;
        float: right;
        background: #FEF4F1;
        border-radius: 0.5vw;
        margin: 2vw;
        font-size: 2.0779vw;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        line-height: 3.247vw;
        color: #72696A;
        font-size: 2.0779vw;
        margin: 3vw 0;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

    .design-your-mat-box-video-container {
        width: 95%;
        height: 50vw;
        margin: 2.5% 2.5% 0 2.5%;
        float: left;
    }

    .design-your-mat-box-control-panel {
        width: 95%;
        overflow: auto;
        margin: 0 2.5% 0 2.5%;
        float: left;
    }

}

@media screen and (min-width: 1100px) {

    .design-your-mat-box-video-container {
        width: 55vw;
        height: 31vw;
        margin: 2vw 0 0 0;
        float: left;
    }

    .design-your-mat-box-control-panel {
        width: calc(100% - 58vw);
        float: left;
        overflow: auto;
        margin: 2vw 1vw 2vw 2vw;
    }

    .photo-cropping-tool-container {
        position: fixed;
        width: 100%;
        /* height: 100%; */
        overflow: scroll;
        background: rgba(0,0,0,0.5);
        /* position: absolute; */
        z-index: 80;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /* overflow: hidden; */
    }

    .photo-cropping-tool-cover {
        width: 90vmin;
        background: #FEF4F1;
        overflow: auto;
        /* margin-left: calc(50% - 45vmin); */
        /* margin-right: 10vmin; */
        margin: 1vmin calc(50% - 45vmin);
        border-radius: 1vmin;
    }

    .close-add-image-modal-button-container {
        float: right
    }

    .close-add-image-modal-button {
        top: 0;
        right: 0;
        background: white;
        float: right;
        width: 98%;
        text-align: right;
        cursor: pointer;
        margin-top: 15px;
    }


    .photo-cropping-tool-cover-topic-header {
        width: auto;
        overflow: auto;
        background: white;
        padding: 2vmin 3vmin;
        text-align: center;
        font-weight: 500;
        border-bottom: 1vmin;
        border-color: #F59276;
        border-bottom: solid 0.1vmin #F59276;
        font-size: 1.11vw;
        line-height: 1.74vw;
    }

    .image-crop-section {
        width: calc(92% - 0.4vmin);
        height: calc(70% - 0.4vmin);
        overflow: hidden;
        margin: 2.5%;
        padding: 1.5%;
        background: white;
        border-radius: 1vmin;
        border: solid #F59276 0.2vmin;
    }

    .image-crop-action-tools {
        width: 100%;
        overflow: auto;
        margin-bottom: 0.5vw;
    }

    .ReactCrop {
        /*width: 100%;*/
        /*height: 100%;*/
    }

    .ReactCrop__image {
        /*max-width: none !important;*/
        /*width: 100%;*/
        background: white;
    }

    .crop-icon-container {
        width: auto;
        overflow: auto;
        cursor: pointer;
        float: left;
        color: #F59276;
        /* background: #F59276; */
        padding: 0.55vw;
        /* border: #F59276 0.1vw solid; */
        border-radius: 0.3vw;
    }

    .crop-icon-container label {
        width: auto;
        overflow: auto;
        cursor: pointer;
    }

    .crop-icon-container-text, .crop-icon {
        float: left;
    }

    .crop-icon-container-text {
        padding: 0.25vw 0 0 1.01vw;
        font-size: 1.11vw;
        line-height: 1.597vw;
    }

    #rotate-and-crop-icon {

    }

    #crop-image-icon {

    }

    #confirm-cropping-icon {
        padding: 0.5vw 2vw;
        float: right;
        background: #FEF4F1;
        border-radius: 0.5vw;
        margin-right: 0.5vw;
        font-size: 1.11vw;
        margin-top: 0.25vw;
    }

    #confirm-cropping-icon:hover {
        background: #F59276;
        color: white;
    }

    .image-crop-action-tools button {
        min-width: 3.5vw;
    }

    .upload-artwork-topic-header {
        font-size: 1.11vw;
        line-height: 3vw;
        color: #72696A;
        font-weight: 400;
        text-align: center;
    }

    .image-rotation-clicked {
        width: 80%;
        margin-top: 15%;
        margin-bottom: 15%;
    }

}

/*For all*/

@media screen and (min-width: 0px) {

    .lazyload-wrapper {
        overflow: hidden;
    }

    .select-box-outside {
        width: 24px;
        height: 24px;
        left: 12px;
        top: 12px;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        border-radius: 2px;
        padding: 0 !important;
    }

    .box-selected {
        width: 16px;
        height: 16px;
        background: #3b3b3b;
        border-radius: 2px;
        margin: 4px;
    }

    .box-unselected {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin: 4px;
    }

    /*a:hover {*/
    /*    text-decoration: none !important;*/
    /*}*/

    .MuiInput-underline:before {
        border-bottom: none !important;
    }


    .cart-slider-item-container {
        width: 100%;
        overflow: auto;
    }

    .cart-subtotal-strikethrough, .cart-subtotal-strikethrough-large {
        text-decoration: line-through;
        opacity: 0.25;
    }



    .cart-slider-shopped-item-quantity span {
        margin: 0 0.5vw;
        cursor: pointer;
    }

    .navbar-position-fixed {
        position: fixed;
    }



    .cart-slider-shopped-item-matboard-colour {
        width: 18px;
        height: 18px;
        margin: -4px 6px;
    }

    .shipping-summary-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        width: 50%;
        overflow: auto;
        float: left;
    }

    .shipping-summary-header-total {
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .shipping-summary-amount {
        width: 50%;
        overflow: auto;
        float: right;
        text-align: right;
        font-size: 16px;
        line-height: 150%;
        font-style: normal;
        font-weight: bold;
    }

    .shipping-summary-header-total {
        width: 50%;
        overflow: auto;
        float: left;
        text-align: left;
    }

    .shipping-summary-amount-total {
        width: 50%;
        overflow: auto;
        float: left;
        text-align: right;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .shipping-summary-add-margin {

    }

    .shipping-summary-add-bottom-line {
        border-bottom: 1px solid #F2F2F4;
        padding-bottom: 0.5vw;
        margin-bottom: 0.5vw;
    }

}




@media screen and (min-width: 0px) and (max-width: 769px) {

    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: 25%;
        border: solid 1px #666161;
        border-radius: 2px;
        margin: 10px 20px;
    }



    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 0 auto 24px auto;
        color: white;
        width: auto;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-delete {
        cursor: pointer;
        width: 20px;
        overflow-y: auto;
        float: right;
        margin-left: 34px;
        margin-top: 20px;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -35px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .custom-mat-design-diagram {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        overflow: auto;
        background: #F2F2F2;
        border-radius: 2px;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

    }

    .discount-info-desc {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #78A891;
    }

    .cart-slider-item-container {
        width: 100%;
        overflow: auto;
        border-top: solid 1px;
        margin-top: 24px;
        padding-top: 24px;
        border-bottom: solid 1px;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 24px 16px 24px;
    }

    .cart-slider-ending {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        margin-top: 24px;
        padding-bottom: 300px;
    }

    .shopping-cart-subtotal-container {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-align: center;
    }

    .cart-slider-ending-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 16px;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
        padding-bottom: 20px;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }

    .cart-slider-container {
        width: 100%;
        z-index: 100;
        background: rgba(0,0,0,0.5);
        position: fixed;
        top: 90px;
        padding-bottom: 200px;
    }

    .cart-slider-content {
        width: 92%;
        height: 100vh;
        overflow-y: auto;
        /*position: absolute;*/
        right: 0;
        background: white;
        padding: 0 4%;
        z-index: 100;
        margin-top: -5px;
    }

    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
    }

    #checkout-cart-slider {
        float: right;
        margin: 0vw 5vw 0 0;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 20px;
        width: 28px;
        height: 28px;
    }

    .cart-slider-second-mat-item-details {
        width: 70%;
        overflow-y: auto;
        float: left;
    }

    .cart-slider-second-mat-item-price {
        width: 30%;
        overflow-y: auto;
        float: left;
    }

    .cart-slider-free-shipping-banner {
        width: 100%;
        float: left;
        margin: 24px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        background: #FAFCFA;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
        margin-right: 16px;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
    }

    .navbar-column-title-checkout-section {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }


    .cart-slider-shopped-item-right {
        width: 60px;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-total {
        width: 100%;
        overflow: auto;
        text-align: right;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
        margin: 0 calc( 50% - 59px );
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-quantity {
        width: 60%;
        overflow: auto;
        float: left;
    }

    .cart-slider-shopped-item-quantity-add-padding {
        margin: 0 5% !important;
    }

    .cart-slider-second-mat-item {
        padding-bottom: 2.5%;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        overflow: auto;
        text-align: center;
    }

    .item-total-coupon-section {
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 50%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 100%;
        text-align: center;
        padding-top: 5vw;
        padding-bottom: 5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        line-height: 20px;
        font-size: 16px;
        margin: 0;

    }

    .customer-satisfaction-policy-banner {
        font-weight: 400;
        line-height: 1.5;
        padding: 0.5vw;
        width: 98%;
        overflow: auto;
        text-align: left;
        margin-left: 1%;
        font-size: 3.75vw;
    }

    .discount-tier-info {
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        margin-top: 10px
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        margin-left: calc(50% - 59px);
        width: 86px;
        padding: 8px 16px;
        text-align: center;
        float: left;
        margin-bottom: 16px;
    }

    .cart-slider-shopped-item-center-center {
        width: calc(100% - 60px);
        overflow: auto;
        float: left;
    }

    .cart-slider-second-mat-container {
        width: calc( 100% - 64px);
        overflow: auto;
        margin-left: 64px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 45px;
        margin: 3px auto 0;
    }

    .cart-slider-shopped-item-quantity-button {
        width: auto;
        overflow: auto;
        float: left;
        font-size: 26px;
        margin-top: 17.5px;
        cursor: pointer;
    }

    .cart-slider-item-controller {
        width: calc( 100% - 64px);
        margin-left: 64px;
        overflow: auto;
    }

    /*.cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {*/
    /*    width: 137px;*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    margin: 0;*/
    /*}*/

    .design-guide-colour-container {
        width: 90%;
        overflow: auto;
        padding: 40px 5%;
        margin: 0 auto;
    }

    .design-guide-section-containe-type-2 {
        width: 70%;
        text-align: center;
        overflow: auto;
        position: absolute;
        background: white;
        z-index: 100;
        padding: 0 0 20px 0;
        margin: 0 15%;
    }

    .design-guide-section-button-type-2 {
        width: auto;
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
    }


}
@media screen and (min-width: 769px) and (max-width: 1100px) {

    .select-window-multiple-window-dimensions div div, .select-mat-product-order-quantity div div {
        width: 100%;
    }

    .design-guide-colour-container {
        width: 642px;
        overflow: auto;
        padding: 40px 0;
        margin: 0 auto;
    }

    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 24px auto;
        color: white;
        width: 250px;
        text-transform: uppercase;
    }

    .MuiFormControl-marginNormal {
        margin: 0 !important;
    }

    .navbar-column-title-checkout-section {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
        margin-left: 16px;
    }


    .cart-slider-container {
        width: 100%;
        z-index: 100;
        background: rgba(0,0,0,0.5);
        position: absolute;
        top: 150px;
    }

    .customer-satisfaction-policy-banner {
        font-weight: 400;
        font-size: 2.0779vw;
        line-height: 1.5;
        padding: 1vw 0.5vw;
        width: 93%;
        overflow: auto;
        text-align: left;
        margin-left: 1vw;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-second-mat-container {
        width: calc( 100% - 80px);
        overflow: auto;
        float: left;
        margin-left: 65px;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }


    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        font-weight: 400;
        color: #F59276;
        text-align: center;
    }

    .cart-slider-start-designing-button {
        width: 196px;
        overflow: auto;
        margin-left: calc( 50% - 98px);
        margin-bottom: 552px;
    }

    .show-empty-cart {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-item-details {
        width: 60%;
        float: left;
        overflow: auto;
    }

    .cart-slider-second-mat-item-price {
        width: 40%;
        overflow: auto;
        float: right;
    }

    #checkout-cart-slider {
        float: right;
        padding-right: 10px;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    .cart-item-count-display {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .discount-tier-info {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 10px;

    }

    .cart-item-count-display-empty-cart {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .cart-slider-free-shipping-banner {
        width: 100%;
        overflow: auto;
        margin-top: 24px;
        padding-bottom: 16px;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        left: 0px;
        top: 0px;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
    }

    .cart-slider-shopped-item-center-center {
        width: 70%;
        overflow: auto;
        float: left;
    }

    .cart-subtotal-strikethrough {
        text-decoration: line-through;
    }

    .cart-slider-shopped-item-right {
        width: 30%;
        float: right;
        text-align: right;
        /* margin-top: 20px; */
        margin-right: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: bold;
    }

    /*.cart-slider-shopped-item-title {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*    font-weight: 400;*/
    /*    margin-bottom: 5px;*/
    /*}*/

    .cart-slider-shopped-item-subtitle {
        /* Body 4 (Bolded) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-quantity {
        width: 50%;
        height: auto;
        margin-top: 20px;
        float: left;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        padding: 20px 0;
        overflow: auto;
        bottom: 0px;
        background: white;
    }

    .item-total-coupon-section {
        font-size: 2.0779vw;
        line-height: 3.247vw;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 49%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 400px;
        text-align: center;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        font-size: 2.0779vw;
        line-height: 3.247vw;;
        /* bottom: 20px; */
        /* right: 10px; */
        /* margin: 0; */
        margin: 0;
        margin-top: 40px;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 0.5vw 0;
        margin-bottom: 0.5vw;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        float: left;
        /* margin: 30px 20px 20px 20px; */
        margin: 2%;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-center {
        width: 55%;
        overflow: auto;
        float: left;
        /* margin: 20px 0; */
        margin-left: 1%;
    }

    /*.delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-right {*/
    /*    width: 20%;*/
    /*    float: right;*/
    /*    text-align: right;*/
    /*    margin: 0;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    /*.delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-title, .delivery-payments-section-shipping-payments-container  .cart-slider-shopped-item-subtitle {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .delivery-payments-section-shipping-payments-container .cart-slider-shopped-item-center {
        margin-top: 0;
    }

    .cart-slider-content {
        width: 80%;
        padding: 0 10%;
        height: auto;
        overflow-y: auto;
        /*position: absolute;*/
        background: white;
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
        border-bottom: solid #3B3B3B 1px;
        padding-bottom: 24px;
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 8px 16px;
        text-align: center;
        float: left;
    }

    .shopping-cart-subtotal-container {
        text-align: right;
        width: 300px;
        float: right;
        /* margin-top: 30px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 4px; */
    }

    .cart-subtotal-strikethrough-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 4px;
    }

    .discount-info-desc {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.02em;

        /* Secondary/Green */

        color: #78A891;
    }

    .cart-slider-item-controller {
        width: calc(100% - 80px);
        overflow: auto;
        float: left;
        margin-left: 65px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 100%;
        margin: 0 auto 0;
        border: none;
    }

    .cart-slider-shopped-item-quantity-button {
        width: 20px;
        overflow: auto;
        float: left;
        font-size: 26px;
        cursor: pointer;
        margin-top: 5px !important;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -20px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: initial;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        height: 191px;
    }

    .cart-slider-shopped-item-subheading {
        margin-top: 13px;
    }

    .cart-slider-shopped-item-total {
        float: right;
        text-align: right;
        font-size: 16px;
        line-height: 150%;
        font-weight: bold;
    }

    .cart-slider-ending-main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
    }

    .cart-slider-ending-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 16px;
    }

    .cart-slider-ending {
        margin-top: 32px;
        width: 100%;
        overflow: auto;
        padding-bottom: 320px;
    }

    .fetch-note-details-container-item-detail {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        width: auto;
        overflow: auto;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: 43px;
        overflow: auto;
        float: left;
        margin: 0 8px;
        border: 1px solid #666161;
    }

    .precut-size-header {
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        margin-bottom: 16px;
        text-transform: uppercase;
    }


}
@media screen and (min-width: 1100px) {

    .design-guide-colour-container {
        width: 100%;
        overflow: auto;
        padding: 40px 0;
    }

    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 24px auto;
        color: white;
        width: 190px;
        text-transform: uppercase;
        font-size: 13px;
    }

    .cart-slider-start-designing-button {
        width: 196px;
        overflow: auto;
        margin-left: calc( 50% - 98px);
        margin-bottom: 552px;
    }

    .show-empty-cart {
        /* Body 4 (Bolded) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 24px;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -20px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .cart-slider-ending-main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding-bottom: 16px;
    }

    .cart-slider-ending-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-bottom: 114px;
    }

    .cart-slider-ending {
        width: 100%;
        overflow: auto;
        padding: 64px 0 300px 0;
        text-align: center;
    }

    .cart-subtotal-strikethrough-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        float: right;
        text-align: right;
    }

    .cart-subtotal-strikethrough {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .discount-info-desc {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.02em;

        /* Secondary/Green */

        color: #78A891;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
    }

    .fetch-note-details-container-item-detail {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: initial;
    }

    .custom-mat-design-diagram img {
        width: 288px;
        height: 191.68px;
        background: #F2F2F2;
        border-radius: 2px;
        margin-top: 16px;
    }


    .cart-slider-shopped-item-subheading {
        margin-top: 13px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-container {
        width: calc(100% - 64px);
        margin-left: 64px;
        overflow: auto;
        float: left;
    }

    #cart-slider-shopped-item-quantity-button-right {
        text-align: right;
    }

    .cart-slider-shopped-item-quantity {
        float: left;
        width: 50%;
        margin-top: 16px;
        overflow: auto;
    }

    .cart-slider-item-controller {
        width: calc( 100% - 64px);
        margin-left: 64px;
        overflow: auto;
    }

    .cart-slider-shopped-item-quantity-button {
        width: 20px;
        float: left;
        font-size: 30px;
        font-weight: 400;
        text-align: left;
        cursor: pointer;
    }

    .cart-slider-shopped-item-quantity .MuiFormControl-marginNormal {
        margin-top: 0;
        margin-bottom: 0;
    }



    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: auto;
        overflow: auto;
        float: left;
        margin: 0 8px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 45px;
        margin: 3px auto 0;
    }

    .navbar-column-title-checkout-section{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-divider-thick {
        width: 640px;
        height: 1px;
        /* left: calc(50% - 640px/2 - 0px); */
        /* bottom: 1564px; */
        background: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 10px 0 0 0;
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 4px;
    }

    .shopping-cart-subtotal-container {
        text-align: right;
        width: 300px;
        float: right;
        /* margin-top: 30px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 4px; */
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 8px 16px;
        text-align: center;
        float: left;
    }

    .discount-tier-info {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 10px;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-second-mat-item-details {
        width: 70%;
        overflow: auto;
        float: left;
    }

    .cart-slider-second-mat-item-price {
        width: auto;
        overflow: auto;
        float: right;
    }

    .cart-slider-second-mat-item {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-shopped-item-total {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-center-center {
        width: 320px;
        overflow: auto;
        float: left;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-second-mat-item-details {
        width: 75%;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }



    .customer-satisfaction-policy-banner {
        font-weight: 400;
        line-height: 1.5;
        font-size: 14px;
        padding: 0.5vw;
        width: 90%;
        overflow: auto;
        text-align: left;
        margin-left: 1vw;
    }

    .cart-item-count-display {
        /* Header 4 */
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .cart-item-count-display-empty-cart {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 10px;
    }

    .cart-slider-content {
        padding: 0 calc( 50% - 320px);
        width: 640px;
        height: auto;
        overflow-y: auto;
        /*position: absolute;*/
        background: white;
    }

    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
        color: #F59276;
        margin-top: 140px;
    }

    #checkout-cart-slider {
        float: right;
        padding-right: 10px;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    .cart-slider-free-shipping-banner {
        padding: 10px 0;
        width: 100%;
        float: left;
        overflow: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        color: #3B3B3B;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
        margin-left: 16px;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        left: 0px;
        top: 0px;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
    }

    .cart-slider-shopped-item-right {
        width: 20%;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: capitalize;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid rgba(59,59,59, 0.25);
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        padding: 24px 0 0 0;
        overflow: auto;
        border-top: 1.5px black solid;
        margin-top: -0.5px;
    }

    .item-total-coupon-section {
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 49%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 400px;
        text-align: center;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        line-height: 20px;
        font-size: 16px;
        /* bottom: 20px; */
        /* right: 10px; */
        /* margin: 0; */
        margin: 0;
        margin-top: 10px;
    }

}



@media screen and (min-width: 1100px) {
    .customer-satisfaction-section {
        position: absolute;
        margin-top: -60vh;
        width: 65vw;
        margin-left: 15vw;
        margin-right: 15vw;
        text-align: center;
        background: #F9F9F9;
        text-align: center;
        color: #72696A;
    }

    .customer-satisfaction-container {
        width: auto;
        margin: 1.67vw 1.81vw;
    }

    .customer-satisfaction-image {
        width: 100%;
        overflow-y: auto;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        font-size: 1.39vw;
        letter-spacing: 0.03vw;
        font-weight: 600;
        line-height: 1.67vw;
        color: #72696A;
    }

    .customer-satisfaction-subtitle {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
    }
}

@media screen and (max-width: 1100px) and (min-width: 450px) {
    .customer-satisfaction-section {
        position: absolute;
        margin-top: -35vh;
        width: 80vw;
        margin-left: 7.5vw;
        margin-right: 7.5vw;
        text-align: center;
        background: #F9F9F9;
        color: #72696A;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        letter-spacing: 0.03vw;
        font-weight: 600;
        color: #72696A;
        width: auto;
        font-size: 2.597vw;
        text-align: center;
        margin: 0 0 3.117vw 0;
        line-height: 3.117vw;
    }

    .customer-satisfaction-subtitle {
        color: #72696A;
        text-align: center;
        font-weight: 400;
        line-height: 3.247vw;
        margin: 0 0 5.195vw 0;
        font-size: 2.0779vw;
    }

    .customer-satisfaction-container {
        width: auto;
    }

    .customer-satisfaction-image {
        width: 100%;
        overflow-y: auto;
    }

}

@media screen and (max-width: 450px) {


    .customer-satisfaction-section {
        position: absolute;
        margin-top: -30vh;
        width: 95vw;
        padding: 2.5vw;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
        background: #F9F9F9;
        color: #72696A;
    }

    .customer-satisfaction-container {
        width: auto;
        margin: 0;
    }

    .customer-satisfaction-image {
        width: 180%;
        margin-left: -40%;
        overflow-y: auto;
        margin-top: 12.5vw;
        margin-bottom: 12.5vw;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        letter-spacing: 0.03vw;
        font-weight: 600;
        margin: 0 0 3.117vw 0;
        text-align: center;
        color: #72696A;
        font-size: 6.25vw;
        line-height: 7.8125vw;
    }

    .customer-satisfaction-subtitle {
        color: #72696A;
        text-align: center;
        margin: 0 0 5.195vw 0;
        font-weight: 400;
        font-size: 5vw;
        line-height: 7.8125vw;
    }
}

@media screen and (min-width: 0px) {

    h1 {
        font-size: 16px;
        font-weight: 600;
    }

    h2 {
        font-size: 16px;
        font-weight: 600;
    }

    .visit-ikea-page {
        width: 100%;
        overflow: auto;
        text-align: center;
        font-size: 12px;
        color: #72696A;
        margin-bottom: 50px;
    }

    th u {
        cursor: pointer;
    }

    .blog-article-photo-description {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 2;
        color: #3B3B3B;
    }

    .blog-content-photo {
        width: 100%;
        overflow: auto;
        border-radius: 5px;
    }

    .blog-content-photo-small {
        width: 50%;
        margin: 0 23%;
        overflow: auto;
        border-radius: 5px;
    }

    .visit-custom-mat-button {
        width: auto;
        margin: 40px auto;
        display: table;
    }

    .blog-app-listing-item-container {
        margin-bottom: 30px;
        display: inline-block;
    }



    .carousel img {
        border-radius: 5px;
    }

    .carousel .control-dots .dot {
        width: 10px !important;
        height: 10px !important;
        display: inline-block !important;
        border-radius: 50% !important;
        background: #cccccc !important;
    }


    .carousel .control-dots .dot.selected {
        background: rgb(41, 41, 41) !important;
    }



    .carousel-read-more-button {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        background-color: rgb(41, 41, 41);
        height: 44px;
        width: auto;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        padding: 0px 27px;
        border: 0px;
        transition: all 0.3s ease 0s;
        border-radius: 3px;
        margin-bottom: 25px;
    }
    




    .blog-carousel-photo {
        width: 100%;
        overflow: auto;
        object-fit: initial;
    }



    .blog-listing-text-section {
        padding: 65px 30px 30px 30px;
        background: rgb(247, 247, 247);
        margin-top: -10px;
    }

    .blog-app-listing-subject-sub {
        font-size: 15px;
        font-weight: 400;
        color: rgb(94, 94, 94);
        line-height: 2;
        margin-bottom: 20px;
        text-align: center;
    }



    .blog-app-listing-read-more-button:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(41, 41, 41);
        transition: all 0.15s ease-in-out 0s;
    }

    .blog-app-listing-item-container {
        border-radius: 5px;
        width: auto;
        overflow: auto;
        display: inline-block;
    }





    .carousel-subject-sub {
        margin-bottom: 20px;
        color: rgb(94, 94, 94);
        line-height: 2;
    }

/*    .carousel .control-dots {
        margin-bottom: -10px;
    }*/


}


@media screen and (min-width: 0px) and (max-width: 769px) {


    .quantity-change-div-button {
        display: none;
    }

    .quantity-change-div {
        width: 100%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 100%;
        float: left;
    }


    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
    }

    .gallery-wall-content-left {
        float: left;
        width: 95%;
        max-height: 600px;
        line-height: 1.5;
        margin-bottom: 40px;
    }

    .gallery-wall-content-right {
        float: left;
        width: 95%;
        max-height: 800px;
        padding: 2.5% 0;
        line-height: 1.5;

    }
    
    .gallery-wall-content-image {
        width: 100%;
        overflow: auto;
    }

    .blog-carousel-photo {
        max-height: 225px;
        object-fit: cover !important;
    }

    .design-guide-illustration-video {
        width: 100%;
        height: 200px;
    }

    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 1;
        grid-column-gap: 32px;
        grid-column-gap: 32px;
        column-gap: 32px;
    }
    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }

    .blog-app-listing-subject-head {
        font-size: 24px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }


    .blog-app-container {
        width: auto;
        padding: 30px;
        margin-top: 90px;
    }

    .carousel-subject {
        text-align: center;
        width: auto;
        padding: 20px 30px 60px;
    }

    .carousel-subject-head {
        font-size: 24px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .blog-app-listing-col-left, .blog-app-listing-col-right {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);
        font-size: 14px;

    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .quantity-change-div-button {
        display: none;
    }


    .quantity-change-div {
        width: 100%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 30%;
        float: right;
    }

    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
        line-height: 1.5;

    }

    .gallery-wall-content-left {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%
    }

    .gallery-wall-content-right {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%
    }

    .blog-carousel-photo {
        max-height: 400px;
        object-fit: cover !important;
    }

    .design-guide-illustration-video {
        width: 100%;
        height: 400px;
    }


    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 2;
        grid-column-gap: 32px;
        grid-column-gap: 32px;
        column-gap: 32px;
    }

    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }


    .blog-app-container {
        width: auto;
        padding: 30px;
        margin-top: 140px;
    }

    .carousel-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .carousel-subject-sub {
        font-size: 15px;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);
        font-size: 14px;

    }

    .blog-app-listing-col-left {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-col-right {
        width: 47.5%;
        margin-left: 2.5%;
        overflow: auto;
        float: right;
    }

    .carousel-subject {
        width: auto;
        text-align: center;
        margin: 30px 120px;
        padding-bottom: 5px;
    }

    .blog-app-listing-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }
}

/*External Monitor*/

@media screen and (min-width: 1100px) {

    .quantity-change-div {
        width: 50%;
        overflow-y: auto;
    }

    .order-quantity-input-box {
        width: 30%;
        float: right;
        margin-top: -15px;
    }

    .gallery-wall-content-container {
        width: 100%;
        overflow-y: hidden;
        margin: 100px 0;
        line-height: 1.5;

    }

    .gallery-wall-content-left {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%;
    }

    .gallery-wall-content-right {
        float: left;
        width: 45%;
        max-height: 400px;
        padding: 2.5%;
    }

    .blog-carousel-photo {
        max-height: 400px;
        object-fit: cover !important;
    }



    .design-guide-illustration-video {
        width: 100%;
        height: 780px;
    }

    .blog-app-listing {
        padding-top: 60px;
        width: 100%;
        overflow: auto;
        column-count: 2;
        grid-column-gap: 32px;
        grid-column-gap: 32px;
        column-gap: 32px;
    }

    .carousel-subject-subhead-type2 {
        font-size: 18px;
        color: #3F5C6B;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
    }

    .carousel-subject-subhead-type3 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        line-height: 1.5;
    }

    .blog-article-content a {
        text-decoration: underline;
    }

    .blog-article-content {
        margin-top: 90px;
        margin-left: auto;
        margin-right: auto;
        width: 780px;
        max-width: 100%;
        font-size: 16px;
        line-height: 2;
        color: #3B3B3B;
        font-weight: 500;
    }

    .carousel-subject {
        width: auto;
        text-align: center;
        margin: 30px 120px;
        padding-bottom: 5px;
    }

    .blog-app-listing-subject-head {
        font-size: 35px;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.65;
        margin-bottom: 15px;
        text-align: center;
    }

    .carousel-subject-head {
        font-size: 48px;
        line-height: 1.5;
        font-weight: 400;
        color: rgb(41, 41, 41);
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .blog-app-container {
        width: 1140px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 150%;
        color: rgb(94, 94, 94);
        margin: 0 calc(50% - 570px);
        padding: 30px 0;
    }

    .blog-app-listing-read-more-button {
        font-weight: 700;
        color: rgb(41, 41, 41);
        background-color: rgb(255, 255, 255);
        text-transform: uppercase;
        padding: 13px 30px;
        display: inline-flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        width: 105px;
        text-align: center;
        border-radius: 3px;
        margin-left: calc(50% - 82.5px);

    }

    .blog-app-listing-col-left {
        width: 100%;
        overflow: auto;
    }

    .blog-app-listing-col-right {
        width: 47.5%;
        margin-left: 2.5%;
        overflow: auto;
        float: right;
    }

    /*.carousel.carousel-slider .control-arrow {*/
    /*    margin-top: 310px !important;*/
    /*}*/


}
a {
    cursor: pointer;
}

.application-container {
    width: 100%;
}

#pay-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 193px;
    height: 52px;
    background: #3B3B3B;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    flex: none;
    order: 2;
    flex-grow: 0;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    margin: 15px calc(50% - 100px);
}



/*For all codes Only*/
@media screen and (min-width: 0px) {



    .diploma-tool-aspect-ratio-desc {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .drawing-pad-example-image-section {
        width: 100%;
    }

    /*.drawing-pad-example-image-section {*/
    /*    width: 90%;*/
    /*    margin: 0 5%;*/
    /*}*/

    .drawing-board-direction-font {
        text-transform: none;
    }
    
    .full-width {
        width: 100%;
        overflow: auto;
        display: inline-block;
    }
    
    .flex-box {
        width: auto;
        overflow: auto;
        display: inline-block;
    }

    .frame-size-desc-options {
        padding: 0.5vw 0;
    }




    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        border: none !important;
    }

    .drawing-app-main-buttons:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .add-matboard-note-section {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .add-matboard-note-section-input-field {
        width: 100%;
        margin: 2vh 0;
    }

    .add-matboard-note-section-input-field div {
        padding: 12px 16.7px;
    }

    .add-matboard-note-section-input-field textarea {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

    }



    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }

    .showing-drawing-pad-example {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }



    #showing-drawing-pad-example-closing-modal-icon {
        width: 150px;
        text-align: center;
        border: solid 0.05vw #F59276;
        margin: 2% calc(50% - 75px);
    }

    #showing-drawing-pad-example-closing-modal-icon:hover {
        color: white;
        background: #F59276;
    }

    .drawing-pad-example-image-container {
        width: 90%;
        margin: 2.5% 5%;
    }



}

/*Mobile*/
@media screen and (min-width: 0px) and (max-width: 769px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 45%;
        margin: 0 2.5%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 30vh;
        border-radius: 2px;
        background: #F2F2F2;
    }


    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

    .showing-drawing-pad-example-modal {
        width: 90%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 5%;
        border-radius: 1vmin;
    }

    .drawing-pad-example-image-section {
        width: 100%;
        margin-top: 2.5%;
    }

    .fetch-note-details-container {
        width: 100%;
        overflow: auto;
        font-size: 3.75vw;
        line-height: 5.9375vw;
        font-weight: 300;
    }


    .drawing-app-directions {
        font-size: 4vw;
        line-height: 2;
        padding: 0.5vw 1vw 0.5vw 0;
        margin: 6vw 1vw 3vw 0;
        font-weight: 400;
        text-transform: none;
        width: auto;
        overflow: auto;
        float: left;
    }

    .select-mat-frame-drawing-canvas {
        z-index: 10;
    }

    .req-mat-sample-button-divider {
        display: none;
    }

    .drawing-board-direction-font {
        width: 100%;
        overflow: auto;
    }

    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }

    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }
    
    .frame-size-desc-title-header-margin {
        width: 100%;
        overflow: auto;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }
}

/*Tablet, Web Half Screen*/
@media screen and (min-width: 769px) and (max-width: 1100px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 50%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 35vh;
        border-radius: 2px;
        background: #F2F2F2;
    }


    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .custom-design-padding-box {
        margin-left: calc( 50% - 81px);
        margin-right: calc( 50% - 81px);
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

    .showing-drawing-pad-example-modal {
        width: 90%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 5%;
        border-radius: 1vmin;
    }

    .fetch-note-details-container {
        width: calc(100% - 120px);
        font-size: 2.0779vw;
        line-height: 3.247vw;
        font-weight: 300;
    }

    .custom-mat-design-diagram {
        width: 288px;
        overflow: auto;
        float: left;
        margin: 16px 0;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        height: 191px;
        margin-top: 16px;
    }

    .drawing-app-directions {
        line-height: 2;
        font-size: 2.0779vw;
        padding: 0.5vw 1vw 0.5vw 0;
        margin: 4vw 1vw 2vw 0;
        font-weight: 400;
        text-transform: none;
        width: auto;
        overflow: auto;
        float: left;
    }

    .select-mat-frame-drawing-canvas {
        z-index: 10;
    }

    .req-mat-sample-button-divider {
        display: none;
    }

    .drawing-board-direction-font {
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        color: #72696A;
        width: 100%;
        text-align: center;
        text-decoration: underline;
    }

    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }

    .frame-size-desc-title-header-margin {
        width: 100%;
    }

    .custom-design-padding-box {
        width: auto !important;
    }

    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

}

/*Web*/
@media screen and (min-width: 1100px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 50%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 45vh;
        border-radius: 2px;
        background: #F2F2F2;
    }




    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

.fetch-note-details-container {
    width: calc(100% - 120px);
    overflow-y: auto;
}

.custom-mat-design-diagram {
    width: auto;
    overflow: auto;
}

.drawing-app-directions {
    font-size: 1.11vw;
    line-height: 2vw;
    padding: 0.5vw 1vw 0.5vw 0;
    margin: 0.5vw 1vw 0.5vw 0;
    font-weight: 400;
    text-transform: none;
    width: auto;
    overflow: auto;
    float: left;
}

.select-mat-frame-drawing-canvas {
    z-index: 10;
}

.drawing-board-direction-font {
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
    color: #72696A;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 32px;
    margin-top: 16px;
}

.drawing-app-main-buttons {
    font-size: 16px;
    line-height: 150%;
    padding: 16px;
    background: #F2F2F2;
    border-radius: 0 !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
}

.drawing-app-saving-status {
    font-size: 12px;
    line-height: 150%;
    margin: -3vw -25vw 0 0;
    float: right;
}
}







.review-section-directions {
    line-height: 2;
}

.no-display {
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 769px) {
    .tablet-display-only {
        display: none;
    }

    .mobile-no-display {
        display: none;
    }


    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        /*font-size: 2.222vw;*/
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 90%;
        overflow: auto;
        margin: 0 0% 5vh;
        padding: 2% 5% 10% 5%;
    }

    .review-field-submit-button-container {
        width: 100%;
        margin: 0;
    }
}

/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .tablet-no-display {
        display: none;
    }

    .tablet-display-only {
        display: inline-block;
    }

    .mobile-display-only {
        display: none;
    }

    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        /*font-size: 2.222vw;*/
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 70%;
        overflow: auto;
        margin: 0 10% 5vh;
        padding: 2% 5%;
    }

    .review-field-submit-button-container {
        width: 40%;
        margin: 0 30%;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .tablet-display-only {
        display: none;
    }

    .mobile-display-only {
        display: none;
    }

    .review-form-margin {
        margin-top: 2.22vh;
    }

    .review-field-margin {
        margin: 2.22vh 0;
    }

    .review-section-header {
        text-align: center;
        letter-spacing: .67px;
        margin: 5.55vh 0;
        font-size: 1.39vw;
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .review-section-directions {
        text-align: center;
        margin-bottom: 5.55vh;
    }

    .top-header-container {
        width: 100%;
        overflow: auto;
        background: #fbf9f8;
    }

    .logo-size {
        width: 100px;
        margin: 1.04vw calc(50% - 50px);
    }

    .review-section {
        width: 60%;
        overflow: auto;
        margin: 0 15% 5vh;
        padding: 2% 5%;
    }

    .review-field-submit-button-container {
        width: 40%;
        margin: 0 30%;
    }

    .review-field-margin p {
        text-align: center;
    }
}

