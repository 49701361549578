/*For all*/
.landing-section {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 10vh;
}



.landing-section-img {
    width: 80%;
    height: auto;
    object-fit: cover;
    overflow-y: auto;
    padding-left: 10%;
    padding-right: 10%;
    background: #ebedee;
}

#landing-section-polaroid-background {
    background: #EAEAEA;
}

.emphasis-type-1 {
    text-align: center;
    background-color: #514945;
    color: #fff;
    border: 1px solid #514945;
    width: 10rem;
    font-weight: 400;
    padding: 1rem 3rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.8rem;
    display: inline-block;
}

.emphasis-type-1 div {
    color: white;
}

.emphasis-section {
    color: #fff;
    padding-left: 3px;
    font-size: 10px;
    box-shadow: 0 0 0 0 hsla(0, 0%, 100%, .7);
    -webkit-animation: pulse 1.5s infinite;
    width: auto;
    height: 10px;
    border-radius: 100%;
    text-align: center;
}

.emphasis-spacing {
    margin-left: 5px;
}

.emphasis-type-1:hover {
    background-color: #f59276;
    border-color: #f59276;
    -webkit-transition: border-color .35s ease-out,color .25s ease-out;
    transition: border-color .35s ease-out,color .25s ease-out;
    -webkit-transition: background-color .35s ease-out,color .25s ease-out;
    transition: background-color .35s ease-out,color .25s ease-out;
}

.introduction-button-groups {
    display: inline-block;
    width: 100%;
    overflow: auto;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 5px rgba(232,76,61,0);
    }
}

.landing-page-product-selection-option {
    width: 15vw;
    float: left;
    text-align: center;
}

.landing-section-left, .landing-section-right {
    background-color: #f1ece4;
    color: #000;
}

#why-matboard-container {
    background: #FFAD9C;
    margin-top: 5vh;
    padding: 5vh 3rem;
}

#why-matboard-container .what-we-do-container-first-container {
    margin-bottom: 0;
}

#why-matboard-container .what-we-do-container-first-container .what-we-do-first-right {
    opacity: 1;
    margin: 0;
}

.why-matboard-header {
    color: white;
    font-size: 2rem;
    font-weight: 400;
    margin-top: 5vh;
}

.why-matboard-subheader {
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.why-matboard-transition {
    width: 4rem;
    height: 1vh;
    background: white;
    margin-top: 2vh;
}

#why-matboard-container .what-we-do-first-left {
    text-align: left;
}

#why-matboard-container .what-we-do-first-left {
    padding-left: 5%;
}

.introduction-section-header {
    font-size: 2rem;
    line-height: 1.5;
}

.introduction-section-subheader {
    font-size: 1.3rem;
    margin-top: 2vh;
}

.intro-section-header-separator {
    width: 4vw;
    height: 0.75vh;
    background: #514945;
    margin-left: 4vw;
    margin-top: 2vh;
    font-weight: 400;
}



/*Mobile, Tablet, Web Half Screen*/

@media screen and (min-width: 0px) and (max-width: 769px) {
    .landing-section-left, .landing-section-right {
        width: 100%;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 3vh 5vw 2vh 5vw;
        font-weight: 400;
        font-size: 1.3rem;
        text-align: center;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
        font-size: 1rem;
        text-align: center;
    }

    .emphasis-type-1 {
        margin-left: calc(50% - 8rem);
    }

    .intro-section-header-separator {
        display: none;
    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 500;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        text-align: center;
        background-color: #514945;
        color: #fff;
        border: 1px solid #514945;
        width: 10rem;
        font-weight: 400;
        /* margin-left: 4vw; */
        /* margin-right: 4vw; */
        padding: 1rem 3rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 0.8rem;
        margin-left: calc(50% - 8rem);
        display: inline-block;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }



}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .landing-section-left, .landing-section-right {
        width: 50%;
        height: 75vh;
        float: left;
        overflow: hidden;
    }

    .introduction-section-header {
        margin: 20vh 0 0 4vw;
        font-weight: 400;
    }

    .introduction-section-subheader {
        font-weight: 400;
        line-height: 2;
        margin-left: 4vw;
    }

    .emphasis-type-1 {
        margin-left: 4vw;
    }



}
