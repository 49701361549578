@media screen and (min-width: 1100px) {

    .cart-slider-container {
        position: absolute;
        background: #ffffff;
        width: 100%;
        height: auto;
        z-index: 30;
    }

    .navbar-web-hover {
        width: 100%;
        height: 30vh;
        position: fixed;
        top: 126px;
        left: 0;
        background: white;
        padding: 54px 132px;
        overflow: hidden;
        z-index: 10;
    }

    .navbar-web-hover-column {
        width: 24.5%;
        float: left;
    }


    .mobile-menu-button {
        display: none;
    }

    .navbar-v2 {
        position: fixed;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }

    .navbar-v2-non-fixed {
        position: relative;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }



    .navbar-v2-narrow {
        position: fixed;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        z-index: 40;
    }

    .navbar-button-v2 {
        width: auto;
        padding: 54px 16px;
        float: left;
        overflow: auto;
        cursor: pointer;
    }

    /*.navbar-button-v2:hover {*/
    /*    text-decoration: underline;*/
    /*}*/

    .logo-vertical-v2 {
        margin: 47.55px 98px  47.55px 4.1%;
        float: left;
    }

    .logo-vertical-v2-narrow {
        margin: 15.55px 128px 15.55px 4.1%;
        float: left;
    }

    .navbar-start-button {
        width: 200px;
        overflow-y: auto;
        float: right;
        margin: 38px 32px;
    }


    .navbar-button-v2-narrow {
        width: auto;
        height: 30px;
        margin: 22.5px 0 22.5px 16px;
        float: left;
        color: #3b3b3b;
    }

    .bag-v2 {
        width: 48px;
        height: 48px;
        margin: 40px 15px 40px 0;
        float: right;
    }

    .bag-v2-narrow {
        width: 48px;
        height: 48px;
        margin: 5px 15px 5px 0;
        float: right;
    }

    .bag-icon-v2 {
        margin: 4px;
        width: 40px;
        height: 40px;
    }




    .navbar-start-designing-button-v2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        margin: 38px 1.5%;
        float: right;
        width: 142px;
        height: auto;
        right: 190.66px;

        /* Primary/Soft Black */

        background: #3B3B3B;
        border-radius: 30px;

        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* identical to box height, or 19px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Secondary/White */

        color: #FFFFFF;

    }

    .enfr-v2 {
        width: 57px;
        float: right;
        margin-left: 0;
        font-weight: 400
    }

    .navbar-top-web-language-selected {
        font-weight: 700 !important;
    }

    .enfr-v2 a span {
        font-weight: 400;
    }

    .navbar-web-hover-column-narrow {
        width: 15%;
        float: left;
    }

    .navbar-web-hover-title {
        width: 100%;
        overflow: auto;
        color: #3B3B3B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 225%; /* 36px */
        text-transform: uppercase;
    }

    .navbar-web-hover-button {
        color: #686868;
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 225%; /* 36px */
        margin-top: 4px;
        text-transform: capitalize;
    }


    .event-message-version2, .event-message-version2-fr {
        /*width: 300px;*/
        /*margin: 0 0 0 calc(50% - 150px);*/
        width: 40%;
        /*height: 24px;*/
        margin: 0 0 0 30%;
        float: left;
        text-transform: none;

        /* Body */
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        align-items: center;
        text-align: center;

        /* Secondary/White */
        color: #FFFFFF;
        padding-top: 4px;
        font-weight: 600;
    }

    .notice-bar-region-icon {
        color: white;
        width: 20px;
        height: 20px;
        margin: 5.5px;
        float: left;
    }

    .notice-bar-region-text {
        float: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: white;
        margin-top: 3.5px;
    }

    .notice-bar-region {
        color: white;
        float: right;
        text-transform: none;
        margin-right: 100px;
    }

    .notice-bar-contact-us {
        color: white;
        float: right;
        text-transform: none;
        margin-right: 64px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-top: 3.5px;
    }

    .navbar-web-hover-button:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .navbar-top-button-groups {
        width: 100%;
        overflow-y: hidden;
        height: 130px;
        padding-bottom: 28px;
        top: 0;
    }

    .navbar-top-button-groups-narrow {
        width: 100%;
        overflow: hidden;
        height: 64px;
    }

    .support-v2-dropdown {
        padding: 16px 25px 25px 25px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: #3B3B3B;
        text-transform: none;
        background: white;
        position: absolute;
        z-index: 100;
    }

    .mobile-navbar-open {
        display: none;
    }

    .navbar-top-web-right-container {
        width: auto;
        float: left;
        position: absolute;
        margin-left: 70vw;
    }

    .navbar-top-container {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        font-size: 1.11vw;
        overflow: hidden;
    }

    .navbar-top-company-logo {
        font-size: 1.5rem;
        padding-top: 0.25vw;
    }

    #navbar-top-start-designing-button {
        text-transform: uppercase;
        width: auto;
        padding: 0;
        width: 13.19vw;
        text-align: center;
        margin: 1.63vw 0 1.63vw 0;
    }

    .navbar-top-company-name-logo {
        width: 7.92vw;
        height: 4.79vw;
        position: absolute;
        float: left;
        margin: 1.04vw 44.9vw;
    }


    .navbar-top-company-description {
        width: auto;
        overflow: auto;
        float: left;
        font-size: 0.8rem;
        margin: 0;
        font-weight: 500;
        color: #f59276;
        margin-left: 0.5rem;
        line-height: 1.5;
        margin-top: 0.75rem;
        text-transform: lowercase;
    }

    .landing-page-banner {
        background: #F59276;
        color: white;
        padding: 0.76vw;
        text-align: center;
        font-family: Montserrat;
        font-weight: 300;
        line-height: 1.32vw;
        font-size: 1.11vw;
    }

    .question-mark-icon {
        width: 0.8rem;
    }


    .start-designing-page-container {
        margin-top: 5rem;
        margin-bottom: 5rem;
        border-top: 0.05rem #f59276 solid;
    }

    .navbar-top-mobile-button {
        display: none;
    }

    .mat-board-size-selector-header {
        font-size: 1rem;
    }

    .question-mark-icon {
        width: 0.8rem;
    }

    .start-designing-page-container {
        margin-top: 5rem;
        margin-bottom: 5rem;
        border-top: 0.05rem #f59276 solid;
    }

    .navbar-top-gallery-web-button {
        font-size: 1.11vw;
        margin: 2.78vw 0 2.78vw 4.65vw;
        float: left;
    }

    .navbar-top-contact-web-button {
        float: left;
        margin: 2.78vw 0 2.78vw 2.78vw;
    }

    .navbar-top-web-cart {
        width: 2.22vw;
        height: 2.22vw;
        float: left;
        margin: 2.36vw 0 2.36vw 3.57vw;
    }

    .navbar-top-web-language {
        width: 4vw;
        height: 1.32vw;
        color: #5F6062;
        margin: 2.76vw 0.5vw 2.76vw 2.33vw;
        float: left;
    }

    .navbar-top-cart-icon-counter {
        position: absolute;
        width: 1.3888vw;
        border-radius: 100%;
        text-align: center;
        margin-top: -3vw;
        margin-left: 1.25vw;
        color: #FFFFFF;
        background: #F59276;
        font-size: 0.972vw;
        padding-top: calc((1.3888vw - 1.25vw) /2);
        line-height: 1.25vw;
        font-weight: 300;
    }
}

@media screen and (min-width: 1920px) {
    .navbar-top-button-groups-narrow {
        max-width: 1440px;
        margin-left: calc( 50% - 770px );
    }

    .cart-slider-container {
        position: absolute;
        background: #ffffff;
        width: 1440px;
        height: auto;
        z-index: 30;
    }

    .display-bottom-blur {
        width: 1440px;
        position: fixed;
        height: 50vh;
        bottom: 0;
        background: rgba(84, 84, 84, 0.25);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        z-index: 30;
    }

    .navbar-web-hover {
        width: 1440px;
        height: 30vh;
        position: fixed;
        top: 122px;
        left: 0;
        background: white;
        overflow: hidden;
        margin-left: calc(50% - 720px);
        z-index: 10;
    }

    .select-mat-container-narrow {
        overflow: auto;
        width: 1440px;
        margin-left: calc(50% - 720px) !important;
        min-height: 80vh;
    }


    .navbar-bottom-company-info-section {
        width: 1220px !important;
        overflow: auto !important;
        margin-left: 110px;
    }

    .general-static-page-container {
        width: 1120px !important;
        margin: 32px auto !important;
    }

}