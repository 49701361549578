.order-change-input {
    width: 100%;
    overflow-y: auto;
}

.order-change-form-right textarea {
    height: 45vh;
}

.order-change-form-submit-button {
    width: 100%;
    overflow-y: auto;
}

.order-change-form-submit-button button {
    width: 10rem;
    margin: 0.5rem calc( 50% - 5rem )
}

.order-change-input div, order-change-form-right div {
    width: 100% !important;
}

/*For all*/


/*Mobile Only*/


/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .order-change-button {
        width: 96%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 96%;
        margin: 0 2%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 96%;
        margin: 0 2%;
        float: right;
    }

}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 49%;
        float: left;
        overflow-y: auto;
        margin-right: 1%;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 50%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    .order-change-button {
        width: 43%;
        float: left;
        text-align: center;
        padding: 0.5rem 2%;
        color: #fff;
        border: 1px solid #514945;
        font-weight: 400;
        background: #514945;
        margin-right: 0.5%;
    }

    .order-change-form-left {
        width: 50%;
        float: left;
        overflow-y: auto;
    }

    .order-change-form-right {
        width: 49%;
        float: right;
    }

}
