/*Mobile Only*/

@media screen and (min-width: 0px) {

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid black !important;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid black !important;
    }

    /*For all*/
    .gallery-container {
        width: auto;
        overflow: auto;
    }

    .gallery-section-content-highlights {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .precut-window-selector {
        width: 100%;
        overflow: hidden;
    }

    .cart-slider-shopped-item-quantity {
        overflow: auto;
    }


    .MuiListItem-button {
        text-transform: capitalize !important;
    }

    .custom-design-padding-box {
        width: auto;
        overflow: auto;
        margin-left: calc( 50% - 81px);
        margin-right: calc( 50% - 81px);
        margin-top: 16px;
        margin-bottom: 32px;
    }


    .overview-section-left {
        float: left;
        padding: 0 4%;
        border-right: solid #F3F6F6 0.1rem;
        border-bottom: solid #F3F6F6 0.1rem;
    }

    .overview-section-right {
        width: 32%;
        float: left;
        padding: 0 4%;
    }

    .overview-section-left-image {
        width: 100%;
    }

    .overview-section-header-container {
        position: absolute;
        line-height: 2;
        padding: 1rem 1rem 1rem 0;
        overflow-y: auto;
    }

    .overview-section-header {
        background: black;
        color: white;
        padding: 0 1rem;
        width: auto;
        overflow: auto;
        font-size: 1rem;
        text-transform: uppercase;
    }

    .inspiration-post {
        width: 100%;
        overflow-y: auto;
        display: inline-block;
        margin-top: -5px;
    }





    .overview-section-header, .overview-section-subheader {
        cursor: pointer;
    }

    .inspiration-logo-IG-POST {
        width: 24px;
        height: 24px;
        float: right;
    }

    .overview-section-subheader {
        background: white;
        color: black;
        padding: 0 1rem;
        width: auto;
        overflow: auto;
        font-size: 1.3rem;
    }

    .overview-section-header-alternative {
        font-size: 1rem;
        font-style: italic;
        font-weight: 500;
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
    }

    .overview-section-subheader-alternative {
        color: #858585;
        font-size: 0.8rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }

    .gallery-left-item-container {
        width: 100%;
        padding-bottom: 2.5rem;
        border-bottom: solid #F3F6F6 0.1rem;
        margin-bottom: 2.5rem;
        overflow: auto;
    }

    .gallery-left-item-container-left {
        width: 15%;
        float: left;
    }

    .gallery-left-item-section {
        background: black;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        text-align: center;
        font-weight: 500;
        color: white;
        padding: 0.25rem 0.5rem 0.75rem 0.5rem;
    }

    .gallery-left-item-container-left span {
        font-size: 0.6rem;
        font-weight: 600;
    }

    .overview-section-left-image-alternative {
        width: 20%;
        float: left;
        overflow: auto;
    }

    .overview-section-header-container-alternative-two {
        width: 77%;
        float: left;
        overflow: auto;
        margin-left: 3%;
    }

    .article-list-container {
        width: 100%;
        overflow: auto;
        border-bottom: #F3F6F6 solid 0.1rem
    }

    .article-list-header {
        text-transform: uppercase;
        color: #c88d75;
        font-weight: 500;
    }

    .article-list-container-option {
        width: auto;
        float: left;
        margin: 0.5rem;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
        border-bottom: 0.1rem solid white;
    }

    .article-list-container-option:hover {
        border-bottom: 0.1rem solid;
        transition: border-bottom-color 0.3s linear;
    }

    .article-list-container-selector {
        width: 100%;
        overflow-y: auto;
        margin: 0.25rem 0;
    }

    .article-list-selected {
        border-bottom: 0.1rem solid;
        color: #f59276;
    }

    .article-list-left {
        width: 5rem;
        height: 5rem;
        float: left;
    }

    .article-list-right {
        width: calc( 99% - 6rem);
        overflow: auto;
        float: left;
        margin-left: 1rem;
    }

    .article-list-image {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
    }

    .article-list-item {
        width: 100%;
        overflow: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: #F3F6F6 solid 0.1rem;
        cursor: pointer;
    }

    .article-list-right-category {
        color: #858585;
        font-weight: 500;
        font-size: 0.8rem;
        padding-bottom: 0.25rem;
        text-transform: uppercase;
        margin-top: 0.5rem;
    }

    .article-list-right-title {
        width: auto;
        overflow: auto;
        line-height: 1.5;
        font-size: 0.9rem;
    }

    .gallery-container-topic {
        width: 80%;
        overflow: auto;
        margin-left: 10%;
        color: #858585;
        font-size: 0.8rem;
        padding-left: 3.5%;
    }

    .gallery-container-topic h1 {
        font-size: 1.5rem;
        color: black;
        font-weight: 500;
        margin: 1rem 0 0.5rem 0;
        padding: 1rem 0;
        border-top: #F3F6F6 solid 0.1rem;
        border-bottom: #F3F6F6 solid 0.1rem;
    }

    .gallery-container-topic h3 {
        font-weight: 400;
        float: left;
        width: auto;
        margin-right: 1rem;
    }

    .overview-section-right .overview-section-header-container-alternative {
        margin-bottom: 2rem;
    }

    .gallery-section-content {
        font-size: 1rem;
        line-height: 2;
    }


    .image-gallery-play-button {
        display: none;
    }

    .gallery-container-topic h3 {
        font-weight: 400;
        float: left;
        width: auto;
    }

    .gallery-section-content a {
        text-decoration: underline;
    }

    .content-paragraph {
        width: 100%;
        overflow: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .blog-article-photo-one {
        width: 100%;
        overflow: auto;
    }

    .blog-article-photo-one-image {
        width: 100%;
    }

    .inspiration-load-more-button {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 24px;
        position: static;
        width: 142px;
        /* height: 52px; */
        left: 489px;
        top: 1762.72px;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        text-align: center;
        margin: 62px calc( 50% - 95px);
        cursor: pointer;
    }

    .blog-article-photo-two-portrait {
        width: 100%;
        overflow-y: auto;
    }


    .image-subscript-type1 {
        margin-top: -8vh;
        margin-bottom: 10vh;
    }

    .image-subscript, .image-subscript-type1 {
        font-size: 0.8rem;
        color: #858585;
        text-align: center;
    }

    .overview-section-left-image, .overview-section-header-container-alternative {
        cursor: pointer;
    }



    .design-guide-button {
        /* Header 4 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 68px;
        margin-bottom: 16px;
    }



    .back-to-my-design-button{
        width: 300px;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: block;
        text-align: center;
        align-items: center;
        color: #3B3B3B;
        margin: 0 calc( 50% - 150px);
    }

    .back-to-my-design-button-icon {
        width: 9px;
        height: 12px;
        margin-right: 20px;
    }

    .design-guide-content-container {
        width: auto;
        margin-top: 80px;
        margin-bottom: 16px;
        background-color: #DDDDDD;
        padding: 32px 32px 64px 32px;
    }

    .design-guide-content-container-topic {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: left;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 30px;
        text-align: center;
    }

    .design-guide-content {
        width: 100%;
        overflow-y: auto;
    }



    .design-guide-content-container-walkthrough-button {
        width: 100%;
        margin-top: 16px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        cursor: pointer;
    }

    .design-guide-content-container-walkthrough-button:hover {
        text-decoration: underline;
    }

    .design-guide-inquiry {
        width: 100%;
        overflow: auto;
        margin: 92px 0;
    }

    .design-guide-inquiry {
        width: 100%;
        overflow-y: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #666161;
    }

    .design-guide-section-button-selected {
        text-decoration: underline;
    }

    .design-guide-content-instruction {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-bottom: 56px;
    }

    .design-guide-photo-container {
        width: 100%;
        overflow: auto;
    }

    .design-guide-step-by-step-container {
        width: 100%;
        overflow: auto;
        margin-bottom: 80px;
    }

    .design-guide-step-by-step-container-left-desc {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        /* Primary/Soft Black */

        color: #3B3B3B;


        /* Inside Auto Layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .design-guide-colour-box div {
        width: 100%;
        height: 40px;
    }

    .fetch-precut-mats-subtitle {
        font-size: 16px;
        line-height: 150%;
    }

    .fetch-precut-mats-title-container:hover {
        text-decoration: underline;
    }




}










