/*For all*/
.landing-page-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: #FFFFFF;
}

.workflow-section {
    margin-top: 300px
}


.customizable-head {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 127.778% */
    letter-spacing: 3.6px;
    text-transform: uppercase;
    color: #3B3B3B
}





.landing-page-review-stars-container {
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
    padding: 64px 0;
}


video {
    clip-path: inset(1px 1px);
}









.select-mat-intro {
    width: 100%;
    overflow: auto;
    position: relative;
}

.landing-page-banner a:hover {
    text-decoration: underline;
    color: white;
}

#matboard-only-mobile-landing {
    width: 100%;
    margin: 3vw 0 5vw 0;
}

.matting-dimension-indicator-helper {
    display: none;
    position: absolute;
}

.matting-dimension-indicator-helper-display {
    display: inline;
    position: absolute;
    background-color: rgb(118, 177, 228);
    text-align: left;
    font-size: 0.8rem;
}

.matting-dimension-indicator-helper-display-text {
    width: 14rem;
    overflow-y: auto;
    margin-top: 0.35rem;
    padding-left: 1rem;
    margin-left: 0.55rem;
    position: absolute;
    background-color: white;
    line-height: 1.5;
    font-size: 0.8rem;
    z-index: 10;
}

.matting-dimension-indicator-helper-arrow {
    width: 1rem;
    background-color: transparent;
    margin-left: -1rem;
    margin-top: 0.1rem;
    position: absolute;
}

.matting-dimension-indicator-helper-container {
    width: auto;
    overflow-y: auto;
    margin-top: 0.3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.landing-page-design-matboard-transition {
    background: #f1ece4;
    text-align: center;
    padding: 15vh 0 10vh 0;
    font-weight: 600;
    color: #000;
}

.introduction-section-1-highlight {
    color: #6a396a;
}





.landing-page-how-it-works-transition {
    font-weight: 300;
}

.shipping-info-highlights {
    font-weight: 500;
    color: white;
}

.shipping-info-highlights div {
    width: auto;
    float: left;
    overflow: auto;
}

.shipping-info-highlights-cities {
    color: white;
    cursor: pointer;
}

.shipping-info-highlights-cities:hover {
    text-decoration: underline;
}



.landing-page-how-it-works-transition-span {
    font-weight: 500;
    font-size: 0.8rem;
}

.landing-page-design-now-button:hover {
    text-decoration: none;
    background-color: #F59276;
    color: white;
}

.landing-page-image {
    width: 100%;
    overflow: auto;
}

.blog-type-2-image-file {
    width: 100%;
    overflow: auto;
}






