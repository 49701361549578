

@media screen and (min-width: 769px) and (max-width: 1100px) {

    .delivery-payments-section-shipping-payments-container {
        margin: 10px 0;
    }

    .promotions-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: static;
        width: 193px;
        height: 52px;
        background: #3B3B3B;
        border-radius: 30px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        flex: none;
        order: 2;
        flex-grow: 0;
        color: white;
        cursor: pointer;
        border: solid 1px #3B3B3B;
    }

    .promotions-button div {
        color: white;
    }

    .promotions-button:hover {
        background: white;
    }


    .promotions-button:hover div {
        color: #3B3B3B;
    }

    .saved-prev-address {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        width: 42.5%;
        height: 200px;
        padding: 2.5%;
        border: solid 1px rgb(118, 118, 118);
        border-radius: 5px;
        float: left;
        margin-right: 2%;
    }

    .payment-processor-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        flex: none;
        order: 5;
        flex-grow: 0;
        margin: 24px 0px;
    }

    .review-page-reminder .cart-slider-ending-subheading {
        margin-bottom: 0;
    }

    .review-page-reminder {
        margin: 24px 0;
    }

    .summary-review-button-left {
        width: auto;
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .summary-review-button-right {
        width: auto;
        float: right;
        overflow: auto;
        text-align: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #3B3B3B;
        text-transform: uppercase;
    }


    .review-section-container-header {
        width: 100%;
        overflow: auto;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #3b3b3b;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .add-text-fields-text-field-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 16px !important;
        line-height: 120%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #666161;
        margin-bottom: 8px;
        text-transform: uppercase;
    }


    .payments-container {
        width: 640px;
        overflow: auto;
        margin: 0 calc( 50% - 320px );
        margin-top: 160px;
        margin-bottom: 128px;
    }

    .delivery-payments-section-order-summary-container {
        overflow-y: auto;
        float: left;
    }

    .delivery-payments-section-shipping-payments-container {
        width: 100%;
        overflow-y: auto;
        float: left;
    }

    .shipping-address-city, .shipping-address-region {
        width: 49%;
        float: left;
        margin-top: 1rem;
    }

    /*.shipping-address-city {*/
    /*    width: 49%;*/
    /*    margin-right: 1% !important;*/
    /*}*/

    /*.shipping-address-region {*/
    /*    width: 49%;*/
    /*    margin-top: 1rem !important;*/
    /*}*/




    /*.shipping-address-country {*/
    /*    !*margin-top: 1rem;*!*/
    /*    !*margin-bottom: 1rem;*!*/
    /*}*/

    /*.shipping-address-country {*/
    /*    width: 49%;*/
    /*    margin-right: 1%;*/
    /*    float: left;*/
    /*    padding-top: 0.3rem;*/
    /*}*/

    /*.shipping-address-suite, .shipping-address-country {*/
    /*    margin-right: 1% !important;*/
    /*}*/

    /*.shipping-address-postal-code input {*/
    /*padding: 0.57rem 0;*/
    /*}*/

    /*.shipping-address-postal-code {*/
    /*    margin-left: 1% !important;*/
    /*}*/

    .shipping-form-container-section-header {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #000000;
        text-transform: uppercase;
        margin-bottom: 21.27px;
    }

    /*.order-summary-item-container {*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .shipping-address-recipient-phone {
        width: 100%;
    }

    .StripeElement {
        /*width: 87%;*/
        border-bottom: solid 0.1rem;
        padding: 0.5rem;
        border: none;
    }

    /*.payment-section-dividers {*/
    /*    width: 100%;*/
    /*    margin: 5vw 0;*/
    /*}*/


    .shipping-form-container, .shipping-form-container-section-header div {
        width: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .credit-card-form-container {
        margin-top: 1rem;
    }

    .StripeCheckout {
        border: none !important;
        font-weight: 400 !important;
        padding: 0 !important;
        text-align: center !important;
    }


    .link-to-stripe {
        text-decoration: underline;
        cursor: pointer;
    }


    .shipping-address-country .jss1 {
        width: 100% !important;
        overflow-y: auto !important;
    }

    .app-button, .app-button label, .app-button input {
        cursor: pointer;
    }

    /*.shipping-address-province-locked {*/
    /*    width: 49%;*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    border-bottom: solid 0.1rem;*/
    /*    margin-right: 1%;*/
    /*    padding: 1rem 0 0.5rem 0;*/
    /*    font-size: 1rem;*/
    /*    color: #72696A;*/
    /*    font-weight: 500;*/
    /*    text-align: center;*/
    /*}*/

    /*.shipping-address-country-locked {*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    border-bottom: solid 0.1rem;*/
    /*    margin-left: 1%;*/
    /*    padding: 1.2rem 0 0.5rem 0;*/
    /*    color: #72696A;*/
    /*    font-weight: 500;*/
    /*    text-align: center;*/
    /*}*/

    /*.shipping-form-container input {*/
    /*    font-family: Montserrat;*/
    /*    font-weight: 500;*/
    /*    color: #72696A;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*    text-align: left;*/
    /*    padding-left: 10px;*/
    /*    padding-right: 10px;*/
    /*}*/

    .order-summary-italic {
        font-style: italic;
    }

    .show-spinner-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.6);
        top: 0;
        left: 0;
        margin-top: 0rem;
        z-index: 300;
        padding: 40vh 0;
    }

    .loader-text {
        color: white;
        margin-top: 1rem;
        text-align: center;
    }

    .loader {
        border: 0.5rem solid #f3f3f3; /* Light grey */
        border-top: 0.5rem solid #3498db; /* Blue */
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        animation: spin 2s linear infinite;
        margin-left: calc( 50% - 1.5rem );
    }
    .artwork-margin-indicator-left
    .discount-input-summary {
        width: 5rem;
        text-align: center;
        border: solid 0.05rem darkgrey;
        border-radius: 0.3rem;
        margin-left: 0.25rem;
        color: #514945;
    }

    .promo-code-selector {
        font-family: Montserrat;
        font-style: normal;
        /*font-weight: normal;*/
        /*text-align: left;*/


        /*Black Friday Promo Code*/
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        line-height: 150%;

        color: #3B3B3B;
        cursor: pointer;
    }

    .promo-code-container {
        font-size: 0.8rem;
        width: 300px;
        overflow: hidden;
        border: black solid 1px;
        border-radius: 10px;
        margin-top: 10px;
        margin-left: calc(50% - 150px);
        margin-bottom: 30px;
    }

    .promo-application-view {
        width: auto;
        overflow: auto;
        float: right;
    }

    .success {
        color: #228B22;
    }

    .error {
        color: #F59276;
    }

    .shipping-address-email, .shipping-address-recipient-phone, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code  {
        width: calc( 50% - 6px);
        float: left;
        margin-right: 6px !important;
        margin-top: 5.27px !important;
    }

    /*.province-state-selector-container, .province-state-selector-details {*/
    /*    font-family: Montserrat;*/
    /*    font-weight: 500;*/
    /*    color: #72696A;*/
    /*    text-align: left;*/
    /*    width: calc( 100% - 10px );*/
    /*    padding: 0 10px;*/
    /*    font-size: 2.0779vw;*/
    /*    line-height: 3.247vw;*/
    /*}*/

    .credit-card-company-logos {
        width: 5vw;
        float: right;
        padding: 1vw;
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .delivery-payments-section-order-summary-container {
        width: 100%;
    }

    /*.delivery-payments-section-shipping-payments-container {*/
    /*    width: 90%;*/
    /*    overflow-y: auto;*/
    /*    margin: 5% 5%;*/
    /*}*/

    .delivery-payments-section-order-summary-container .pricing-summary-details, .delivery-payments-section-order-summary-container .pricing-summary-shipping-line-item {
        font-size: 1rem;
        padding: 0.5rem 0;
    }

    /*.shipping-address-recipient-name, .shipping-address-street, .shipping-address-email {*/
    /*    margin-top: 0.25rem !important;*/
    /*    margin-bottom: 0.5rem !important;*/
    /*    width: 100% !important;*/
    /*    overflow-y: auto !important;*/
    /*}*/


    .StripeCheckout {
        margin-top: 0.5rem;
    }



    .shipping-address-country-locked {
        width: 49%;
        font-size: 1rem;
    }

    .country-selector-container {
        width: 100%;
    }

    #select-Country {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;
    }

    .select-country-form {
        width: 100%;
    }

    .country-selector-lable {
        width: 50%;
        padding: 0;
        margin: 0;
        float: left;
    }

    .country-selector-label span {
        font-family: Montserrat;
        font-weight: 500;
        color: #777;
        text-align: center;

    }

    .payment-section-container-header {
        width: auto;
        text-align: center;
        letter-spacing: 0.03vw;
        color: #72696A;
        font-weight: 600;
        font-size: 2.597vw;
        line-height: 3.117vw;
        margin: 2vw;
        text-transform: uppercase;
    }

    .checkout-progress-bar {
        width: auto;
        height: 84px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        text-align: center;
    }

    .cart-slider-shopped-item-delete {
        width: 20px;
        overflow: auto;
        float: right;
        margin-left: 34px;
        margin-top: 20px;
    }

    .checkout-progress-option-selected {
        border-bottom: 1.5px solid #3B3B3B;
    }

    .checkout-progress-option-left {
        width: auto;
        overflow-y: auto;
        margin-left: 80px;
        float: left;
        cursor: pointer;
    }

    .checkout-progress-option {
        width: auto;
        overflow-y: auto;
        margin-left: 65px;
        float: left;
        cursor: pointer;
    }
    .shipping-address-section-wrapper {
        width: 100%;
        overflow-y: auto;
        margin-bottom: 48px;
        overflow-x: hidden;
    }

    .shipping-address-recipient-name, .shipping-address-street, .shipping-address-suite, .discount-input-summary {
        width: calc( 100% - 6px);
        margin-top: 5.27px;
    }

    .shipping-address-email, .shipping-address-recipient-phone, .shipping-address-city, .province-state-selector-container, .shipping-address-postal-code  {
        width: calc( 50% - 6px);
        float: left;
        margin-right: 6px !important;
        margin-top: 5.27px !important;
    }

    .shipping-address-suite div {
        width: 100%;
    }

    .delivery-timeline-notification {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        padding-top: 8px;
        margin-bottom: 30px;
    }

}