.navbar-bottom-company-logo-container {
    text-transform: uppercase;
    letter-spacing: 2px;
}

.navbar-column-title-icons {
    width: 20px;
    float: left;
    margin-right: 10px
}




.navbar-bottom-sitemap {
    width: 100%;
    overflow-y: auto;
}

.navbar-sitemap-left-container, .navbar-sitemap-middle-container {
    float: left;
    overflow-y: auto;
}

.sitemap-button {
    width: auto;
    height: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5;
    display: inline-block;
    text-decoration: none;
    color: #514945;
    border-bottom: solid 1.5px #FCF9F8;
    transition: border 0.3s linear;
}

.sitemap-button:hover {
    border-bottom: solid 1.5px #FCC4BF;
}

.key-service-areas-header-text a:hover {
    border-bottom: solid 0.1rem #f59276;
}

#business-email-contact:hover {
    text-decoration: underline;
}

.navbar-right-container-image {
    width: 40%;
    overflow-y: auto;
    float: left;
}

.navbar-right-container-text {
    width: 55%;
    margin-left: 5%;
    float: left;
    font-size: 1rem;
    font-weight: 600;
}

.business-owner-header {
    font-size: 1rem;
    font-weight: 400;
    margin: 10px 0;
    width: 100%;
    overflow-y: auto;
    text-align: left;
}

.bottom-contact-message {
    text-decoration: none;
}

.bottom-contact-message:hover {
    text-decoration: underline;
}


.business-owner-button {
    background-color: #514945;
    color: white;
    font-size: 1rem;
    width: 302px;
    overflow-y: auto;
    padding: 11px 30px;
    text-align: center;
    margin-top: 10px;
}

.business-owner-button:hover {
    background-color: #F59276;
    color: white;
    transition: background 0.5s linear, color 0.3s linear;
}

.bottom-contact-message {
    float: left;
    width: 100%;
}

.fa-heart {
    color: #f59276;
}

::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::placeholder {
    color: #514945;
    font-size: 1rem;
    font-family: Montserrat;
}

input::placeholder {
    color: #514945;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.navbar-bottom-container {
    background: #3F5C6B;
    position: relative;
    color: white;
}

.email-subscription-input-field {
    border: solid 1px #514945;
    width: 219px;
    height: 1.2rem;
    padding: 10px 5px;
    font-size: 1rem;
    color: #514945;
}

.email-subscription-submit-button {
    background: #514945;
    border: solid 0.5px #514945;
    width: 130px;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    font-family: Montserrat;
    cursor: pointer;
    padding: 0.6rem 0 0.65rem;
}

.email-subscription-submit-button:hover {
    background-color: #F59276;
    color: white;
    border: solid 0.5px #F59276;
    transition: border 0.5s linear, background 0.5s linear, color 0.3s linear;
}

.navbar-sitemap-right-separator {
    width: 300px;
    height: 1px;
    background-color: #514945;
}

.navbar-bottom-uppercase {
    text-transform: uppercase;
}

.navbar-bottom-section {
    width: auto;
    overflow: auto;
    font-size: 0.8rem;
    text-align: left;
    line-height: 2;
    float: left;
    padding-top: 0.75rem;
    padding-left: 1rem;
}

.business-owner-button {
    cursor: pointer;
}

.email-field-no-show {
    display: none;
}

.email-input-alternative-message {
    width: 100%;
    color: #F59276;
    font-size: 1.2rem;
    height: 1.2rem;
    text-align: center;
    font-weight: 400;
}

.navbar-sitemap-right-container {
    overflow-y: auto;
    float: left;
}

.key-service-areas {
    width: calc( 100% - 4rem );
    overflow: auto;
    margin-bottom: 4rem;
    padding: 2rem;
}

.key-service-areas-header-text {
    font-weight: 400;
    text-align: center;
    font-size: 0.8rem;
    line-height: 2;
}


.navbar-bottom-column-questions {
    font-size: 1.11vw;
    letter-spacing: 0.0174vw;
    line-height: 1.736vw;
    font-weight: 600;
    color: #72696A;
}

.navbar-bottom-column-question {
    font-weight: 600;
    color: #72696A;
}










.navbar-bottom-column-phone, .navbar-bottom-column-email {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 225%;
    /* or 36px */
    letter-spacing: 0.02em;
    color: white;

}

.navbar-bottom-company-address-container div {
    color: #72696A;
}


/*For all*/



/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .navbar-bottom-company-info-section {
        width: 100%;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
    }

    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;
        overflow: auto;
        text-align: right;
        margin-bottom: 48px;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {

    .navbar-bottom-column-min-height {
        min-height: 250px;
    }

    .navbar-sitemap-left-container, .navbar-sitemap-middle-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
    }

    .navbar-bottom-happiness-guarantee-banner {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
    }

    .navbar-bottom-company-address-container {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: right;
        letter-spacing: 1.3px;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
        margin-top: 24px;
    }

    .navbar-sitemap-right-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
        line-height: 2;
    }

    .key-service-areas-header-text {
        width: 100%;
    }

    .navbar-bottom-section {
        width: auto;
        overflow: auto;
        font-size: 0.8rem;
        text-align: center;
        padding: 1rem;
    }

    .navbar-bottom-container {
        background: #3F5C6B;
        position: relative;
        color: white;
        width: calc(100% - 128px);
        padding: 64px;
    }

    .navbar-bottom-column {
        width: 50%;
        height: auto;
        float: left;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: #3B3B3B;
        margin-bottom: 64px;
    }

    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
        margin-right: 22.9px;
    }

    .navbar-bottom-company-info-section {
        width: 100%;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
    }

    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 100%;
        overflow: auto;
        text-align: right;
        margin-top: 24px;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }

    .navbar-bottom-options {
        width: 100%;
        overflow: auto;
    }




}

/*Normal Laptop*/

@media screen and (min-width: 1100px) and (max-width: 1919px) {

    .select-mat-rendering {
        width: 45%;
        margin-left: 2.5%;
        position: fixed;
        float: left;
        overflow-x: hidden;
    }

    .select-mat-rendering-event {
        /*width: 480px;*/
        width: 40%;
        margin-left: 10%;
        position: fixed;
        float: left;
        overflow: auto;
        /*margin: 0 calc( (100% - 960px) / 3 );*/
        overflow-x: hidden;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }


    .company-address-full-line {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        width: 100%;
        overflow: auto;
        text-align: right;
        margin-top: 72px;
    }
    .navbar-sitemap-left-container, .navbar-sitemap-middle-container {
        width: 25%;
    }

    .navbar-sitemap-right-container {
        margin-left: calc(50% - 362px);
        width: 362px;
    }

    .navbar-bottom-company-logo-container {
        font-size: 1rem;
    }

    .key-service-areas-header-text {
        width: 60%;
        margin-left: 20%;
    }

    .navbar-bottom-container {
        width: 100%;
        overflow: hidden;
        padding: 56px 0 24px 0;
        position: relative;
        color: white;
    }

    .navbar-bottom-column {
        width: 200px;
        height: auto;
        float: left;
        margin-right: 100px;
    }

    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        margin-bottom: 16px;
    }



    .navbar-column-buttons {
        width: 300px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
    }

    .navbar-bottom-company-address-container {
        width: 650px;
        overflow-y: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-happiness-guarantee-banner {
        margin-left: 22.9px;
        float: left;
        width: 502px;
        /* height: 48px; */
        /* left: 216px; */
        /* top: 371px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-leaf-vector {
        width: 15.69px;
        height: 16.86px;
    }

    .navbar-bottom-company-info-section {
        width: 1220px;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
        margin-left: calc( 50% - 610px);

    }

    .navbar-bottom-options  {
        width: 1220px;
        overflow: auto;
        margin-left: calc( 50% - 610px);
    }

    .navbar-bottom-options {
        width: 1220px;
        overflow: auto;
        margin: 0 calc( 50% - 610px);
    }


}

@media screen and (min-width: 1920px) {

    .navbar-bottom-options {
        width: 1220px;
        overflow: auto;
        margin: 0 calc( 50% - 610px);
    }

    .select-mat-rendering {
        width: 700px;
        overflow: auto;
        overflow-x: hidden;
        margin-left: 0px;
    }


    .select-mat-rendering-event {
        /*width: 480px;*/
        width: 30%;
        margin-left: 10%;
        position: fixed;
        float: left;
        overflow: auto;
        /*margin: 0 calc( (100% - 960px) / 3 );*/
        overflow-x: hidden;
    }


    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }

    .navbar-top-container {
        width: 1440px;
        margin-left: calc(50% - 720px);
    }


    .navbar-bottom-container {
        width: 1440px;
        overflow: hidden;
        padding: 56px 0 24px 0;
        position: relative;
        color: white;
        margin-left: calc(50% - 720px);
    }

    .navbar-bottom-happiness-guarantee-banner {
        margin-left: 22.9px;
        float: left;
        width: 502px;
        /* height: 48px; */
        /* left: 216px; */
        /* top: 371px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: white;
    }

    .navbar-bottom-company-info-section {
        width: 1220px;
        overflow: auto;
        border-top: 1px solid white;
        margin-top: 24px;
        padding-top: 24px;
        margin-left: calc( 50% - 610px);

    }

    .navbar-bottom-company-address-container {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: right;
        letter-spacing: 1.3px;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: white;
        margin-top: 24px;
    }

    .navbar-bottom-company-logo {
        width: 33.1px;
        height: 33.1px;
        float: left;
    }


}