@media screen and (min-width: 769px) and (max-width: 1100px) {

    .matboard-size-frations .window-size-input {
        width: 44%;
        float: left;
        margin-right: 5% !important;

    }

    .matboard-size-frations select {
        float: right;
        padding: 10.5px 14px;
        margin: 0;
        background: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        display: flex;
        align-items: center;
        text-align: center;
    }


    .select-mat-guidance-desc-long{
        width: calc( 100% - 55px );
        float: left;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .matboard-size-fractional-input {
        width: 65%;
        float: left;
    }

    .matboard-size-frations div {
        border-radius: 2px;
    }

    .mobile-and-tablet-display-only {
        display: inherit;
    }

    .artwork-margin-indicator-number-right {
        width: 40px;
        position: absolute;
        height: 18px;
        top: -21px;
        left: 30px;
        text-align: left;
    }

    .artwork-margin-move-down {
        position: absolute;
        left: calc(50% - 1.5vw);
        bottom: 1.5vw;
    }

    .artwork-margin-move-up {
        width: 3vw;
        height: 3vw;
        left: calc(50% - 1.5vw);
    }

    .switch-to-drawing-tool-container {
        width: 400px;
        margin-left: calc(50% - 200px);
        text-align: center;
        margin-top: 32px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        text-transform: uppercase;
        cursor: pointer;
    }

    .checkout-subtotal-figure {
        width: auto;
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 8px;
    }

    .select-mat-row-divider {
        width: calc(100% - 36px);
        overflow: auto;
        float: right;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 12px;
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .req-mat-sample-button-cover {
        /* Primary/Light Gray */
        width: 445px;
        overflow: auto;
        margin-left: calc( 50% - 232.5px );
        background: #F2F2F2;
        border-radius: 2px;
        /* Inside Auto Layout */
        flex: none;
        order: 3;
        flex-grow: 0;
        padding: 10px;
    }
    .live-help-contact-box {
        position: fixed;
        width: 60vw;
        overflow: auto;
        background: white;
        right: 2vw;
        bottom: 7.5vw;
        padding: 2vw;
        line-height: 5vw;
        color: #72696A;
        font-weight: 400;
        border: #F59276;
        border: solid 0.2vw #F59276;
        border-radius: 1vw;
        z-index: 10;
    }

    .live-help-contact-box a {
        text-decoration: underline;
        color: #72696A;
    }

    .live-help-contact-box-close {
        margin-top: 1vw;
        text-align: center;
        cursor: pointer;
    }

    .live-help-contact-box-close:hover {
        text-decoration: underline;
    }

    .live-help-button {
        width: 4vw;
        height: 4vw;
        position: fixed;
        right: 2vw;
        bottom: 2vw;
        cursor: pointer;
    }


    .select-mat-section-frame-buttons {
        width: 100%;
        overflow: auto;
        margin-top: 24px;
    }

    .color-modal-open-icon {
        width: 3vw;
        margin: 0 calc(50% - 1.5vw);
        cursor: pointer;
    }

    .req-mat-sample-button {
        width: 50%;
        float: left;
        font-size: 2.0779vw;
        line-height: 3.247vw;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .product-rendering-subcontrol-button {
        font-size: 2.0779vw;
        line-height: 3.247vw;
        /*color: #72696A;*/
        font-weight: 400;
        border: solid 0.1vw rgba(0, 0, 0, 0.23);
        float: left;
        text-align: center;
        width: 47%;
        margin: 0 1%;
        padding: 1vw 0;
        border-radius: 0.25vw;
        cursor: pointer;
    }

    .select-mat-window-quantity-row-column-transition {
        width: 9px;
        overflow: auto;
        float: left;
        padding: 25px 35px 0 35px;
        text-align: center;
        overflow: hidden;
        color: #3B3B3B;
    }

    .product-rendering-subcontrol-button:hover {
        background: rgba(0, 0, 0, 0.23);
        text-align: center;
    }

    .product-rendering-subcontrol-button-image {
        width: 3vw;
        float: left;
        margin: 0 0.5vw;
        cursor: pointer;
    }

    .product-rendering-subcontrol-button-image-text {
        float: left;
    }


    .artwork-margin-indicator-number-up {
        width: 40px;
        height: 18px;
        position: absolute;
        bottom: 30%;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-down {
        width: 6vw;
        height: 3vw;
        left: 5px;
        text-align: left;
    }

    .artwork-margin-indicator-number-left {
        width: 40px;
        height: 18px;
        position: absolute;
        top: -20px;
        right: 30px;
        text-align: right;
    }

    .select-mat-window-quantity-header, .multiple-window-artwork-size-selection-panel {
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .multiple-window-opening-modal {
        width: 100%;
        overflow: auto;
        padding-top: 9px
    }

    .select-mat-rendering-event {
        width: 60%;
        z-index: 50;
        background: white;
        padding-bottom: 32px;
        margin-top: 140px;
        overflow-x: hidden;
        padding-left: 20%;
        padding-right: 20%;
    }


    .select-mat-rendering {
        width: 60%;
        z-index: 50;
        background: white;
        padding-bottom: 32px;
        margin-top: 140px;
        overflow-x: hidden;
        padding-left: 20%;
        padding-right: 20%;
    }

    .select-matboard-window {
        box-shadow: inset 0 0.05rem 0.05rem rgba(10,10,10,.8);
        float: left;
    }

    .select-matboard-description-container {
        width: 480px;
        overflow: auto;
        float: left;
        margin-left: calc( (100% - 480px) / 2 );
        margin-top: 16px;
    }

    .select-mat-window-quantity-container .display label span {
        line-height: 3.247vw;
        font-size: 2.0779vw;
    }

    .change-frame-orientation-instructions {
        float: left;
        margin-right: 0.5rem;
    }

    .multiple-window-artwork-size-selection-panel-title {
        width: 100%;
        font-weight: 600;
        color: #72696A;
        text-transform: uppercase;
        font-size: 2.597vw;
        line-height: 3.117vw;
        padding: 2vw 0;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field input {
        padding-top: 2px !important;
    }

    .window-opening-field label + .MuiInput-formControl-24 {
        margin-top: 0px !important;
    }

    .artwork-dimension-view {
        height: 7vmin;
    }

    .iconSize {
        width: 3vw;
        height: 3vw;
    }

    .artwork-margin-controller {
        font-size: 2.08vw;
    }

    .artwork-margin-controller-up, .artwork-margin-controller-down {;
        font-size: 2.08vw;
    }

    .artwork-margin-controller-up {
        width: 1.5vw;
        height: 1.5vw;
        text-align: center;
        position: absolute;
        left: calc( 50% - 0.75vw );
    }

    .artwork-margin-controller-left {
        width: 3vw;
        height: 3vw;
        top: calc(50% - 1.5vw);
        position: absolute;
    }

    .artwork-margin-controller-right {
        width: 3vw;
        height: 3vw;
        float: right;
        font-size: 2.08vw;
        position: absolute;
        right: 0;
        top: calc(50% - 1.5vw);
    }

    .artwork-margin-controller-down {
        width: 100%;
        height: 3vw;
        /* margin: 0 calc( (100% - 6vw) / 2); */
        text-align: center;
        /* margin-top: 1vw; */
        position: absolute;
        bottom: -1vw;
    }

    .artwork-margin-indicator-up {
        font-weight: 500;
        position: absolute;
        float: left;
        border-left: solid 0.2vw;
        left: 50%;
        bottom: 100%;
    }

    .artwork-margin-indicator-down {
        /* font-size: 2.08vmin; */
        margin-top: 2.1vw;
        /* margin-left: -3vw; */
        border-right: solid 0.2vw;
        font-weight: 500;
        position: absolute;
        left: 50%;
        bottom: 1vw;
    }

    .artwork-margin-indicator-left {
        float: left;
        width: 6vw;
        padding: 0.3vw 0;
        border-top: solid 0.2vw;
        position: absolute;
        right: 100%;
        top: 50%;
    }

    .artwork-margin-indicator-right {
        float: right;
        padding: 0.3vw 0;
        border-top: solid 0.2vw;
        text-align: center;
        position: absolute;
        left: 100%;
        top: calc(50%);
    }

    .artwork-margin-move-right {
        float: left;
        margin: 0;
    }

    .artwork-margin-move-left {
        float: right;
        margin: 0;
    }

    .artwork-margin-indicator-number-down {
        width: 40px;
        height: 18px;
        position: absolute;
        left: 5px;
        text-align: left;
        top: 30%;
    }

    .matboard-window-central-controller {
        width: 100%;
        position: absolute;
        top: 22.5%;
        height: 50%;
        position: absolute;
    }

    .select-matboard-modal-subtitle {
        font-size: 2vmin;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-opening-field-form-aid {
        font-size: 2vmin;
    }

    .window-opening-field-exit-container {
        padding: 0 10vmin 1vmin 10vmin;
    }

    .window-opening-field-exit {
        width: 10vw;
        padding: 1vw;
    }

    .window-opening-illustration-image-file {
        width: 50%;
        margin-left: 25%;
        margin-bottom: 1vmin;
    }


    .matboard-window-action-icon-container img {
        cursor: pointer;
        width: 3vw;
    }

    .matboard-window-size-indicator {
        font-size: 2vw;
    }


    .product-rendering-subcontrol-button-image-text:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .matboard-colour {
        width: 100%;
        height: 100%;
    }

    .select-matboard-description-container .matboard-selected-colour {
        float: right;


    }

    .matboard-selected-colour {
        width: 32px;
        height: 32px;
        border: 1px solid #666161;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 0;
        margin-left: 12px;
    }

    .select-matboard-window-caption-text {
        width: 250px;
        overflow: auto;
        float: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: #3b3b3b;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
    }

    .matboard-selector-checkmark-container {
        position: absolute;
        margin: -5.5vw 0 0 2.5vw;
    }

    .matboard-selected-colour-container {
        width: 30px;
        height: 30px;
        float: left;
        margin: 6px;
        border-radius: 1px;
        cursor: pointer;
        border: 0.5px solid #666161;
    }

    .change-matboard-colour-layout {
        width: 100%;
        overflow: auto;
    }

    .frame-size-desc-dimensions {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-top: 0.25vw;
    }

    .frame-size-desc-button {
        width: auto;
        overflow-y: auto;
        float: left;
        margin-left: 0.5vw;
        text-decoration: underline;
    }

    .multiple-window-artwork-size-selection-panel {
        width: 100%;
        overflow: auto;
        border-top: solid 1px #828282;
    }

    .matboard-only-frame-size-selector {
        width: 100%;
        overflow: auto;
    }

    .matboard-only-frame-size-selection-modal {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }

    .matboard-size-selection-demo-photo {
        width: 80%;
        margin-left: 10%;
    }

    .select-your-frame-size-modal {
        width: 100vmin;
        background: #FEF4F1;
        overflow: auto;
        margin: 1.5vmin calc(50% - 51.5vmin);
        border-radius: 1vmin;
    }

    .select-frame-size-leader {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        width: 100%;
        text-align: center;
    }

    .show-how-to-measure-a-frame {
        width: 100%;
        text-align: center;
        line-height: 3.247vw;
        font-size: 2.0779vw;
        float: left;
        color: #72696A;
        cursor: pointer;
        margin-top: 0.75vw;
    }

    .change-matboard-colour-button {
        line-height: 3.247vw;
        font-size: 2.0779vw;
        width: 100%;
        overflow: auto;
        text-align: center;
        padding-top: 1vw;
        color: #72696A;
        cursor: pointer;
    }

    .select-matboard-subdivider {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
    }

    .add-to-bag-button {
        width: 150px !important;
        overflow: auto;
        background: #3B3B3B;
        border-radius: 30px;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 8px 16px;
        float: left;
        cursor: pointer;
    }

    .select-mat-container-row {
        width: 200px;
        float: right;
        text-align: right;
        line-height: 150%;
        font-size: 16px;
    }

    .select-mat-row {
        width: 100%;
        overflow: auto;
        float: left;
    }

    .product-desc-dividers-header {
        font-weight: 600;
        margin-top: 25px;
    }

    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        float: left;
    }


    .product-desc-dividers {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        float: left;
    }

    .select-matboard-window-caption-arrow {
        width: 18px;
        height: 18px;
        float: left;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        margin-top: 4px;
    }

    .select-mat-size-option-toggle {
        border-top: 1px solid #828282;
        width: calc(100% - 36px);
        overflow: auto;
        margin-left: 36px;
    }

    .select-mat-sub-row {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .select-mat-guidance-icon {
        width: 24px;
        height: 24px;
        float: left;
        overflow: auto;
        margin: 12px 12px 12px 0;
    }

    .select-mat-guidance-desc {
        width: calc( 100% - 72px - 55px );
        float: left;
        overflow: auto;
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .select-mat-guidance-button {
        width: 50px;
        overflow: auto;
        float: right;
        /* Body 2 (Underlined Body) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        /* identical to box height, or 24px */

        text-decoration-line: underline;

        /* Primary/Soft Black */

        color: #3B3B3B;
    }

    .select-window-multiple-window-dimensions {
        width: 181px;
        overflow: auto;
        float: left;
    }

    .single-diploma-mat-box {
        width: 45%;
        overflow: auto;
        float: left;
    }

    .select-window-multiple-window-dimensions input {
        padding: 9px 0 !important;
    }

    .matboard-colour-name-desc {
        width: auto;
        overflow: auto;
        margin-top: 5px;
        color: #3b3b3b;
    }

    .select-mat-input-field-direction, .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        /* Secondary/Dark Gray */

        color: #666161;
    }

    .select-mat-input-field-direction-long {
        width: 100%;
        overflow: auto;
        display: block;
    }

    .select-mat-request-samples {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #3B3B3B;
        margin: 10px 0 0 0;
        cursor: pointer;
    }

    .MuiIconButton-root-123 {
        padding: 0.5vw 1vw !important;
    }

    .select-mat-window-quantity-row div label, .select-mat-window-quantity-column div label {
        background: white;
    }

    .window-opening-field div {
        margin: 0 !important;
    }

    .window-size-input, .window-size-input label, .window-size-input input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #3B3B3B;
        border: solid 1px #666161;
        border-radius: 2px;
    }

    .backing-mat-instructions {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .select-matboard-window-caption-selected-colour-text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
        text-align: right;
        width: auto;
        float: right;
    }

    .show-secondary-mat-colour-container {
        width: 100%;
        overflow: auto;
        margin: 10px 0;
    }

    #choose-double-matting-option {
        margin-bottom: 28px;
    }

    .select-mat-navigation-icon {
        float: left;
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .select-mat-navigation-script {
        /* Body */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }


}