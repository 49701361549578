/*For all*/

#what-we-do-container {
    width: auto;
    overflow-y: auto;
    background-color: #f1ece4;
    /*margin-top: 4rem;*/
}

.how-it-works-steps-image {
    object-fit: cover;
}

.how-it-works-steps-image-container, .how-it-works-steps-content {
    width: 100%;
    text-align: center;
}

.how-it-works-steps-sub-content-option {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 500;
    margin: 1rem 0 0 0;
}

.how-it-works-steps-sub-content-option:hover {
    text-decoration: underline;
}

.how-it-works-quicklink {
    width: 97%;
    overflow-y: auto;
    color: #858585;
    text-align: center;
    cursor: pointer;
    /* text-decoration: underline; */
    margin: 5vh 1rem;
    float: left;
}

.how-it-works-quicklink:hover {
    text-decoration: underline;
}

.how-it-works-quicklink:hover {
    color: #f59276;
}

.how-it-works-steps-content {
    padding-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.2;
    color: #514945;
}

a {
    text-decoration: none;
}

.how-it-works-steps-content-desc {
    width: 100%;
    overflow: auto;
    margin-top: 1rem;
    text-align: center;
    line-height: 2;
    color: #858585;
}

#frequently-asked-question-button {
    color: #514945;
}

.how-it-works-step-container {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}


/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    .how-it-works-steps {
        width: auto;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 2rem calc( 50% - 10rem );
    }

    /*.section-topic-header {*/
        /*font-size: 1.3rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 15rem;
        height: 15rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 7.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 2rem;
    }

    .how-it-works-container {
        padding: 5rem 0.5rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 769px) and (max-width: 1450px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 1.5rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 15rem;
        height: 15rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 7.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 2rem;
    }

    .how-it-works-container {
        padding: 5rem 3rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 2rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 25rem;
        height: 25rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 12.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 4rem;
        font-size: 1.5rem;
    }

    .how-it-works-container {
        padding: 15rem;
    }

    #how-it-works-container {
        padding: 5rem 3rem;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {

    .how-it-works-steps {
        width: 47%;
        min-width: 20rem;
        overflow-y: auto;
        float: left;
        margin: 3rem 1.5%;
    }

    /*.section-topic-header {*/
        /*font-size: 2rem;*/
    /*}*/

    .how-it-works-steps-image {
        width: 25rem;
        height: 25rem;
        border-radius: 100%;
        margin: 0 calc( 50% - 12.5rem);
    }

    .how-it-works-steps-content {
        padding-top: 4rem;
        font-size: 1.5rem;
    }

    .how-it-works-container {
        padding: 5rem 30rem;
    }

    #how-it-works-container {
        padding: 5rem 20%;
        background: rgba(255,0,0,0.05);
        width: auto;
        overflow: auto;
    }

    .how-it-works-steps-content-desc {
        font-size: 1.3rem;
    }
}
