/*For all*/

.customer-review-header {
    text-align: center;
    letter-spacing: 0.67px;
    margin: 5.55vh 0;
    color: #72696A;
    font-size: 2.222vw;
    font-size: 1.39vw;
    line-height: 1.67vw;
    font-weight: 600;
    text-transform: uppercase;
}


.navbar-column-title-divider {
    width: 100%;
    height: 16px;
}

.eapg-photo-gallery-loadMore {
    width: 280px;
    margin: 0 auto;
    padding: 16px 60px;
    display: block !important;
    border: 2px solid #111;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: 0.3s ease all;
    text-align: center;
}


.customer-review-section-customer-location {
    margin-top: 1rem;
}

.more-review-button {
    width: 100%;
    overflow: auto;
    text-align: center;
    margin-top: 2.5vh;
}


.customer-review-container {
    width: 100%;
    overflow-y: auto;
}



/*Mobile Only*/

@media screen and (min-width: 0px) and (max-width: 769px) {

    .support-v2:hover {
        cursor: pointer;
    }

    .customer-review-section-divider {
        width: 50vw;
        overflow: auto;
        margin-left: calc((100% - 50vw) / 2);
    }

    .customer-review-section-divider div {
        width: 12.5vw;
        overflow: auto;
        text-align: center;
        float: left;
        cursor: pointer;
    }


    .overall-review-stars {
        width: 100%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        font-size: 5vw;
        line-height: 1.5;
        text-align: center;
        margin-left: 0;
        margin-bottom: 2.5%;
    }

    .customer-review-dot-not-selected {
        font-size: 2vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-dot-selected {
        font-size: 2vw;
        text-align: center;
        color: #F59276;
    }

    .customer-review-number {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.5;
        font-size: 5vw;
        text-align: center;
    }

    .customer-review-container {
        padding-bottom: 5vh;
    }

    .customer-review-header {
        margin-top: 12.5vw;
        margin-bottom: 4.6875vw;
        font-size: 7.5vw;
        line-height: 17.8125vw;
        letter-spacing: 0.15625vw;
    }

    .customer-review-section-row {
        width: 90vw;
        overflow: auto;
        padding-bottom: 12.5vw;
        margin: 5vh 5vw;
        border-bottom: 0.3125vw solid #DEDAD3;
    }

    .customer-review-selected, .customer-review-not-selected {
        width: 12.5vw;
        height: 2.5vw;
        float: left;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 3.125vw;
    }


    .customer-review-section-row-left, .customer-review-section-row-right {
        width: 90vw;
        overflow: auto;
        margin: 7.5vw 0 0 0;
    }

    .customer-review-section-row-right {
        line-height: 7.8125vw;
    }

    .customer-review-section-customer-location {
        margin: 2.5vw 0;
        font-size: 5vw;
        color: #72696A;
    }

    .customer-review-section-customer-name {
        font-size: 6.25vw;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.13125vw;
        color: #72696A;
    }

    .customer-review-section-customer-review-content {
        font-size: 5vw;
        color: #72696A;
    }

    .customer-review-section-customer-review-header {
        font-size: 5vw;
        margin-bottom: 2.5vw;
        text-transform: uppercase;
        font-weight: 600;
        color: #72696A;
        line-height: 7.8125vw;
    }

    .navbar-bottom-options {
        width: auto;
        overflow-y: auto;
        padding: 0 16px 0 16px;
    }


    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }

    .navbar-bottom-column {
        font-size: 3.75vw;
        line-height: 5.625vw;
        text-align: center;
        margin-top: 12.5vw;
    }

    .navbar-column-buttons {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        color: white;
        text-align: left;
    }

    .navbar-bottom-column-email, .navbar-bottom-column-question, .navbar-bottom-column-phone {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 225%;
        letter-spacing: 0.02em;
        color: white;
        text-align: left;
    }

    .navbar-bottom-company-logo {
        width: 35.625vw;
        margin: 8.75vw calc((100% - 35.625vw) / 2) 7.5vw;
    }

    .navbar-bottom-company-address-container {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: right;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        width: auto;
        margin: 0 16px 63.72px;
    }

    .mobile-display-only {
        display: inline-block;
    }



}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 769px) and (max-width: 1100px) {

    .support-v2:hover {
        cursor: pointer;
    }

    .overall-review-stars {
        width: 60%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
        text-align: center;
        margin-left: 20%;
        margin-bottom: 2.5%;
    }

    .customer-review-dot-not-selected {
        font-size: 0.75vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-dot-selected {
        font-size: 0.75vw;
        text-align: center;
        color: #F59276;
    }

    .customer-review-number {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
    }

    .customer-review-section-row-left {
        width: 100%;
        overflow-y: auto;
        float: left;
        margin-bottom: 1rem;
        text-align: center;
    }

    .customer-review-section-row-right {
        width: calc( 100% - 24.675vw);
        overflow: auto;
        font-size: 5vw;
        line-height: 1.5;
        text-align: left;
    }


    #business-owner-alternative, #business-email-contact {
        text-align: center;
    }

    .customer-review-container {
        width: auto;
        padding: 5.70vw 7.5%;
    }

    .customer-review-header {
        text-align: center;
        letter-spacing: 0.67px;
        margin: 5.55vh 0;
        color: #72696A;
        font-size: 1.39vw;
        line-height: 1.67vw;
        font-weight: 600;
        text-transform: uppercase;
    }

    .customer-review-section-divider {
        width: 20.79vw;
        overflow: auto;
        margin-left: calc((100% - 20.79vw) / 2);
    }

    .customer-review-dot-selected {
        font-size: 0.75vw;
    }

    .customer-review-section-divider div {
        width: 5.194vw;
        overflow: auto;
        text-align: center;
        float: left;
        cursor: pointer;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 1.298vw;
    }

    .customer-review-section-row {
        padding: 6.234vw 0;
        width: auto;
        overflow: auto;
        letter-spacing: 0.05rem;
        margin: 0;
        border-bottom: 0.0694vw solid #DEDAD3;
    }

    .customer-review-section-row-left {
        width: 24.675vw;
        overflow-y: auto;
        float: left;
        text-align: left;

    }

    .customer-review-section-customer-name {
        font-weight: 600;
        font-size: 2.597vw;
        text-transform: uppercase;
        color: rgba(0,0,0,0.5);
        line-height: 3.117vw;
        letter-spacing: 0.061vw;
    }

    .customer-review-section-customer-location {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.116vw;
        font-size: 2.078vw;
    }

    .customer-review-section-customer-review-header {
        font-size: 2.597vw;
        color: #72696A;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        line-height: 3.117vw;
        font-weight: 600;
    }

    .customer-review-section-customer-review-content {
        margin-top: 1.039vw;
        font-weight: 300;
        color: #72696A;
        line-height: 3.247vw;
        font-size: 2.078vw;
    }

    .mobile-display-only {
        display: none;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) {

    .support-v2:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .customer-review-dot-not-selected {
        font-size: 0.5vw;
        text-align: center;
        color: #72696A;
    }

    .customer-review-number {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .customer-review-dot-selected {
        font-size: 0.5vw;
        text-align: center;
        color: #F59276;
    }

    .overall-review-stars {
        width: 40%;
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
        /* float: right; */
        text-align: center;
        margin-left: 30%;
        margin-bottom: 2.5%;
    }

    .customer-review-container-new-design {
        margin: 1.67vw 1.81vw;
    }

    .customer-review-container {
        margin: 1.67vw 1.81vw;
        width: auto;
        overflow: auto;
        padding: 5.70vw 0;
    }

    .customer-review-section-row-left {
        width: 30%;
        overflow-y: auto;
        float: left;
    }

    .customer-review-section-row-right {
        width: auto;
        overflow: auto;
        line-height: 1.5;
    }

    .customer-review-section-customer-review-header {
        font-weight: 600;
        font-size: 1.39vw;
        text-transform: uppercase;
        letter-spacing: 0.029vw;
        color: rgba(0,0,0,0.5);
        line-height: 1.67vw;
    }

    .customer-review-section-divider {
        width: 11.12vw;
        overflow: auto;
        margin-left: calc((100% - 11.12vw) / 2);
    }

    .customer-review-section-divider div {
        width: 2.78vw;
        overflow: auto;
        float: left;
        cursor: pointer;
        text-align: center;
    }

    #customer-review-section-divider-1, #customer-review-section-divider-2 {
        margin-left: 0.69vw;
    }

    .customer-review-section-row {
        padding: 2.78vw 0;
        width: auto;
        overflow: auto;
        letter-spacing: 0.05rem;
        margin: 0 13.33vw;
        border-bottom: 0.0694vw solid #DEDAD3;
    }

    .customer-review-section-customer-name {
        font-weight: 600;
        font-size: 1.39vw;
        text-transform: uppercase;
        letter-spacing: 0.029vw;
        color: rgba(0,0,0,0.5);
        line-height: 1.67vw;
    }

    .customer-review-section-customer-location {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .customer-review-section-customer-review-content {
        margin-top: 0.56vw;
        font-weight: 300;
        color: #72696A;
        line-height: 1.74vw;
        font-size: 1.11vw;
    }

    .mobile-display-only {
        display: none;
    }

}



.customer-review-selected {
     background-color: #F59276;
}

.customer-review-not-selected {
    background-color: #F8F6F2;
}

.customer-review-container a {
    font-weight: 400;
    color: #72696A;
    text-align: center;
    width: 100%;
}

#navbar-column-title-checkout-section {

}

.customer-review-container {
    width: 96%;
    margin-top: 160px;
    padding: 2%;
    overflow: hidden;
}



@media screen and (min-width: 1440px) {

    .customer-review-container {
        width: 1360px;
        margin-left: calc(50% - 720px);
        margin-top: 160px;
        padding: 40px;
        overflow: hidden;
    }

    #navbar-column-title-checkout-section, .cart-slider-shopped-item-right, .item-total-title, .item-total-amount {
        font-size: 16px;
    }

    .navbar-bottom-column {
        width: 200px;
        height: auto;
        float: left;
        margin-right: 100px;
    }


    .navbar-column-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: white;
        text-align: left;
        margin-bottom: 16px;
    }


    #cart-slider-checkout-button {
        padding: 0.75vw 0 !important;
    }
}
