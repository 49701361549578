

@media screen and (min-width: 0px) and (max-width: 769px) {

    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: 25%;
        border: solid 1px #666161;
        border-radius: 2px;
        margin: 10px 20px;
    }



    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 0 auto 24px auto;
        color: white;
        width: auto;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-delete {
        cursor: pointer;
        width: 20px;
        overflow-y: auto;
        float: right;
        margin-left: 34px;
        margin-top: 20px;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -35px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .custom-mat-design-diagram {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow-y: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        overflow: auto;
        background: #F2F2F2;
        border-radius: 2px;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

    }

    .discount-info-desc {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #78A891;
    }

    .cart-slider-item-container {
        width: 100%;
        overflow: auto;
        border-top: solid 1px;
        margin-top: 24px;
        padding-top: 24px;
        border-bottom: solid 1px;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 24px 16px 24px;
    }

    .cart-slider-ending {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        margin-top: 24px;
        padding-bottom: 300px;
    }

    .shopping-cart-subtotal-container {
        width: 100%;
        overflow: auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-align: center;
    }

    .cart-slider-ending-subheading {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 16px;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
        padding-bottom: 20px;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }

    .cart-slider-container {
        width: 100%;
        z-index: 100;
        background: rgba(0,0,0,0.5);
        position: fixed;
        top: 90px;
        padding-bottom: 200px;
    }

    .cart-slider-content {
        width: 92%;
        height: 100vh;
        overflow-y: auto;
        /*position: absolute;*/
        right: 0;
        background: white;
        padding: 0 4%;
        z-index: 100;
        margin-top: -5px;
    }

    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        text-align: center;
    }

    #checkout-cart-slider {
        float: right;
        margin: 0vw 5vw 0 0;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 20px;
        width: 28px;
        height: 28px;
    }

    .cart-slider-second-mat-item-details {
        width: 70%;
        overflow-y: auto;
        float: left;
    }

    .cart-slider-second-mat-item-price {
        width: 30%;
        overflow-y: auto;
        float: left;
    }

    .cart-slider-free-shipping-banner {
        width: 100%;
        float: left;
        margin: 24px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        background: #FAFCFA;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
        margin-right: 16px;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
    }

    .navbar-column-title-checkout-section {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }


    .cart-slider-shopped-item-right {
        width: 60px;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-total {
        width: 100%;
        overflow: auto;
        text-align: right;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
        margin: 0 calc( 50% - 59px );
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-quantity {
        width: 60%;
        overflow: auto;
        float: left;
    }

    .cart-slider-shopped-item-quantity-add-padding {
        margin: 0 5% !important;
    }

    .cart-slider-second-mat-item {
        padding-bottom: 2.5%;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid #F2F2F4;
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        overflow: auto;
        text-align: center;
    }

    .item-total-coupon-section {
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 50%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 100%;
        text-align: center;
        padding-top: 5vw;
        padding-bottom: 5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        line-height: 20px;
        font-size: 16px;
        margin: 0;

    }

    .customer-satisfaction-policy-banner {
        font-weight: 400;
        line-height: 1.5;
        padding: 0.5vw;
        width: 98%;
        overflow: auto;
        text-align: left;
        margin-left: 1%;
        font-size: 3.75vw;
    }

    .discount-tier-info {
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        margin-top: 10px
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        margin-left: calc(50% - 59px);
        width: 86px;
        padding: 8px 16px;
        text-align: center;
        float: left;
        margin-bottom: 16px;
    }

    .cart-slider-shopped-item-center-center {
        width: calc(100% - 60px);
        overflow: auto;
        float: left;
    }

    .cart-slider-second-mat-container {
        width: calc( 100% - 64px);
        overflow: auto;
        margin-left: 64px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 45px;
        margin: 3px auto 0;
    }

    .cart-slider-shopped-item-quantity-button {
        width: auto;
        overflow: auto;
        float: left;
        font-size: 26px;
        margin-top: 17.5px;
        cursor: pointer;
    }

    .cart-slider-item-controller {
        width: calc( 100% - 64px);
        margin-left: 64px;
        overflow: auto;
    }

    /*.cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {*/
    /*    width: 137px;*/
    /*    overflow: auto;*/
    /*    float: left;*/
    /*    margin: 0;*/
    /*}*/

    .design-guide-colour-container {
        width: 90%;
        overflow: auto;
        padding: 40px 5%;
        margin: 0 auto;
    }

    .design-guide-section-containe-type-2 {
        width: 70%;
        text-align: center;
        overflow: auto;
        position: absolute;
        background: white;
        z-index: 100;
        padding: 0 0 20px 0;
        margin: 0 15%;
    }

    .design-guide-section-button-type-2 {
        width: auto;
        padding: 10px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
    }


}