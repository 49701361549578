
/*For all codes Only*/
@media screen and (min-width: 0px) {



    .diploma-tool-aspect-ratio-desc {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
    }

    .drawing-pad-example-image-section {
        width: 100%;
    }

    /*.drawing-pad-example-image-section {*/
    /*    width: 90%;*/
    /*    margin: 0 5%;*/
    /*}*/

    .drawing-board-direction-font {
        text-transform: none;
    }
    
    .full-width {
        width: 100%;
        overflow: auto;
        display: inline-block;
    }
    
    .flex-box {
        width: auto;
        overflow: auto;
        display: inline-block;
    }

    .frame-size-desc-options {
        padding: 0.5vw 0;
    }




    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        border: none !important;
    }

    .drawing-app-main-buttons:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .add-matboard-note-section {
        width: 100%;
        overflow: auto;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .add-matboard-note-section-input-field {
        width: 100%;
        margin: 2vh 0;
    }

    .add-matboard-note-section-input-field div {
        padding: 12px 16.7px;
    }

    .add-matboard-note-section-input-field textarea {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

    }



    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }

    .showing-drawing-pad-example {
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 30;
        background: rgba(0,0,0,0.5);
    }



    #showing-drawing-pad-example-closing-modal-icon {
        width: 150px;
        text-align: center;
        border: solid 0.05vw #F59276;
        margin: 2% calc(50% - 75px);
    }

    #showing-drawing-pad-example-closing-modal-icon:hover {
        color: white;
        background: #F59276;
    }

    .drawing-pad-example-image-container {
        width: 90%;
        margin: 2.5% 5%;
    }



}

/*Mobile*/
@media screen and (min-width: 0px) and (max-width: 769px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 45%;
        margin: 0 2.5%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 30vh;
        border-radius: 2px;
        background: #F2F2F2;
    }


    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

    .showing-drawing-pad-example-modal {
        width: 90%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 5%;
        border-radius: 1vmin;
    }

    .drawing-pad-example-image-section {
        width: 100%;
        margin-top: 2.5%;
    }

    .fetch-note-details-container {
        width: 100%;
        overflow: auto;
        font-size: 3.75vw;
        line-height: 5.9375vw;
        font-weight: 300;
    }


    .drawing-app-directions {
        font-size: 4vw;
        line-height: 2;
        padding: 0.5vw 1vw 0.5vw 0;
        margin: 6vw 1vw 3vw 0;
        font-weight: 400;
        text-transform: none;
        width: auto;
        overflow: auto;
        float: left;
    }

    .select-mat-frame-drawing-canvas {
        z-index: 10;
    }

    .req-mat-sample-button-divider {
        display: none;
    }

    .drawing-board-direction-font {
        width: 100%;
        overflow: auto;
    }

    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }

    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }
    
    .frame-size-desc-title-header-margin {
        width: 100%;
        overflow: auto;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }
}

/*Tablet, Web Half Screen*/
@media screen and (min-width: 769px) and (max-width: 1100px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 50%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 35vh;
        border-radius: 2px;
        background: #F2F2F2;
    }


    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .custom-design-padding-box {
        margin-left: calc( 50% - 81px);
        margin-right: calc( 50% - 81px);
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

    .showing-drawing-pad-example-modal {
        width: 90%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 5%;
        border-radius: 1vmin;
    }

    .fetch-note-details-container {
        width: calc(100% - 120px);
        font-size: 2.0779vw;
        line-height: 3.247vw;
        font-weight: 300;
    }

    .custom-mat-design-diagram {
        width: 288px;
        overflow: auto;
        float: left;
        margin: 16px 0;
    }

    .custom-mat-design-diagram img {
        width: 100%;
        height: 191px;
        margin-top: 16px;
    }

    .drawing-app-directions {
        line-height: 2;
        font-size: 2.0779vw;
        padding: 0.5vw 1vw 0.5vw 0;
        margin: 4vw 1vw 2vw 0;
        font-weight: 400;
        text-transform: none;
        width: auto;
        overflow: auto;
        float: left;
    }

    .select-mat-frame-drawing-canvas {
        z-index: 10;
    }

    .req-mat-sample-button-divider {
        display: none;
    }

    .drawing-board-direction-font {
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        color: #72696A;
        width: 100%;
        text-align: center;
        text-decoration: underline;
    }

    .drawing-app-main-buttons {
        font-size: 16px;
        line-height: 150%;
        padding: 16px;
        background: #F2F2F2;
        border-radius: 0 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }

    .frame-size-desc-title-header-margin {
        width: 100%;
    }

    .custom-design-padding-box {
        width: auto !important;
    }

    .drawing-app-saving-status {
        font-size: 12px;
        line-height: 150%;
        margin: -3vw -25vw 0 0;
        float: right;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

}

/*Web*/
@media screen and (min-width: 1100px) {

    .diploma-tool-selection {
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0 0 16px 0px;
        width: 50%;
        text-align: center;
        /* margin-left: calc( 50% - 200px); */
        text-transform: uppercase;
        cursor: pointer;
        float: left;
    }

    .diploma-mat-container .select-mat-frame {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    .signatureCanvas {
        border: 0.05vw solid #F2F2F2;
        width: 99%;
        height: 45vh;
        border-radius: 2px;
        background: #F2F2F2;
    }




    .drawing-pad-example-small-image-section-container-left {
        width: 50%;
        float: left;
    }

    .drawing-pad-example-small-image-section {
        width: 100%;
        float: left;
    }

    .showing-drawing-pad-example-modal {
        width: 50%;
        height: 94vh;
        background: #FEF4F1;
        overflow: auto;
        margin: 3vh 25%;
        border-radius: 1vmin;
    }

.fetch-note-details-container {
    width: calc(100% - 120px);
    overflow-y: auto;
}

.custom-mat-design-diagram {
    width: auto;
    overflow: auto;
}

.drawing-app-directions {
    font-size: 1.11vw;
    line-height: 2vw;
    padding: 0.5vw 1vw 0.5vw 0;
    margin: 0.5vw 1vw 0.5vw 0;
    font-weight: 400;
    text-transform: none;
    width: auto;
    overflow: auto;
    float: left;
}

.select-mat-frame-drawing-canvas {
    z-index: 10;
}

.drawing-board-direction-font {
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
    color: #72696A;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 32px;
    margin-top: 16px;
}

.drawing-app-main-buttons {
    font-size: 16px;
    line-height: 150%;
    padding: 16px;
    background: #F2F2F2;
    border-radius: 0 !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
}

.drawing-app-saving-status {
    font-size: 12px;
    line-height: 150%;
    margin: -3vw -25vw 0 0;
    float: right;
}
}






