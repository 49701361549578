@media screen and (min-width: 1100px) {

    .design-guide-colour-container {
        width: 100%;
        overflow: auto;
        padding: 40px 0;
    }

    .cart-start-new-design-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        position: static;
        /* left: 34.22%; */
        /* right: 34.22%; */
        top: 5.13%;
        bottom: 92.1%;
        background: #3B3B3B;
        border-radius: 30px;
        /* flex: none; */
        order: 1;
        flex-grow: 0;
        margin: 24px auto;
        color: white;
        width: 190px;
        text-transform: uppercase;
        font-size: 13px;
    }

    .cart-slider-start-designing-button {
        width: 196px;
        overflow: auto;
        margin-left: calc( 50% - 98px);
        margin-bottom: 552px;
    }

    .show-empty-cart {
        /* Body 4 (Bolded) */

        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 24px;
    }

    .custom-design-matboard-sign {
        width: 15px;
        height: 15px;
        position: absolute;
        padding: 2.5px;
        margin-top: 30px;
        margin-left: -20px;
        background: #F2F2F2;
        border: 1px solid #666161;
        border-radius: 2px;
    }

    .cart-slider-ending-main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding-bottom: 16px;
    }

    .cart-slider-ending-subheading {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-bottom: 114px;
    }

    .cart-slider-ending {
        width: 100%;
        overflow: auto;
        padding: 64px 0 300px 0;
        text-align: center;
    }

    .cart-subtotal-strikethrough-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        /* identical to box height, or 36px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .shopping-cart-subtotal-container-large {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        float: right;
        text-align: right;
    }

    .cart-subtotal-strikethrough {
        /* Body */

        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */


        /* Primary/Soft Black */

        color: #3B3B3B;

        opacity: 0.25;
        text-decoration: line-through;
    }

    .discount-info-desc {
        /* Entry Label */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.02em;

        /* Secondary/Green */

        color: #78A891;
    }

    .shopping-cart-checkout-button-thick {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 16px 24px;
        text-align: center;
        float: left;
    }

    .fetch-note-details-container-item-detail {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
    }

    .cart-slider-second-mat-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: initial;
    }

    .custom-mat-design-diagram img {
        width: 288px;
        height: 191.68px;
        background: #F2F2F2;
        border-radius: 2px;
        margin-top: 16px;
    }


    .cart-slider-shopped-item-subheading {
        margin-top: 13px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-slider-second-mat-container {
        width: calc(100% - 64px);
        margin-left: 64px;
        overflow: auto;
        float: left;
    }

    #cart-slider-shopped-item-quantity-button-right {
        text-align: right;
    }

    .cart-slider-shopped-item-quantity {
        float: left;
        width: 50%;
        margin-top: 16px;
        overflow: auto;
    }

    .cart-slider-item-controller {
        width: calc( 100% - 64px);
        margin-left: 64px;
        overflow: auto;
    }

    .cart-slider-shopped-item-quantity-button {
        width: 20px;
        float: left;
        font-size: 30px;
        font-weight: 400;
        text-align: left;
        cursor: pointer;
    }

    .cart-slider-shopped-item-quantity .MuiFormControl-marginNormal {
        margin-top: 0;
        margin-bottom: 0;
    }



    .cart-slider-shopped-item-quantity .select-window-multiple-window-dimensions {
        width: auto;
        overflow: auto;
        float: left;
        margin: 0 8px;
    }

    .cart-slider-shopped-item-quantity input {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3B3B3B;
        padding: 8px;
        width: 45px;
        margin: 3px auto 0;
    }

    .navbar-column-title-checkout-section{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: uppercase;
    }

    .cart-divider-thick {
        width: 640px;
        height: 1px;
        /* left: calc(50% - 640px/2 - 0px); */
        /* bottom: 1564px; */
        background: #3B3B3B;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 10px 0 0 0;
    }

    .cart-subtotal-section {
        width: 100%;
        overflow: auto;
    }

    .cart-subtotal-highlight {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 4px;
    }

    .shopping-cart-subtotal-container {
        text-align: right;
        width: 300px;
        float: right;
        /* margin-top: 30px; */
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        flex: none;
        order: 0;
        flex-grow: 0;
        /* margin: 0px 4px; */
    }

    .shopping-cart-checkout-button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        display: block;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #3F5C6B;
        border-radius: 30px;
        width: 86px;
        padding: 8px 16px;
        text-align: center;
        float: left;
    }

    .discount-tier-info {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3B3B3B;
        margin-top: 10px;
    }

    .cart-slider-shopped-item-container {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-second-mat-item-details {
        width: 70%;
        overflow: auto;
        float: left;
    }

    .cart-slider-second-mat-item-price {
        width: auto;
        overflow: auto;
        float: right;
    }

    .cart-slider-second-mat-item {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .cart-slider-second-mat {
        width: 100%;
        overflow: auto;
    }

    .cart-slider-shopped-item-total {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .cart-slider-shopped-item-center-center {
        width: 320px;
        overflow: auto;
        float: left;
    }

    .delivery-payments-section-shipping-payments-container .cart-slider-second-mat-item-details {
        width: 75%;
    }

    .shipping-cost-container {
        width: 100%;
        overflow-y: auto;
        padding: 8px 0;
    }



    .customer-satisfaction-policy-banner {
        font-weight: 400;
        line-height: 1.5;
        font-size: 14px;
        padding: 0.5vw;
        width: 90%;
        overflow: auto;
        text-align: left;
        margin-left: 1vw;
    }

    .cart-item-count-display {
        /* Header 4 */
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        /* or 36px */
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    .cart-item-count-display-empty-cart {
        /* Header 5 */

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        /* or 19px */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Primary/Soft Black */

        color: #3B3B3B;
        margin-top: 10px;
    }

    .cart-slider-content {
        padding: 0 calc( 50% - 320px);
        width: 640px;
        height: auto;
        overflow-y: auto;
        /*position: absolute;*/
        background: white;
    }

    .cart-slider-top-container {
        width: 100%;
        overflow-y: auto;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        font-weight: 400;
        color: #F59276;
        margin-top: 140px;
    }

    #checkout-cart-slider {
        float: right;
        padding-right: 10px;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    #checkout-arrow-slider {
        float: left;
        margin: 15px 25px;
        width: 28px;
        height: 28px;
    }

    .cart-slider-free-shipping-banner {
        padding: 10px 0;
        width: 100%;
        float: left;
        overflow: auto;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.1em;
        color: #3B3B3B;
    }

    .cart-slider-top-container-divider {
        width: 100%;
        height: 2.5px;
    }

    .cart-slider-top-container-divider-inner-gauge {
        width: 40%;
        height: 2.5px;
        background: #F59276;
    }

    .cart-slider-shopped-item-center {
        width: calc(100% - 64px);
        overflow: auto;
        float: left;
        margin-left: 16px;
    }

    .cart-slider-shopped-item-left {
        width: 48px;
        height: 48px;
        left: 0px;
        top: 0px;
        border: 1px solid #666161;
        box-sizing: border-box;
        border-radius: 2px;
        float: left;
    }

    .cart-slider-shopped-item-right {
        width: 20%;
        float: right;
        text-align: right;
    }

    .cart-slider-shopped-item-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #3B3B3B;
        margin-top: 13px;
        text-transform: uppercase;
    }

    .cart-slider-shopped-item-subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #3B3B3B;
        text-transform: capitalize;
    }

    .cart-slider-shopped-item-cover {
        width: 100%;
        overflow: auto;
        border-bottom: 1px solid rgba(59,59,59, 0.25);
        padding: 24px 0;
    }

    .cart-slider-shopped-item-summary {
        width: 100%;
        padding: 24px 0 0 0;
        overflow: auto;
        border-top: 1.5px black solid;
        margin-top: -0.5px;
    }

    .item-total-coupon-section {
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        font-weight: 500;
    }

    .item-total-title, .item-total-amount {
        width: 49%;
        float: left;
    }

    .item-total-amount {
        text-align: right;
        font-weight: 500;
    }

    #cart-slider-checkout-button {
        text-transform: uppercase;
        padding: 0;
        width: 400px;
        text-align: center;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        color: white;
        background: #F59276;
        font-weight: 400;
        border: solid 0.21vw #f59276;
        /* position: fixed; */
        line-height: 20px;
        font-size: 16px;
        /* bottom: 20px; */
        /* right: 10px; */
        /* margin: 0; */
        margin: 0;
        margin-top: 10px;
    }

}