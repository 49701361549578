

.faq-table-row {
    border: solid #F3F6F6 0.1rem;
    width: 23.5%;
    overflow: auto;
    padding: 16px 6px;
    text-align: center;
    font-weight: 600;
}

.faq-table-row-subtext {
    font-size: 10px;
}

.faq-table-row-number {
    border: solid #F3F6F6 0.1rem;
    padding: 16px 6px;
    text-align: center;
    font-weight: 600;
}

.faq-table-row-size {
    text-align: center;
}

#faq-container a {
    text-decoration: underline;
}

.faq-header {
    font-size: 1.3rem;
    color: #3B3B3B;
    padding-bottom: 1.5rem;
}

.faq-category-header {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #3B3B3B;
}

.faster-shipping-area-expandable {
    width: 100%;
    overflow: auto;
}

.faq-question {
    width: auto;
    overflow: auto;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 1em;
    color: #3B3B3B;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 11px;
    cursor: pointer;
}

.faq-paragraph {
    width: auto;
    overflow: auto;
    font-size: 1rem;
    padding-bottom: 1rem;
    line-height: 2;
}


.faq-static-page-container-heading {
    width: 100%;
    overflow: auto;
    text-align: left;
    /* Header 4 */

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Primary/Soft Black */

    color: #3B3B3B;
    margin-bottom: 28px;



}

.faq-question-close {
    width: 22px;
    margin-left: 10px;
    margin-right: 30px;
    float: left;
}

.faq-question-open {
    width: 22px;
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 5px;
    transform: rotate(180deg);
    float: left;
}

.faq-answer-paragraph {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    color: #3B3B3B;
    margin-top: 16px;
}

.faq-question-right {
    width: calc(100% - 62px);
    float: left;
    overflow: hidden;
}

.faq-answer {
    margin-bottom: 43px;
}

.faq-question-bloc {
    margin-bottom: 64px;
}

.faq-question-right {
    line-height: 150%;
}

/*Mobile, Tablet, Web Half Screen*/


@media screen and (min-width: 0px) and (max-width: 769px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 10% 5rem 10%;
    }
}


@media screen and (min-width: 769px) and (max-width: 1100px) {
    .faq-table-row-number {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .faq-table-row-size {
        text-align: center;
    }
}

/*Normal Laptop*/

@media screen and (min-width: 1100px) and (max-width: 1450px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}

/*External Monitor*/

@media screen and (min-width: 1450px) and (max-width: 1920px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}

/*4K, UHD Monitors*/

@media screen and (min-width: 1920px) {
    #faq-container {
        width: auto;
        overflow: auto;
        padding: 15vh 40% 5rem 15%;
        font-size: 12px;
    }
}