/*For all*/

@media screen and (min-width: 0px) {

    .lazyload-wrapper {
        overflow: hidden;
    }

    .select-box-outside {
        width: 24px;
        height: 24px;
        left: 12px;
        top: 12px;
        background: #FFFFFF;
        border: 1px solid #3B3B3B;
        border-radius: 2px;
        padding: 0 !important;
    }

    .box-selected {
        width: 16px;
        height: 16px;
        background: #3b3b3b;
        border-radius: 2px;
        margin: 4px;
    }

    .box-unselected {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        margin: 4px;
    }

    /*a:hover {*/
    /*    text-decoration: none !important;*/
    /*}*/

    .MuiInput-underline:before {
        border-bottom: none !important;
    }


    .cart-slider-item-container {
        width: 100%;
        overflow: auto;
    }

    .cart-subtotal-strikethrough, .cart-subtotal-strikethrough-large {
        text-decoration: line-through;
        opacity: 0.25;
    }



    .cart-slider-shopped-item-quantity span {
        margin: 0 0.5vw;
        cursor: pointer;
    }

    .navbar-position-fixed {
        position: fixed;
    }



    .cart-slider-shopped-item-matboard-colour {
        width: 18px;
        height: 18px;
        margin: -4px 6px;
    }

    .shipping-summary-header {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
        width: 50%;
        overflow: auto;
        float: left;
    }

    .shipping-summary-header-total {
        font-size: 24px;
        line-height: 150%;
        color: #3B3B3B;
    }

    .shipping-summary-amount {
        width: 50%;
        overflow: auto;
        float: right;
        text-align: right;
        font-size: 16px;
        line-height: 150%;
        font-style: normal;
        font-weight: bold;
    }

    .shipping-summary-header-total {
        width: 50%;
        overflow: auto;
        float: left;
        text-align: left;
    }

    .shipping-summary-amount-total {
        width: 50%;
        overflow: auto;
        float: left;
        text-align: right;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        text-align: right;
        color: #3B3B3B;
    }

    .shipping-summary-add-margin {

    }

    .shipping-summary-add-bottom-line {
        border-bottom: 1px solid #F2F2F4;
        padding-bottom: 0.5vw;
        margin-bottom: 0.5vw;
    }

}

