.workflow-order-container {
    width: 100%;
    overflow: auto;
    border-bottom: solid 1px black;
    margin-bottom: 2rem;
    line-height: 2;

}

.workflow-order-container-overall-info {
    width: 35%;
    overflow: auto;
    float: left;
}

.workflow-order-container-order-items {
    width: 30%;
    overflow: auto;
    float: left;
}

.workflow-order-container-order-leader {

}

.workflow-section-title {
    font-weight: 600;
    font-size: 1.3rem;
}

.workflow-section-subtitle {
    margin-right: 0.5rem;
    font-weight: 500;
}

.workflow-section .select-mat-rendering {
    position: absolute !important;
}

.workflow-section .select-mat-rendering {
    width: 50%;
    position: fixed;
    float: left;
    overflow: auto;
    margin-left: 0 !important;
}

.workflow-order-container-pricing {

}

.workflow-order-container-primary-matting {

}

.workflow-order-container-primary-window {

}

.workflow-order-container-primary-secondary {

}

.workflow-status-change-button {
text-align: center;
width: 80%;
overflow: auto;
margin-right: 20%;
border: solid 0.1rem;
margin: 1rem 0;
cursor: pointer;
padding: 0.5rem 0;
}