@import "navBarTop/navBarTop.css";
@import "navBarTop/navBarTopMobile.css";
@import "navBarTop/navBarTopTablet.css";
@import "navBarTop/navBarTopWeb.css";
@import "landingPage/landingPage.css";
@import "landingPage/landingPageMobile.css";
@import "landingPage/landingPageTablet.css";
@import "landingPage/landingPageWeb.css";
@import "landingPage/landingPageV2.0.css";
@import "canvasApp/canvasApp.css";
@import "navBarBottom/navBarBottom.css";
@import "howItWorks/howItWorks.css";
@import "whatWeDo/whatWeDo.css";
@import "customerReview/customerReview.css";
@import "payments/payments.css";
@import "payments/paymentsMobile.css";
@import "payments/paymentsTablet.css";
@import "payments/paymentsWeb.css";
@import "payments/orderSummary.css";
@import "elements/buttonElements.css";
@import "confirmationApp/confirmation.css";
@import "subSections/userPolicies.css";
@import "orderChanges/orderChanges.css";
@import "workflow/workflow.css";
@import "gallery/theGallery.css";
@import "gallery/theGalleryWeb.css";
@import "gallery/theGalleryTablet.css";
@import "gallery/theGalleryMobile.css";
@import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
@import "../stylesheets/faq/faq.css";
@import "../stylesheets/region/region.css";
@import "../stylesheets/landingPage/landingSection.css";
@import "../stylesheets/productOffering/productOffering.css";
@import "../stylesheets/selectMatApp/selectMat.css";
@import "../stylesheets/selectMatApp/selectMatMobile.css";
@import "../stylesheets/selectMatApp/selectMatTablet.css";
@import "../stylesheets/selectMatApp/selectMatWeb.css";
@import "../stylesheets/selectFrame/selectFrame.css";
@import "../stylesheets/photoCropping/photoCropping.css";
@import "../stylesheets/cart/cartSlider.css";
@import "../stylesheets/cart/cartSliderMobile.css";
@import "../stylesheets/cart/cartSliderTablet.css";
@import "../stylesheets/cart/cartSliderWeb.css";
@import "../stylesheets/satisfaction/satisfaction.css";
@import "../stylesheets/blog2.0/blog2.0.css";

a {
    cursor: pointer;
}

.application-container {
    width: 100%;
}

#pay-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 193px;
    height: 52px;
    background: #3B3B3B;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    flex: none;
    order: 2;
    flex-grow: 0;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    margin: 15px calc(50% - 100px);
}

