


@media screen and (min-width: 1100px) {
    .customer-satisfaction-section {
        position: absolute;
        margin-top: -60vh;
        width: 65vw;
        margin-left: 15vw;
        margin-right: 15vw;
        text-align: center;
        background: #F9F9F9;
        text-align: center;
        color: #72696A;
    }

    .customer-satisfaction-container {
        width: auto;
        margin: 1.67vw 1.81vw;
    }

    .customer-satisfaction-image {
        width: 100%;
        overflow-y: auto;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        font-size: 1.39vw;
        letter-spacing: 0.03vw;
        font-weight: 600;
        line-height: 1.67vw;
        color: #72696A;
    }

    .customer-satisfaction-subtitle {
        font-size: 1.11vw;
        line-height: 1.74vw;
        color: #72696A;
        text-align: center;
        margin: 0 0 3.96vw 0;
        font-weight: 400;
    }
}

@media screen and (max-width: 1100px) and (min-width: 450px) {
    .customer-satisfaction-section {
        position: absolute;
        margin-top: -35vh;
        width: 80vw;
        margin-left: 7.5vw;
        margin-right: 7.5vw;
        text-align: center;
        background: #F9F9F9;
        color: #72696A;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        letter-spacing: 0.03vw;
        font-weight: 600;
        color: #72696A;
        width: auto;
        font-size: 2.597vw;
        text-align: center;
        margin: 0 0 3.117vw 0;
        line-height: 3.117vw;
    }

    .customer-satisfaction-subtitle {
        color: #72696A;
        text-align: center;
        font-weight: 400;
        line-height: 3.247vw;
        margin: 0 0 5.195vw 0;
        font-size: 2.0779vw;
    }

    .customer-satisfaction-container {
        width: auto;
    }

    .customer-satisfaction-image {
        width: 100%;
        overflow-y: auto;
    }

}

@media screen and (max-width: 450px) {


    .customer-satisfaction-section {
        position: absolute;
        margin-top: -30vh;
        width: 95vw;
        padding: 2.5vw;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
        background: #F9F9F9;
        color: #72696A;
    }

    .customer-satisfaction-container {
        width: auto;
        margin: 0;
    }

    .customer-satisfaction-image {
        width: 180%;
        margin-left: -40%;
        overflow-y: auto;
        margin-top: 12.5vw;
        margin-bottom: 12.5vw;
    }

    .customer-satisfaction-title {
        padding: 5vw 0 0 0;
        letter-spacing: 0.03vw;
        font-weight: 600;
        margin: 0 0 3.117vw 0;
        text-align: center;
        color: #72696A;
        font-size: 6.25vw;
        line-height: 7.8125vw;
    }

    .customer-satisfaction-subtitle {
        color: #72696A;
        text-align: center;
        margin: 0 0 5.195vw 0;
        font-weight: 400;
        font-size: 5vw;
        line-height: 7.8125vw;
    }
}